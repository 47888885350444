import arka from "../assets/images/arka/arka-text.svg";
import home from "./icons/home.svg";
import home_a from "./icons/home-a.svg";
import admin from "./icons/admin.svg";
import admin_a from "./icons/admin-a.svg";
import user from "./icons/user.svg";
import user_a from "./icons/user-a.svg";
import adaption from "./icons/adaption.svg";
import adaption_a from "./icons/adaption-a.svg";
import cost from "./icons/cost.svg";
import cost_a from "./icons/cost-a.svg";
import doc from "./icons/doc.svg";
import doc_a from "./icons/doc-a.svg";
import analytic from "./icons/analytic.svg";
import analytic_a from "./icons/analytic-a.svg";
import settings from "./icons/settings.svg";
import settings_a from "./icons/settings_active.svg";
import asset_icon from './icons/approval.svg'
import asset_icon_white from './icons/approval_white.svg'

const data = [
  {
    title: "Home",
    path: "/home",
    rootpath: "/",
    iconHeight: "18px",
    inactiveIcon: home,
    activeIcon: home_a,
  },
  {
    title: "Interface",
    path: "/interface",
    iconWidth: "38px",
    inactiveIcon: arka,
    activeIcon: arka,
  },
  {
    title: "Adaption Details",
    path: "/adaption-detail",
    inactiveIcon: adaption,
    activeIcon: adaption_a,
  },
  {
    title: "Document Management",
    path: "/document-management",
    inactiveIcon: doc,
    activeIcon: doc_a,
  },
  {
    title: "Integration Settings",
    path: "/integration-settings",
    inactiveIcon: settings,
    activeIcon: settings_a,
  },
  // {
  //   activeIcon: asset_icon_white,
  //   inactiveIcon: asset_icon,
  //   path: "/asset-approvals",
  //   title: "Asset Approvals",
  // },
  //
  {
    title: "Cost Management",
    path: "/cost-management",
    inactiveIcon: cost,
    activeIcon: cost_a,
  },
  {
    title: "Analytics",
    path: "/analytics",
    inactiveIcon: analytic,
    activeIcon: analytic_a,
  },
  {
    title: "User Management",
    path: "/user-management",
    inactiveIcon: user,
    activeIcon: user_a,
  },
  {
    title: "Administration",
    path: "/administration",
    inactiveIcon: admin,
    activeIcon: admin_a,
  },
];

export default data;
