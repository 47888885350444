import { useState } from "react";
import PropTypes from "prop-types";
import { Row, Col, Table } from "reactstrap";
import validator from "validator";
import styled from "styled-components";
import TitleInput from "../../../components/TitleInput";
import { colors } from "../../../styles/theme";
import { CustomImg, MiButton, NotoP } from "../../../styles/common";
import TitleInputGroup from "../../../components/TitleInputGroup";
import FormSpinner from "../../../components/FormSpinner";
import { API, PATH } from "../../../services/Axios";
import { notify } from "../../../utils";
import MyCheckbox from "../../../components/MyCheckbox";
import styles from '../Administration.module.css'
import edit from "../../../assets/icons/edit.svg";
import deleteImg from "../../../assets/icons/delete.svg";
import BtnSpinner from '../../../components/BtnSpinner';
import { useEffect } from "react";

const initialOne = {
  firstName: "",
  lastName: "",
  phone: "",
  phoneExt: "",
  fax: "",
  mobileNumber: "",
  emailAddress: "",
  designation: "",
  active: true
};


function ContactDetails({ user_data }) {
  const [loading, setLoading] = useState(false);

  const [showError, setShowError] = useState(false);
  const [phoneCode, setPhoneCode] = useState("+91");
  const [mobileCode, setMobileCode] = useState("+91");
  const [contactOneData, setContactOneData] = useState(initialOne);
  // const [isActive, setIsActive] = useState(true);
  const { firstName, lastName, phone, phoneExt, mobileNumber, fax, designation, emailAddress } = contactOneData;
  const [data, setData] = useState([]);
  const [isEdit, setIsEdit] = useState(null);

  const getCustomerContacts = async () => {
    const result = await API.post(
      PATH.getCustomerContacts,
      {},
      user_data.token
    );
    setData(result);
  };

  useEffect(() => {
    getCustomerContacts();
  }, [user_data]);

  const handleClear = () => {
    setContactOneData({
      firstName: "",
      lastName: "",
      phone: "",
      phoneExt: "",
      fax: "",
      mobileNumber: "",
      emailAddress: "",
      designation: "",
      active: true
    });
    setPhoneCode('+91');
    setMobileCode('+91');
    setIsEdit(null);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setContactOneData((prev) => ({ ...prev, [name]: value }));
  };

  const handleAddData = async () => {
    const conditions =
      firstName.length > 0 &&
      lastName.length > 0 &&
      validator.isAlpha(designation) &&
      validator.isEmail(emailAddress);

    if (conditions) {
      setShowError(false);
      const newData = {
        contactId: null,
        firstName: firstName,
        lastName: lastName,
        phone: phone,
        phoneExt: phoneExt,
        fax: fax,
        mobileNumber: mobileNumber,
        emailAddress: emailAddress,
        designation: designation,
        countryCode: phoneCode,
        mobileCountryCode: mobileCode
      };
      console.log(newData);

      setLoading(true);
      try {
        const result = await API.post(
          PATH.addUpdateUserContacts,
          newData,
          user_data.token
        );
        console.log(result);
        if (result) {
          notify("Customer Contact Details Added", true);
          getCustomerContacts();
          handleClear();
        } else {
          notify(
            "Customer Contacts not updated. Please send an email to admin@ideafuse.ai or create an issue using the Create Issue Screen"
          );
        }
        setLoading(false);
      } catch (err) {
        console.log(err);
        notify(
          "Customer Contacts not updated. Please send an email to admin@ideafuse.ai or create an issue using the Create Issue Screen"
        );
        setLoading(false);
      }
    } else {
      setShowError(true);
    }
  };

  const handleUpdateData = async () => {
    const conditions =
      firstName.length > 0 &&
      lastName.length > 0 &&
      validator.isAlpha(designation) &&
      validator.isEmail(emailAddress);

    if (conditions) {
      setShowError(false);
      const newData = {
        contactId: isEdit.id,
        firstName: firstName,
        lastName: lastName,
        phone: phone,
        phoneExt: phoneExt,
        fax: fax,
        mobileNumber: mobileNumber,
        emailAddress: emailAddress,
        designation: designation,
        countryCode: phoneCode,
        mobileCountryCode: mobileCode
      };
      console.log(newData);

      setLoading(true);
      try {
        const result = await API.post(
          PATH.addUpdateUserContacts,
          newData,
          user_data.token
        );
        console.log(result);
        if (result) {
          notify("Customer Contact Details Updated", true);
          getCustomerContacts();
          handleClear();
        } else {
          notify(
            "Customer Contacts not updated. Please send an email to email to admin@ideafuse.ai or create an issue using the Create Issue Screen"
          );
        }
        setLoading(false);
      } catch (err) {
        console.log(err);
        notify(
          "Customer Contacts not updated. Please send an email to email to admin@ideafuse.ai or create an issue using the Create Issue Screen"
        );
        setLoading(false);
      }
    } else {
      setShowError(true);
    }
  };

  const handleEdit = (item) => {
    setContactOneData(item);
    setPhoneCode(item.countryCode || '+91');
    setMobileCode(item.mobileCountryCode || '+91');
    setIsEdit({ id: item.contactId, isEdit: true });
  };

  const handleDelete = async (item) => {
    const contactId = {
      contactId: item.contactId
    };

    try {
      const result = await API.post(
        PATH.deleteUserContacts,
        contactId,
        user_data.token
      );
      console.log(result);
      if (result) {
        notify("Customer Contact Details deleted", true);
        getCustomerContacts();
      } else {
        notify(
          "Customer Contact Details not deleted. Please send an email to admin@ideafuse.ai or create an issue using the Create Issue Screen"
        );
      }
      // setLoading(false);
    } catch (err) {
      console.log(err);
      notify(
        "Customer Contact Details not deleted. Please send an email to admin@ideafuse.ai or create an issue using the Create Issue Screen"
      );
      // setLoading(false);
    }
  };

  return (
    <Row>
      {loading && <FormSpinner />}

      <Col xs={12} sm={12} md={12} lg={6} className="mx-auto">
        <Row>
          <Col md={12} lg={6}>
            <TitleInput
              label="First name"
              name="firstName"
              placeholder="Enter Your First Name"
              value={firstName}
              onChange={handleChange}
              showerr={showError}
              errmsg="First name contains only letters (a-zA-Z)"
              valid={validator.isAlpha(firstName)}
            />
          </Col>
          <Col md={12} lg={6}>
            <TitleInput
              label="Last name"
              name="lastName"
              placeholder="Enter Your Last Name"
              value={lastName}
              onChange={handleChange}
              showerr={showError}
              errmsg="Last name contains only letters (a-zA-Z)"
              valid={validator.isAlpha(lastName)}
            />
          </Col>
        </Row>

        <Row>
          <Col md={12} lg={6}>
            <TitleInputGroup
              label="Phone Number"
              name="phone"
              placeholder="Enter Your Mobile Number"
              code={phoneCode}
              handleCode={setPhoneCode}
              value={phone}
              onChange={handleChange}
              showerr={showError}
              errmsg="Invalid Phone number"
              valid={validator.isMobilePhone(phone)}
            />
          </Col>
          <Col md={12} lg={6}>
            <TitleInput
              label="Phone Ext"
              name="phoneExt"
              placeholder="Enter Your Phone Ext"
              value={phoneExt}
              required={false}
              onChange={handleChange}
              showerr={showError}
              errmsg="Invalid Phone Ext"
              valid={validator.isNumeric(phoneExt)}
            />
          </Col>
        </Row>

        <Row>
          <Col md={12} lg={6}>
            <TitleInputGroup
              label="Mobile Number"
              required={false}
              name="mobileNumber"
              placeholder="Enter Your Mobile Number"
              code={mobileCode}
              handleCode={setMobileCode}
              value={mobileNumber}
              onChange={handleChange}
              showerr={showError}
              errmsg="Invalid Mobile number"
              valid={validator.isMobilePhone(mobileNumber)}
            />
          </Col>
          <Col md={12} lg={6}>
            <TitleInput
              required={false}
              label="Fax Number"
              name="fax"
              placeholder="Enter Your Fax Number"
              value={fax}
              onChange={handleChange}
              showerr={showError}
              errmsg="Invalid fax number"
              valid={validator.isNumeric(fax)}
            />
          </Col>
        </Row>

        <TitleInput
          label="Designation"
          name="designation"
          placeholder="Enter Designation"
          value={designation}
          onChange={handleChange}
          showerr={showError}
          errmsg="Invalid designation"
          valid={validator.isAlpha(designation)}
        />
        <TitleInput
          label="Email ID"
          name="emailAddress"
          placeholder="Enter Your Email Address"
          value={emailAddress}
          onChange={handleChange}
          showerr={showError}
          errmsg="Invalid email"
          valid={validator.isEmail(emailAddress)}
        />

        <div className="d-flex gap-3">
          <button
            onClick={isEdit?.isEdit ? handleUpdateData : handleAddData}
            className='saveBtn mt-4'
          >
            {loading && <BtnSpinner />}
            {isEdit?.isEdit ? 'Update' : 'Save'}
          </button>
          {isEdit?.isEdit &&
            <button
              onClick={() => handleClear()}
              className='cancelBtn mt-4'
            >
              Cancel
            </button>
          }
        </div>
      </Col>

      <Col sm={10} className='mx-auto'>
        <div
          style={{ maxHeight: "60vh" }}
          className="mt-4 position-relative overflow-auto custom-scrollbar"
        >
          <Table striped borderless>
            <thead className={styles.thead}>
              <tr>
                <th className="text-white">First name</th>
                <th className="text-white">Last name</th>
                <th className="text-white">Phone Number</th>
                <th className="text-white">Phone Ext</th>
                <th className="text-white">Mobile Number</th>
                <th className="text-white">Fax Number</th>
                <th className="text-white">Designation</th>
                <th className="text-white">Email ID</th>
                <th className="text-white">Edit / Delete</th>
              </tr>
            </thead>
            <tbody>
              {data?.map((item, index) => (
                <>
                  <tr style={{
                    backgroundColor: index % 2 === 0
                      ? "rgba(37, 65, 178, 0.1)"
                      : colors.blank, height: "30px"
                  }}>
                    <td>{item.firstName}</td>
                    <td>{item.lastName}</td>
                    <td>
                      {item?.countryCode}
                      {item.phone}
                    </td>
                    <td>{item.phoneExt || '--'}</td>
                    <td>
                      {item?.mobileCountryCode || ''}
                      {item.mobileNumber || '--'}
                    </td>
                    <td>{item.fax || '--'}</td>
                    <td>{item.designation}</td>
                    <td>{item.emailAddress}</td>
                    <td className='d-flex gap-3 justify-content-center'>
                      <CustomImg
                        style={{ cursor: 'pointer' }}
                        onClick={() => handleEdit(item)} src={edit} w="16px" h="16px" />
                      <CustomImg
                        style={{ cursor: 'pointer' }}
                        onClick={() => handleDelete(item)}
                        src={deleteImg}
                        w="16px" h="16px" />
                    </td>
                  </tr>
                </>
              ))}
            </tbody>
          </Table>
        </div>
      </Col>
    </Row >
  );
}

const CheckboxContainer = styled.div`
  display: block;
  position: relative;
  padding-left: 35px;
  margin-bottom: 12px;
  cursor: pointer;
  font-size: 22px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;

  font-family: "Noto Sans", "Noto Sans JP", sans-serif;
  font-size: 14px;
  color: #03256c;
  font-weight: 600;
  /* identical to box height */

  /* primary-color */

  color: #03256c;

  input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
  }
  .checkmark {
    position: absolute;
    top: 0;
    left: 0;
    height: 22px;
    width: 22px;
    border-radius: 3px;
    border: 2px solid;
  }

  input:checked ~ .checkmark {
    background-color: #03256c;
    border-radius: 3px;
    border: 2px solid;
  }
  .checkmark:after {
    left: 6px;
    top: 2px;
    width: 6px;
    height: 12px;
    border: solid white;
    border-width: 0 3px 3px 0;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
  }

  /* Create the checkmark/indicator (hidden when not checked) */
  .checkmark:after {
    content: "";
    position: absolute;
    display: none;
  }

  input:checked ~ .checkmark:after {
    display: block;
  }
`;

const Title = styled.h3`
  font-family: "Noto Sans", sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: ${({ size }) => (size ? size : 18)}px;
  line-height: ${({ lineHeight }) => (lineHeight ? lineHeight : 20)}px;
  margin-left: 1em;
  margin-top: 1em;
  text-align: center;

  /* secondary-color */

  color: #2541b2;
  white-space: nowrap;
  @media (min-width: 320px) and (max-width: 480px) and (orientation: portrait) {
    margin-top: 1.5em;
    font-size: 15px;
    line-height: 15px;
  }
`;

ContactDetails.propTypes = {
  user_data: PropTypes.object,
};

export default ContactDetails;
