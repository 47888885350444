import { useState, useEffect } from "react";
import { Row, Col, Container, Table } from "reactstrap";
import { useSelector } from "react-redux";
import styles from "../Adaption.module.css";
import DocThresholdRow from "../../../components/DocThresholdRow";
import NotFoundMsg from "../../../components/NotFoundMsg";
import { colors } from "../../../styles/theme";
import { API, PATH } from "../../../services/Axios";
import { notify } from "../../../utils";
import BtnSpinner from "../../../components/BtnSpinner";

const tempData = [
  {
    docType: "Production Log",
    docFormat: "Formato-1",
    docSysThreshold: 60,
    docCustThreshold: 70,
    docEnabled: true,
  },
  {
    docType: "Production Log 2",
    docFormat: "Formato-2",
    docSysThreshold: 60,
    docCustThreshold: 70,
    docEnabled: false,
  },
  {
    docType: "Production Log 3",
    docFormat: "Formato-3",
    docSysThreshold: 60,
    docCustThreshold: 70,
    docEnabled: false,
  },
];

export default function DocumentThresholds() {
  const { user_data } = useSelector((state) => ({
    user_data: state.auth.user_data,
  }));

  const [loading, setLoading] = useState(false);
  const [thresholdData, setThresholdData] = useState([]);
  const [showError, setShowError] = useState(false);

  const getDocumentThresholds = async () => {
    let data = {
      CustId: user_data?.customerInfo?.custId,
    };
    setLoading(true);
    try {
      const result = await API.post(
        PATH.documentThresholds,
        data,
        user_data.token
      );
      if (result) {
        setThresholdData(result);
      }
      setLoading(false);
    } catch (err) {
      console.log(err);
      setLoading(false);
    }
  };

  useEffect(() => {
    getDocumentThresholds();
  }, []);

  const generateThresholdArr = () => {
    const newArr = [];
    thresholdData.forEach((item) => {
      const data = {
        DocTypeName: item.docTypeName,
        CustomThreshold: item.customThreshold,
        DocTypeAnalyticsKey: item.analyticsKey,
        DocTypeEnabled: item.enabled,
      };
      newArr.push(data);
    });

    return newArr;
  };

  const handleSave = async () => {
    const updatedThresholdData = generateThresholdArr();
    // for (const item of updatedThresholdData) {
    //   if (!item.CustomThreshold) {
    //     notify("Enter a Valid custom threshold value");
    //     return;
    //   }
    // }

    const data = {
      CustId: user_data?.customerInfo?.custId,
      LoginId: user_data?.userInfo?.userEmail,
      LstItems: updatedThresholdData,
    };
    setLoading(true);
    try {
      const result = await API.post(
        PATH.updateThresholds,
        data,
        user_data.token
      );
      console.log(result);
      if (result) {
        notify("Updated successfully.", true);
      } else {
        notify("Update failed.");
      }
      setLoading(false);
    } catch (err) {
      console.log(err);
      notify("Update failed.");
      setLoading(false);
    }
  };

  return (
    <Container fluid>
      <Row className="mt-5 ps-md-4 pe-md-2">
        <Col xs={12} sm={12} md={12}>
          {thresholdData.length > 0 ? (
            <>
              <div className="d-flex justify-content-end">
                <button
                  disabled={loading}
                  onClick={handleSave}
                  className={styles.savebtn}
                >
                  {loading && <BtnSpinner />}
                  Save
                </button>
              </div>
              <div
                style={{ maxHeight: "60vh" }}
                className="mt-4 position-relative overflow-auto custom-scrollbar"
              >
                <Table striped borderless>
                  <thead className={styles.thead}>
                    <tr>
                      <th className="text-white">Document Type</th>
                      <th className="text-white">System Threshold(%)</th>
                      <th className="text-white">Custom Threshold(%)</th>
                      <th className="text-white">Analytics</th>
                      <th className="text-white">Enabled</th>
                    </tr>
                  </thead>
                  <tbody>
                    {thresholdData.map((item, index) => (
                      <DocThresholdRow
                        key={index}
                        bgColor={
                          index % 2 === 0
                            ? "rgba(37, 65, 178, 0.1)"
                            : colors.blank
                        }
                        item={item}
                      />
                    ))}
                  </tbody>
                </Table>
              </div>
            </>
          ) : (
            <>
              {loading ? <NotFoundMsg msg="Please wait..." /> : <NotFoundMsg />}
            </>
          )}
        </Col>
      </Row>
    </Container>
  );
}
