import PropTypes from "prop-types";
import { NavItem, NavLink } from "reactstrap";
import styled, { css } from "styled-components";

const TabNavItem = styled(NavItem)`
  cursor: pointer;
  position: relative;
`;

const TabNavLink = styled(NavLink)`
  font-family: Noto Sans;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 20px;

  height: 40px;
  min-width: 9.5vw;
  border-radius: 10px !important;
  /* identical to box height */

  display: flex;
  align-items: center;
  text-align: center;
  justify-content: center;
  

  /* primary-color */

  color: #7889ad;

  isolation: isolate;
  border: none !important;

  padding: 0.5rem 1.5rem;

  &:hover,
  &:focus-visible {
    outline: none !important;
    color: #03256c;
    border: none !important;
  }

  ${({ isactive }) =>
    isactive &&
    css`
      color: #ffffff !important;
      background-color: #03256c !important;
      border: none !important;
      border-color: transparent;
    `}

  @media (max-width: 768px) {
    font-size: 13px;
    line-height: 16px;
  }
`;

const MyTab = ({ onClick, isActive, title }) => {
  return (
    <TabNavItem>
      <TabNavLink isactive={isActive} onClick={onClick}>
        {title}
      </TabNavLink>
    </TabNavItem>
  );
};

MyTab.propTypes = {
  onClick: PropTypes.func,
  isActive: PropTypes.bool,
  title: PropTypes.string,
};

export default MyTab;
