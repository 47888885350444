import { useState, useEffect } from "react";
import { Container, Row, Col, Table } from "reactstrap";
import classnames from "classnames";
import { useSelector } from "react-redux";
import moment from "moment";
import DatePicker from "react-datepicker";
import styles from "../Issue.module.css";
import { CustomImg, ElementPos, NotoP } from "../../../styles/common";
import { colors } from "../../../styles/theme";
// import searchIcon from "../../../assets/icons/search.svg";

import NotFoundMsg from "../../../components/NotFoundMsg";
import { API, PATH } from "../../../services/Axios";
import BtnSpinner from "../../../components/BtnSpinner";

// import searchBlue from "../../../assets/icons/search-blue.svg";
import searchW from "../../../assets/icons/search-w.svg";
import IssueCollapseableSupport from '../../../components/IssueCollapseableSupport';
import { store } from "react-notifications-component";

export default function SupportDashboard() {
  const { user_data } = useSelector((state) => ({
    user_data: state.auth.user_data,
  }));

  const [loading, setLoading] = useState(false);

  const startOfMonth = moment().startOf("month").format("YYYY-MM-DD hh:mm");
  const endOfMonth = moment().endOf("month").format("YYYY-MM-DD hh:mm");

  const [startDate, setStartDate] = useState(moment(startOfMonth).toDate());
  const [endDate, setEndDate] = useState(moment(endOfMonth).toDate());
  const [issueNumber, setIssueNumber] = useState("");
  const [search, setSearch] = useState([]);
  const [searchId, setSearchId] = useState("");

  const [issueList, setIssueList] = useState([]);

  useEffect(() => {
    getCustomerList();
  }, [])

  const getCustomerList = async () => {
    const result = await API.post(
      PATH.getCustomerList,
      '',
      user_data.token
    );
    setSearch(result);
  }

  const handleSearch = async () => {
    console.log(searchId);
    if (searchId) {
      const data = {
        // CustId: user_data?.customerInfo?.custId,
        CustId: parseInt(searchId),
        FromDate: moment(startDate).format("YYYY-MM-DD"),
        ToDate: moment(endDate).format("YYYY-MM-DD"),
        CustIssueId: issueNumber,
        // CustIssueId: "ARKA-20210901-19",
      };
      // console.log(data);
      setLoading(true);
      try {
        const result = await API.post(PATH.issueList, data, user_data.token);
        console.log(result);
        if (result?.lstIssueSummary) {
          setIssueList(result.lstIssueSummary);
        }
        setLoading(false);
      } catch (err) {
        console.log(err);
        setLoading(false);
      }
    }
    else {
      store.addNotification({
        message: 'Please select client first',
        type: "warning",
        insert: "bottom",
        container: "top-right",
        animationIn: ["animated", "fadeIn"],
        animationOut: ["animated", "fadeOut"],
        dismiss: {
          duration: 3000,
          onScreen: true,
          pauseOnHover: true,
        },
      });
    }

  };

  return (
    <Container fluid>
      <Row className="mt-5">
        <Col md={12} className="px-md-5">
          <div className="d-flex align-items-end justify-content-center mb-4">
            <div className="mt-2 mt-md-0 me-md-4 me-2">
              <div className="position-relative">
                <select
                  className={styles.searchbar}
                  id="search"
                  name="search"
                  type="text"
                  autoComplete="off"
                  onChange={(e) => setSearchId(e.target.value)}
                >
                  <option value="">Select</option>
                  {search &&
                    search.map(({ custId, firstName, lastName, companyName }) => (<option key={custId} value={custId}>{firstName} {lastName}</option>))
                  }

                </select>
              </div>
            </div>
          </div>
        </Col>
        <Col md={12} className="px-md-5">

          <div className="d-flex align-items-end justify-content-between flex-wrap">
            <div className="d-flex align-items-end flex-wrap">
              <div className="mt-2 mt-md-0 me-md-4 me-2">

              </div>
              <div className="mt-2 mt-md-0 me-md-4 me-2">
                <NotoP fs="12px" lh="19px" fw="600" c={colors.primary}>
                  From Date
                </NotoP>
                <DatePicker
                  selected={startDate}
                  onChange={(date) => setStartDate(date)}
                  //minDate={new Date()}
                  maxDate={endDate}
                  customInput={
                    <button className={styles.datebtn}>
                      {moment(startDate).format("MM/DD/YYYY")}
                    </button>
                  }
                />
              </div>
              <div className="mt-2 mt-md-0 me-md-4 me-2">
                <NotoP fs="12px" lh="19px" fw="600" c={colors.primary}>
                  To Date
                </NotoP>
                <DatePicker
                  selected={endDate}
                  onChange={(date) => setEndDate(date)}
                  minDate={startDate}
                  //maxDate={endDate}
                  customInput={
                    <button className={styles.datebtn}>
                      {moment(endDate).format("MM/DD/YYYY")}
                    </button>
                  }
                />
              </div>
              <div className="mt-2 mt-md-0">
                <NotoP fs="12px" lh="19px" fw="600" c={colors.primary}>
                  Issue Number
                </NotoP>
                <input
                  className={styles.textbtn}
                  placeholder="Issue No..."
                  value={issueNumber}
                  onChange={(e) => setIssueNumber(e.target.value)}
                />
              </div>
            </div>
            <button
              disabled={loading}
              onClick={handleSearch}
              className={classnames(styles.searchbtn, "mt-2 mt-md-0")}
            >
              {loading ? (
                <BtnSpinner />
              ) : (
                <CustomImg src={searchW} w="20px" h="20px" mr="10px" />
              )}
              Search
            </button>

          </div>
          {/* TABLES */}
          {issueList.length > 0 ? (
            <Table borderless responsive className="mt-4">
              <thead>
                <tr>
                  <th className={classnames(styles.issuetd, "ps-3 w-25")}>
                    Creation Date
                  </th>
                  <th className={styles.issuetd}>Issue Number</th>
                  <th>Issue Details</th>
                  <th>Status</th>
                </tr>
              </thead>
              <tbody>
                {issueList.map((item, index) => (
                  <IssueCollapseableSupport
                    key={item.issueId}
                    bgColor={
                      index % 2 === 0 ? "rgba(37, 65, 178, 0.1)" : colors.white
                    }
                    item={item}
                    user_data={user_data}
                    searchId={searchId}
                  />
                ))}
              </tbody>
            </Table>
          ) : (
            <>
              {loading ? <NotFoundMsg msg="Please wait..." /> : <NotFoundMsg />}
            </>
          )}
        </Col>
      </Row>
    </Container>
  );
}
