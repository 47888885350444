import React, { useState } from "react";
import cls from "classnames";
import { Container, Row, Col } from "reactstrap";
import styles from "./landing-catalog.module.css";
import InfoModal from "../info-modal";

import aiwImg from "../../assets/images/landing/ai-w.svg";
import mlImg from "../../assets/images/landing/ml.svg";
import nlpImg from "../../assets/images/landing/nlp.svg";
import caiImg from "../../assets/images/landing/c-ai.svg";
import ctImg from "../../assets/images/landing/c-tools.svg";
import daImg from "../../assets/images/landing/data-a.svg";
import dlsImg from "../../assets/images/landing/dls.svg";

const catalogData = [
  {
    id: 1,
    pic: aiwImg,
    title: "AI Enabled workshop as a service",
    data: [
      "Business Problem statement determination",
      "ROI analysis",
      "Shortlist and evaluation of solution(s)",
    ],
  },
  {
    id: 2,
    pic: mlImg,
    title: "Machine Learning",
    data: [
      "Alignment with business foundation and decision-making process",
      "Develop end to end machine learning solutions to produce defined business outcomes and ROI",
    ],
  },
  {
    id: 3,
    pic: nlpImg,
    title: "Natural Language Processing",
    data: [
      "Semantic Search",
      "Machine Learning",
      "Speech Recognition",
      "Information extraction and translation",
      "Control Spamming",
      "Emotional Meaning",
      "Summarization",
      "Question Answering",
    ],
  },
  {
    id: 4,
    pic: caiImg,
    title: "Custom AI Powered Solutions",
    data: [
      "Configure and customize existing AI based solutions",
      "Building AI solutions from scratch using existing libraries or new",
    ],
  },
  {
    id: 5,
    pic: ctImg,
    title: "Conversational Tools",
    data: [
      "Domain based solution and alignment",
      "Chatbots development and maintenance",
      "Virtual Agents",
      "Virtual Assistants",
    ],
  },
  {
    id: 6,
    pic: daImg,
    title: "Data Analytics",
    data: [
      "Data Governance",
      "Data Engineering",
      "BI & Visualization",
      "Data Science",
      "Marketing Analytics",
      "Customer Analytics",
      "Sales Analytics",
      "Competitive Intelligence Analytics",
    ],
  },
  {
    id: 7,
    pic: dlsImg,
    title: "Deep Learning System",
    data: [
      "Deep Learning as a service",
      "Deep Neural networks",
      "Virtual reality development services",
    ],
  },
];

const CatalogCard = ({ pic, title, classes = "", onClick }) => (
  <div onClick={onClick} className={cls(styles.catalogBox, classes)}>
    <img src={pic} alt={title} className="my-2" />
    <p className={styles.catalogText}>{title}</p>
  </div>
);

const LandingCatalog = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [item, setItem] = useState(null);

  const handleModal = (x) => {
    setItem(x);
    setIsOpen(true);
  };

  return (
    <div id="services" className={styles.wrapper}>
      <div className="header_content--fix" />
      <InfoModal isOpen={isOpen} setIsOpen={setIsOpen} item={item} />
      <Container fluid>
        <Row>
          <Col
            md={{ size: 6, order: 1 }}
            sm={{ size: 12, order: 2 }}
            xs={{ size: 12, order: 2 }}
          >
            <div className={styles.mdCards}>
              <div className="d-flex align-items-center justify-content-center">
                {[...catalogData].splice(0, 3).map((x, i) => (
                  <CatalogCard
                    key={x.id}
                    pic={x.pic}
                    title={x.title}
                    classes={i === 1 && "mx-4"}
                    onClick={() => handleModal(x)}
                  />
                ))}
              </div>
              <div
                className="d-flex align-items-center justify-content-between"
                style={{ marginTop: "-15px" }}
              >
                {[...catalogData].splice(3).map((x, i) => (
                  <CatalogCard
                    key={x.id}
                    pic={x.pic}
                    title={x.title}
                    classes={(i === 2 || i === 3) && "mt-md-0 mt-4"}
                    onClick={() => handleModal(x)}
                  />
                ))}
              </div>
            </div>
            {/* MOBILE VIEW */}
            <div className={styles.smCards}>
              <div className="d-flex align-items-center justify-content-between flex-wrap">
                {catalogData.map((x, i) => (
                  <CatalogCard
                    key={x.id}
                    pic={x.pic}
                    title={x.title}
                    classes="mt-4"
                    //classes={i === 1 && "mx-4"}
                    onClick={() => handleModal(x)}
                  />
                ))}
              </div>
            </div>
          </Col>
          <Col
            md={{ size: 6, order: 1 }}
            sm={{ size: 10, order: 1 }}
            xs={{ size: 12, order: 1 }}
          >
            <div className={styles.catalogContent}>
              <p className={styles.sectionTitle}>Service Catalog</p>
              <p className={styles.sectionSubtitle}>
                We Help our customers implementing and scaling applied
                intelligence solutions to solve business problems and achieve
                their goals.
              </p>
            </div>
          </Col>
        </Row>
        {/* BUG it should be in next section to scroll */}
        <span id="offerings"></span>
      </Container>
    </div>
  );
};

export default LandingCatalog;
