import { Spinner } from "reactstrap";
import styled from "styled-components";
import styles from "./Components.module.css";

const ParentDiv = styled.div`
  position: absolute;
  width: 96%;
  height: 100%;
  left: 2%;
  right: 2%;
  background-color: #00000085;
  border-radius: 10px;
  z-index: 100;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export default function FormSpinner() {
  return (
    <ParentDiv>
      <Spinner className={styles.form_spinner} children={null} />
    </ParentDiv>
  );
}
