import PropTypes from "prop-types";
import { CustomImg, MontP } from "../styles/common";
import { colors } from "../styles/theme";
import styled from "styled-components";

import folderPlus from "../assets/icons/folder-plus.svg";

const DocPickerDiv = styled.div`
  opacity: 0.8;
  border-radius: 5px;
  background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' stroke='%23256C' stroke-width='2' stroke-dasharray='6%2c 14' stroke-dashoffset='0' stroke-linecap='square'/%3e%3c/svg%3e");
  box-sizing: border-box;
  height: 40px;
`;

function SimpleDocPicker({
  inputRef,
  handleChange,
  accept,
  inputName,
  filename,
  setFile,
  coverstyle,
}) {
  return (
    <div className={coverstyle}>
      <input
        style={{ display: "none" }}
        type="file"
        accept={accept}
        onChange={handleChange}
        name={inputName}
        ref={inputRef}
      />
      <div
        onDragOver={(e) => {
          e.preventDefault();
        }}
        onDragLeave={(e) => {
          e.preventDefault();
        }}
        onDrop={(e) => {
          e.preventDefault();
          const droppedFile = Array.from(e.dataTransfer.files);
          //console.log(droppedFile[0]);
          if (droppedFile.length > 0) {
            setFile(droppedFile[0]);
          }
        }}
      >
        <DocPickerDiv
          role="button"
          onClick={() => inputRef.current.click()}
          onKeyPress={() => inputRef.current.click()}
          className="content_center--flex mt-2"
        >
          {filename ? (
            <MontP fs="10px" lh="13px" b c={colors.primary}>
              {filename}
            </MontP>
          ) : (
            <div className="d-flex align-items-center">
              <CustomImg src={folderPlus} w="16px" h="16px" mr="5px" />
              <MontP fs="10px" lh="13px" b c={colors.primary}>
                Click to upload or Drag & Drop
              </MontP>
            </div>
          )}
        </DocPickerDiv>
      </div>
    </div>
  );
}

SimpleDocPicker.propTypes = {
  inputRef: PropTypes.element,
  inputName: PropTypes.string,
  handleChange: PropTypes.func,
  setFile: PropTypes.func,
  accept: PropTypes.string,
  coverstyle: PropTypes.string,
};

SimpleDocPicker.defaultProps = {
  accept: "application/pdf,image/jpeg,image/jpg,image/png",
  inputName: "doc",
  coverstyle: "mt-2",
};

export default SimpleDocPicker;
