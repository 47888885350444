import "react-datepicker/dist/react-datepicker.css";
import { Container, TabContent, TabPane, Nav, Row, Col } from "reactstrap";
import BatchProcess from "./Tabs/BatchProcess";
import SearchDocuments from "./Tabs/SearchDocuments";
import TabsCover from "../../components/tabbar/TabsCover";
import MyTab from "../../components/tabbar/MyTab";
import { useAccessTabs } from "../../hooks/useAccessTabs";

export default function Document() {
  const [tabs, activeTab, setActiveTab] = useAccessTabs();

  const toggle = (tab) => {
    if (activeTab !== tab) setActiveTab(tab);
  };

  return (
    <Container fluid className="pb-5">
      <Row className="justify-content-center mt-4">
        <Col xs={12} sm={12} md={12} className="py-5">
          <Nav tabs className="justify-content-center border-0">
            <TabsCover>
              {tabs.map((item, index) => (
                <MyTab
                  title={item.menuTabName}
                  onClick={() => toggle(item.menuTabName_path)}
                  isActive={activeTab === item.menuTabName_path}
                />
              ))}
            </TabsCover>
          </Nav>
          <TabContent activeTab={activeTab} className="position-relative">
            <TabPane tabId="1">
              <BatchProcess />
            </TabPane>
            <TabPane tabId="2">
              <SearchDocuments />
            </TabPane>
          </TabContent>
        </Col>
      </Row>
    </Container>
  );
}
