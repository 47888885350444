import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";
import styled from "styled-components";
import { useSelector, useDispatch } from "react-redux";
import SimpleNavbar from "../components/layouts/navbar/SimpleNavbar";
import SimpleSidebar from "../components/layouts/sidebar/SimpleSidebar";
import { CustomImg, ElementPos } from "../styles/common";
import { colors } from "../styles/theme";
import { isIpadOS, navbar_height } from "../utils";
import { toggleMainSidebar, toggleMobileSidebar } from "../redux/actions/app_A";

import ideafuseBg from "../assets/images/ideafuse-bg.svg";
import doubleright from "../assets/icons/doubleright.svg";

const ChildrenDiv = styled.div`
  height: calc(100vh - ${navbar_height});
  overflow-y: auto;
  /* background-color: ${colors.white}; */
  width: 100%;
`;

const NavbarSidebarLayout = ({ children }) => {
  const { main_sidebar } = useSelector((state) => ({
    main_sidebar: state.app.main_sidebar,
  }));
  const dispatch = useDispatch();

  return (
    <Container maxWidth="xl" disableGutters>
      <Grid container>
        <Grid
          item
          xs={12}
          sm={12}
          md={12}
          lg={12}
          xl={12}
          style={{
            background:
              "linear-gradient(355.22deg, #e8f0ff -72.34%, #ffffff 148.59%)",
          }}
        >
          <SimpleNavbar />
          <ElementPos pos="fixed" t="3vh" r="3vw" style={{ zIndex: 0 }}>
            <CustomImg src={ideafuseBg} w="50vw" mw="60vw" />
          </ElementPos>
          <Grid container>
            {main_sidebar ? (
              <>
                <Grid item md={2} lg={2} xl={2}>
                  {window.innerWidth > 991 && <SimpleSidebar />}
                </Grid>
                <Grid item xs={12} sm={12} md={10} lg={10} xl={10}>
                  <ChildrenDiv className="custom-scrollbar">
                    {children}
                  </ChildrenDiv>
                </Grid>
              </>
            ) : (
              <Grid
                item
                xs={12}
                sm={12}
                md={12}
                lg={12}
                xl={12}
                className="d-flex"
              >
                <div className="d-none d-md-block shadow-lg p-2">
                  <button
                    onClick={() => {
                      dispatch(toggleMainSidebar());
                      isIpadOS() && dispatch(toggleMobileSidebar(true));
                    }}
                    className="d-none d-md-block border-0 py-1 px-2 mt-2 bg-white"
                  >
                    <CustomImg src={doubleright} alt="open" w="14px" />
                  </button>
                </div>
                <ChildrenDiv className="custom-scrollbar">
                  {children}
                </ChildrenDiv>
              </Grid>
            )}
            {/* MSG  TEST */}
            {/* {main_sidebar && (
              <Grid item md={2} lg={2} xl={2}>
                {window.innerWidth > 991 && <SimpleSidebar />}
              </Grid>
            )}
            <Grid
              item
              xs={12}
              sm={12}
              md={main_sidebar ? 10 : 12}
              lg={main_sidebar ? 10 : 12}
              xl={main_sidebar ? 10 : 12}
              className="d-flex"
            >
              {!main_sidebar && (
                <div className="d-none d-md-block shadow-lg p-2">
                  <button
                    onClick={() => {
                      dispatch(toggleMainSidebar());
                      isIpadOS() && dispatch(toggleMobileSidebar(true));
                    }}
                    className="d-none d-md-block border-0 py-1 px-2 mt-2 bg-white"
                  >
                    <CustomImg src={doubleright} alt="open" w="14px" />
                  </button>
                </div>
              )}
              <ChildrenDiv className="custom-scrollbar">{children}</ChildrenDiv>
            </Grid> */}
          </Grid>
        </Grid>
      </Grid>
    </Container>
  );
};

export default NavbarSidebarLayout;
