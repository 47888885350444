import { useState, useRef, useEffect } from "react";
import { Row, Col, } from "reactstrap";
import TitleInput from "../../../components/TitleInput";
import { colors } from "../../../styles/theme";
import { MiButton, NotoP } from "../../../styles/common";
import FormSpinner from "../../../components/FormSpinner";
import styles from './CustomSettings.module.css'
import validator from "validator";
import { API, PATH } from "../../../services/Axios";
import { useSelector } from "react-redux";
import { notify } from "../../../utils";
import BtnSpinner from "../../../components/BtnSpinner";

const EmailSettings = ({ user_data }) => {
    const [loading, setLoading] = useState(false);
    const [secret, setSecret] = useState(true);

    const userType = [{ id: 1, name: 'Yes', isTrue: true, }, { id: 2, name: 'No', isTrue: false, }];


    const [ssl, setSsl] = useState(false);
    const [emailActive, setEmailActive] = useState(false);

    const [showError, setShowError] = useState(false);

    const initialApiData = () => {
        return {
            emailAddress: "",
            password: "",
            imapAddress: "",
            portNumber: "",
            ssl: false,
            senderEmail: "",
            senderSubject: "",
            inboxFolderName: "",
            archiveFolderName: "",
            emailSettingsActive: false,
        };
    };

    const [data, setData] = useState(initialApiData);

    const getSettingsData = async () => {
        try {
            const result = await API.get(PATH.CustomerEmailSetting, user_data.token);
            console.log(result);
            if (result) {
                setData(result);
                setSsl(result?.ssl);
                setEmailActive(result?.emailSettingActive);
            }
        } catch (err) {
            console.log(err);
        }
    };

    useEffect(() => {
        getSettingsData();
        // console.log(data);
    }, []);




    const handleChange = (e) => {
        const { name, value } = e.target;
        setData((prev) => ({ ...prev, [name]: value }));
    };

    const handleSubmit = async () => {
        const conditions =
            !validator.isEmpty(data?.emailAddress || '') &&
            !validator.isEmpty(data?.password || '') &&
            !validator.isEmpty(data?.imapAddress || '') &&
            !validator.isEmpty(`${data?.portNumber || ''}`) &&
            !validator.isEmpty(data?.inboxFolderName || '') &&
            !validator.isEmpty(data.archiveFolderName || '');
        if (conditions) {

            const updateData = {
                ...data,
                ssl: ssl,
                portNumber: parseInt(data.portNumber),
                emailSettingActive: emailActive,
                // "emailSettingId": null
            };

            try {
                const result = await API.post(
                    PATH.AddUpdateCustomerEmailSetting,
                    updateData,
                    user_data.token
                );
                console.log(result);
                if (result) {
                    getSettingsData();
                    notify("Your configuration settings were sucessfully saved", true);
                } else {
                    notify(
                        "Error encountered while saving your configuration settings. Please try again or if the problem persists create and issue using our Issue Management Service."
                    );
                }
                setLoading(false);
            } catch (err) {
                console.log(err);
                setLoading(false);
            }

        } else {
            console.log(data);
            setShowError(true);
        }

    };

    return (
        <Row className="mt-4 ps-4 pe-2">
            <Col md={{ size: 6, offset: 3 }} className="position-relative">
                {loading && <FormSpinner />}

                <div className="d-none">
                    <input type="f_email" name="" id="" autoComplete />
                    <input type="f_password" name="" id="" autoComplete />
                </div>

                <TitleInput
                    label="Email Address"
                    name="emailAddress"
                    placeholder="Enter Email Address"
                    value={data?.emailAddress}
                    onChange={handleChange}
                    showerr={showError}
                    valid={!validator.isEmpty(data?.emailAddress || '')}

                />
                <TitleInput
                    label="Password"
                    name="password"
                    type={secret ? 'password' : 'text'}
                    handleEye={() => setSecret(!secret)}
                    placeholder="Enter Password"
                    value={data?.password}
                    onChange={handleChange}
                    showerr={showError}
                    valid={!validator.isEmpty(data?.password || '')}
                />


                <Row>
                    <Col md={8}>
                        <TitleInput
                            label="IMAP Address"
                            name="imapAddress"
                            placeholder="Enter IMAP Address"
                            value={data?.imapAddress}
                            onChange={handleChange}
                            showerr={showError}
                            valid={!validator.isEmpty(data?.imapAddress || '')}
                        />
                    </Col>
                    <Col md={4}>
                        <TitleInput
                            label="Port Number"
                            name="portNumber"
                            placeholder="Enter Port Number"
                            value={data?.portNumber}
                            onChange={handleChange}
                            showerr={showError}
                            valid={!validator.isEmpty(`${data?.portNumber || ''}`)}
                        />
                    </Col>
                </Row>

                <div className="d-lg-flex justify-content-between align-items-center">
                    {/* SSL  */}
                    <div className="d-flex align-items-center  mt-4">
                        <NotoP
                            fs="14px"
                            lh="19px"
                            tfs="14px"
                            tlh="19px"
                            mfs="12px"
                            mlh="16px"
                            fw="600"
                            c={colors.primary}
                        >
                            SSL
                            <span className="iRed">*</span>
                        </NotoP>
                        <div className="d-flex align-items-center">
                            {userType.map((itm) => (
                                <div
                                    key={itm.id}
                                    role="button"
                                    onClick={() => setSsl(itm.isTrue)}
                                    onKeyPress={null}
                                    className="d-flex align-items-center ms-4"
                                >
                                    <span
                                        className={itm.isTrue === ssl ? styles.activedot : styles.dot}
                                    ></span>
                                    <NotoP
                                        fs="14px"
                                        lh="18px"
                                        fw="500"
                                        c={colors.secondary}
                                        ml="3px"
                                    >
                                        {itm.name}
                                    </NotoP>
                                </div>
                            ))}
                        </div>
                    </div>

                    {/* email Settings Active  */}
                    <div className="d-flex align-items-center  mt-4">
                        <NotoP
                            fs="14px"
                            lh="19px"
                            tfs="14px"
                            tlh="19px"
                            mfs="12px"
                            mlh="16px"
                            fw="600"
                            c={colors.primary}
                        >
                            Enabled
                            <span className="iRed">*</span>
                        </NotoP>
                        <div className="d-flex align-items-center">
                            {userType.map((itm) => (
                                <div
                                    key={itm.id}
                                    role="button"
                                    onClick={() => setEmailActive(itm.isTrue)}
                                    onKeyPress={null}
                                    className="d-flex align-items-center ms-4"
                                >
                                    <span
                                        className={itm.isTrue === emailActive ? styles.activedot : styles.dot}
                                    ></span>
                                    <NotoP
                                        fs="14px"
                                        lh="18px"
                                        fw="500"
                                        c={colors.secondary}
                                        ml="3px"
                                    >
                                        {itm.name}
                                    </NotoP>
                                </div>
                            ))}
                        </div>
                    </div>
                </div>


                <TitleInput
                    required={false}
                    label="Subject Key Word"
                    placeholder="Enter your Subject Key Word"
                    name="senderSubject"
                    value={data?.senderSubject}
                    onChange={handleChange}
                    showerr={showError}
                    valid={!validator.isEmpty(data?.senderSubject || '')}
                />

                <TitleInput
                    required={false}
                    label="Sender Email"
                    name="senderEmail"
                    placeholder="Enter Sender Email"
                    value={data?.senderEmail}
                    onChange={handleChange}
                    showerr={showError}
                    valid={!validator.isEmpty(data?.senderEmail || '')}
                />

                <Row>
                    <Col >
                        <TitleInput
                            label="Inbox Folder Name"
                            name="inboxFolderName"
                            placeholder="Enter Inbox Folder Name"
                            value={data?.inboxFolderName}
                            onChange={handleChange}
                            showerr={showError}
                            valid={!validator.isEmpty(data?.inboxFolderName || '')}
                        />
                    </Col>
                    <Col>
                        <TitleInput
                            label="Archive Folder Name"
                            name="archiveFolderName"
                            placeholder="Enter Archive Folder Name"
                            value={data?.archiveFolderName}
                            onChange={handleChange}
                            showerr={showError}
                            valid={!validator.isEmpty(data?.archiveFolderName || '')}
                        />
                    </Col>
                </Row>


                <div className="text-center">
                    <button
                        onClick={handleSubmit}
                        disabled={loading}
                        className='saveBtn w-50 mx-auto mt-4'
                    >
                        {loading && <BtnSpinner />}
                        Save
                    </button>
                </div>
            </Col>
        </Row>
    );
};

export default EmailSettings;