import { Button } from "@material-ui/core";
import styled, { css } from "styled-components";
import { colors } from "./theme";

export const MontP = styled.p`
  font-family: "Montserrat", sans-serif;
  margin-top: ${({ mt }) => mt || 0};
  margin-bottom: ${({ mb }) => mb || 0};
  margin-left: ${({ ml }) => ml || 0};
  margin-right: ${({ mr }) => mr || 0};
  font-size: ${({ fs }) => fs || "16px"};
  color: ${({ c }) => c || colors.black};
  font-weight: ${({ b, fw }) => (b ? "bold" : fw ? fw : "500")};
  text-decoration: ${({ td }) => td || "none"};
  opacity: ${({ o }) => o || 1};

  ${({ fontStyle }) =>
    fontStyle &&
    css`
      font-style: ${fontStyle};
    `}

  ${({ lh }) =>
    lh &&
    css`
      line-height: ${lh};
    `}

  ${({ ls }) =>
    ls &&
    css`
      letter-spacing: ${ls};
    `}

    ${({ tt }) =>
    tt &&
    css`
      text-transform: ${tt};
    `}

  @media only screen and (max-width: 768px) {
    ${({ mfs }) =>
      mfs &&
      css`
        font-size: ${mfs};
      `}

    ${({ mlh }) =>
      mlh &&
      css`
        line-height: ${mlh};
      `}
  }

  @media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (-webkit-min-device-pixel-ratio: 2) {
    ${({ tfs }) =>
      tfs &&
      css`
        font-size: ${tfs};
      `}

    ${({ tlh }) =>
      tlh &&
      css`
        line-height: ${tlh};
      `}
  }
`;

export const NotoP = styled.p`
  font-family: "Noto Sans", "Noto Sans JP", sans-serif;
  margin-top: ${({ mt }) => mt || 0};
  margin-bottom: ${({ mb }) => mb || 0};
  margin-left: ${({ ml }) => ml || 0};
  margin-right: ${({ mr }) => mr || 0};
  font-size: ${({ fs }) => fs || "16px"};
  color: ${({ c }) => c || colors.black};
  font-weight: ${({ b, fw }) => (b ? "bold" : fw ? fw : "500")};
  text-decoration: ${({ td }) => td || "none"};
  opacity: ${({ o }) => o || 1};

  ${({ fontStyle }) =>
    fontStyle &&
    css`
      font-style: ${fontStyle};
    `}

  ${({ lh }) =>
    lh &&
    css`
      line-height: ${lh};
    `}

  ${({ ls }) =>
    ls &&
    css`
      letter-spacing: ${ls};
    `}
      
    ${({ tt }) =>
    tt &&
    css`
      text-transform: ${tt};
    `}

  @media only screen and (max-width: 768px) {
    ${({ mfs }) =>
      mfs &&
      css`
        font-size: ${mfs};
      `}

    ${({ mlh }) =>
      mlh &&
      css`
        line-height: ${mlh};
      `}
  }

  @media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (-webkit-min-device-pixel-ratio: 2) {
    ${({ tfs }) =>
      tfs &&
      css`
        font-size: ${tfs};
      `}

    ${({ tlh }) =>
      tlh &&
      css`
        line-height: ${tlh};
      `}
  }
`;

export const CustomIcon = styled.img`
  width: ${({ w }) => w || "2.4rem"};
  height: ${({ h }) => h || "2.4rem"};
  border-radius: ${({ br }) => br || 0};
  object-fit: ${({ of }) => of || "contain"};
  margin-top: ${({ mt }) => mt || 0};
  margin-bottom: ${({ mb }) => mb || 0};
  margin-left: ${({ ml }) => ml || 0};
  margin-right: ${({ mr }) => mr || 0};
`;

export const CustomImg = styled.img`
  width: ${({ w }) => w || "10rem"};
  border-radius: ${({ br }) => br || 0};
  object-fit: ${({ of }) => of || "contain"};

  margin-top: ${({ mt }) => mt || 0};
  margin-bottom: ${({ mb }) => mb || 0};
  margin-left: ${({ ml }) => ml || 0};
  margin-right: ${({ mr }) => mr || 0};

  ${({ h }) =>
    h &&
    css`
      height: ${h};
    `}

  @media only screen and (max-width: 768px) {
    ${({ mw }) =>
      mw &&
      css`
        width: ${mw};
      `}

    ${({ mh }) =>
      mh &&
      css`
        height: ${mh};
      `}
  }

  @media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (-webkit-min-device-pixel-ratio: 2) {
    ${({ tw }) =>
      tw &&
      css`
        width: ${tw};
      `}

    ${({ th }) =>
      th &&
      css`
        height: ${th};
      `}
  }
`;

export const ElementPos = styled.span`
  position: ${({ pos }) => pos || "absolute"};
  z-index: ${({ zi }) => zi || 0};

  ${({ t }) =>
    t &&
    css`
      top: ${t};
    `}
  ${({ b }) =>
    b &&
    css`
      bottom: ${b};
    `}
${({ l }) =>
    l &&
    css`
      left: ${l};
    `}
${({ r }) =>
    r &&
    css`
      right: ${r};
    `}

  @media only screen and (max-width: 768px) {
    ${({ mt }) =>
      mt &&
      css`
        top: ${mt};
      `}
    ${({ mb }) =>
      mb &&
      css`
        bottom: ${mb};
      `}
${({ ml }) =>
      ml &&
      css`
        left: ${ml};
      `}
${({ mr }) =>
      mr &&
      css`
        right: ${mr};
      `}
  }

  @media only screen and (min-width: 768px) and (max-width: 1024px) {
    ${({ tt }) =>
      tt &&
      css`
        top: ${tt};
      `}
    ${({ tb }) =>
      tb &&
      css`
        bottom: ${tb};
      `}
${({ tl }) =>
      tl &&
      css`
        left: ${tl};
      `}
${({ tr }) =>
      tr &&
      css`
        right: ${tr};
      `}
  }
`;

export const MiButton = styled(Button)`
  background-color: ${({ bg }) => bg || colors.primary};
  border-radius: ${({ br }) => br || "35px"};
  height: ${({ h }) => h || "44px"};
  width: ${({ w }) => w || "100%"};
  margin-top: ${({ mt }) => mt || 0};
  margin-bottom: ${({ mb }) => mb || 0};
  margin-left: ${({ ml }) => ml || 0};
  margin-right: ${({ mr }) => mr || 0};

  &:hover {
    background-color: ${({ hbg }) => hbg || colors.blue5};
  }
`;

export const MiOutlineButton = styled(Button)`
  border-radius: ${({ br }) => br || "35px"};
  border: ${({ bw, bc }) => `${bw || 3}px solid ${bc || colors.primary}`};
  color: ${({ c }) => c || colors.primary};
  height: ${({ h }) => h || "44px"};
  width: ${({ w }) => w || "100%"};
  margin-top: ${({ mt }) => mt || 0};
  margin-bottom: ${({ mb }) => mb || 0};
  margin-left: ${({ ml }) => ml || 0};
  margin-right: ${({ mr }) => mr || 0};

  &:hover {
    background-color: ${({ hbg }) => hbg || colors.primary};
    color: ${({ hc }) => hc || colors.white};
  }
`;

export const Line = styled.div`
  border-top: ${({ bw, bc }) =>
    `${bw || 2}px solid ${bc || "rgba(0, 0, 0, 0.1)"}`};
  margin-top: ${({ mt }) => mt || 0};
  margin-bottom: ${({ mb }) => mb || 0};
  margin-left: ${({ ml }) => ml || 0};
  margin-right: ${({ mr }) => mr || 0};
`;
