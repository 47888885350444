import React, { useEffect, useState } from "react";
import { Col, Container, Row } from "reactstrap";
import styles from "./landing-carousel.module.css";
import { HideOn } from "react-hide-on-scroll";

import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader

import introductionImg from "../../assets/images/landing/introduction.jpg";
import productsImg from "../../assets/images/landing/products.jpg";
import servicesImg from "../../assets/images/landing/services.jpg";
import promotionsImg from "../../assets/images/landing/promotions.jpg";

const carouselData = [
  {
    id: 0,
    path: "/#arka",
    title: "Seeing is Believing . . .",
    subTitle: `Empower your business using our Innovative Solutions\nand Services powered by Artificial Intelligence\nand Machine Learning`,
    bgPhoto: introductionImg,
  },
  {
    id: 1,
    path: "/#work-steps",
    title: "Lets Innovate Together . . .",
    subTitle: `Ideafuse not only builds Innovative Solutions powered by Artificial Intelligence\nand Machine Learning Capabilities,  but also works closely with our clients in scaling\nand automating AI & ML powered solutions. We work with our clients implementing and\nscaling applied intelligence to help them achieve their growth objectives.`,
    bgPhoto: productsImg,
  },
  {
    id: 2,
    path: "/#services",
    title: "Services",
    subTitle: `We Deliver You The Technology Of Your Dreams \nFrom The Future, Right To Your Doorstep . . . \n\n 1. Browse our service catalog\n2. See how you can utilise our exclusive advanced and innovative services to\n    empower your business.\n3. Explore our Innovative workshop.\n4. Experience our innovative services blending Artificial Intelligence and\n    Machine learning capabilities`,
    bgPhoto: servicesImg,
  },
  {
    id: 3,
    path: "#plans",
    title: "PROMOTIONS & PLANS",
    subTitle: `Experience the power of AI and ML Capabilities at our cost for a\nlimited time duration!!`,
    bgPhoto: promotionsImg,
  },
];

const LandingCarousel = () => {
  const isWindow = navigator.userAgent.indexOf("Win") != -1;
  const [isWindowFullscreen, setIsWindowFullScreen] = useState(false);
  const [currItem, setCurrItem] = useState(null);

  const handleMatchMedia = ({ matches }) => {
    if (matches && isWindow) {
      setIsWindowFullScreen(true);
    } else {
      setIsWindowFullScreen(false);
    }
  };

  useEffect(() => {
    window
      .matchMedia("(display-mode: fullscreen)")
      .addEventListener("change", handleMatchMedia);
    return () => {
      window.removeEventListener("change", handleMatchMedia);
    };
  }, []);

  const handleOnChange = (index) => {
    const newItem = carouselData.find((x) => x.id === index);
    setCurrItem(newItem);
  };

  return (
    <Container id="home" fluid className={styles.wrapper}>
      <div className="header_content--fix" />
      <Row>
        <Col className="p-0">
          <Carousel
            showThumbs={false}
            dynamicHeight={false}
            // TODO  uncomment below lines (auto play)
            autoPlay
            infiniteLoop
            stopOnHover={false}
            showStatus={false}
            showArrows={false}
            onChange={handleOnChange}
            renderIndicator={(onClickHandler, isSelected, index, label) => (
              <li
                className={styles.carouselDotCover}
                style={{
                  zoom: isSelected ? "1.2" : "1",
                  marginBottom: isWindowFullscreen ? "5vh" : "10px",
                }}
                onClick={onClickHandler}
                onKeyDown={onClickHandler}
              >
                <div className={styles.carouselDot} />
              </li>
            )}
          >
            {carouselData.map((item) => (
              <div className={styles.contentWrapper}>
                <img
                  src={item.bgPhoto}
                  alt={item.title}
                  className={styles.bgPhoto}
                />
                <div className={styles.overlay} />
                <div className={styles.content}>
                  {/* {item.heading && (
                    <p className={styles.heading}>{item.heading}</p>
                  )} */}
                  <p className={styles.heading}>{item.heading}</p>
                  <pre className={styles.title}>{item.title}</pre>
                  <pre className={styles.subTitle}>{item.subTitle}</pre>
                </div>
              </div>
            ))}
          </Carousel>
          <HideOn height={100}>
            {currItem && (
              <a href={currItem.path}>
                <div class="icon-scroll" />
              </a>
            )}
          </HideOn>
        </Col>
      </Row>
    </Container>
  );
};

export default LandingCarousel;
