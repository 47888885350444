import { memo } from "react";
import PropTypes from "prop-types";
import { Line } from "react-chartjs-2";

const options = {
  scales: {
    y:{
        ticks: {
          beginAtZero: true,
          callback: function(value, index, values) {
            return value;
          },
          maxTicksLimit: 5,
          granularityEnabled: false,
          stepSize: 1

        },
      }
  },
  title: {
    display: false,
  },
  legend: {
    display: false,
  },
};

const DocumentProcessed = (props) => {
  const { labels, documentData } = props;

  const data = {
    labels,
    datasets: [
      {
        label: "Document processed",
        data: documentData,
        fill: true,
        //   background:
        //     "linear-gradient(200.35deg, #B2CAF7 6.41%, rgba(184, 204, 243, 0) 86.47%)",
        backgroundColor: "#B2CAF7",
        borderColor: "#3364C2",
      },
    ],
  };

  return <Line data={data} options={options} />;
};

DocumentProcessed.propTypes = {
  labels: PropTypes.array,
  documentData: PropTypes.array,
};

DocumentProcessed.defaultProps = {
  labels: [],
  documentData: [],
};

export default memo(DocumentProcessed);
