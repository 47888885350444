import { useState, useRef } from "react";
import { Container, Col, Row } from "reactstrap";
import { useSelector } from "react-redux";
import styled from "styled-components";
import TitleInput from "../../../components/TitleInput";
import TitleTextArea from "../../../components/TitleTextArea";
import BtnSpinner from "../../../components/BtnSpinner";
import { CustomImg, MiButton, MontP, NotoP } from "../../../styles/common";
import { colors } from "../../../styles/theme";
import { API, PATH } from "../../../services/Axios";
import { isIpadOS, notify } from "../../../utils";

import LogIssue from "../../../assets/images/support.png";
import folderPlus from "../../../assets/icons/folder-plus.svg";
import issueGit from "../../../assets/icons/issue-git.svg";
import SimpleDocPicker from "../../../components/SimpleDocPicker";

const initialData = {
  subject: "",
  summary: "",
  details: "",
};

export default function CreateIssue() {
  const docRef = useRef();
  const { user_data } = useSelector((state) => ({
    user_data: state.auth.user_data,
  }));

  const [loading, setLoading] = useState(false);

  const [doc, setDoc] = useState(null);
  const [showError, setShowError] = useState(false); // IMP  bydefault should be false
  const [issueData, setIssueData] = useState(initialData);

  const { subject, summary, details } = issueData;

  const handleSubmit = async () => {
    const conditions =
      subject.length >= 10 &&
      summary.length >= 2 &&
      summary.length <= 500 &&
      details.length <= 1000;

    if (conditions) {
      setLoading(true);
      setShowError(false);

      let fileFormData = new FormData();
      fileFormData.append("CustId", user_data?.customerInfo?.custId);
      fileFormData.append("LoginId", user_data?.userInfo?.userEmail);
      fileFormData.append("IssueSubject", subject);
      fileFormData.append("IssueSummary", summary);
      fileFormData.append("IssueDetails", details);
      if (doc) {
        fileFormData.append("FileName", doc.name);
        fileFormData.append("FileObject", doc);
      }

      try {
        const result = await API.postFileKey(
          PATH.createIssue,
          fileFormData,
          user_data?.token
        );
        console.log(result);

        if (result?.isSuccess) {
          notify(
            `Issue Id ${result.custIssueId} has been created and we will send you details on the progress.`,
            true
          );
          setIssueData(initialData);
          setDoc(null);
        }
        setLoading(false);
      } catch (err) {
        setLoading(false);
        console.error(err);
      }
    } else {
      setShowError(true);
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setIssueData((prev) => ({ ...prev, [name]: value }));
  };

  const handleDocPicker = (e) => {
    const { name, files } = e.target;
    console.log(name);

    if (files.length > 0) {
      setDoc(files[0]);
    }
  };

  return (
    <Container fluid>
      <Row className="mt-4">
        {!isIpadOS() && (
          <Col md={6} className="ps-md-3 w-100--ipad">
            <Title size={24}>Do you have issue?</Title>
            <Subtitle size={22}>Let’s resolve it together...</Subtitle>
            <Image
              src={LogIssue}
              className="mt-3 d-none d-md-block d-lg-block"
            />
          </Col>)}
        <Col md={6} className="w-100--ipad">
          <div className="position-relative mb-sm-5">
            <IssueCard className="custom-scrollbar">
              <Title size={24} align="center">
                Log your Issue ...
              </Title>
              <TitleInput
                label="What your issue is about?"
                name="subject"
                value={subject}
                onChange={handleChange}
                placeholder="Subject of your issue"
                showerr={showError}
                errmsg="Minimum length should be 10"
                valid={subject.length >= 10}
              />
              <TitleTextArea
                rows={2}
                label="Issue Summary"
                name="summary"
                value={summary}
                onChange={handleChange}
                placeholder="Write Issue Summary"
              //showerr={showError}
              //errmsg="Maximum length should be 500 characters"
              //valid={summary.length <= 500}
              />
              <FooterText align="right">Maximun 500 characters</FooterText>
              <TitleTextArea
                rows={2}
                label="Additional Details(optional)"
                name="details"
                value={details}
                onChange={handleChange}
                placeholder="Explain your issue"
                required={false}
              //showerr={showError}
              //errmsg="Maximum length should be 1000 characters"
              //valid={details.length <= 1000}
              />
              <FooterText align="right">Maximun 1000 characters</FooterText>
              <SimpleDocPicker
                inputRef={docRef}
                setFile={setDoc}
                handleChange={handleDocPicker}
                filename={doc?.name}
              />
              <FooterText>File supported .png, .jpg, .pdf</FooterText>

              <button
                disabled={loading}
                onClick={handleSubmit}
                className='saveBtn mt-4'
              >
                {loading ? (
                  <BtnSpinner />
                ) : (
                  <CustomImg src={issueGit} w="16px" h="16px" mr="5px" />)}
                Create issue
              </button>

            </IssueCard>
          </div>
        </Col>
      </Row>
    </Container>
  );
}

const Title = styled.h3`
  font-family: Montserrat;
  font-style: normal;
  font-weight: bold;
  font-size: ${({ size }) => (size ? size : 38)}px;
  line-height: 30px;
  /* primary-color */
  color: #03256c;
  text-align: ${({ align }) => (align ? align : "left")};
  margin-bottom: 0;
`;

const Subtitle = styled.p`
  font-family: Montserrat;
  font-style: normal;
  font-weight: 500;
  font-size: ${({ size }) => (size ? size : 38)}px;
  line-height: 30px;
  /* primary-color */
  color: #03256c;
`;

const Image = styled.img`
  width: 60vh;
  height: 45vh;
  object-fit: contain;

  @media (max-width: 768px) {
    width: 95%;
    height: 45vh;
  }
`;

const FooterText = styled.span`
  font-family: Montserrat;
  font-style: italic;
  font-weight: normal;
  font-size: 12px;
  line-height: 18px;
  /* identical to box height */
  display: block;
  align-items: center;
  text-align: ${({ align }) => (align ? align : "left")};
  /* primary-color */
  color: #03256c;
`;

const IssueCard = styled.div`
  /* background: #ffffff;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 10px; */
  padding: 10px 30px;
  z-index: 1;
  min-height: 66vh;
  max-width: 95%;
  overflow-y: auto;
`;
