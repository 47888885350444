import PropTypes from "prop-types";
import { Card } from "reactstrap";
import styled from "styled-components";
import { MontP, NotoP, CustomImg } from "../../styles/common";
import { colors } from "../../styles/theme";
import styles from "./Analytics.module.css";

import pieCenter from "../../assets/images/pie-center.svg";

const SvgCover = styled.div`
  /* background-color: goldenrod; */
  width: 120px;
  height: 120px;
  border-radius: 50%;
  position: relative;
`;

const ImgPos = styled.span`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -45%);
`;

const availableColors = [
  colors.yellow,
  colors.green,
  "#DC5DE5",
  "#5DE5DC",
  "#92E65E",
  colors.secondary,
  "#C0E65E",
  "#E4B974",
  "#E6805E",
  colors.primary,
];

const getCirclesData = (arr, total) => {
  const countArr = [];
  const circlesArr = [];
  for (const item of arr) {
    countArr.push(item.docCount);
  }
  countArr.forEach((item, index, arr) => {
    if (index === 0) {
      circlesArr.push(total);
    } else {
      let prevCount = circlesArr[circlesArr.length - 1];
      circlesArr.push(prevCount - arr[index - 1]);
    }
  });
  // console.log(total, countArr, circlesArr);
  return circlesArr;
};

function AnalyticsPieCard({ title, items }) {
  const total = items.reduce((acc, curr) => {
    return acc + curr.docCount;
  }, 0);

  return (
    <Card className={styles.piecard}>
      <MontP fs="16px" lh="20px" fw="600" c={colors.primary}>
        {title} ({total})
      </MontP>
      <div className="d-flex align-items-center justify-content-between mt-3">
        <div style={{ flex: 2 }}>
          <SvgCover className="content_center--flex">
            <svg height="100%" width="100%" viewBox="0 0 20 20">
              {/* <circle r="8.5" cx="10" cy="10" fill={colors.grey} /> */}
              {getCirclesData(items, total).map((item, index) => (
                <circle
                  key={index}
                  r="5"
                  cx="10"
                  cy="10"
                  fill="transparent"
                  stroke={availableColors[index]}
                  strokeWidth="10"
                  strokeDasharray={`calc(${
                    (item / total) * 100
                  } * 31.4 / 100) 31.4`}
                  transform="rotate(-90) translate(-20)"
                  strokeDashoffset="0"
                />
              ))}
            </svg>
            <ImgPos>
              <CustomImg src={pieCenter} alt="pie circle" w="80px" h="80px" />
            </ImgPos>
          </SvgCover>
        </div>
        <div style={{ flex: 3 }} className="ms-md-5 ms-3">
          {items.map((item, index) => (
            <div
              key={index}
              className="d-flex align-items-center justify-content-between"
            >
              <div className="d-flex align-items-center">
                <span
                  className={styles.squarebox}
                  style={{
                    backgroundColor: availableColors[index],
                  }}
                ></span>
                <NotoP fs="13px" lh="20px" c={colors.secondary}>
                  {item.kpiValue || "Blank"}
                </NotoP>
              </div>
              <MontP fs="14px" lh="20px" fw="600" c={colors.secondary}>
                {item.docCount}
              </MontP>
            </div>
          ))}
        </div>
      </div>
    </Card>
  );
}

AnalyticsPieCard.propTypes = {
  title: PropTypes.string.isRequired,
  items: PropTypes.array.isRequired,
};

export default AnalyticsPieCard;
