// import { useEffect } from "react";
import { Route, Switch } from "react-router-dom";
import { useSelector } from "react-redux";
import commonRoutes from "../routes/commonRoutes";
import adminRoutes from "../routes/adminRoutes";
// import analystRoutes from "../routes/analystRoutes";

const SwitchRoutes = () => {
  const { user_data } = useSelector((state) => ({
    user_data: state.auth.user_data,
  }));

  // console.log(user_data);
  // debugger;

  const activeRoutes = user_data ? adminRoutes : commonRoutes;

  // useEffect(() => {
  //   // window.location.host = "localhost:3000";
  // }, []);

  return (
    <Switch>
      {activeRoutes.map((route, index) => (
        <Route
          key={index}
          path={route.path}
          exact={route.exact}
          component={(props) => (
            <route.layout {...props}>
              <route.component {...props} />
            </route.layout>
          )}
        />
      ))}
    </Switch>
  );
};

export default SwitchRoutes;
