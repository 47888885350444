import React, { memo }  from "react";
import PropTypes from "prop-types";
import AmCharts from "@amcharts/amcharts3-react";

const CostComparision = ({ arkaData }) => {

  console.log(arkaData);
  var up = false;

  function formatLabel(value, valueString, axis){

    // if(arkaData.length === 9) {
    //   if(up) {
    //       axis.labelOffset=0;
    //   }
    //   else {
    //       axis.labelOffset=20;
    //   }
    //   up=!up;
    // }

    if(arkaData.length === 10) {
      return value.replace(" 202", "'2");
    } else if(arkaData.length > 7) {
      return value.replace("202", "'2");
    } else {
      return value;
    }
  }
  const config = {
    "type": "serial",
    "theme": "light",
    "marginTop": 40,
    "legend": {
      "useGraphSettings": true,
      "cornerRadius": 12
    },
    "valueAxes": [{
      "stackType": "3d",
      "unit": " ($)",
      "position": "left",
      "id": "v1",
      "title": ""
    }],
    "startDuration": 1,
    "graphs": [{
      "balloonText": "ARKA Cost ([[period]]): <b> $[[value]]</b>",
      "fillAlphas": 0.9,
      "lineAlpha": 0.2,
      "fillColors": "#2541b2",
      "lineColor": "#2541b2",
      "title": "ArkaCost",
      "type": "column",
      "valueField": "ArkaCost"
  }, {
      "balloonText": "Manual Cost ([[period]]): <b>$[[value]]</b>",
      "fillAlphas": 0.9,
      "lineAlpha": 0.2,
      "fillColors": "#cdcdcd",
      "lineColor": "#cdcdcd",
      "title": "ManualCost",
      "type": "column",
      "valueField": "ManualCost"
  }],
  "plotAreaFillAlphas": 0.1,
  "depth3D": 60,
  "angle": 40,
  "categoryField": "period",
  "categoryAxis": {
      "gridPosition": "start",
      "labelFunction":formatLabel
  },
  "export": {
    "enabled": false
   },
    "dataProvider": arkaData? arkaData : []
  };

  return (
    <div className="App">
      <AmCharts.React style={{ width: "100%", height: '340px' }} options={config} />
    </div>
  );
  // return <Bar data={data} options={options} />;
};

CostComparision.propTypes = {
  labels: PropTypes.array,
  arkaData: PropTypes.array,
  manualData: PropTypes.array,
};

CostComparision.defaultProps = {
  labels: [],
  arkaData: [],
  manualData: [],
};

export default memo(CostComparision);
