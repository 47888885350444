import { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { Row, Col } from "reactstrap";
import Cookies from "js-cookie";
import TitleInput from "../../../components/TitleInput";
import { colors } from "../../../styles/theme";
import { MiButton, NotoP } from "../../../styles/common";
import styled from "styled-components";
import BtnSpinner from "../../../components/BtnSpinner";
import { API, PATH } from "../../../services/Axios";
import { notify } from "../../../utils";
import FormSpinner from "../../../components/FormSpinner";

function ChangePassword(props) {
  const { user_data } = props;

  const [loading, setLoading] = useState(false);
  const [random, setRandom] = useState(0);

  const initialPwdErrs = [
    { id: 1, msg: "Min 8 Characters", pending: true },
    {
      id: 2,
      msg: "Atleast one capital letter, one number &amp; one special character(@#$*&amp;^% etc.)",
      pending: true,
    },
    { id: 1, msg: "Don’t use your email address as password", pending: true },
  ];
  const [passwordErrors, setPasswordErrors] = useState(initialPwdErrs);

  const [showError, setShowError] = useState(false);

  const [currentPassword, setCurrentPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");

  useEffect(() => {
    if (newPassword) {
      // MSG  min character
      if (newPassword.length >= 8) {
        passwordErrors[0].pending = false;
      } else if (newPassword.length < 8) {
        passwordErrors[0].pending = true;
      }
      // MSG  UpperCase, Numeric, Symbol
      // const regexp = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[-+_!@#$%^&*.,?])/;
      const regexp = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[-+_!@#$%^&*.,?])/;
      if (regexp.test(newPassword)) {
        passwordErrors[1].pending = false;
      } else if (!regexp.test(newPassword)) {
        passwordErrors[1].pending = true;
      }
      // MSG  not use email as password
      if (newPassword !== user_data?.userInfo?.userEmail) {
        passwordErrors[2].pending = false;
      } else if (newPassword === user_data?.userInfo?.userEmail) {
        passwordErrors[2].pending = true;
      }
      setRandom(Math.random(2));
    } else {
      setPasswordErrors(initialPwdErrs);
    }
  }, [newPassword]);

  const handleSave = async () => {
    const conditions =
      currentPassword.length >= 6 && newPassword === confirmPassword;
    const isPending = passwordErrors.findIndex((x) => x.pending);
    if (conditions && isPending === -1) {
      const data = {
        UserId: user_data?.userInfo?.userEmail,
        OldPassword: currentPassword,
        NewPassword: newPassword,
      };
      // console.log(data);
      setLoading(true);
      try {
        const result = await API.post(
          PATH.updateUserPassword,
          data,
          user_data.token
        );
        console.log(result);
        if (result) {
          const cookieData = Cookies.get();
          // console.log(cookieData);
          if (cookieData?.rememberMe === "yes") {
            Cookies.set("password", newPassword);
          }
          notify("User Password Updated", true);
          // MSG  Resetting address
          setConfirmPassword("");
          setNewPassword("");
          setConfirmPassword("");
        } else {
          notify(
            "User Password not updated. Please send an email to arka.admin@ideafuse or create an issue using the Create Issue Screen"
          );
        }
        setLoading(false);
      } catch (err) {
        console.log(err);
        notify(
          "User Password not updated. Please send an email to arka.admin@ideafuse or create an issue using the Create Issue Screen"
        );
        setLoading(false);
      }
    } else {
      setShowError(true);
    }
  };

  return (
    <Row >
            <Col xs={12} sm={12} md={12} lg={6} className="mx-auto">
        {loading && <FormSpinner />}
        <TitleInput
          label="Current Password"
          name="currentPassword"
          type="password"
          placeholder="Enter Your Current Password"
          value={currentPassword}
          onChange={(e) => setCurrentPassword(e.target.value)}
          showerr={showError}
          errmsg="Minimum password length should be 6"
          valid={currentPassword.length >= 6}
          secret
        />
        <TitleInput
          label="New Password"
          name="newPassword"
          type="password"
          placeholder="Enter Your New Password"
          value={newPassword}
          onChange={(e) => setNewPassword(e.target.value)}
          secret
        />
        <ul className="p-4 pt-3 pb-1">
          {passwordErrors.map((itm, idx) => (
            <ErrorMsg key={idx} pending={itm.pending}>
              {itm.msg}
            </ErrorMsg>
          ))}
        </ul>
        <TitleInput
          label="Confirm Password"
          name="confirmPassword"
          type="password"
          placeholder="Confirm New Password"
          value={confirmPassword}
          onChange={(e) => setConfirmPassword(e.target.value)}
          showerr={showError}
          errmsg="New password & confirm password should be same"
          valid={newPassword === confirmPassword}
          secret
        />
        <button
          disabled={loading} 
          onClick={handleSave}
          className='saveBtn mt-4'
        >
          Change Password
        </button>
      </Col>
    </Row>
  );
}

const ErrorMsg = styled.li`
  font-family: "Noto Sans", sans-serif;
  font-style: italic;
  font-weight: normal;
  font-size: 12px;
  line-height: 16px;
  color: ${({ pending }) => (pending ? colors.red : colors.secondary)};
`;

ChangePassword.propTypes = {
  user_data: PropTypes.object,
};

export default ChangePassword;
