import { useEffect, useState } from "react";
import { Link, useHistory, useLocation } from "react-router-dom";
import styled from "styled-components";
import AppBar from "@material-ui/core/AppBar";
import { useSelector, useDispatch } from "react-redux";
import Toolbar from "@material-ui/core/Toolbar";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import IconButton from "@material-ui/core/IconButton";
import decode from "jwt-decode";
import { toggleMobileSidebar } from "../../../redux/actions/app_A";
import { updateUser } from "../../../redux/actions/auth_A";
import MobileSidebar from "../sidebar/MobileSidebar";
import { isIpadOS, navbar_height } from "../../../utils";
import { colors } from "../../../styles/theme";
import { CustomImg, MontP } from "../../../styles/common";
import { isPathDisabled } from "../../../utils";

import avatar from "../../../assets/images/avatar.svg";
import avatarChev from "../../../assets/images/avatar-chev.svg";

import ideafuse from "../../../assets/images/idea-fuse.png";
import menu from "../../../assets/icons/header-menu.svg";
import version from '../../../../package.json'

const AppbarCover = styled.div`
  height: ${navbar_height};
`;

const CustomAppbar = styled(AppBar)`
  height: ${navbar_height};
  justify-content: center;
`;

const CustomToolbar = styled(Toolbar)`
  height: ${navbar_height};
  background-color: ${colors.white};
  box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.25);
  z-index: 99;

  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;
`;

const SimpleNavbar = () => {
  const history = useHistory();
  const location = useLocation();

  const { user_data } = useSelector((state) => ({
    user_data: state.auth.user_data,
  }));

  const dispatch = useDispatch();

  const [profileEl, setProfileEl] = useState(null);

  const openProfileMenu = (event) => {
    setProfileEl(event.currentTarget);
  };

  const closeProfileMenu = () => {
    setProfileEl(null);
  };

  const handleLogout = () => {
    dispatch(updateUser(null)); // MSG  RESET user for current host
    if (process.env.NODE_ENV === "development") {
      window.location.replace("http://localhost:3000");
    } else {
      if(window.location.origin === "https://ideafuse-dev.netlify.app") {
        window.location.replace("https://ideafuse-dev.netlify.app");
      } else {
        window.location.replace("https://ideafuse.ai");
      }
    }
    dispatch(updateUser(null)); // MSG  RESET user info form Redirect host
  };

  useEffect(() => {
    console.log("User Data", user_data);
    if (user_data?.token) {
      const decodeToken = decode(user_data.token);
      if (decodeToken.exp * 1000 < new Date().getTime()) {
        handleLogout(); // NOTE  UNCOMMENT for PROD
        console.log("Token expired.");
      } else {
        console.log("Token valid.");
      }

      // PART  For Unauthorized Access
      // if (isPathDisabled(location.pathname, user_data.lstUserRoleAccess)) {
      //   history.push("/unauthorized");
      // }
    } else {
      handleLogout();
    }
  }, []);

  const handleSidebar = (status) => {
    dispatch(toggleMobileSidebar(status));
  };

  const renderProfileMenu = (
    <Menu
      anchorEl={profileEl}
      anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
      keepMounted
      transformOrigin={{ vertical: "top", horizontal: "right" }}
      open={Boolean(profileEl)}
      onClose={closeProfileMenu}
      className="mt-5"
    >
      <MenuItem button={false}>
        {`Customer ID: `}&nbsp;<b>{user_data?.customerInfo?.custId}</b>
      </MenuItem>
      <MenuItem button={false}>
        {`Subscription ID: `}&nbsp;
        <b>{user_data?.subscriptionInfo?.subscriptionId}</b>
      </MenuItem>
      <MenuItem button={false}>
        {`Email ID: `}&nbsp;
        <b>{user_data?.userInfo?.userEmail}</b>
      </MenuItem>

      <MenuItem onClick={handleLogout}>Logout</MenuItem>
      <MenuItem button={false}>
      {`Version: `}&nbsp;
        <b>v{version.version}</b>
      </MenuItem>
    </Menu>
  );

  return (
    <AppbarCover>
      <CustomAppbar position="static" className="shadow-none">
        <CustomToolbar className="px-2 px-md-4">
          <Link to="/home">
            <CustomImg
              src={ideafuse}
              alt="logo"
              w="15vw"
              tw={isIpadOS() ? "18vw" : "30vw"}
              mw="40vw"
            />
          </Link>
          <div className="d-flex align-items-center">
            <div className="me-md-5 d-none d-md-inline-block"></div>
            <MontP
              fs="15px"
              lh="20px"
              tfs="15px"
              tlh="20px"
              mfs="12px"
              mlh="16px"
              fw="600"
              c={colors.primary}
            >
              {user_data?.userInfo?.userFirstName}{" "}
              {user_data?.userInfo?.userLastName}
            </MontP>
            <div
              role="button"
              onClick={openProfileMenu}
              className="d-flex align-items-center ms-md-4 ms-3"
            >
              <CustomImg
                src={avatar}
                alt="avatar"
                w="45px"
                h="45px"
                tw="45px"
                th="45px"
                mw="35px"
                mh="35px"
                mr="6px"
              />
              <CustomImg src={avatarChev} alt="chevron" w="8px" h="11px" />
            </div>
            {window.innerWidth < 991 && (
              <div className="ms-auto">
                <IconButton onClick={() => handleSidebar(true)} color="inherit">
                  <CustomImg
                    src={menu}
                    alt="menu"
                    w={isIpadOS() ? "3vw" : "5vw"}
                  />
                </IconButton>
              </div>
            )}
          </div>
        </CustomToolbar>
      </CustomAppbar>

      {/* PART SIDEBAR */}
      <MobileSidebar />

      {renderProfileMenu}
    </AppbarCover>
  );
};

export default SimpleNavbar;
