import { useState, useRef, useEffect } from "react";
import { Row, Col, Container, Table } from "reactstrap";
import classnames from "classnames";
import DatePicker from "react-datepicker";
import { useSelector } from "react-redux";
import moment from "moment";
import styles from "../Adaption.module.css";
import DocPicker from "../../../components/DocPicker";
import { API, PATH } from "../../../services/Axios";
import { colors } from "../../../styles/theme";
import NotFoundMsg from "../../../components/NotFoundMsg";
import BtnSpinner from "../../../components/BtnSpinner";
import { CustomImg, MontP, NotoP } from "../../../styles/common";

import searchW from "../../../assets/icons/search-w.svg";
import MailTo from "../../../components/MailTo";
import SummaryCollapseable from "../../../components/SummaryCollapseable";

export default function DocumentsAdaption() {
  const { user_data } = useSelector((state) => ({
    user_data: state.auth.user_data,
  }));

  const [loading, setLoading] = useState(false);

  const minDate = new Date(moment().subtract(60, "days"));
  const startOfMonth = moment().startOf("month").format("YYYY-MM-DD hh:mm");
  const endOfMonth = moment().endOf("month").format("YYYY-MM-DD hh:mm");

  const [startDate, setStartDate] = useState(moment(startOfMonth).toDate());
  const [endDate, setEndDate] = useState(moment(endOfMonth).toDate());

  const [summaryData, setSummaryData] = useState(null);
  const trainingRef = useRef();
  const [tLoading, setTLoading] = useState(false);
  const [trainingDoc, setTrainingDoc] = useState(null);
  const annotationRef = useRef();
  const [aLoading, setALoading] = useState(false);
  const [annotationDoc, setAnnotationDoc] = useState(null);
  const kpiRef = useRef();
  const [kLoading, setKLoading] = useState(false);
  const [kpiDoc, setKpiDoc] = useState(null);

  const handleSearch = async () => {
    const data = {
      CustId: user_data?.customerInfo?.custId,
      FromDate: moment(startDate).format("YYYY-MM-DD"),
      ToDate: moment(endDate).format("YYYY-MM-DD"),
    };
    // console.log(data);
    setLoading(true);
    try {
      const result = await API.post(
        PATH.adaptionSummary,
        data,
        user_data.token
      );
      console.log(result);
      setSummaryData(result);
      setLoading(false);
    } catch (err) {
      console.log(err);
      setLoading(false);
    }
  };

  const getAdaptionSummary = async () => {
    let data = {
      CustId: user_data?.customerInfo?.custId,
    };
    setLoading(true);
    try {
      const result = await API.post(
        PATH.adaptionSummary,
        data,
        user_data.token
      );
      if (result) {
        setSummaryData(result);
      }
      setLoading(false);
    } catch (err) {
      console.log(err);
      setLoading(false);
    }
  };

  const uploadAdaptionDoc = (key, docFile) => {
    let fileFormData = new FormData();
    fileFormData.append("LoggedInUserId", user_data?.userInfo?.userEmail);
    fileFormData.append("CustId", user_data?.customerInfo?.custId);
    fileFormData.append("DocumentType", key);
    fileFormData.append("FileName", docFile.name);
    fileFormData.append("FileObject", docFile);

    return new Promise(async (resolve, reject) => {
      try {
        const result = await API.postFileKey(
          PATH.documentAdaption,
          fileFormData,
          user_data.token
        );
        resolve(result);
      } catch (err) {
        reject(err);
      }
    });
  };

  const handleUpload = async (key) => {
    switch (key) {
      case "T":
        if (trainingDoc) {
          setTLoading(true);
          try {
            const result = await uploadAdaptionDoc(key, trainingDoc);
            if (result) {
              summaryData.trainedDocStatusCompleted = true;
            }
            setTrainingDoc(null);
            setTLoading(false);
          } catch (err) {
            console.log(err);
            setTLoading(false);
          }
        }
        break;
      case "A":
        if (annotationDoc) {
          setALoading(true);
          try {
            const result = await uploadAdaptionDoc(key, annotationDoc);
            if (result) {
              summaryData.adaptionDocStatusCompleted = true;
            }
            setAnnotationDoc(null);
            setALoading(false);
          } catch (err) {
            console.log(err);
            setALoading(false);
          }
        }
        break;
      case "K":
        if (kpiDoc) {
          setKLoading(true);
          try {
            const result = await uploadAdaptionDoc(key, kpiDoc);
            if (result) {
              summaryData.kpiDocStatusCompleted = true;
            }
            setKpiDoc(null);
            setKLoading(false);
          } catch (err) {
            console.log(err);
            setKLoading(false);
          }
        }
        break;

      default:
        break;
    }
  };

  const handleDocPicker = (e) => {
    const { name, files } = e.target;
    console.log(name);

    if (files.length > 0) {
      switch (name) {
        case "training":
          setTrainingDoc(files[0]);
          break;
        case "annotation":
          setAnnotationDoc(files[0]);
          break;
        case "kpi":
          setKpiDoc(files[0]);
          break;

        default:
          break;
      }
    }
  };

  return (
    <Row className="mt-5 ps-4 pe-2">
      <Col xs={12} sm={12} md={4}>
        <DocPicker
          title="Training document"
          setFile={setTrainingDoc}
          inputRef={trainingRef}
          inputName="training"
          handleChange={handleDocPicker}
          filename={trainingDoc?.name}
          loading={tLoading}
          handleUpload={() => handleUpload("T")}
          dotColor={
            summaryData
              ? summaryData?.trainedDocStatusCompleted
                ? colors.green
                : colors.yellow
              : colors.disable
          }
          accept='.zip'
          //active={summaryData?.trainedDocStatusCompleted}
        />
        <DocPicker
          title="Annonation document"
          setFile={setAnnotationDoc}
          inputRef={annotationRef}
          inputName="annotation"
          handleChange={handleDocPicker}
          filename={annotationDoc?.name}
          loading={aLoading}
          handleUpload={() => handleUpload("A")}
          dotColor={
            summaryData
              ? summaryData?.adaptionDocStatusCompleted
                ? colors.green
                : colors.yellow
              : colors.disable
          }
          accept='.zip'
          //active={summaryData?.adaptionDocStatusCompleted}
        />
        <DocPicker
          title="KPI mapping document"
          setFile={setKpiDoc}
          inputRef={kpiRef}
          inputName="kpi"
          handleChange={handleDocPicker}
          filename={kpiDoc?.name}
          loading={kLoading}
          handleUpload={() => handleUpload("K")}
          dotColor={
            summaryData
              ? summaryData?.kpiDocStatusCompleted
                ? colors.green
                : colors.yellow
              : colors.disable
          }
          accept='.zip'
          //active={summaryData?.kpiDocStatusCompleted}
        />
      </Col>
      <Col xs={12} sm={12} md={8}>
        <div className="d-flex align-items-end justify-content-between flex-wrap">
          <div className="mt-2 mt-md-0">
            <NotoP fs="12px" lh="19px" fw="600" c={colors.primary}>
              From Date
            </NotoP>
            <DatePicker
              selected={startDate}
              onChange={(date) => setStartDate(date)}
              minDate={minDate}
              maxDate={endDate}
              customInput={
                <button className={styles.datebtn}>
                  {moment(startDate).format("MM/DD/YYYY")}
                </button>
              }
            />
          </div>
          <div className="mt-2 mt-md-0">
            <NotoP fs="12px" lh="19px" fw="600" c={colors.primary}>
              To Date
            </NotoP>
            <DatePicker
              selected={endDate}
              onChange={(date) => setEndDate(date)}
              minDate={startDate}
              //maxDate={endDate}
              customInput={
                <button className={styles.datebtn}>
                  {moment(endDate).format("MM/DD/YYYY")}
                </button>
              }
            />
          </div>
          <button
            disabled={loading}
            onClick={handleSearch}
            // onClick={getAdaptionSummary}
            className={classnames(styles.searchbtn, "mt-2 mt-md-0")}
          >
            {loading ? (
              <BtnSpinner />
            ) : (
              <CustomImg src={searchW} w="20px" h="20px" mr="10px" />
            )}
            Search
          </button>
        </div>
        <div className="card bg-transparent border-0 my-2">
          <MontP fs="11px" lh="15px" fw="500" c="#03256c">
            Historically you can go back 60 days for batch Summary details. Send
            an email to{" "}
            <MailTo email="arka.admin@ideafuse.ai" subject="" body="">
              <span>arka.admin@ideafuse.ai</span>
            </MailTo>{" "}
            for information older than 60 days.
          </MontP>
        </div>
        {/* TABLES */}
        {summaryData?.lstAdaptionDetails?.length > 0 ? (
          <Table borderless responsive>
            <thead>
              <tr>
                <th>Adaption Upload</th>
                <th>Creation Date</th>
                <th>Adaption Type</th>
                <th>File Name</th>
                <th>Status</th>
                <th>Training Status</th>
              </tr>
            </thead>
            <tbody>
              {summaryData.lstAdaptionDetails.map((item, index) => (
                <SummaryCollapseable
                  key={index}
                  bgColor={
                    //index % 2 === 0 ? colors.red : colors.blue
                    index % 2 === 0 ? "rgba(37, 65, 178, 0.1)" : colors.white
                  }
                  item={item}
                />
              ))}
            </tbody>
          </Table>
        ) : (
          <>
            {loading ? <NotFoundMsg msg="Please wait..." /> : <NotFoundMsg />}
          </>
        )}
      </Col>
    </Row>
  );
}
