import React, { useState } from "react";
import classnames from "classnames";
import styles from "./Administration.module.css";
import {
  Container,
  TabContent,
  TabPane,
  Nav,
  NavItem,
  NavLink,
  Row,
  Col,
  Card,
} from "reactstrap";
import { useSelector } from "react-redux";

import Profile from "./Tabs/Profile";
import ContactDetails from "./Tabs/ContactDetails";
import ChangePassword from "./Tabs/ChangePassword";
import TabsCover from "../../components/tabbar/TabsCover";
import MyTab from "../../components/tabbar/MyTab";
import { useAccessTabs } from "../../hooks/useAccessTabs";
import AddressDetails from "./Tabs/AddressDetails";

function Administration() {
  const { user_data } = useSelector((state) => ({
    user_data: state.auth.user_data,
  }));

  const [tabs, activeTab, setActiveTab] = useAccessTabs();
  console.log(tabs);

  const toggle = (tab) => {
    if (activeTab !== tab) setActiveTab(tab);
  };

  return (
    <Container fluid className="pb-5">
      <Row className="justify-content-center mt-4">
        <Col xs={12} sm={12} md={12} className="py-5">
          <Nav tabs className="justify-content-center border-0">
            <TabsCover>
              {tabs.slice(0, 2).map((item, index) => (
                <MyTab
                  title={item.menuTabName}
                  onClick={() => toggle(item.menuTabName_path)}
                  isActive={activeTab === item.menuTabName_path}
                />
              ))}
              <MyTab
                title='Address Details'
                onClick={() => toggle('4')}
                isActive={activeTab === '4'}
              />
              {tabs.slice(2).map((item, index) => (
                <MyTab
                  title={item.menuTabName}
                  onClick={() => toggle(item.menuTabName_path)}
                  isActive={activeTab === item.menuTabName_path}
                />
              ))}
            </TabsCover>
          </Nav>
         
              <TabContent activeTab={activeTab} className="position-relative">
                <TabPane tabId="1">
                  <Profile user_data={user_data} />
                </TabPane>
                <TabPane tabId="2">
                  <ContactDetails user_data={user_data} />
                </TabPane>

                <TabPane tabId="4">
                  <AddressDetails user_data={user_data} />
                </TabPane>

                <TabPane tabId="3">
                  <ChangePassword user_data={user_data} />
                </TabPane>
              </TabContent>

        </Col>
      </Row>
    </Container >
  );
}

export default Administration;
