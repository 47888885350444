import { useState, useEffect } from "react";
import {
  Row,
  Col,
  Table,
  Card,
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Container,
} from "reactstrap";
import moment from "moment";
import classnames from "classnames";
import { useSelector } from "react-redux";
import ReportCards from "../../components/ReportCards";
import { CustomImg, MontP, NotoP } from "../../styles/common";
import { colors } from "../../styles/theme";
import styles from "./Cost.module.css";
import chev_down from "../../assets/icons/chev-down.svg";
import MailTo from "../../components/MailTo";
import { API, PATH } from "../../services/Axios";
import NotFoundMsg from "../../components/NotFoundMsg";

export default function Cost() {
  const { user_data } = useSelector((state) => ({
    user_data: state.auth.user_data,
  }));

  const [loading, setLoading] = useState(false);

  const minDate = new Date(moment().subtract(12, "months"));
  const startOfMonth = moment().startOf("month").format("YYYY-MM-DD hh:mm");
  const endOfMonth = moment().endOf("month").format("YYYY-MM-DD hh:mm");

  const [startDate, setStartDate] = useState(moment(startOfMonth).toDate());
  const [endDate, setEndDate] = useState(moment(endOfMonth).toDate());

  const [showPaymentType, setShowPaymentType] = useState(false);
  const [paymentType, setPaymentType] = useState("UnPaid"); // UnPaid, Paid

  const [invoicesData, setInvoicesData] = useState(null);

  const [enableCall, setEnableCall] = useState(false);

  const handleSearch = async () => {
    const data = {
      CustId: user_data?.customerInfo?.custId,
      InvoiceStatus: paymentType,
      FromDate: moment(startDate).format("YYYY-MM-DD"),
      ToDate: moment(endDate).format("YYYY-MM-DD"),
    };
    // console.log(data);
    setLoading(true);
    try {
      const result = await API.post(PATH.invoiceDetails, data, user_data.token);
      // console.log(result);
      if (result) {
        setInvoicesData(result);
      }
      setLoading(false);
    } catch (err) {
      console.log(err);
      setLoading(false);
    }
  };

  useEffect(() => {
    if (enableCall) {
      handleSearch();
    } else {
      setEnableCall(true);
    }
  }, [paymentType]);

  // useEffect(() => {
  //   if (enableCall) {
  //     handleSearch();
  //   } else {
  //     setEnableCall(true);
  //   }
  // }, [startDate, endDate, paymentType]);

  const togglePaymentType = () => {
    setShowPaymentType(!showPaymentType);
  };

  return (
    <Container fluid className="p-4 mt-5 px-md-5">
      <ReportCards
        firstText="Amount Due"
        firstValue={`$${invoicesData?.invoiceSummary?.amountDue || 0}`}
        secondText="Documents Processed"
        secondValue={invoicesData?.invoiceSummary?.documentsProcessed || 0}
        thirdText="Accured amount"
        thirdValue={`$${invoicesData?.invoiceSummary?.accuredAmount || 0}`}
        fourthText="Rate bill ($)"
        fourthValue={invoicesData?.invoiceSummary?.billRate || 0}
        minDate={minDate}
        startDate={startDate}
        setStartDate={setStartDate}
        endDate={endDate}
        setEndDate={setEndDate}
        loading={loading}
        handleSubmit={handleSearch}
      />
      <div className="card bg-transparent border-0 mt-4 mb-3">
        <MontP fs="14px" lh="22px" fw="normal" c="#03256c">
          Historically you can go back 12 months for Invoice Summary. Send an
          email to{" "}
          <MailTo email="arka.admin@ideafuse.ai" subject="Subject" body="Body">
            <span style={{ fontWeight: "600" }}>arka.admin@ideafuse.ai</span>
          </MailTo>{" "}
          for historical information more than 12 months.
        </MontP>
      </div>
      {/* INVOICES */}
      <Row>
        <Col md="12">
          <div className="d-flex align-items-center justify-content-between mb-3">
            <MontP
              fs="30px"
              lh="40px"
              mfs="24px"
              mlh="30px"
              b
              c={colors.primary}
            >
              Invoices
            </MontP>
            <Dropdown isOpen={showPaymentType} toggle={togglePaymentType}>
              <DropdownToggle className={styles.curvebtn}>
                <NotoP fs="14px" lh="20px" fw="600" c={colors.white}>
                  {paymentType}
                </NotoP>
                <CustomImg src={chev_down} w="12px" h="8px" />
              </DropdownToggle>

              <DropdownMenu>
                {["UnPaid", "Paid"].map((x, i) => (
                  <DropdownItem
                    key={i}
                    disabled={paymentType === x}
                    onClick={() => setPaymentType(x)}
                  >
                    <NotoP fs="14px" lh="20px" fw="600" c={colors.secondary}>
                      {x}
                    </NotoP>
                  </DropdownItem>
                ))}
              </DropdownMenu>
            </Dropdown>
          </div>
          {/* TABLE */}
          {invoicesData?.invoiceDetails?.length > 0 ? (
            <Card className="overflow-auto">
              <Table borderless>
                <thead>
                  <tr className={styles.theadtr}>
                    <th>
                      <MontP
                        fs="16px"
                        lh="20px"
                        mfs="13px"
                        mlh="17px"
                        fw="800"
                        c={colors.secondary}
                      >
                        Status
                      </MontP>
                    </th>
                    <th>
                      <MontP
                        fs="16px"
                        lh="20px"
                        mfs="13px"
                        mlh="17px"
                        fw="800"
                        c={colors.secondary}
                      >
                        Invoice Number
                      </MontP>
                    </th>
                    <th>
                      <MontP
                        fs="16px"
                        lh="20px"
                        mfs="13px"
                        mlh="17px"
                        fw="800"
                        c={colors.secondary}
                      >
                        Invoice Generated on
                      </MontP>
                    </th>
                    <th>
                      <MontP
                        fs="16px"
                        lh="20px"
                        mfs="13px"
                        mlh="17px"
                        fw="800"
                        c={colors.secondary}
                      >
                        Amount
                      </MontP>
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {invoicesData.invoiceDetails.map((item, index) => (
                    <tr key={index} className={styles.tbodytr}>
                      <td>
                        <div
                          className={classnames(styles.redcircle, "mx-auto")}
                          style={{
                            backgroundColor:
                              item.invoiceStatus === "Paid"
                                ? colors.green
                                : colors.red,
                          }}
                        />
                      </td>
                      <td>
                        <NotoP
                          fs="16px"
                          lh="20px"
                          mfs="13px"
                          mlh="17px"
                          fw="600"
                          c={colors.black}
                        >
                          {item.invoiceNumber}
                        </NotoP>
                      </td>
                      <td>
                        <NotoP
                          fs="16px"
                          lh="20px"
                          mfs="13px"
                          mlh="17px"
                          fw="600"
                          c={colors.black}
                        >
                          {moment(item.invoiceDate).format("MM/DD/YYYY")}
                        </NotoP>
                      </td>
                      <td>
                        <NotoP
                          fs="16px"
                          lh="20px"
                          mfs="13px"
                          mlh="17px"
                          fw="600"
                          c={colors.black}
                        >
                          `${item.invoiceAmount}`
                        </NotoP>
                      </td>
                      <td>
                        {item.invoiceStatus !== "Paid" && (
                          <button className={styles.paynow}>
                            <NotoP
                              fs="14px"
                              lh="20px"
                              mfs="12px"
                              mlh="16px"
                              fw="600"
                              c={colors.white}
                            >
                              Pay now
                            </NotoP>
                          </button>
                        )}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </Table>
            </Card>
          ) : (
            <>
              {loading ? <NotFoundMsg msg="Please wait..." /> : <NotFoundMsg />}
            </>
          )}
        </Col>
      </Row>
    </Container>
  );
}
