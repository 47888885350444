import classNames from "classnames";
import PropTypes from "prop-types";
import { MontP, NotoP } from "../styles/common";
import BtnSpinner from "./BtnSpinner";
import styles from "./Components.module.css";

function ConfirmCard({
  title,
  message,
  cancelText,
  confirmText,
  handleCancel,
  handleConfirm,
  width,
  height,
  loading,
}) {
  return (
    <div
      className={styles.confirmcard}
      style={{ width: width, height: height }}
    >
      <MontP fs="16px" lh="22px" b c="#000">
        {title}
      </MontP>
      <MontP fs="14px" lh="19px" fw="400" c="#000" mt="20px">
        {message}
      </MontP>
      <div className={styles.confirmcard_btnscover}>
        <button onClick={handleCancel} className={styles.confirmcard_btn}>
          <NotoP fs="14px" lh="19px" fw="500" c="#03256C">
            {cancelText}
          </NotoP>
        </button>
        <button
          disabled={loading}
          onClick={handleConfirm}
          className={classNames(
            styles.confirmcard_btn,
            "ms-3 content_center--flex"
          )}
          style={{ backgroundColor: "#03256C" }}
        >
          {loading && <BtnSpinner />}
          <NotoP fs="14px" lh="19px" fw="500" c="#fff">
            {confirmText}
          </NotoP>
        </button>
      </div>
    </div>
  );
}

ConfirmCard.propTypes = {
  title: PropTypes.string,
  message: PropTypes.string,
  cancelText: PropTypes.string,
  confirmText: PropTypes.string,
  handleCancel: PropTypes.func,
  handleConfirm: PropTypes.func,
  width: PropTypes.string,
  height: PropTypes.string,
  loading: PropTypes.bool,
};

ConfirmCard.defaultProps = {
  width: "320px",
  height: "240px",
};

export default ConfirmCard;
