import styled from "styled-components";
import { useSelector, useDispatch } from "react-redux";
import Drawer from "@material-ui/core/Drawer";
import { toggleMobileSidebar } from "../../../redux/actions/app_A";
import SidebarMenu from "./SidebarMenu";
import SidebarAppLogo from "./SidebarAppLogo";
import adminMenus from "../../../menus/adminMenus";
import userBottomMenus from "../../../menus/userBottomMenus";
import { colors } from "../../../styles/theme";
import { CustomImg } from "../../../styles/common";

import close from "../../../assets/icons/close-menu.svg";
import SidebarBottomMenu from "./SidebarBottomMenu";
import asset_icon_white from '../../../menus/icons/approval_white.svg'
import asset_icon from '../../../menus/icons/approval.svg'

const SidebarCover = styled.div`
  background: ${colors.white};
  width: 300px;
  height: 100vh;
`;

const IconCover = styled.div`
  position: absolute;
  right: 10px;
  top: 10px;
  z-index: 200;
  width: 22px;
  height: 22px;
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
`;

const Backdrop = styled.div`
  background-color: transparent;
  width: 100%;
  height: 100%;
`;

const LineDiv = styled.div`
  border-top: 2px solid #d3d2f9;
  margin: 16px auto;
  width: 90%;
`;

const MobileSidebar = () => {
  const { mobile_sidebar, user_data } = useSelector((state) => ({
    mobile_sidebar: state.app.mobile_sidebar,
    user_data: state.auth.user_data,
  }));
  const dispatch = useDispatch();

  const activeMenu = user_data ? adminMenus : [];
  const activeSubmenu = user_data ? userBottomMenus : [];

  const handleSidebar = (status) => {
    dispatch(toggleMobileSidebar(status));
  };

  return (
    <Drawer
      anchor="left"
      open={mobile_sidebar}
      onClose={() => handleSidebar(false)}
      onKeyDown={() => handleSidebar(false)}
      BackdropComponent={() => (
        <Backdrop onClick={() => handleSidebar(false)} />
      )}
    >
      <SidebarCover onClick={() => handleSidebar(false)}>
        {/* <IconCover onClick={() => handleSidebar(false)}>
          <CustomImg src={close} alt="close" w="4vw" />
        </IconCover> */}
        <SidebarAppLogo />
        {activeMenu.slice(0, 5).map((item, index) => (
          <SidebarMenu user_data={user_data} item={item} key={index} />
        ))}
        {activeMenu.slice(5).map((item, index) => (
          <SidebarMenu user_data={user_data} item={item} key={index} />
        ))}
        <LineDiv />
        {activeSubmenu.map((item, index) => (
          <SidebarBottomMenu item={item} key={index} />
        ))}
      </SidebarCover>
    </Drawer>
  );
};

export default MobileSidebar;
