import { useState } from "react";
import { useSelector } from "react-redux";
import { Link, useHistory, useLocation } from "react-router-dom";
import styled from "styled-components";
import { Collapse } from "reactstrap";
import { IconButton, ClickAwayListener } from "@material-ui/core";
import { ElementPos, MontP, NotoP } from "../styles/common";
import { colors } from "../styles/theme";

import ideafuse from "../assets/images/idea-fuse.png";
import menu from "../assets/icons/header-menu.svg";
import close from "../assets/icons/close-menu.svg";
import chevRight from "../assets/icons/nav-chev-right.svg";
import chevDown from "../assets/icons/nav-chev-down.svg";

const HeaderDiv = styled.div`
  height: 60px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 2vh 3vw 1vh 1vw;
`;

const MenuDiv = styled.div`
  position: relative;
  z-index: 999;
`;

const MenuCover = styled.div`
  height: 99vh;
  width: 26vw;
  background: #d7eaf8;
  border-radius: 20px 0px 0px 20px;
  padding: 20px;
  position: absolute;
  top: -5vh;
  right: -3vw;
  overflow-y: auto;
  transition: all 0.3s;
  transform-origin: right;

  transform: ${({ open }) => (open ? "rotateY(0deg)" : "rotateY(90deg)")};

  @media only screen and (max-width: 768px) {
    width: 80vw;
  }
  @media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (-webkit-min-device-pixel-ratio: 2) {
    width: 40vw;
    top: -1vh;
  }
`;

const CloseCover = styled.div`
  display: flex;
  justify-content: flex-end;
`;

const NavDiv = styled.div`
  height: 55%;
`;

const ConnectDiv = styled.div`
  height: 35%;
  width: 80%;
  border-top: 1px solid #000000;
`;

const NotoPre = styled.pre`
  font-family: Noto Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 15px;
  line-height: 20px;
  color: #0b1b4d;

  @media only screen and (max-width: 768px) {
    font-size: 12px;
    line-height: 16px;
  }

  @media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (-webkit-min-device-pixel-ratio: 2) {
    font-size: 15px;
    line-height: 20px;
  }
`;

const SubmenuDiv = styled.div`
  margin-left: 2vw;
  transition: all 1s;
`;

export default function Header() {
  const history = useHistory();
  const { pathname, hash } = useLocation();
  const { user_data } = useSelector((state) => ({
    user_data: state.auth.user_data,
  }));

  const [, setRandomNo] = useState(0);
  const [screens] = useState([
    { name: "Home", route: "/#home" },
    { name: "About Us", route: "/about-us" },
    {
      name: "Products",
      route: "/#product",
      submenu: [
        {
          name: "ARKA",
          route: "/#arka",
          //submenu: [{ name: "Request a Demo", route: "/request-demo" }],
        },
      ],
    },
    { name: "Service(s) Catalog", route: "/#catalog" },
    { name: "Alliances & Partners", route: "/#partnership" },
    { name: "Request a Demo", route: "/request-demo" },
    { name: "Contact Us", route: "/#contact-us" },
    { name: "Sign In", route: "/registered-email" },
  ]);

  const [open, setOpen] = useState(false);

  const handleMenuClick = () => {
    setOpen((prev) => !prev);
  };

  const handleMenuClickAway = () => {
    setOpen(false);
  };

  const handleMouseEnterLeave = (item) => {
    if (item.submenu) {
      item.showSubmenu = item.showSubmenu ? false : true;
      setRandomNo(Math.random(3));
    }
  };

  const showSignin = () => {
    // console.log(">>>>>", pathname, hash);
    if (
      pathname === "/" ||
      pathname === "/privacy-policy" ||
      pathname === "/request-demo" ||
      pathname === "/about-us" ||
      hash === "#product" ||
      hash === "#arka" ||
      hash === "#home" ||
      hash === "#catalog" ||
      hash === "#partnership" ||
      hash === "#contact-us"
    ) {
      return true;
    }
    return false;
  };

  const showRequestDemo = () => {
    if (
      pathname === "/" ||
      pathname === "/privacy-policy" ||
      pathname === "/registered-email" ||
      pathname === "/about-us" ||
      hash === "#product" ||
      hash === "#arka" ||
      hash === "#home" ||
      hash === "#catalog" ||
      hash === "#partnership" ||
      hash === "#contact-us"
    ) {
      return true;
    }
    return false;
  };

  const navToNext = (e, route) => {
    e.stopPropagation();
    if (process.env.NODE_ENV === "development") {
      window.location.replace(`http://localhost:3000${route}`);
    } else {
      window.location.replace(`"https://www.ideafuse.ai"${route}`);
    }
    // history.replace(route);
    setOpen(false);
  };

  const navToParent = (e, route) => {
    e.stopPropagation();
    if (
      window.location.hostname === "www.ideafuse.ai" ||
      window.location.hostname === "ideafuse.ai" ||
      window.location.origin === "http://localhost:3000" ||
      window.location.origin === "https://ideafuse-dev.netlify.app"
    ) {
      window.location.replace(`${window.location.origin}${route}`);
    } else {
      window.location.replace(`https://www.ideafuse.ai${route}`);
    }
  };

  return (
    <HeaderDiv>
      <span
        role="button"
        onKeyPress={(e) => navToNext(e, user_data ? "/" : "/#home")}
        onClick={(e) => navToNext(e, user_data ? "/" : "/#home")}
      >
        <img src={ideafuse} alt="logo" className="header-logo" />
      </span>
      <div className="d-flex align-items-center">
        {showRequestDemo() && (
          <button
            onClick={(e) => navToParent(e, "/request-demo")}
            className="header-signIn--btn me-md-4 me-2"
            style={{ zIndex: 99 }}
          >
            <NotoP
              fs="12px"
              lh="16px"
              tfs="12px"
              tlh="16px"
              mfs="10px"
              mlh="12px"
              fw="600"
              c={colors.white}
            >
              Request a Demo
            </NotoP>
          </button>
        )}
        {showSignin() && (
          <button
            onClick={(e) => navToParent(e, "/registered-email")}
            className="header-signIn--btn"
            style={{ zIndex: 99 }}
          >
            <NotoP
              fs="12px"
              lh="16px"
              tfs="12px"
              tlh="16px"
              mfs="10px"
              mlh="12px"
              fw="600"
              c={colors.white}
            >
              Sign In
            </NotoP>
          </button>
        )}
        <ClickAwayListener onClickAway={handleMenuClickAway}>

          <MenuDiv className="position-relative">
            <IconButton onClick={handleMenuClick} size="small">
              <img src={menu} alt="menu" className="header-menu" />
            </IconButton>
            <MenuCover open={open}>
              <CloseCover className="me-md-4 mt-md-3">
                <IconButton onClick={handleMenuClick} size="small">
                  <img src={close} alt="close" className="header-menu" />
                </IconButton>
              </CloseCover>
              <NavDiv>
                {screens.map((item, index) => (
                  <div
                    onMouseEnter={() => handleMouseEnterLeave(item)}
                    onMouseLeave={() => handleMouseEnterLeave(item)}
                    className="position-relative mb-3"
                  >
                    <div
                      key={index}
                      role="button"
                      onKeyPress={(e) => navToParent(e, item.route)}
                      onClick={(e) => navToParent(e, item.route)}
                      className="d-flex align-items-center"
                    >
                      <MontP
                        fs="20px"
                        lh="25px"
                        tfs="20px"
                        tlh="25px"
                        mfs="16px"
                        mlh="20px"
                        fw="600"
                        mr="10px"
                      >
                        {item.name}
                      </MontP>
                      {item.submenu && (
                        <img
                          src={item.showSubmenu ? chevDown : chevRight}
                          alt="chev"
                          width="14px"
                          height="14px"
                        />
                      )}
                    </div>
                    <Collapse isOpen={item.showSubmenu}>
                      {item.submenu && (
                        <SubmenuDiv>
                          {item.submenu.map((subitm, subidx) => (
                            <span
                              key={subidx}
                              role="button"
                              onKeyPress={(e) => navToParent(e, subitm.route)}
                              onClick={(e) => navToParent(e, subitm.route)}
                              onMouseEnter={() => handleMouseEnterLeave(subitm)}
                              onMouseLeave={() => handleMouseEnterLeave(subitm)}
                            >
                              <MontP
                                fs="16px"
                                lh="22px"
                                tfs="16px"
                                tlh="22px"
                                mfs="13px"
                                mlh="18px"
                                mt="15px"
                                fw="600"
                              >
                                {subitm.name}
                              </MontP>
                              <Collapse isOpen={subitm.showSubmenu}>
                                {subitm.submenu && (
                                  <SubmenuDiv>
                                    {subitm.submenu.map((superitm) => (
                                      <span
                                        key={subidx}
                                        role="button"
                                        onKeyPress={(e) =>
                                          navToParent(e, superitm.route)
                                        }
                                        onClick={(e) =>
                                          navToParent(e, superitm.route)
                                        }
                                      >
                                        <MontP
                                          fs="14px"
                                          lh="18px"
                                          tfs="14px"
                                          tlh="18px"
                                          mfs="12px"
                                          mlh="14px"
                                          mt="10px"
                                          fw="600"
                                        >
                                          {superitm.name}
                                        </MontP>
                                      </span>
                                    ))}
                                  </SubmenuDiv>
                                )}
                              </Collapse>
                            </span>
                          ))}
                        </SubmenuDiv>
                      )}
                    </Collapse>
                  </div>
                ))}
              </NavDiv>
              <ConnectDiv className="pt-3">
                <MontP
                  fs="22px"
                  lh="28px"
                  tfs="22px"
                  tlh="28px"
                  mfs="16px"
                  mlh="20px"
                  fw="600"
                >
                  connect with us
                </MontP>
                <NotoPre>
                  {`IDEAFUSE AI SOLUTIONS LLC\n244 S Randall Rd\nSuite # 1071\nElgin, Illinois U.S.A 60123`}
                </NotoPre>
                <div className="d-flex align-items-center ipad-pro--zoom">
                  <MontP fs="13px" mfs="11px" b mr="4px">
                    Email:
                  </MontP>
                  <MontP fs="14px" fw="400" mfs="12px">
                    info@ideafuse.ai
                  </MontP>
                </div>
              </ConnectDiv>
              <ElementPos b="2vh" r="1vw" mr="4vw">
                <a
                  href="https://www.ideafuse.ai/privacy-policy"
                  className="text-decoration-none"
                >
                  <NotoP fs="14px" tfs="14px" mfs="12px" fw="600">
                    Privacy Policy
                  </NotoP>
                </a>
              </ElementPos>
            </MenuCover>
          </MenuDiv>

        </ClickAwayListener>
      </div>
    </HeaderDiv>
  );
}
