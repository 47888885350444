import { useState } from "react";
import PropTypes from "prop-types";
import {
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap";
import styles from "../Components.module.css";
import { CustomImg, MontP, NotoP } from "../../styles/common";
import { colors } from "../../styles/theme";

import chev_down from "../../assets/icons/chev-down.svg";

const GraphHeader = (props) => {
  const { title, btnText, count, setCount, options } = props;

  const [open, setOpen] = useState(false);

  return (
    <div className="d-flex align-items-center justify-content-between">
      <MontP fs="20px" lh="29px" mfs="16px" mlh="20px" b c={colors.blue5}>
        {title}
      </MontP>
      <Dropdown isOpen={open} toggle={() => setOpen(!open)}>
        <DropdownToggle className={styles.costcomp_curvebtn}>
          <NotoP fs="10px" lh="13px" fw="600" c={colors.white} mr="5px">
            {btnText}
          </NotoP>
          <CustomImg src={chev_down} w="12px" h="8px" />
        </DropdownToggle>
        <DropdownMenu>
          {options.map((x, i) => (
            <DropdownItem
              key={i}
              disabled={count === x}
              onClick={() => setCount(x)}
              className="text-center"
            >
              <NotoP fs="13px" lh="18px" b c={colors.secondary}>
                {x}
              </NotoP>
            </DropdownItem>
          ))}
        </DropdownMenu>
      </Dropdown>
    </div>
  );
};

GraphHeader.propTypes = {
  title: PropTypes.string,
  btnText: PropTypes.string,
  count: PropTypes.number,
  setCount: PropTypes.func,
  options: PropTypes.array,
};

GraphHeader.defaultProps = {
  options: [],
};

export default GraphHeader;
