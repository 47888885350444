import { createReducer } from "@reduxjs/toolkit";
import * as types from "../types";

const initialState = {
  app_name: "ideafuse.ai",
  mobile_sidebar: false,
  main_sidebar: true,
};

const reducer = createReducer(initialState, (builder) => {
  builder.addCase(types.APP_NAME, (state, action) => {
    state.app_name = action.payload;
  });
  builder.addCase(types.MOBILE_SIDEBAR, (state, action) => {
    state.mobile_sidebar = action.payload;
  });
  builder.addCase(types.MAIN_SIDEBAR, (state, action) => {
    state.main_sidebar = !state.main_sidebar;
  });
});

export default reducer;
