import { useState } from "react";
import PropTypes from "prop-types";
import { Row, Card, Col, Dropdown, DropdownToggle, DropdownMenu, DropdownItem } from "reactstrap";
import validator from "validator";
import { useSelector } from "react-redux";
import TitleInput from "../../../components/TitleInput";
import { colors } from "../../../styles/theme";
import { CustomImg, MiButton, MontP, NotoP } from "../../../styles/common";
import chev_down from "../../../assets/icons/chev-down.svg";
import styles from "../User.module.css";
import { API, PATH } from "../../../services/Axios";
import BtnSpinner from "../../../components/BtnSpinner";
import { notify } from "../../../utils";
import { useEffect } from "react";
import styled from "styled-components";

const initialUser = {
  firstName: "",
  lastName: "",
  email: "",
  phoneNumber: "",
};

const CustomDropdownToggle = styled(DropdownToggle)`
width: 100%;
height: 40px;
border-radius: 49px;
background: #03256c;
display: flex;
align-items: center;
justify-content: space-between;
padding: 0 15px;
outline: none;
border: none;
z-index: 10;

&:hover,
&:active,
&:focus {
  background-color: #03256c;
  outline: none;
  border: none;
  box-shadow: none;
}
`;
export default function AddUsers({ userType, usersList, user_data, getUsersList }) {

  const [loading, setLoading] = useState(false);

  const [userSummaryData, setUserSummaryData] = useState([]);
  const [user, setUser] = useState(initialUser);

  const [role, setRole] = useState("");
  const [showError, setShowError] = useState(false);

  const { firstName, lastName, phoneNumber, email } = user;

  const [showServerType, setShowServerType] = useState(false);
  const [serverType, setServerType] = useState('Select Role');

  const toggleServerType = () => {
    setShowServerType(!showServerType);
  };

  const handleServerType = (x) => {
    setServerType(x);
    setRole(x);
  };

  const showUserSummary = () => {
    let userSummaryArray = [];

    for (const key in usersList?.usersSummary) {
      const value = usersList?.usersSummary[key];

      if (key === 'totalUsers') {
        userSummaryArray.push({
          id: 1,
          name: key.replace('totalUsers', 'Total Users'),
          value: value
        });
      }
      else if (key === 'totalActiveUsers') {
        userSummaryArray.push({
          id: 2,
          name: key.replace('total', '').replace('User', " User"),
          value: value
        });
      }
      else if (key === 'totalInActiveUsers') {
        userSummaryArray.push({
          id: 3,
          name: key.replace('total', '').replace('User', " User"),
          value: value
        });
      }
      else {
        userSummaryArray.push({
          id: userSummaryArray.length + 3,
          name: key.replace('total', '').replace('User', " User"),
          value: value
        });
      }

    }
    setUserSummaryData(userSummaryArray?.sort((a, b) => a.id - b.id));
  };

  useEffect(() => {
    showUserSummary();
  }, [usersList, user_data]);

  const handleSave = async () => {
    const conditions =
      validator.isAlpha(firstName) &&
      validator.isAlpha(lastName) &&
      validator.isMobilePhone(phoneNumber) &&
      validator.isEmail(email);

    if (conditions) {

      const data = {
        CustId: user_data?.customerInfo?.custId,
        UserId: email,
        FirstName: firstName,
        LastName: lastName,
        Email: email,
        Phone: phoneNumber,
        StrUserRoleId: role,
      };
      // console.log(data);
      setLoading(true);
      try {
        const result = await API.post(PATH.newUser, data, user_data.token);
        console.log(result);
        if (result) {
          notify("User Successfully Added", true);
          setUser(initialUser);
          getUsersList();
        } else {
          notify(
            "Error encountered adding the user, please send an email to aka.admin@ideafuse.ai with error details or create an issue using the Issue Management portal"
          );
        }
        setLoading(false);
      } catch (err) {
        console.log(err);
        notify(
          "Error encountered adding the user, please send an email to aka.admin@ideafuse.ai with error details or create an issue using the Issue Management portal"
        );
        setLoading(false);
      }
    } else {
      setShowError(true);
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setUser((prev) => ({ ...prev, [name]: value }));
  };

  console.log('userType', userType)
  return (
    <Row className="mt-5">
      <Col xm={12} lg={4} className="p-0">
        <Card className={styles.reportcard}>

          {
            userSummaryData.map((item) => <div key={item.id} className="d-flex align-items-center justify-content-between mb-2">
              <MontP fs="18px" lh="25px" mfs="14px" mlh="19px" b c={colors.white}>
                {item.name}
              </MontP>
              <MontP
                fs="18px"
                lh="25px"
                mfs="14px"
                mlh="19px"
                fw="500"
                c={colors.white}
              >
                {item.value}
              </MontP>
            </div>)
          }
        </Card>
      </Col>

      <Col xm={12} lg={8} className="p-0">
        <Card className={styles.formcard}>
          <MontP
            fs="22px"
            lh="28px"
            b
            c={colors.primary}
            className="text-center"
          >
            Add new User
          </MontP>
          <TitleInput
            label="First Name"
            name="firstName"
            value={firstName}
            onChange={handleChange}
            placeholder="Enter user’s first name"
            showerr={showError}
            errmsg="First name contains only letters (a-zA-Z)"
            valid={validator.isAlpha(firstName)}
          />
          <TitleInput
            label="Last Name"
            name="lastName"
            placeholder="Enter user’s last name"
            value={lastName}
            onChange={handleChange}
            showerr={showError}
            errmsg="Last name contains only letters (a-zA-Z)"
            valid={validator.isAlpha(lastName)}
          />
          <TitleInput
            label="Email Address"
            name="email"
            placeholder="Enter user’s email address"
            value={email}
            onChange={handleChange}
            showerr={showError}
            errmsg="Invalid email"
            valid={validator.isEmail(email)}
          />
          <TitleInput
            label="Phone Number"
            name="phoneNumber"
            placeholder="Enter user’s phone number"
            value={phoneNumber}
            onChange={handleChange}
            showerr={showError}
            errmsg="Invalid Phone number"
            valid={validator.isMobilePhone(phoneNumber)}
          />

          <div className="mt-3 d-flex align-items-center justify-content-between">
            <NotoP
              fs="14px"
              lh="19px"
              mfs="12px"
              mlh="16px"
              fw="600"
              c={colors.primary}
            >
              User Role :
            </NotoP>
            <Dropdown
              isOpen={showServerType}
              toggle={toggleServerType}
            >
              <CustomDropdownToggle>
                <NotoP
                  style={{ whiteSpace: 'nowrap' }}
                  fs="14px"
                  lh="20px"
                  fw="600"
                  c={colors.white}>
                  {serverType}
                </NotoP>
                <CustomImg src={chev_down} w="12px" h="8px" className='ms-1' />
              </CustomDropdownToggle>

              <DropdownMenu>
                {userType?.map((x, i) => (
                  <DropdownItem
                    key={x?.userRoleId}
                    onClick={() => handleServerType(x?.userRole)}
                  >
                    <NotoP fs="14px" lh="20px" fw="600" c={colors.secondary}>
                      {x?.userRole}
                    </NotoP>
                  </DropdownItem>
                ))}
              </DropdownMenu>
            </Dropdown>
          </div>

          <button
            disabled={loading}
            onClick={handleSave}
            className='saveBtn mt-4'
          >
            {loading && <BtnSpinner />}
            Add User
          </button>

        </Card>
      </Col>

    </Row>
  );
}

AddUsers.propTypes = {
  usersList: PropTypes.object,
  user_data: PropTypes.object,
};
