import React, { useState } from "react";
import { Col, Collapse, Container, Row } from "reactstrap";
import cls from "classnames";
import styles from "./landing-work-steps.module.css";

import worksImg from "../../assets/images/landing/works.svg";
import arrowRight from "../../assets/images/landing/arrow-right.svg";
import arrowDown from "../../assets/images/landing/arrow-down.svg";

const data = [
  { id: 1, title: "Customer Sign Up Process" },
  {
    id: 2,
    title: "Upload & Train your own documents",
    subTitle: [
      "No AI - technical expertise required",
      "Driven by business users",
    ],
  },
  {
    id: 3,
    title: "Solution Consumption",
    subTitle: [
      "Single Page Processing",
      "Batch Processing",
      "Real time API Integration with existing downstream business systems",
    ],
  },
  { id: 4, title: "Customer KPI Analytics" },
  {
    id: 5,
    title: "Unified Customer Dashboard for",
    subTitle: [
      "Trained Documents",
      "Consumption Comparison",
      "Cost Comparison",
    ],
  },
  {
    id: 6,
    title: "Auto-Adaptability and scalability to elevated business needs",
  },
];

const LandingWorkSteps = () => {
  const [works, setWorks] = useState(data);

  const toggleWorkShow = (item) => {
    if (!item.subTitle) return;

    const tempArr = [...works];
    const idx = tempArr.findIndex((x) => x.id === item.id);
    tempArr[idx] = { ...item, show: !Boolean(item.show) };
    setWorks(tempArr);
  };

  const isOpen = (item) => {
    return Boolean(item.subTitle && item.show);
  };
  return (
    <div id="work-steps" className={styles.wrapper}>
      {/* <img src={worksImg} alt="AI" className={styles.aiImg} /> */}
      <Container fluid>
        <Row>
          <Col className="ps-0 d-none d-sm-block" md={6} sm={6}>
            <div>
              <img src={worksImg} alt="AI" className={styles.aiImg} />
            </div>
          </Col>
          <Col md={6} sm={6}>
            <div className={cls(styles.rightWrapper, "custom-scrollbar")}>
              <p className={styles.title}>How it works</p>
              <p className={cls(styles.subTitle, "mb-4")}>
                Ideafuse partners includes business software providers, niche
                technology developers, and platform and IT infrastructure
                vendors.
              </p>

              {works.map((item) => (
                <div key={item.id} className={styles.workCard}>
                  <ul
                    onClick={() => toggleWorkShow(item)}
                    className="d-flex align-items-center justify-content-between ps-3"
                    style={{ cursor: "pointer" }}
                  >
                    {/* <li className={cls(styles.workTitle, "cut--text")}> */}
                    <li className={styles.workTitle}>{item.title}</li>
                    {item.subTitle && (
                      <img
                        src={isOpen(item) ? arrowDown : arrowRight}
                        alt="Arrow"
                      />
                    )}
                  </ul>
                  <Collapse isOpen={isOpen(item)}>
                    <ul>
                      {item.subTitle?.map((x, i) => (
                        <li key={i} className={styles.workSubtitle}>
                          {x}
                        </li>
                      ))}
                    </ul>
                  </Collapse>
                </div>
              ))}
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default LandingWorkSteps;
