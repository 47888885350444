import PropTypes from "prop-types";

const MyCheckbox = ({ onClick, checked, onChange }) => {
  return (
    <label
      onClick={onClick}
      onKeyPress={onClick}
      class="my_checkbox bounce me-2"
    >
      <input type="checkbox" checked={checked} onChange={onChange} />
      <svg viewBox="0 0 21 21">
        <polyline points="5 10.75 8.5 14.25 16 6"></polyline>
      </svg>
    </label>
  );
};

MyCheckbox.propTypes = {
  onClick: PropTypes.func,
  checked: PropTypes.bool,
  onChange: PropTypes.func,
};

export default MyCheckbox;
