import React from "react";
import { Col, Container, NavLink, Row } from "reactstrap";
import { useHistory } from "react-router-dom";
import cls from "classnames";
import styles from "./footer.module.css";

import logo from "../../assets/images/ideafuse-logo.svg";
import fb from "../../assets/icons/social/facebook.svg";
import insta from "../../assets/icons/social/instagram.svg";
import twitter from "../../assets/icons/social/twitter.svg";
import yt from "../../assets/icons/social/youtube.svg";

const Footer = () => {
  const history = useHistory();

  const navToPage = (routeName) => {
    history.push(routeName);
  };

  const navToHome = (e) => {
    const { tagName, dataset } = e.target;
    if (tagName === "IMG") {
      history.push(dataset.path);
    }
  };

  return (
    <div className={styles.wrapper}>
      <Container fluid>
        <Row>
          <Col md={3} sm={9}>
            <div>
              <img
                onClick={() => navToPage("/")}
                src={logo}
                alt="logo"
                className={styles.logo}
              />
              <p className={styles.ideafuseMsg}>
                Ideafuse delivers you the technology of your dreams from the
                future, right to your doorstep.
              </p>

              <div onClick={navToHome} className="d-flex align-items-center">
                <img
                  data-path="/"
                  src={fb}
                  alt="facebook"
                  className={styles.socialLogo}
                />
                <img
                  data-path="/"
                  src={insta}
                  alt="instagram"
                  className={styles.socialLogo}
                />
                <img
                  data-path="/"
                  src={twitter}
                  alt="twitter"
                  className={styles.socialLogo}
                />
                <img
                  data-path="/"
                  src={yt}
                  alt="youtube"
                  className={styles.socialLogo}
                />
              </div>
            </div>
          </Col>
          <Col xs={6} sm={4} md={{ size: 2, offset: 2 }}>
            <div>
              <p className={styles.title}>Company</p>
              <NavLink href="/about-us" className="p-0">
                <p className={styles.linkText}>
                About Us
                </p>
              </NavLink>
              <NavLink href="/#partners" className="p-0">
                <p className={styles.linkText}>Alliances &amp; Partners</p>
              </NavLink>
              <NavLink href="/news-and-releases" className="p-0">
                <p className={styles.linkText}>News &amp; Releases</p>
              </NavLink>
              {/* <p className={styles.linkText}>Client Feedback</p> */}
            </div>
          </Col>
          <Col xs={6} sm={4} md={2}>
            <div>
              <p className={styles.title}>Product &amp; Services</p>
              <NavLink href="/#arka" className="p-0">
                <p className={styles.linkText}>ARKA</p>
              </NavLink>
              <NavLink href="/#services" className="p-0">
                <p className={styles.linkText}>Services Catalog</p>
              </NavLink>
            </div>
          </Col>
          <Col xs={9} sm={4} md={3}>
            <div className="ms-md-5">
              <p className={styles.title}>Contact Info</p>
              <p className={styles.linkText}>IDEAFUSE AI SOLUTIONS LLC</p>
              <p className={styles.linkText}>
                <span className={styles.subTitle}>Address: </span>
                Suite # 1071 Elgin, Illinois U.S.A 60123
              </p>
              <p className={styles.linkText}>
                <span className={styles.subTitle}>Email: </span>
                info@ideafuse.ai
              </p>
            </div>
          </Col>
        </Row>
        <div className={styles.line} />
        <div className="d-flex justify-content-between">
          <p
            className={cls(styles.linkText, styles.copyrightText)}
            style={{ width: "70%" }}
          >
            © Copyright IDEAFUSE AI SOLUTIONS LLC. All rights reserved.
          </p>
          <p
            className={cls(styles.linkText, styles.privacyText)}
            onClick={() => navToPage("/privacy-policy")}
          >
            Privacy Policy
          </p>
        </div>
      </Container>
    </div>
  );
};

export default Footer;
