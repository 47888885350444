import { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import { getAccessTabs } from "../utils";

export const useAccessTabs = () => {
  const location = useLocation();
  const { user_data } = useSelector((state) => ({
    user_data: state.auth.user_data,
  }));

  const [activeTab, setActiveTab] = useState("");
  const [tabs, setTabs] = useState([]);

  useEffect(() => {
    if (user_data) {
      const result = getAccessTabs(
        location.pathname,
        user_data?.lstUserRoleAccess
      );
      setTabs(result);
      setActiveTab(result[0]?.menuTabName_path);
    }
  }, []);
  return [tabs, activeTab, setActiveTab];
};
