import { useState } from "react";
import PropTypes from "prop-types";
import moment from "moment";
import { Col } from "reactstrap";
import DatePicker from "react-datepicker";
import { MenuItem, Select } from "@material-ui/core";
import styles from "./Analytics.module.css";
import { colors } from "../../styles/theme";
import { CustomImg, NotoP } from "../../styles/common";

import chev_tri_down from "../../assets/icons/chev-tri-down.svg";
import AnalyticsPieCard from "./AnalyticsPieCard";
import { API, PATH } from "../../services/Axios";
import BtnSpinner from "../../components/BtnSpinner";

const AnalyticsPieChart = (props) => {
  const { kpiList, user_data } = props;

  const [loading, setLoading] = useState(false);

  const minDate = new Date(moment().subtract(30, "days"));
  const startOfMonth = moment().startOf("month").format("YYYY-MM-DD hh:mm");
  const endOfMonth = moment().endOf("month").format("YYYY-MM-DD hh:mm");

  const [startDate, setStartDate] = useState(moment(startOfMonth).toDate());
  const [endDate, setEndDate] = useState(moment(endOfMonth).toDate());

  const [selectOpen, setSelectOpen] = useState(false);
  const [selectedKpis, setSelectedKpis] = useState([]);
  const [kpiSummary, setKpiSummary] = useState(null);

  const handleChange = (e) => {
    setKpiSummary(null);
    if (e.target.value.length <= 2) {
      setSelectedKpis(e.target.value);
    }
  };

  const handleSubmit = async () => {
    const data = {
      CustId: user_data?.customerInfo?.custId,
      LstKpis: selectedKpis,
    };
    setLoading(true);
    try {
      const result = await API.post(PATH.kpiSummary, data, user_data.token);
      // console.log(result);
      if (result) {
        setKpiSummary(result.dictSummaryItems);
      }
      setLoading(false);
    } catch (err) {
      console.log(err);
      setLoading(false);
    }
  };

  return (
    <>
      <Col md={8} className="my-4 my-md-5">
        <div className="d-flex align-items-center justify-content-between flex-wrap">
          <div className="position-relative">
            <NotoP fs="12px" lh="19px" fw="600" c={colors.primary}>
              Analytic KPI
            </NotoP>
            <button
              onClick={() => setSelectOpen(true)}
              className={styles.kpibtn}
            >
              {selectedKpis.length > 0
                ? selectedKpis.toString()
                : "Select KPIs"}
              <CustomImg src={chev_tri_down} w="12px" h="8px" ml="5px" />
            </button>
            <span className={styles.hint_text}>
              Maximum of 2 KPI's at ay given time
            </span>
            <Select
              id="kpibtn"
              multiple
              value={selectedKpis}
              onChange={handleChange}
              className="hide--element"
              open={selectOpen}
              onOpen={() => setSelectOpen(true)}
              onClose={() => setSelectOpen(false)}
            >
              <MenuItem disabled value="">
                <em>Select max 2 KPIs</em>
              </MenuItem>
              {kpiList.map((item, index) => (
                <MenuItem key={index} value={item.kpiName}>
                  {item.kpiName}
                </MenuItem>
              ))}
            </Select>
          </div>
          <div>
            <div className="d-flex align-items-center">
              <div className="me-4">
                <NotoP fs="12px" lh="19px" fw="600" c={colors.primary}>
                  From Date
                </NotoP>
                <DatePicker
                  selected={startDate}
                  onChange={(date) => setStartDate(date)}
                  minDate={minDate}
                  maxDate={endDate}
                  customInput={
                    <button className={styles.datebtn}>
                      {moment(startDate).format("MM/DD/YYYY")}
                    </button>
                  }
                />
              </div>
              <div>
                <NotoP fs="12px" lh="19px" fw="600" c={colors.primary}>
                  To Date
                </NotoP>
                <DatePicker
                  selected={endDate}
                  onChange={(date) => setEndDate(date)}
                  minDate={startDate}
                  //maxDate={endDate}
                  customInput={
                    <button className={styles.datebtn}>
                      {moment(endDate).format("MM/DD/YYYY")}
                    </button>
                  }
                />
              </div>
            </div>
            <span className={styles.hint_text}>
              Data range cannot be more than 30 days
            </span>
          </div>
          <button
            disabled={loading || selectedKpis.length === 0}
            onClick={handleSubmit}
            className={styles.savebtn}
          >
            {loading && <BtnSpinner />}
            Submit
          </button>
        </div>
      </Col>
      {kpiSummary &&
        selectedKpis.map((item, index) => (
          <Col key={index} md={6}>
            <AnalyticsPieCard title={item} items={kpiSummary[item]?.lstItems} />
          </Col>
        ))}
    </>
  );
};

AnalyticsPieChart.propTypes = {
  kpiList: PropTypes.array.isRequired,
  user_data: PropTypes.object.isRequired,
};

export default AnalyticsPieChart;
