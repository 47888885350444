import React from "react";
import { colors } from "../styles/theme";

const Unauthorize = () => {
  return (
    <div className="container-fluid">
      <div className="row">
        <div
          className="col-md-12 vh-100 content_center--flex"
          style={{ backgroundColor: colors.red }}
        >
          <p className="display-3 fw-bold">Unauthorized Access!!!</p>
        </div>
      </div>
    </div>
  );
};

export default Unauthorize;
