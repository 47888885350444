import { useState, useRef, useEffect } from "react";
import PropTypes from "prop-types";
import { Row, Col } from "reactstrap";
import validator from "validator";
import styled from "styled-components";
import TitleInput from "../../../components/TitleInput";
import { colors } from "../../../styles/theme";
import { MiButton, NotoP } from "../../../styles/common";
import { API, PATH } from "../../../services/Axios";
import { notify } from "../../../utils";
import FormSpinner from "../../../components/FormSpinner";
import SimpleDocPicker from "../../../components/SimpleDocPicker";
import BtnSpinner from "../../../components/BtnSpinner";

const FooterText = styled.span`
  font-family: Montserrat;
  font-style: italic;
  font-weight: normal;
  font-size: 12px;
  line-height: 18px;
  /* identical to box height */
  display: block;
  align-items: center;
  text-align: ${({ align }) => (align ? align : "left")};
  /* primary-color */
  color: #03256c;
`;

function RestApi(props) {
  const { user_data, settings_data } = props;
  const docRef = useRef();
  const [loading, setLoading] = useState(false);

  const initialApiData = () => {
    return {
      apiUrl: "",
      endPoint: "",
      parameters: "",
      authorization: "",
      additional_details: "",
    };
  };

  const [doc, setDoc] = useState(null);
  const [showError, setShowError] = useState(false); // IMP  bydefault should be false
  const [apiData, setApiData] = useState(initialApiData);
  const { apiUrl, endPoint, parameters, authorization, additional_details } =
    apiData;
  const {
    cusApiUrl,
    cusApiEndpoint,
    cusApiInputParameters,
    cusApiAuthorization,
    cusApiAdditionalDetails,
    cusApiFileName,
    cusIntegrationType,
    cusServerName,
    cusServerIp,
    cusConnString,
    cusAdditionalDetails,
    cusStodbFileName,
  } = settings_data;

  useEffect(() => {
    const newData = {
      apiUrl: cusApiUrl || "",
      endPoint: cusApiEndpoint || "",
      parameters: cusApiInputParameters || "",
      authorization: cusApiAuthorization || "",
      additional_details: cusApiAdditionalDetails || "",
    };
    setApiData(newData);
  }, [settings_data]);

  const handleSubmit = async () => {
    const conditions =
      //validator.isURL(apiUrl) &&
      !validator.isEmpty(apiUrl) &&
      !validator.isEmpty(endPoint) &&
      !validator.isEmpty(parameters) &&
      !validator.isEmpty(authorization) &&
      !validator.isEmpty(additional_details);

    if (conditions) {
      const formData = new FormData();
      formData.append("CustId", user_data?.customerInfo?.custId);
      formData.append("LoginId", user_data?.userInfo?.userEmail);
      formData.append("CusIntegrationId", settings_data?.cusIntegrationId || 0);
      formData.append("IsUpdate", Boolean(settings_data?.cusIntegrationId));
      formData.append("CusApiUrl", apiUrl);
      formData.append("CusApiEndpoint", endPoint);
      formData.append("CusApiInputParameters", parameters);
      formData.append("CusApiAuthorization", authorization);
      formData.append("CusApiAdditionalDetails", additional_details);
      formData.append("CusApiFileName", doc?.name || cusApiFileName);
      // break;
      formData.append("CusIntegrationType", cusIntegrationType);
      formData.append("CusServerName", cusServerName);
      formData.append("CusServerIp", cusServerIp);
      formData.append("CusConnString", cusConnString);
      formData.append("CusAdditionalDetails", cusAdditionalDetails);
      formData.append("CusStodbFileName", cusStodbFileName);
      // File Upload
      if (doc) {
        formData.append("ApiFileObject", doc);
      }

      setLoading(true);
      try {
        const result = await API.post(
          PATH.updateIntegSettings,
          formData,
          user_data.token
        );
        console.log(result);
        if (result) {
          notify("Your configuration settings were successfully saved", true);
        } else {
          notify(
            "Error encountered while saving your configuration settings. Please try again or if the problem persists create and issue using our Issue Management Service."
          );
        }
        setLoading(false);
      } catch (err) {
        console.log(err);
        setLoading(false);
      }
    } else {
      setShowError(true);
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setApiData((prev) => ({ ...prev, [name]: value }));
  };

  const handleDocPicker = (e) => {
    const { name, files } = e.target;
    console.log(name);

    if (files.length > 0) {
      setDoc(files[0]);
    }
  };

  return (
    <Row className="mt-4 ps-4 pe-2">
      <Col md={{ size: 6, offset: 3 }} className="position-relative">
        {loading && <FormSpinner />}
        <TitleInput
          label="API URL"
          name="apiUrl"
          placeholder="Enter api url"
          value={apiUrl}
          onChange={handleChange}
          showerr={showError}
          //errmsg="Invalid URL"
          //valid={validator.isURL(apiUrl)}
          valid={!validator.isEmpty(apiUrl)}
        />
        <TitleInput
          label="End Point"
          name="endPoint"
          placeholder="Enter end point"
          value={endPoint}
          onChange={handleChange}
          showerr={showError}
          //errmsg="Invalid End point"
          valid={!validator.isEmpty(endPoint)}
        />
        <TitleInput
          label="Input Parameters"
          name="parameters"
          placeholder="Enter parameters"
          value={parameters}
          onChange={handleChange}
          showerr={showError}
          //errmsg="Invalid parameters"
          valid={!validator.isEmpty(parameters)}
        />
        <TitleInput
          label="Authorization"
          name="authorization"
          placeholder="Enter your authorization"
          value={authorization}
          onChange={handleChange}
          showerr={showError}
          //errmsg="Invalid authorization"
          valid={!validator.isEmpty(authorization)}
        />
        <TitleInput
          label="Additional Details"
          name="additional_details"
          placeholder="Enter additional details"
          value={additional_details}
          onChange={handleChange}
          showerr={showError}
          //errmsg="Invalid End point"
          valid={!validator.isEmpty(additional_details)}
        />
        <div className="mt-3">
          <NotoP
            fs="14px"
            lh="19px"
            mfs="12px"
            mlh="16px"
            fw="600"
            c={colors.primary}
          >
            File Attachment
            {<span className="iRed">*</span>}
          </NotoP>
          <SimpleDocPicker
            accept={".pdf,.docx"}
            inputRef={docRef}
            setFile={setDoc}
            handleChange={handleDocPicker}
            filename={doc?.name || cusApiFileName}
          />
          <FooterText>File supported .pdf, .doc</FooterText>
        </div>
        <div className="text-center">
          <button
            disabled={loading}
            onClick={handleSubmit}
            className='saveBtn w-50 mx-auto mt-4'
          >
            {/* {loading && <BtnSpinner />} */}
            Submit
          </button>
        </div>
      </Col>
    </Row>
  );
}

RestApi.propTypes = {
  user_data: PropTypes.object,
  settings_data: PropTypes.object,
};

export default RestApi;
