import PropTypes from "prop-types";
import { Card } from "reactstrap";
import styled from "styled-components";
import { MontP, NotoP, CustomImg } from "../styles/common";
import { colors } from "../styles/theme";
import styles from "./Components.module.css";

import pieCenter from "../assets/images/pie-center.svg";

const SvgCover = styled.div`
  /* background-color: goldenrod; */
  width: 120px;
  height: 120px;
  border-radius: 50%;
  position: relative;
`;

const ImgPos = styled.span`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -45%);
`;

function PieCard({
  title,
  text1,
  text2,
  text3,
  value1,
  value1Unit,
  value2,
  value3,
  value2Unit,
  value3Unit,
  boxColor1,
  boxColor2,
  boxColor3,
  value1rightUnit,
  value2rightUnit,
  value3rightUnit,
  pieType,
}) {
  return (
    <Card className={styles.piecard}>
      <MontP fs="16px" lh="20px" fw="600" c={colors.primary}>
        {title}
      </MontP>
      <div className="d-flex align-items-center justify-content-between mt-3">
        <div style={{ flex: 2 }}>
          <SvgCover className="content_center--flex">
            {pieType === "analytics-chart" ? (
              <svg height="100%" width="100%" viewBox="0 0 20 20">
                <circle r="8.5" cx="10" cy="10" fill={boxColor2} />
                <circle
                  r="5"
                  cx="10"
                  cy="10"
                  fill="transparent"
                  stroke={boxColor3}
                  strokeWidth="10"
                  strokeDasharray={`calc(${
                    (value3 / value1) * 100
                  } * 31.4 / 100) 31.4`}
                  transform="rotate(-90) translate(-20)"
                  strokeDashoffset="0"
                />
              </svg>
            ) : (
              <svg height="100%" width="100%" viewBox="0 0 20 20">
                <circle r="8.5" cx="10" cy="10" fill={boxColor3 || boxColor2} />
                <circle
                  r="5"
                  cx="10"
                  cy="10"
                  fill="transparent"
                  stroke={!boxColor3 ? boxColor1 : boxColor2}
                  strokeWidth="10"
                  strokeDasharray={`calc(${
                    (value3 ? value2 / value3 : value2 / value1) * 100
                  } * 31.4 / 100) 31.4`}
                  transform="rotate(-90) translate(-20)"
                  strokeDashoffset="0"
                />
              </svg>
            )}
            <ImgPos>
              <CustomImg src={pieCenter} alt="pie circle" w="80px" h="80px" />
            </ImgPos>
          </SvgCover>
        </div>
        <div style={{ flex: 3 }} className="ms-md-5 ms-3">
          <div className="d-flex align-items-center justify-content-between">
            <div className="d-flex align-items-center">
              <span
                className={styles.squarebox}
                style={{ backgroundColor: boxColor1 }}
              ></span>
              <NotoP fs="13px" lh="20px" c={colors.secondary}>
                {text1}
              </NotoP>
            </div>
            <MontP fs="15px" lh="20px" fw="600" c={colors.secondary}>
              {value1Unit}
              {value1}
              {value1rightUnit}
            </MontP>
          </div>
          <div className="d-flex align-items-center justify-content-between mt-2">
            <div className="d-flex align-items-center">
              <span
                className={styles.squarebox}
                style={{ backgroundColor: boxColor2 }}
              ></span>
              <NotoP fs="13px" lh="20px" c={colors.secondary}>
                {text2}
              </NotoP>
            </div>
            <MontP fs="15px" lh="20px" fw="600" c={colors.secondary}>
              {value2Unit}
              {value2}
              {value2rightUnit}
            </MontP>
          </div>
          {value3 && (
            <div className="d-flex align-items-center justify-content-between mt-2">
              <div className="d-flex align-items-center">
                <span
                  className={styles.squarebox}
                  style={{ backgroundColor: boxColor3 }}
                ></span>
                <NotoP fs="13px" lh="20px" c={colors.secondary}>
                  {text3}
                </NotoP>
              </div>
              <MontP fs="15px" lh="20px" fw="600" c={colors.secondary}>
                {value3Unit}
                {value3}
                {value3rightUnit}
              </MontP>
            </div>
          )}
        </div>
      </div>
    </Card>
  );
}

PieCard.propTypes = {
  title: PropTypes.string,
  text1: PropTypes.string,
  text2: PropTypes.string,
  value1: PropTypes.any,
  value2: PropTypes.any,
  boxColor1: PropTypes.string,
  boxColor2: PropTypes.string,
  value1Unit: PropTypes.string,
  value2Unit: PropTypes.string,
  value1rightUnit: PropTypes.string,
  value2rightUnit: PropTypes.string,
  pieType: PropTypes.string,
};

export default PieCard;
