import PropTypes from "prop-types";
import styled from "styled-components";

const CoverCard = styled.div`
  background-color: #eee;
  padding: 6px;
  border-radius: 10px;
`;

const TabsCover = ({ children }) => {
  return (
    <CoverCard className="card">
      <div className="d-flex">{children}</div>
    </CoverCard>
  );
};

TabsCover.propTypes = {
  children: PropTypes.elementType.isRequired,
};

export default TabsCover;
