import { useState } from "react";
import styled from "styled-components";
import { Container, Row, Col } from "reactstrap";
import { useSelector } from "react-redux";

import Cost from "../assets/icons/cost.svg";
import Doc from "../assets/icons/doc.svg";
import Box from "../assets/icons/box.svg";
import Lock from "../assets/icons/lock.svg";
import Key from "../assets/icons/key.svg";
import User from "../assets/icons/user-manage.svg";
import analytic from "../assets/icons/analytic.svg";
import { API, PATH } from "../services/Axios";
import { colors } from "../styles/theme";
import settings from "../assets/icons/settings_help.svg";
import BtnSpinner from "../components/BtnSpinner";
//Help Screen
function Help(props) {
  const { user_data } = useSelector((state) => ({
    user_data: state.auth.user_data,
  }));

  const [loading, setLoading] = useState(false);

  const Cards = [
    {
      title: "Cost management",
      subtitle: "Know about cost management",
      image: Cost,
      fileName: "CostManagement.pdf",
    },
    {
      title: "Document management",
      subtitle: "Know about document management",
      image: Doc,
      fileName: "DocManagement.pdf",
    },
    {
      title: "Document Adaption",
      subtitle: "Explore our products",
      image: Box,
      fileName: "DocAdaption.pdf",
    },
    {
      title: "Login/Signup",
      subtitle: "Get login and sign up support",
      image: Lock,
      fileName: "LoginSignup.pdf",
    },
    {
      title: "Password",
      subtitle: "Get password support",
      image: Key,
      fileName: "PwdManagement.pdf",
    },
    {
      title: "User management",
      subtitle: "Know about user management",
      image: User,
      fileName: "UsrManagement.pdf",
    },
    {
      title: "Analytics",
      subtitle: "Know about your Data Analytics",
      image: analytic,
      fileName: "Analaytics.pdf",
    },
    {
      title: "Integration Settings",
      subtitle: "Know about ARKA Integration settings",
      image: settings,
      fileName: "IntegrationSettings.pdf",
    },
  ];

  const handleClick = async (item) => {
    const data = {
      FileName: item.fileName,
    };
    setLoading(true);
    try {
      const result = await API.post(PATH.helpDocument, data, user_data.token);
      // console.log(result);
      if (result) {
        let pdfWindow = window.open("");
        pdfWindow.document.write(
          "<iframe width='100%' height='100%' src='data:application/pdf;base64, " +
            encodeURI(result) +
            "'></iframe>"
        );
      }
      setLoading(false);
    } catch (err) {
      console.log(err);
      setLoading(false);
    }
  };

  return (
    <Container fluid className="pb-5">
      <Row className="justify-content-center">
        <Col
          xs={12}
          sm={12}
          md={{ size: window.innerWidth > 991 ? 9 : 12 }}
          className="pt-md-4 pt-5"
        >
          <Title
            size={window.innerWidth > 768 ? 36 : 18}
            lineHeight={44}
            className="mb-md-4"
          >
            {"Hi! How can we help you?"}
          </Title>
          {loading && (
            <div className="mb-3 text-center">
              <BtnSpinner color={colors.primary} size="lg" />
            </div>
          )}
          <Row>
            {Cards.map((item, index) => (
              <Col
                key={index}
                role="button"
                onClick={() => handleClick(item)}
                onKeyPress={() => handleClick(item)}
                md={6}
                sm={12}
                xs={12}
                offset={3}
              >
                <Card>
                  <Image src={item.image} />
                  <Inline>
                    <Title>{item.title}</Title>
                    <Subtitle>{item.subtitle}</Subtitle>
                  </Inline>
                </Card>
              </Col>
            ))}
          </Row>
        </Col>
        <Col md={12}>
          <P className="text-center mt-3">
            {"For more information send an email to "}
            <Link href="mailto:arka.admin@ideafuse.ai">
              {"arka.admin@ideafuse.ai"}
            </Link>
          </P>
        </Col>
      </Row>
    </Container>
  );
}

export default Help;

const Card = styled.div`
  height: 77px;
  background: #ffffff;
  box-shadow: 0px 0px 20px rgb(0 0 0 / 25%);
  border-radius: 5px;
  position: relative;
  margin: 1em;
  display: inline-flex;
  text-align: center;
  min-width: -webkit-fill-available;
`;

const Image = styled.img`
  height: 50px;
  margin-right: 10px;
  position: relative;
  display: inline-flex;
  left: 20px;
  top: 15px;
  min-width: 50px;

  @media (min-width: 320px) and (max-width: 480px) and (orientation: portrait) {
    height: 30px;
    left: 20px;
    top: 25px;
    min-width: auto;
  }
`;

const Title = styled.h3`
  font-family: "Noto Sans", sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: ${({ size }) => (size ? size : 18)}px;
  line-height: ${({ lineHeight }) => (lineHeight ? lineHeight : 20)}px;
  margin-left: 1em;
  margin-top: 1em;
  text-align: center;
  color: #0b1b4d;
  white-space: nowrap;
  @media (min-width: 320px) and (max-width: 480px) and (orientation: portrait) {
    margin-top: 1.5em;
    font-size: 15px;
    line-height: 15px;
  }
`;

const Subtitle = styled.p`
  font-family: "Noto Sans", sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 20px;
  margin-left: 1em;
  color: #0b1b4d;
  text-align: center;
  white-space: nowrap;
  @media (min-width: 320px) and (max-width: 480px) and (orientation: portrait) {
    font-size: 11px;
    line-height: 15px;
  }
`;

const Inline = styled.div`
  display: inline-block;
  margin-left: 1em;
  text-align: center;
  width: 65%;
`;

const P = styled.p`
  font-family: "Noto Sans", sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 25px;
  color: #0b1b4d;
`;

const Link = styled.a`
  font-family: "Noto Sans", sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 25px;
  color: #2541b2;
`;
