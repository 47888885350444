const routes = [
  {
    menuName: "Arka Home Page",
    menuName_path: "/",
    menuTabName: "",
    menuTabName_path: "",
  },
  {
    menuName: "Arka Home Page",
    menuName_path: "/home",
    menuTabName: "",
    menuTabName_path: "",
  },
  {
    menuName: "Arka Interface",
    menuName_path: "/interface",
    menuTabName: "",
    menuTabName_path: "",
  },
  {
    menuName: "Administration",
    menuName_path: "/administration",
    menuTabName: "",
    menuTabName_path: "",
  },
  {
    menuName: "Administration",
    menuName_path: "/administration",
    menuTabName: "Profile",
    menuTabName_path: "1",
  },
  {
    menuName: "Administration",
    menuName_path: "/administration",
    menuTabName: "Contact Details",
    menuTabName_path: "2",
  },
  {
    menuName: "Administration",
    menuName_path: "/administration",
    menuTabName: "Change Password",
    menuTabName_path: "3",
  },
  {
    menuName: "User Management",
    menuName_path: "/user-management",
    menuTabName: "",
    menuTabName_path: "",
  },
  {
    menuName: "User Management",
    menuName_path: "/user-management",
    menuTabName: "Add Users",
    menuTabName_path: "1",
  },
  {
    menuName: "User Management",
    menuName_path: "/user-management",
    menuTabName: "Search Users",
    menuTabName_path: "2",
  },
  {
    menuName: "User Management",
    menuName_path: "/user-management",
    menuTabName: "Role Management",
    menuTabName_path: "3",
  },
  {
    menuName: "Adaption Details",
    menuName_path: "/adaption-detail",
    menuTabName: "",
    menuTabName_path: "",
  },
  {
    menuName: "Adaption Details",
    menuName_path: "/adaption-detail",
    menuTabName: "Documents Adaption",
    menuTabName_path: "1",
  },
  {
    menuName: "Adaption Details",
    menuName_path: "/adaption-detail",
    menuTabName: "Trained Documents",
    menuTabName_path: "2",
  },
  {
    menuName: "Adaption Details",
    menuName_path: "/adaption-detail",
    menuTabName: "KPI Significance",
    menuTabName_path: "3",
  },
  {
    menuName: "Adaption Details",
    menuName_path: "/adaption-detail",
    menuTabName: "Document Thresholds",
    menuTabName_path: "4",
  },
  {
    menuName: "Cost Management",
    menuName_path: "/cost-management",
    menuTabName: "",
    menuTabName_path: "",
  },
  {
    menuName: "Document Management",
    menuName_path: "/document-management",
    menuTabName: "",
    menuTabName_path: "",
  },
  {
    menuName: "Document Management",
    menuName_path: "/document-management",
    menuTabName: "Batch Process",
    menuTabName_path: "1",
  },
  {
    menuName: "Document Management",
    menuName_path: "/document-management",
    menuTabName: "Search Documents",
    menuTabName_path: "2",
  },
  {
    menuName: "Analytics",
    menuName_path: "/analytics",
    menuTabName: "",
    menuTabName_path: "",
  },
  {
    menuName: "Issue Management",
    menuName_path: "/issue-management",
    menuTabName: "",
    menuTabName_path: "",
  },
  {
    menuName: "Issue Management",
    menuName_path: "/issue-management",
    menuTabName: "Dashboard",
    menuTabName_path: "1",
  },
  {
    menuName: "Issue Management",
    menuName_path: "/issue-management",
    menuTabName: "Create Issue",
    menuTabName_path: "2",
  },
  {
    menuName: "Issue Management",
    menuName_path: "/issue-management",
    menuTabName: "Support Dashboard",
    menuTabName_path: "3",
  },
  {
    menuName: "Find Help",
    menuName_path: "/help",
    menuTabName: "",
    menuTabName_path: "",
  },
  {
    menuName: "Privacy Policy",
    menuName_path: "/privacy-policy",
    menuTabName: "",
    menuTabName_path: "",
  },
  {
    menuName: "Integration Settings",
    menuName_path: "/integration-settings",
    menuTabName: "",
    menuTabName_path: "",
  },
  {
    menuName: "Integration Settings",
    menuName_path: "/integration-settings",
    menuTabName: "Rest API Settings",
    menuTabName_path: "1",
  },
  {
    menuName: "Integration Settings",
    menuName_path: "/integration-settings",
    menuTabName: "Data Drop Settings",
    menuTabName_path: "2",
  },
  {
    menuName: "Integration Settings",
    menuName_path: "/integration-settings",
    menuTabName: "Email Settings",
    menuTabName_path: "3",
  },
  {
    menuName: "Integration Settings",
    menuName_path: "/integration-settings",
    menuTabName: "Custom Settings",
    menuTabName_path: "4",
  },
  {
    menuName: "Asset Approvals",
    menuName_path: "/asset-approvals",
    menuTabName: "",
    menuTabName_path: "",
  },
  {
    menuName: "Asset Approvals",
    menuName_path: "/asset-approvals",
    menuTabName: "Dashboard",
    menuTabName_path: "1",
  },
  {
    menuName: "Asset Approvals",
    menuName_path: "/asset-approvals",
    menuTabName: "Asset Setup",
    menuTabName_path: "2",
  },
  {
    menuName: "Asset Approvals",
    menuName_path: "/asset-approvals",
    menuTabName: "Approval Setup",
    menuTabName_path: "3",
  },
  {
    menuName: "Asset Approvals",
    menuName_path: "/asset-approvals",
    menuTabName: "Approval Report",
    menuTabName_path: "4",
  },

];

export default routes;
