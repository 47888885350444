import { useState } from "react";
import { Collapse } from "reactstrap";
import PropTypes from "prop-types";
import { MontP, NotoP } from "../styles/common";
import { colors } from "../styles/theme";
import styles from "./Components.module.css";

import chevup from "../assets/icons/chev-up-d.svg";
import chevdown from "../assets/icons/chev-down-d.svg";

function TrainedCollapseable({ bgColor, title, data, cbImageLink }) {
  const [isOpen, setIsOpen] = useState(false);

  const toggle = () => setIsOpen(!isOpen);

  return (
    <>
      <div
        role="button"
        onClick={toggle}
        onKeyPress={toggle}
        className="d-flex align-items-center justify-content-between px-2 py-1"
        style={{ backgroundColor: bgColor }}
      >
        <MontP fs="15px" lh="20px" b c={colors.blue5}>
          {title}
        </MontP>
        <img
          src={isOpen ? chevup : chevdown}
          alt="up"
          width="22px"
          height="22px"
        />
      </div>
      <Collapse isOpen={isOpen}>
        <div style={{ backgroundColor: bgColor }}>
          {data.map((item, index) => (
            <div
              key={index}
              className="d-flex align-items-center justify-content-between px-2 py-1"
            >
              <NotoP fs="12px" lh="16px" fw="500" c={colors.primary}>
                {item.displayName}
              </NotoP>
              <button
                onClick={() => cbImageLink({ ...item, documentType: title })}
                className={styles.collapsecontentBtn}
              >
                Image Link
              </button>
            </div>
          ))}
        </div>
      </Collapse>
    </>
  );
}

TrainedCollapseable.propTypes = {
  bgColor: PropTypes.string,
  title: PropTypes.string,
  data: PropTypes.array,
  cbImageLink: PropTypes.func,
};

TrainedCollapseable.defaultProps = {
  bgColor: "#FFF",
};

export default TrainedCollapseable;
