import PropTypes from "prop-types";
import { Modal } from "reactstrap";
import styles from "./Components.module.css";

function MyModal({ isOpen, setIsOpen, children }) {
  return (
    <Modal
      isOpen={isOpen}
      toggle={() => setIsOpen(!isOpen)}
      backdropClassName={styles.mymodal_backdrop}
      className={styles.mymodal}
      contentClassName={styles.mymodal_content}
      //wrapClassName={styles.modal}
      //modalClassName={styles.modal}
    >
      {children}
    </Modal>
  );
}

MyModal.propTypes = {
  isOpen: PropTypes.bool,
  setIsOpen: PropTypes.func,
  children: PropTypes.any,
};

export default MyModal;
