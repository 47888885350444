import { useState, useRef } from "react";
import { useSelector } from "react-redux";
import moment from "moment";
import { Row, Col, Container } from "reactstrap";
import BtnSpinner from "../components/BtnSpinner";
import {
  CustomImg,
  ElementPos,
  MiButton,
  MontP,
  NotoP,
} from "../styles/common";
import { colors } from "../styles/theme";

import scan from "../assets/icons/scan.svg";
import folderPlus from "../assets/icons/folder-plus.svg";
import { getFileType, isIpadOS } from "../utils";
import { API, PATH } from "../services/Axios";

import { Document, Page, pdfjs } from "react-pdf";
import { AiOutlineZoomIn, AiOutlineZoomOut } from "react-icons/ai";
import PreviewSpinner from "../components/PreviewSpinner";
import InterfaceCollapseable from "../components/InterfaceCollapseable";
import RadioButton from '../components/RadioButton/RadioButton';
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;


export default function Interface() {
  const docRef = useRef();
  const { user_data, main_sidebar } = useSelector((state) => ({
    user_data: state.auth.user_data,
    main_sidebar: state.app.main_sidebar,
  }));

  const pdfPageWidth =
    window.innerWidth > 991
      ? main_sidebar
        ? 0.605
        : 0.72
      : window.innerWidth > 768
        ? 0.6
        : 0.52;

  const [loading, setLoading] = useState(false);
  const [doc, setDoc] = useState(null);
  const [numPages, setNumPages] = useState(null);
  const [pageNumber, setPageNumber] = useState(1);
  const [pageScale, setPageScale] = useState(pdfPageWidth);
  // const [imgScale, setImgScale] = useState(1);
  const [imgWidth, setImgWidth] = useState(99);

  const [analyzeData, setAnalyzeData] = useState(null);
  const [analysisType, setAnalysisType] = useState(false);

  const handleAnalyze = async () => {
    if (doc) {
      setLoading(true);
      const id_date = moment().format("DDMMYYYY");
      const id_time = moment().format("HHmmss");

      let fileFormData = new FormData();
      fileFormData.append("DocId", `ARKA_Interface_${id_date}_${id_time}`);
      fileFormData.append("CustomerId", user_data?.customerInfo?.custId);
      fileFormData.append("DocFormat", getFileType(doc));
      fileFormData.append("RequestDate", moment().format("MM/DD/YYYY"));
      fileFormData.append("DocFile", doc);
      fileFormData.append("ExtractionOnly", analysisType);

      try {
        const result = await API.postFileKey(PATH.arkaInterface, fileFormData);
        // console.log(">>>", result);
        setAnalyzeData(result);
        setLoading(false);
      } catch (err) {
        setLoading(false);
        console.log(err);
      }
    }
  };

  const onDocumentLoadSuccess = ({ numPages }) => {
    setNumPages(numPages);
  };

  const handleDocPicker = (e) => {
    const { name, files } = e.target;

    if (files.length > 0) {
      // console.log(files[0]);
      // console.log(getFileType(files[0]));
      setDoc(files[0]);
    }
  };

  return (
    <Container fluid className="px-md-5 px-4 py-3 mt-5">
      <input
        style={{ display: "none" }}
        type="file"
        accept="application/pdf,image/jpeg,image/jpg,image/png,image/tiff,.tif"
        onChange={handleDocPicker}
        name="doc"
        ref={docRef}
      />
      <Row>
        <Col xs={12} sm={12} md={isIpadOS() ? 5 : 8} className="ps-0 pe-4">
          <NotoP
            fs="20px"
            lh="28px"
            mfs="16px"
            mlh="22px"
            fw="600"
            c={colors.primary}
            mb="12px"
          >
            Analysis Type
          </NotoP>

          <RadioButton
            value={analysisType}
            option1Label="Document Classifier &amp; Data Extraction"
            option1Value={false}
            option2Label="Data Extraction"
            option2Value={true}
            onClick={setAnalysisType}
          />

          <NotoP
            fs="20px"
            lh="28px"
            mfs="16px"
            mlh="22px"
            fw="600"
            c={colors.primary}
            mb="5px"
          >
            Select File For Analysis
          </NotoP>
          <div
            onDragOver={(e) => {
              e.preventDefault();
            }}
            onDragLeave={(e) => {
              e.preventDefault();
            }}
            onDrop={(e) => {
              e.preventDefault();
              const droppedFile = Array.from(e.dataTransfer.files);
              //console.log(droppedFile[0]);
              if (droppedFile.length > 0) {
                setDoc(droppedFile[0]);
              }
            }}
          >
            <div
              role="button"
              onClick={() => docRef.current.click()}
              onKeyPress={() => docRef.current.click()}
              className="arka_interface-docpicker content_center--flex"
            >
              {doc ? (
                <MontP fs="11px" lh="15px" b c={colors.primary}>
                  {doc.name}
                </MontP>
              ) : (
                <div className="d-flex align-items-center">
                  <CustomImg src={folderPlus} w="20px" h="20px" mr="5px" />
                  <MontP fs="11px" lh="15px" b c={colors.primary}>
                    Click to upload or Drag & Drop
                  </MontP>
                </div>
              )}
            </div>
          </div>
          <MontP fs="12px" lh="18px" fw="normal" fontStyle="italic" c="#03256c">
            File supported .png, .jpg, .tiff, .pdf
          </MontP>
          
          <button
            disabled={loading}
            onClick={handleAnalyze}
            style={{ width: '150px' }}
            className='saveBtn mx-auto mt-3 mb-4'
          >
            {loading ? (
              <BtnSpinner />
            ) : (
              <CustomImg src={scan} w="18px" h="18px" mr="5px" />
            )}
            Analyze
          </button>


          <div className="d-flex align-items-center justify-content-between">
            <MontP
              fs="18px"
              lh="26px"
              mfs="16px"
              mlh="20px"
              b
              c={colors.primary}
            >
              Data Retrieved
            </MontP>
            {/* <span style={{ zIndex: 10 }} title="Copy" role="button">
                  <CustomImg src={copy} w="22px" h="22px" mw="15px" mh="15px" />
                </span> */}
          </div>
          {analyzeData?.predictions?.length > 0 ? (
            <div
              style={{ height: "44vh" }}
              className="mt-3 overflow-auto custom-scrollbar"
            >
              {analyzeData.predictions.map((item, index) => (
                <InterfaceCollapseable
                  key={item.pageNo.toString()}
                  bgColor={colors.primary}
                  data={item}
                />
              ))}
            </div>
          ) : (
            <div style={{ height: "44vh" }} className="content_center--flex">
              <div className="w-50">
                <NotoP
                  fs="16px"
                  lh="24px"
                  mfs="13px"
                  mlh="20px"
                  b
                  c={colors.black}
                >
                  {loading
                    ? "Retrieving data...."
                    : "Data retrieved from document will shown here...."}
                </NotoP>
              </div>
            </div>
          )}
        </Col>
        <Col
          xs={12}
          sm={12}
          md={isIpadOS() ? 7 : 4}
          className="p-3"
          style={{ background: "rgba(3, 37, 108, 0.1)" }}
        >
          <MontP
            fs="20px"
            lh="26px"
            mfs="16px"
            mlh="22px"
            b
            c={colors.blue5}
            className="text-center mb-2"
          >
            Document View
          </MontP>
          <div className="arka_interface-docview content_center--flex position-relative">
            {doc ? (
              <>
                {getFileType(doc) === "pdf" ? (
                  <div className="pdf_doc--cover custom-scrollbar">
                    <Document
                      file={doc}
                      onLoadSuccess={onDocumentLoadSuccess}
                      onLoadError={(err) => console.log(err)}
                    >
                      <Page pageNumber={pageNumber} scale={pageScale} />
                      <ElementPos b="0" r="0">
                        <div className="d-flex align-items-center p-3">
                          <AiOutlineZoomOut onClick={() => setPageScale(pageScale - .25)} size={22} color={colors.grey5} className="me-2 pointer" />
                          <AiOutlineZoomIn onClick={() => setPageScale(pageScale + .25)} size={22} color={colors.grey5} className="me-1 pointer" />
                        </div>
                      </ElementPos>
                    </Document>
                    <ElementPos b="-8%" l="40%">
                      <div className="d-flex align-items-center">
                        <button
                          className="btn btn-sm"
                          disabled={pageNumber === 1}
                          onClick={() => setPageNumber(pageNumber - 1)}
                        >
                          Prev
                        </button>
                        <button
                          className="btn btn-sm"
                          disabled={pageNumber === numPages}
                          onClick={() => setPageNumber(pageNumber + 1)}
                        >
                          Next
                        </button>
                      </div>
                    </ElementPos>
                  </div>
                ) : (
                  <div className="img_view--cover custom-scrollbar text-center">
                    <img
                      src={URL.createObjectURL(doc)}
                      alt="file"
                      style={{ width: `${imgWidth}%` }}
                    //className="arka_interface-img-preview"
                    //style={{transform: `scale(${imgScale})`}}
                    />
                    <ElementPos b="0" r="0">
                      <div className="d-flex align-items-center p-3">
                        <AiOutlineZoomOut onClick={() => setImgWidth(imgWidth - 10)} size={22} color={colors.grey5} className="me-2 pointer" />
                        <AiOutlineZoomIn onClick={() => setImgWidth(imgWidth + 10)} size={22} color={colors.grey5} className="me-1 pointer" />
                      </div>
                    </ElementPos>
                  </div>
                )}
              </>
            ) : (
              <div className="w-50">
                <NotoP
                  fs="16px"
                  lh="24px"
                  mfs="13px"
                  mlh="20px"
                  b
                  c={colors.black}
                >
                  Uploaded document will shown here...
                </NotoP>
              </div>
            )}
            {loading && <PreviewSpinner loadingText="Scanning document..." />}
          </div>
        </Col>
      </Row>
    </Container>
  );
}
