import { useState, useEffect } from "react";
import { Link, useHistory, useParams } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import Modal from "@material-ui/core/Modal";
import validator from "validator";
import Cookies from "js-cookie";
import cls from "classnames";
import styles from "./auth.module.css";
import {
  CustomImg,
  ElementPos,
  Line,
  MiButton,
  MiOutlineButton,
  MontP,
  NotoP,
} from "../../styles/common";
import { colors } from "../../styles/theme";
import TitleInput from "../../components/TitleInput";
import Vectors from "../../components/Vectors";
import { updateUser } from "../../redux/actions/auth_A";
import { API, PATH } from "../../services/Axios";
import { getAccessRoutes, isIpadOS, notify } from "../../utils";
import BtnSpinner from "../../components/BtnSpinner";
import MyCheckbox from "../../components/MyCheckbox";

import logo from "../../assets/images/ideafuse-logo.svg";
import arka from "../../assets/images/arka/arka-text.svg";

import signinImg from "../../assets/images/signin-img.png";
import arkaText from "../../assets/images/arka/arka-text.svg";
import { Card, Col, Container, Row } from "reactstrap";

const SignIn = () => {
  const history = useHistory();
  const { email: reg_email } = useParams();

  const { user_data } = useSelector((state) => ({
    user_data: state.auth.user_data,
  }));
  const dispatch = useDispatch();

  const [loading, setLoading] = useState(false);

  const [showError, setShowError] = useState(false); // IMP  bydefault should be false
  // const [user, setUser] = useState({
  //   userId: "demo_admin@ideafuse.ai",
  //   password: "Ideafuse2021!",
  // });
  const [user, setUser] = useState({
    userId: "",
    password: "",
  });
  const [remember, setRemember] = useState(false);
  const [showError2, setShowError2] = useState(false); // IMP  bydefault should be false
  const [forgotData, setForgotData] = useState({
    email: "",
    code: "",
  });
  const [showModal, setShowModal] = useState(false);
  const [isCodeSent, setIsCodeSent] = useState(false);

  const { userId, password } = user;
  const { email, code } = forgotData;

  useEffect(() => {
    const cookieData = Cookies.get();
    // console.log(cookieData);
    if (cookieData?.rememberMe === "yes") {
      // const data = {
      //   userId: cookieData?.userId,
      //   password: cookieData?.password,
      // };
      const data = {
        userId: reg_email,
        password: cookieData?.password,
      };
      setRemember(true);
      setUser(data);
    } else {
      const data = {
        userId: reg_email,
        password: "",
      };
      setRemember(false);
      setUser(data);
    }
    // history.replace(
    //   `${window.location.pathname.replace('/'+reg_email, '')}`
    // );
    // history.replace('/');
  }, []);

  useEffect(() => {
    if (user_data) {
      history.replace("/");
    }
  }, [user_data]);

  const handleSubmit = async () => {
    const conditions = validator.isEmail(userId) && password.length >= 6;

    if (conditions) {
      setLoading(true);
      setShowError(false);
      const data = {
        UserId: userId,
        UserPwd: password,
      };
      try {
        const result = await API.postAuth(PATH.userSignin, data);
        // console.log("Login Res:", result);
        if (result?.isValidLogin) {
          if (remember) {
            // SET cookie
            Cookies.set("userId", userId);
            Cookies.set("password", password);
            Cookies.set("rememberMe", "yes");
          } else {
            // RESET cookie
            Cookies.remove("userId");
            Cookies.remove("password");
            Cookies.remove("rememberMe");
          }

          if (result?.lstUserRoleAccess?.length) {
            result.lstUserRoleAccess = getAccessRoutes(
              result.lstUserRoleAccess
            );
          }
          dispatch(updateUser(result));
          // result.preAuthHost = window.location.host;
          // const new_host = `${window.location.protocol}//${result.customerInfo.custDomain}.${window.location.host}`;
          // setNewHost(new_host);
          // postCrossDomainMessage(result, new_host);
        } else {
          notify("Invalid credential!!!");
        }
      } catch (err) {
        console.error(err);
        notify("something went wrong!!!. Please try later.");
      } finally {
        setLoading(false);
      }
    } else {
      setShowError(true);
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setUser((prev) => ({ ...prev, [name]: value }));
  };

  const handleForgotSubmit = async () => {
    // console.log(forgotData);
    const conditions = validator.isEmail(email) && code.length >= 6;

    if (conditions) {
      setLoading(true);
      setShowError2(false);
      const data = {
        UserId: email,
        SecurityKey: code,
      };
      try {
        const result = await API.post(PATH.forgotPassword, data);
        console.log(result);

        if (result) {
          notify(
            "An Email will be sent to the registered email associated with this account with instructions and temporary password",
            true
          );
          setIsCodeSent(true);
        } else {
          notify("Invalid UserId or Security Key.");
        }
        setLoading(false);
      } catch (err) {
        setLoading(false);
        console.error(err);
      }
    } else {
      setShowError2(true);
    }
  };

  const handleForgotData = (e) => {
    const { name, value } = e.target;
    setForgotData((prev) => ({ ...prev, [name]: value }));
  };

  const handleHideModal = () => {
    setShowModal(false);
  };

  const handleShowModal = () => {
    setIsCodeSent(false);
    setShowModal(true);
  };

  const navToParent = (route) => {
    if (
      window.location.hostname === "www.ideafuse.ai" ||
      window.location.hostname === "ideafuse.ai"
    ) {
      history.replace(route);
    } else {
      window.location.href = `https://www.ideafuse.ai${route}`;
    }
  };

  return (
    <>
      <Modal
        open={showModal}
        className="content_center--flex"
        BackdropComponent={() => (
          <span
            role="button"
            onClick={handleHideModal}
            onKeyPress={handleHideModal}
            aria-label="close"
            className="modal-backdrop--my"
          />
        )}
      >
        <div className="modal-content--my forgot-card">
          {isCodeSent ? (
            <div className="text-center">
              <MontP
                fs="18px"
                lh="26px"
                mfs="14px"
                mlh="20px"
                fw="600"
                c={colors.blue5}
                mt="10px"
              >
                We have sent you an email with your temporary password. Log in
                with your new password.
              </MontP>
            </div>
          ) : (
            <>
              <div className="text-center mb-4">
                <MontP
                  fs="24px"
                  lh="36px"
                  mfs="20px"
                  mlh="26px"
                  b
                  c={colors.blue5}
                >
                  Forgot Password!
                </MontP>
                <MontP
                  fs="16px"
                  lh="22px"
                  mfs="12px"
                  mlh="18px"
                  fw="400"
                  c={colors.blue5}
                  mt="10px"
                >
                  Send the following details to reset your password
                </MontP>
              </div>
              <TitleInput
                label="Email Address"
                name="email"
                value={email}
                onChange={handleForgotData}
                placeholder="Enter Your Email Address"
                showerr={showError2}
                errmsg="Invalid email"
                valid={validator.isEmail(email)}
              />
              <TitleInput
                label="Security Code"
                name="code"
                type="password"
                value={code}
                onChange={handleForgotData}
                placeholder="Enter security code"
                showerr={showError2}
                errmsg="Minimum length should be 6"
                valid={code.length >= 6}
                secret
              />
            </>
          )}

          <button
            onClick={isCodeSent ? handleHideModal : handleForgotSubmit}
            disabled={loading}
            className='saveBtn mt-4'
          >
            {loading && <BtnSpinner />}
            {isCodeSent ? "Login" : "Reset Password"}
          </button>

        </div>
      </Modal>

      <div className={styles.wrapper}>
        <Card className={cls(styles.card, "custom-scrollbar")}>
          <Container fluid>
            <Row>
              <Col className="p-md-3 py-md-4 p-3">
                <div>
                  <a href="https://ideafuse.ai/#home">
                    <img src={logo} alt="Ideafuse" className={styles.logo} />
                  </a>
                  <p className={styles.title}>User Login</p>
                  <p className={styles.subtitle}>
                    Enter your registered email address to access your account
                  </p>
                  <TitleInput
                    label="User ID"
                    name="userId"
                    value={userId}
                    onChange={handleChange}
                    placeholder="Enter Your User ID"
                    showerr={showError}
                    errmsg="Invalid userId"
                    valid={validator.isEmail(userId)}
                    disabled
                  />
                  <TitleInput
                    label="Password"
                    name="password"
                    type="password"
                    value={password}
                    onChange={handleChange}
                    placeholder="Enter Your Password"
                    showerr={showError}
                    errmsg="Minimum length should be 6"
                    valid={password.length >= 6}
                    secret
                  />
                  <div className="d-flex align-items-center justify-content-between mt-4">
                    <div
                      onClick={() => setRemember(!remember)}
                      className="d-flex align-items-center pointer"
                    >
                      <MyCheckbox checked={remember} />
                      <NotoP
                        fs="13px"
                        lh="16px"
                        tfs="14px"
                        tlh="16px"
                        fw="600"
                        c={colors.primary}
                      >
                        Remember me
                      </NotoP>
                    </div>
                    <NotoP
                      fs="11px"
                      lh="15px"
                      b
                      c={colors.primary}
                      onClick={handleShowModal}
                      td="underline"
                      ml="auto"
                      className="pointer"
                    >
                      Forgot password ?
                    </NotoP>
                  </div>
                  <button
                    onClick={handleSubmit}
                    disabled={loading}
                    className={styles.continueBtn}
                  >
                    {loading && <BtnSpinner />}
                    Sign In
                  </button>

                  <p className={styles.notYetText}>
                    Not yet registered with{" "}
                    <span>
                      <img src={arka} alt="arka" className={styles.arkaText} />
                    </span>{" "}
                    ?
                  </p>

                  <div onClick={() => navToParent("/register")}>
                    <p className={styles.register}>Register</p>
                  </div>
                </div>
              </Col>
            </Row>
          </Container>
        </Card>
      </div>
    </>
  );
};

export default SignIn;
