import React from "react";
import PropTypes from "prop-types";
import { Modal } from "reactstrap";
import styles from "./info-modal.module.css";

import closeImg from "../../assets/icons/close-cross.svg";

const InfoModal = ({ size = "md", isOpen = false, setIsOpen, item }) => {
  const toggle = () => {
    setIsOpen(!isOpen);
  };

  return (
    <Modal
      isOpen={isOpen}
      toggle={toggle}
      size={size}
      //className={styles.wrapper}
      //wrapClassName={styles.wrap}
      //modalClassName={styles.modal}
      contentClassName={styles.content}
      backdrop={true}
    >
      <span onClick={toggle} className={styles.closeImg}>
        <img src={closeImg} alt="Close" />
      </span>
      {item && (
        <div>
          <p className={styles.title}>{item.title}</p>
          <ul className={styles.ul}>
            {item.data?.map((x, i) => (
              <li key={i} className={styles.li}>
                {x}
              </li>
            ))}
          </ul>
        </div>
      )}
    </Modal>
  );
};

InfoModal.propTypes = {
  size: PropTypes.string,
  isOpen: PropTypes.bool,
  setIsOpen: PropTypes.func,
  item: PropTypes.object.isRequired,
};

export default InfoModal;
