import { Container, TabContent, TabPane, Nav, Row, Col } from "reactstrap";
import DocumentsAdaption from "./Tabs/DocumentsAdaption";
import TrainedDocuments from "./Tabs/TrainedDocuments";
import TabsCover from "../../components/tabbar/TabsCover";
import MyTab from "../../components/tabbar/MyTab";
import KPIsignificance from "./Tabs/KPIsignificance";
import DocumentThresholds from "./Tabs/DocumentThresholds";
import { useAccessTabs } from "../../hooks/useAccessTabs";

export default function Adaption() {
  const [tabs, activeTab, setActiveTab] = useAccessTabs();

  const toggle = (tab) => {
    if (activeTab !== tab) setActiveTab(tab);
  };

  return (
    <Container fluid className="pb-5">
      <Row className="justify-content-center mt-4">
        <Col xs={12} sm={12} md={12} className="pt-5">
          <Nav tabs style={{marginTop:'-30px'}} className="justify-content-center border-0">
            <TabsCover>
              <div className="d-lg-flex">
                {tabs.map((item, index) => (
                  <MyTab
                    title={item.menuTabName}
                    onClick={() => toggle(item.menuTabName_path)}
                    isActive={activeTab === item.menuTabName_path}
                  />
                ))}
              </div>
            </TabsCover>
          </Nav>
          <TabContent activeTab={activeTab} className="position-relative">
            <TabPane tabId="1">
              <DocumentsAdaption />
            </TabPane>
            <TabPane tabId="2">
              <TrainedDocuments />
            </TabPane>
            <TabPane tabId="3">
              <KPIsignificance />
            </TabPane>
            <TabPane tabId="4">
              <DocumentThresholds />
            </TabPane>
          </TabContent>
        </Col>
      </Row>
    </Container>
  );
}
