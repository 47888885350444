import React from 'react';
import { useState } from "react";
import { Row, Col, Dropdown, DropdownToggle, DropdownMenu, DropdownItem, Table } from "reactstrap";
import validator from "validator";
import styled from "styled-components";
import TitleInput from "../../../components/TitleInput";
import { colors } from "../../../styles/theme";
import { CustomImg, NotoP } from "../../../styles/common";
import FormSpinner from "../../../components/FormSpinner";
import { API, PATH } from "../../../services/Axios";
import { notify } from "../../../utils";
import MyCheckbox from "../../../components/MyCheckbox";
import chev_down from "../../../assets/icons/chev-down.svg";
import styles from '../Administration.module.css'
import edit from "../../../assets/icons/edit.svg";
import deleteImg from "../../../assets/icons/delete.svg";
import BtnSpinner from '../../../components/BtnSpinner';
import { useEffect } from 'react';
import { current } from '@reduxjs/toolkit';


const CustomDropdownToggle = styled(DropdownToggle)`
width: 110px;
height: 40px;
border-radius: 49px;
background: #03256c;
display: flex;
align-items: center;
justify-content: space-between;
padding: 0 15px;
outline: none;
border: none;
z-index: 10;

&:hover,
&:active,
&:focus {
  background-color: #03256c;
  outline: none;
  border: none;
  box-shadow: none;
}
`;

const initialTwo = {
    addressId: null,
    contactId: 1,
    addressTypeId: 2,
    addressLine1: "",
    addressLine2: "",
    city: "",
    country: "",
    zipCode: "",
    mailing: false,
    active: true
};

const AddressDetails = ({ user_data }) => {

    const [loading, setLoading] = useState(false);
    const [showError, setShowError] = useState(false);
    const [contactTwoData, setContactTwoData] = useState(initialTwo);
    const { addressLine1, addressLine2, country, city, zipCode } = contactTwoData;
    const [showServerType, setShowServerType] = useState(false);
    const [addressTypeData, setAddressTypeData] = useState([]);
    const [data, setData] = useState([]);
    const [addressType, setAddressType] = useState({});
    const [mailingType, setMailingType] = useState(false);
    const [currentType, setCurrentType] = useState(false);

    const [isEdit, setIsEdit] = useState(null);

    const toggleServerType = () => {
        setShowServerType(!showServerType);
    };

    const getAddressData = async () => {

        const contactId = {
            // contactId: 1
        };
        const result = await API.post(
            PATH.getCustomerContactAddress,
            contactId,
            user_data.token
        );
        console.log(result);
        setData(result);
    };

    const getAddressTypes = async () => {
        const result = await API.post(
            PATH.getCustomerAddressTypes,
            {},
            user_data.token
        );
        setAddressTypeData(result);
        setAddressType(result[0]);
    }

    useEffect(() => {
        getAddressTypes();
        getAddressData();
    }, [user_data]);

    console.log(user_data);

    const handleClear = () => {
        setContactTwoData({
            addressLine1: "",
            addressLine2: "",
            country: "",
            city: "",
            zipCode: "",
        });
        setMailingType(false);
        setCurrentType(false);
        setIsEdit(null);
    };

    const handleAddData = async () => {
        const conditions =
            addressLine1.length >= 10 &&
            addressLine2.length >= 10 &&
            validator.isAlpha(country) &&
            validator.isAlpha(city) &&
            zipCode.length >= 4;

        if (conditions) {
            setShowError(false);
            const newData = {
                addressId: null,
                addressTypeId: addressType.addressTypeId,
                addressLine1: addressLine1,
                addressLine2: addressLine2,
                city: city,
                country: country,
                zipCode: zipCode,
                mailing: mailingType,
                isCurrent: currentType,
            }
            console.log(newData);
            setLoading(true);
            try {
                const result = await API.post(
                    PATH.addUpdateUserContactAddress,
                    newData,
                    user_data.token
                );
                console.log(result);
                if (result) {
                    notify("Customer Address Details Added", true);
                    getAddressData();
                    handleClear();
                } else {
                    notify(
                        "Customer Address Details not updated. Please send an email to admin@ideafuse.ai or create an issue using the Create Issue Screen"
                    );
                }
                setLoading(false);
            } catch (err) {
                console.log(err);
                notify(
                    "Customer Address Details not updated. Please send an email to admin@ideafuse.ai or create an issue using the Create Issue Screen"
                );
                setLoading(false);
            }
        } else {
            setShowError(true);
        }
    };

    const handleUpdateData = async () => {
        const conditions =
            addressLine1.length >= 10 &&
            addressLine2.length >= 10 &&
            validator.isAlpha(country) &&
            validator.isAlpha(city) &&
            zipCode.length >= 4;

        if (conditions) {
            setShowError(false);
            const newData = {
                addressId: isEdit.id,
                addressTypeId: addressType.addressTypeId,
                addressLine1: addressLine1,
                addressLine2: addressLine2,
                city: city,
                country: country,
                zipCode: zipCode,
                mailing: mailingType,
                isCurrent: currentType,
            };
            console.log(newData);

            setLoading(true);
            try {
                const result = await API.post(
                    PATH.addUpdateUserContactAddress,
                    newData,
                    user_data.token
                );
                console.log(result);
                if (result) {
                    notify("Customer Address Details Updated", true);
                    getAddressData();
                    handleClear();
                } else {
                    notify(
                        "Customer Address Details not updated. Please send an email to admin@ideafuse.ai or create an issue using the Create Issue Screen"
                    );
                }
                setLoading(false);
            } catch (err) {
                console.log(err);
                notify(
                    "Customer Address Details not updated. Please send an email to admin@ideafuse.ai or create an issue using the Create Issue Screen"
                );
                setLoading(false);
            }
            setIsEdit(null);
        } else {
            setShowError(true);
        }
    };

    const handleChangeTwo = (e) => {
        const { name, value } = e.target;
        setContactTwoData((prev) => ({ ...prev, [name]: value }));
    };

    const handleEdit = (item) => {
        setContactTwoData(item);
        setIsEdit({ id: item.addressId, isEdit: true });
        setMailingType(item.mailing);
        setCurrentType(item.isCurrent);
        setAddressType(addressTypeData.find(x => x.addressTypeId === item.addressTypeId));
    };

    const handleDelete = async (item) => {
        const addressId = {
            addressId: item.addressId
        };

        try {
            const result = await API.post(
                PATH.deleteUserContactAddress,
                addressId,
                user_data.token
            );
            console.log(result);
            if (result) {
                notify("Customer Address Details deleted", true);
                getAddressData();
            } else {
                notify(
                    "Customer Address Details not deleted. Please send an email to admin@ideafuse.ai or create an issue using the Create Issue Screen"
                );
            }
            // setLoading(false);
        } catch (err) {
            console.log(err);
            notify(
                "Customer Address Details not deleted. Please send an email to admin@ideafuse.ai or create an issue using the Create Issue Screen"
            );
            // setLoading(false);
        }
    };


    return (
        <Row >
            {loading && <FormSpinner />}
            <Col xs={12} sm={12} md={12} lg={6} className="mx-auto" >
                <TitleInput
                    label="Address Line 1"
                    name="addressLine1"
                    type="text"
                    placeholder="Enter Your Address Line 1"
                    value={addressLine1}
                    onChange={handleChangeTwo}
                    showerr={showError}
                    errmsg="Minimum length should be 10"
                    valid={addressLine1.length >= 10}
                />
                <TitleInput
                    label="Address Line 2"
                    name="addressLine2"
                    type="text"
                    placeholder="Enter Your Address Line 2"
                    value={addressLine2}
                    onChange={handleChangeTwo}
                    showerr={showError}
                    errmsg="Minimum length should be 10"
                    valid={addressLine2.length >= 10}
                />
                <TitleInput
                    label="Country"
                    name="country"
                    type="text"
                    placeholder="Enter Your Country"
                    value={country}
                    onChange={handleChangeTwo}
                    showerr={showError}
                    errmsg="Country name contains only letters (a-zA-Z)"
                    valid={validator.isAlpha(country)}
                />
                <Row>
                    <Col md={6}>
                        <TitleInput
                            label="City"
                            name="city"
                            type="text"
                            placeholder="Enter Your City"
                            value={city}
                            onChange={handleChangeTwo}
                            showerr={showError}
                            errmsg="City name contains only letters (a-zA-Z)"
                            valid={validator.isAlpha(city)}
                        />
                    </Col>
                    <Col md={6}>
                        <TitleInput
                            label="Zip Code"
                            name="zipCode"
                            type="text"
                            placeholder="Enter Your Zip Code"
                            value={zipCode}
                            onChange={handleChangeTwo}
                            showerr={showError}
                            errmsg="Invalid zipcode/postalcode."
                            valid={zipCode?.length >= 4}
                        />
                    </Col>
                    <Col md={12} className="mt-4">
                        <NotoP
                            fs="14px"
                            lh="19px"
                            tfs="14px"
                            tlh="19px"
                            mfs="12px"
                            mlh="16px"
                            fw="600"
                            c={colors.primary}
                        >
                            Address Type
                            <span className="iRed">*</span>
                        </NotoP>
                        <Dropdown className='mt-1' isOpen={showServerType} toggle={toggleServerType}>
                            <CustomDropdownToggle className='w-50'>
                                <NotoP
                                    style={{ whiteSpace: 'nowrap' }}
                                    fs="14px"
                                    lh="20px"
                                    fw="600"
                                    c={colors.white}>
                                    {addressType.addressType}
                                </NotoP>
                                <CustomImg src={chev_down} w="12px" h="8px" />
                            </CustomDropdownToggle>

                            <DropdownMenu>
                                {addressTypeData?.map((x) => (
                                    <DropdownItem
                                        key={x.addressTypeId}
                                        disabled={addressType.addressTypeId === x.addressTypeId}
                                        onClick={() => setAddressType(x)}
                                    >
                                        <NotoP fs="14px" lh="20px" fw="600" c={colors.secondary}>
                                            {x.addressType}
                                        </NotoP>
                                    </DropdownItem>
                                ))}
                            </DropdownMenu>
                        </Dropdown>
                    </Col>
                    <Col md={12} className="mt-4">
                        <div
                            onClick={() => setCurrentType(!currentType)}
                            className="d-flex align-items-center mt-3 pointer"
                        >
                            <MyCheckbox checked={currentType} />
                            <NotoP fs="14px" fw="600" c={colors.primary}>
                                Is this your current address ?
                            </NotoP>
                        </div>
                        <div
                            onClick={() => setMailingType(!mailingType)}
                            className="d-flex align-items-center mt-3 pointer"
                        >
                            <MyCheckbox checked={mailingType} />
                            <NotoP fs="14px" fw="600" c={colors.primary}>
                                Is this your mailing address ?
                            </NotoP>
                        </div>
                    </Col>
                </Row>


                <div className="d-flex gap-3">
                    <button
                        onClick={isEdit?.isEdit ? handleUpdateData : handleAddData}
                        className='saveBtn mt-4'
                    >
                        {loading && <BtnSpinner />}
                        {isEdit?.isEdit ? 'Update' : 'Save'}
                    </button>
                    {isEdit?.isEdit &&
                        <button
                            onClick={() => handleClear()}
                            className='cancelBtn mt-4'
                        >
                            Cancel
                        </button>
                    }
                </div>

            </Col>

            <Col sm={10} className="mx-auto">
                <div
                    style={{ maxHeight: "60vh" }}
                    className="mt-4 position-relative overflow-auto custom-scrollbar"
                >
                    <Table striped borderless>
                        <thead className={styles.thead}>
                            <tr>
                                <th className="text-white">Address Line 1</th>
                                <th className="text-white">Address Line 2</th>
                                <th className="text-white">Country</th>
                                <th className="text-white">City</th>
                                <th className="text-white">Zip Code</th>
                                <th className="text-white">Address Type</th>
                                <th className="text-white">Mailing Address</th>
                                <th className="text-white">Current Address</th>
                                <th className="text-white">Edit / Delete</th>
                            </tr>
                        </thead>
                        <tbody>
                            {data?.map((item, index) => (
                                <>
                                    <tr style={{
                                        backgroundColor: index % 2 === 0
                                            ? "rgba(37, 65, 178, 0.1)"
                                            : colors.blank, height: "30px"
                                    }}>
                                        <td>{item.addressLine1}</td>
                                        <td>{item.addressLine2}</td>
                                        <td>{item.country}</td>
                                        <td>{item.city}</td>
                                        <td>{item.zipCode}</td>
                                        <td>{item.addressType}</td>
                                        <td>
                                            <MyCheckbox
                                                checked={item.mailing}
                                            />
                                        </td>
                                        <td>
                                            <MyCheckbox
                                                checked={item.isCurrent}
                                            />
                                        </td>
                                        <td className='d-flex gap-3 justify-content-center'>
                                            <CustomImg
                                                style={{ cursor: 'pointer' }}
                                                onClick={() => handleEdit(item)} src={edit} w="16px" h="16px" />
                                            <CustomImg
                                                style={{ cursor: 'pointer' }}
                                                onClick={() => handleDelete(item)}
                                                src={deleteImg}
                                                w="16px" h="16px" />
                                        </td>
                                    </tr>
                                </>
                            ))}
                        </tbody>
                    </Table>
                </div>
            </Col>
        </Row>
    );
};

export default AddressDetails;