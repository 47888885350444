import { useState, useEffect } from "react";
import classnames from "classnames";
import styles from "./User.module.css";
import {
  Container,
  TabContent,
  TabPane,
  Nav,
  NavItem,
  NavLink,
  Row,
  Col,
} from "reactstrap";
import { useSelector } from "react-redux";
import AddUsers from "./Tabs/AddUsers";
import SearchUsers from "./Tabs/SearchUsers";
import { API, PATH } from "../../services/Axios";
import TabsCover from "../../components/tabbar/TabsCover";
import MyTab from "../../components/tabbar/MyTab";
import RoleAccess from "./Tabs/RoleAccess";
import { useAccessTabs } from "../../hooks/useAccessTabs";

export default function User() {
  const { user_data } = useSelector((state) => ({
    user_data: state.auth.user_data,
  }));

  const [tabs, activeTab, setActiveTab] = useAccessTabs();

  const [usersList, setUsersList] = useState(null);
  const [userType, setUserType] = useState([]);

  const toggle = (tab) => {
    if (activeTab !== tab) setActiveTab(tab);
  };

  const getUsersList = async () => {
    const data = {
      LoggedInUserId: user_data?.userInfo?.userEmail,
    };
    try {
      const result = await API.post(PATH.usersList, data, user_data.token);
      console.log(result);
      if (result) {
        setUsersList(result);
      }
    } catch (err) {
      console.log(err);
    }
  };

  const getUserType = async () => {
    try {
      const result = await API.get(PATH.userType, user_data.token);
      console.log(result);
      if (result) {
        setUserType(result);
      }
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    getUserType();
    getUsersList();
  }, [user_data]);

  return (
    <Container fluid className="pb-5">
      <Row className="justify-content-center mt-3">
        <Col
          xs={12}
          sm={12}
          md={{ size: window.innerWidth > 991 ? 8 : 12 }}
          className="pt-5 pb-5"
        >
          <Nav tabs className="justify-content-center border-0">
            <TabsCover>
              {tabs.map((item, index) => (
                <MyTab
                  title={item.menuTabName}
                  onClick={() => toggle(item.menuTabName_path)}
                  isActive={activeTab === item.menuTabName_path}
                />
              ))}
            </TabsCover>
          </Nav>
          <TabContent activeTab={activeTab} className="position-relative">
            <TabPane tabId="1">
              <AddUsers userType={userType} usersList={usersList} getUsersList={getUsersList} user_data={user_data} />
            </TabPane>
            <TabPane tabId="2">
              <SearchUsers userType={userType} usersList={usersList} user_data={user_data} />
            </TabPane>
            <TabPane tabId="3">
              <RoleAccess userType={userType} user_data={user_data} />
            </TabPane>
          </TabContent>
        </Col>
      </Row>
    </Container>
  );
}
