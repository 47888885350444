import { useState, useRef, useEffect } from "react";
import PropTypes from "prop-types";
import {
  Row,
  Col,
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap";
import validator from "validator";
import styled from "styled-components";
import TitleInput from "../../../components/TitleInput";
import { colors } from "../../../styles/theme";
import { CustomImg, MiButton, NotoP } from "../../../styles/common";
import { API, PATH } from "../../../services/Axios";
import { notify } from "../../../utils";
import FormSpinner from "../../../components/FormSpinner";
import SimpleDocPicker from "../../../components/SimpleDocPicker";

import chev_down from "../../../assets/icons/chev-down.svg";

const CustomDropdownToggle = styled(DropdownToggle)`
  width: 110px;
  height: 40px;
  border-radius: 49px;
  background: #03256c;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 15px;
  outline: none;
  border: none;
  z-index: 10;

  &:hover,
  &:active,
  &:focus {
    background-color: #03256c;
    outline: none;
    border: none;
    box-shadow: none;
  }
`;

const FooterText = styled.span`
  font-family: Montserrat;
  font-style: italic;
  font-weight: normal;
  font-size: 12px;
  line-height: 18px;
  /* identical to box height */
  display: block;
  align-items: center;
  text-align: ${({ align }) => (align ? align : "left")};
  /* primary-color */
  color: #03256c;
`;

function DataDrop(props) {
  const { user_data, settings_data } = props;
  const docRef = useRef();
  const [loading, setLoading] = useState(false);

  const initialApiData = () => {
    return {
      serverName: "",
      serverIp: "",
      connection_strings: "",
      additional_details: "",
    };
  };
  const [showServerType, setShowServerType] = useState(false);
  const [serverType, setServerType] = useState("Storage"); // Storage, DB
  const [doc, setDoc] = useState(null);
  const [showError, setShowError] = useState(false); // IMP  bydefault should be false
  const [serverData, setServerData] = useState(initialApiData);
  const { serverName, serverIp, connection_strings, additional_details } =
    serverData;
  const {
    cusApiUrl,
    cusApiEndpoint,
    cusApiInputParameters,
    cusApiAuthorization,
    cusApiAdditionalDetails,
    cusApiFileName,
    cusIntegrationType,
    cusServerName,
    cusServerIp,
    cusConnString,
    cusAdditionalDetails,
    cusStodbFileName,
  } = settings_data;

  useEffect(() => {
    const newData = {
      serverName: cusServerName || "",
      serverIp: cusServerIp || "",
      connection_strings: cusConnString || "",
      additional_details: cusAdditionalDetails || "",
    };
    setServerData(newData);
    setServerType(cusIntegrationType || "Storage");
  }, [settings_data]);

  const handleSubmit = async () => {
    const conditions =
      !validator.isEmpty(serverName) &&
      !validator.isEmpty(serverIp) &&
      !validator.isEmpty(connection_strings) &&
      !validator.isEmpty(additional_details);

    if (conditions) {
      const formData = new FormData();
      formData.append("CustId", user_data?.customerInfo?.custId);
      formData.append("LoginId", user_data?.userInfo?.userEmail);
      formData.append("CusIntegrationId", settings_data?.cusIntegrationId || 0);
      formData.append("IsUpdate", Boolean(settings_data?.cusIntegrationId));
      formData.append("CusIntegrationType", serverType);
      formData.append("CusServerName", serverName);
      formData.append("CusServerIp", serverIp);
      formData.append("CusConnString", connection_strings);
      formData.append("CusAdditionalDetails", additional_details);
      formData.append("CusStodbFileName", doc?.name || cusStodbFileName);
      // break;
      formData.append("CusApiUrl", cusApiUrl || "");
      formData.append("CusApiEndpoint", cusApiEndpoint || "");
      formData.append("CusApiInputParameters", cusApiInputParameters || "");
      formData.append("CusApiAuthorization", cusApiAuthorization || "");
      formData.append("CusApiAdditionalDetails", cusApiAdditionalDetails || "");
      formData.append("CusApiFileName", cusApiFileName || "");

      // File Upload
      if (doc) {
        formData.append("StobFileObject", doc);
      }

      setLoading(true);
      try {
        const result = await API.post(
          PATH.updateIntegSettings,
          formData,
          user_data.token
        );
        console.log(result);
        if (result) {
          notify("Your configuration settings were sucessfully saved", true);
        } else {
          notify(
            "Error encountered while saving your configuration settings. Please try again or if the problem persists create and issue using our Issue Management Service."
          );
        }
        setLoading(false);
      } catch (err) {
        console.log(err);
        setLoading(false);
      }
    } else {
      setShowError(true);
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setServerData((prev) => ({ ...prev, [name]: value }));
  };

  const handleDocPicker = (e) => {
    const { name, files } = e.target;
    console.log(name);

    if (files.length > 0) {
      setDoc(files[0]);
    }
  };

  const toggleServerType = () => {
    setShowServerType(!showServerType);
  };

  return (
    <Row className="mt-4 ps-4 pe-2">
      <Col md={{ size: 6, offset: 3 }} className="position-relative">
        {loading && <FormSpinner />}
        <div className="mt-3 d-flex align-items-center justify-content-between">
          <NotoP
            fs="14px"
            lh="19px"
            mfs="12px"
            mlh="16px"
            fw="600"
            c={colors.primary}
          >
            Server Type
          </NotoP>
          <Dropdown isOpen={showServerType} toggle={toggleServerType}>
            <CustomDropdownToggle>
              <NotoP fs="14px" lh="20px" fw="600" c={colors.white}>
                {serverType}
              </NotoP>
              <CustomImg src={chev_down} w="12px" h="8px" />
            </CustomDropdownToggle>

            <DropdownMenu>
              {["Storage", "DB"].map((x, i) => (
                <DropdownItem
                  key={i}
                  disabled={serverType === x}
                  onClick={() => setServerType(x)}
                >
                  <NotoP fs="14px" lh="20px" fw="600" c={colors.secondary}>
                    {x}
                  </NotoP>
                </DropdownItem>
              ))}
            </DropdownMenu>
          </Dropdown>
        </div>
        <TitleInput
          label="Server Name"
          name="serverName"
          placeholder="Enter server name"
          value={serverName}
          onChange={handleChange}
          showerr={showError}
          //errmsg="Invalid End point"
          valid={!validator.isEmpty(serverName)}
        />
        <TitleInput
          label="Server IP"
          name="serverIp"
          placeholder="Enter server ip"
          value={serverIp}
          onChange={handleChange}
          showerr={showError}
          //errmsg="Invalid server IP"
          valid={!validator.isEmpty(serverIp)}
        />
        <TitleInput
          label="Connection Strings"
          name="connection_strings"
          placeholder="Enter your connection strings"
          value={connection_strings}
          onChange={handleChange}
          showerr={showError}
          //errmsg="Invalid connection_strings"
          valid={!validator.isEmpty(connection_strings)}
        />
        <TitleInput
          label="Additional Details"
          name="additional_details"
          placeholder="Enter additional details"
          value={additional_details}
          onChange={handleChange}
          showerr={showError}
          //errmsg="Invalid End point"
          valid={!validator.isEmpty(additional_details)}
        />
        <div className="mt-3">
          <NotoP
            fs="14px"
            lh="19px"
            mfs="12px"
            mlh="16px"
            fw="600"
            c={colors.primary}
          >
            File Attachment
            {<span className="iRed">*</span>}
          </NotoP>
          <SimpleDocPicker
            accept={".pdf,.docx"}
            inputRef={docRef}
            setFile={setDoc}
            handleChange={handleDocPicker}
            filename={doc?.name || cusStodbFileName}
          />
          <FooterText>File supported .pdf, .doc</FooterText>
        </div>
        <div className="text-center">
          <button
            disabled={loading}
            onClick={handleSubmit}
            className='saveBtn w-50 mx-auto mt-4'
          >
            {/* {loading && <BtnSpinner />} */}
            Submit
          </button>
        </div>
      </Col>
    </Row>
  );
}

DataDrop.propTypes = {
  user_data: PropTypes.object,
  settings_data: PropTypes.object,
};

export default DataDrop;
