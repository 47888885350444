import { Link } from "react-router-dom";
import styled from "styled-components";
import { useDispatch } from "react-redux";

import { CustomImg } from "../../../styles/common";
import { toggleMainSidebar } from "../../../redux/actions/app_A";

import arka from "../../../assets/images/arka/arka-text.svg";
import doubleleft from "../../../assets/icons/doubleleft.svg";

const Brand = styled(Link)`
  text-decoration: none;
  padding-left: 2vw;

  &:hover {
    text-decoration: none;
    transition: 0.5s;
  }

  @media only screen and (max-width: 768px) {
    padding-left: 5vw;
  }
`;

const SidebarAppLogo = () => {
  const dispatch = useDispatch();

  return (
    <div className="d-flex align-items-center justify-content-between">
      <Brand to="/">
        <CustomImg src={arka} alt="logo" w="80px" mt="15px" mb="15px" />
      </Brand>
      <button
        onClick={() => dispatch(toggleMainSidebar())}
        className="me-3 d-none d-md-block border-0 py-1 px-2 bg-white"
      >
        <CustomImg src={doubleleft} alt="close" w="14px" />
      </button>
    </div>
  );
};

export default SidebarAppLogo;
