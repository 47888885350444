import React, { useState } from "react";
import cls from "classnames";
import { useHistory, useLocation } from "react-router-dom";
import styles from "./arka-toast.module.css";

import arka from "../../assets/images/arka/arka-text.svg";
import close from "../../assets/icons/close-white.svg";

const ArkaToast = () => {
  // const { pathname, hash } = useLocation();
  const [showToast, setShowToast] = useState(true);
  // console.log(location);
  // const isToastPage = () =>
  //   pathname === "/" && (hash === "" || hash === "#home") && showToast;

  return (
    <div className={cls(styles.wrapper, !showToast && "d-none")} >
    <div className='pos-relative'>
      <a href='/#offerings' className={cls(styles.link, styles.container)}>
        <p className={styles.text}>
          We are offering 4 weeks free trial for
          <span className="ms-2">
            <img src={arka} alt="arka" className={styles.arkaText} />
          </span>
        </p>
        </a>
        <span onClick={() => setShowToast(false)} className={styles.closePos}>
          <img src={close} alt="Close" />
        </span>
      </div>    
    </div>
  );
};

export default ArkaToast;
