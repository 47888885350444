import { useState } from "react";
import PropTypes from "prop-types";
import styles from "./Components.module.css";
import MyCheckbox from "./MyCheckbox";
import { NotoP } from "../styles/common";

const KPIsignRow = ({ bgColor, item }) => {
  const [, setRandomNo] = useState(0);

  return (
    <tr style={{ backgroundColor: bgColor, height: "30px" }}>
      <td>{item.cusKpiName}</td>
      <td>
        <input
          value={item.cusKpiBaseValue}
          onChange={(e) => {
            item.cusKpiBaseValue = e.target.value;
            setRandomNo(Math.random(2));
          }}
          className={styles.basevalueInput}
        />
        {item.cusKpiAnalyticsKey && !item.cusKpiBaseValue && (
          <NotoP fs="10px" className="text-danger py-1">
            Enter a Valid Base Value
          </NotoP>
        )}
      </td>
      <td>
        <MyCheckbox
          checked={item.cusKpiAnalyticsKey}
          onChange={(e) => {
            item.cusKpiAnalyticsKey = e.target.checked;
            setRandomNo(Math.random(2));
          }}
        />
      </td>
      <td>
        <MyCheckbox
          checked={item.cusKpiEnabled}
          onChange={(e) => {
            item.cusKpiEnabled = e.target.checked;
            setRandomNo(Math.random(2));
          }}
        />
      </td>
    </tr>
  );
};

KPIsignRow.propTypes = {
  bgColor: PropTypes.string,
  item: PropTypes.object,
};

export default KPIsignRow;
