import React, { useState } from "react";
import { Card, Collapse } from "reactstrap";
import cls from "classnames";
import { useHistory } from "react-router-dom";
import styles from "./collapse-plan.module.css";
import { colors } from "../../styles/theme";

import chevRight from "../../assets/icons/chev-right-primary.svg";
import chevDown from "../../assets/icons/chev-down-white.svg";
import checkWhiteImg from "../../assets/icons/check-w.svg";

const CollapsePlan = ({ title, data }) => {
  const history = useHistory();
  const [isOpen, setIsOpen] = useState(false);

  return (
    <Card
      className={styles.card}
      style={{ backgroundColor: isOpen ? colors.primary : "#dce8ff" }}
    >
      <div
        onClick={() => setIsOpen(!isOpen)}
        className={cls(styles.header, { [styles.active]: isOpen })}
      >
        <p>{title}</p>
        <img src={isOpen ? chevDown : chevRight} alt="Arrow" />
      </div>
      <Collapse isOpen={isOpen}>
        <div className={styles.body}>
          <div>
            {data.map((x, i) => (
              <div key={i} className={styles.featureDiv}>
                <img src={checkWhiteImg} alt="Check" />
                <p>{x}</p>
              </div>
            ))}
          </div>
          <button
            onClick={() => history.push("/request-demo")}
            className={styles.learnMoreBtn}
          >
            Learn More
          </button>
        </div>
      </Collapse>
    </Card>
  );
};

export default CollapsePlan;
