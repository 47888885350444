import { MontP, NotoP } from "../styles/common";
import { colors } from "../styles/theme";

import ideafuseBg from "../assets/images/ideafuse-bg.svg";

export default function PrivacyPolicy() {
  const personalInfo = [
    "First and Last Name",
    "Mailing Address",
    "E-mail Address",
    "Phone Number",
    "Employer",
    "Job Title",
  ];
  const delSubject = [
    "Delete your personal information from our records; and",
    "Direct any service providers to delete your personal information from their records.",
  ];
  const delNote = [
    "Complete the transaction for which the personal information was collected, fulfill the terms of a written warranty or product recall conducted in accordance with federal law, provide a good or service requested by you, or reasonably anticipated within the context of our ongoing business relationship with you, or otherwise perform a contract between you and us;",
    "Detect security incidents, protect against malicious, deceptive, fraudulent, or illegal activity; or prosecute those responsible for that activity;",
    "Debug to identify and repair errors that impair existing intended functionality;",
    "Exercise free speech, ensure the right of another consumer to exercise his or her right of free speech, or exercise another right provided for by law;",
    "Comply with the California Electronic Communications Privacy Act;",
    "Engage in public or peer-reviewed scientific, historical, or statistical research in the public interest that adheres to all other applicable ethics and privacy laws, when our deletion of the information is likely to render impossible or seriously impair the achievement of such research, provided we have obtained your informed consent;",
    "Enable solely internal uses that are reasonably aligned with your expectations based on your relationship with us;",
    "Comply with an existing legal obligation; or",
    "Otherwise use your personal information, internally, in a lawful manner that is compatible with the context in which you provided the information.",
  ];

  return (
    <>
      <img src={ideafuseBg} alt="ideafuse" className="pp_ideafuse--bg" />
      <div className="ps-md-5 pe-md-3 p-3">
        <MontP
          fs="24px"
          lh="30px"
          tfs="21px"
          tlh="26px"
          mfs="18px"
          mlh="24px"
          b
          c={colors.blue5}
          mt="100px"
        >
          Ideafuse Privacy Policy
        </MontP>
        <NotoP
          fs="16px"
          lh="22px"
          tfs="14px"
          tlh="20px"
          mfs="12px"
          mlh="18px"
          fw="400"
          c={colors.blue5}
          mt="2vw"
        >
          Protecting your private information is our priority. This Statement of
          Privacy applies to IDEAFUSE AI SOLUTIONS LLC, ARKA and governs data
          collection and usage. For the purposes of this Privacy Policy, unless
          otherwise noted, all references to IDEAFUSE AI SOLUTIONS LLC include
          www.ideafuse.ai and IDEAFUSE AI SOLUTIONS LLC’s ARKA application. By
          using the IDEAFUSE AI SOLUTIONS LLC application, you consent to the
          data practices described in this statement.{" "}
        </NotoP>
        {/* <NotoP
          fs="16px"
          lh="22px"
          mfs="13px"
          mlh="18px"
          fw="600"
          c={colors.secondary}
          mt="2vw"
          mb="2vw"
        >
          Download a copy of this Privacy Policy (PDF)
        </NotoP> */}
        <MontP
          fs="18px"
          lh="25px"
          tfs="17px"
          tlh="24px"
          mfs="15px"
          mlh="20px"
          b
          c={colors.blue5}
          mt="1.5vw"
        >
          Collection of your Personal Information
        </MontP>
        <NotoP
          fs="16px"
          lh="22px"
          tfs="14px"
          tlh="20px"
          mfs="12px"
          mlh="18px"
          fw="400"
          c={colors.blue5}
          mt="8px"
        >
          In order to better provide you with products and services offered,
          IDEAFUSE AI SOLUTIONS LLC may collect personally identifiable
          information, such as your:
        </NotoP>
        {personalInfo.map((x, i) => (
          <div key={i} className="d-flex">
            <div>
              <div className="privacy-sm--dot"></div>
            </div>
            <NotoP
              fs="16px"
              lh="22px"
              tfs="14px"
              tlh="20px"
              mfs="12px"
              mlh="18px"
              fw="400"
              c={colors.blue5}
            >
              {x}
            </NotoP>
          </div>
        ))}
        <NotoP
          fs="16px"
          lh="22px"
          tfs="14px"
          tlh="20px"
          mfs="12px"
          mlh="18px"
          fw="400"
          c={colors.blue5}
          mt="8px"
        >
          If you purchase IDEAFUSE AI SOLUTIONS LLC products and services, we
          collect billing and credit card information. This information is used
          to complete the purchase transaction.
        </NotoP>
        <NotoP
          fs="16px"
          lh="22px"
          tfs="14px"
          tlh="20px"
          mfs="12px"
          mlh="18px"
          fw="400"
          c={colors.blue5}
          mt="8px"
        >
          IDEAFUSE AI SOLUTIONS LLC may also collect anonymous demographic
          information, which is not unique to you, such as your:{" "}
        </NotoP>
        <NotoP
          fs="16px"
          lh="22px"
          tfs="14px"
          tlh="20px"
          mfs="12px"
          mlh="18px"
          fw="400"
          c={colors.blue5}
          mt="8px"
        >
          We do not collect any personal information about you unless you
          voluntarily provide it to us. However, you may be required to provide
          certain personal information to us when you elect to use certain
          products or services. These may include: (a) registering for an
          account; (b) entering a sweepstakes or contest sponsored by us or one
          of our partners; (c) signing up for special offers from selected third
          parties; (d) sending us an email message; (e) submitting your credit
          card or other payment information when ordering and purchasing
          products and services. To wit, we will use your information for, but
          not limited to, communicating with you in relation to services and/or
          products you have requested from us. We also may gather additional
          personal or non-personal information in the future.{" "}
        </NotoP>
        <MontP
          fs="18px"
          lh="25px"
          tfs="17px"
          tlh="24px"
          mfs="15px"
          mlh="20px"
          b
          c={colors.blue5}
          mt="1.5vw"
        >
          Use of your Personal Information
        </MontP>
        <NotoP
          fs="16px"
          lh="22px"
          tfs="14px"
          tlh="20px"
          mfs="12px"
          mlh="18px"
          fw="400"
          c={colors.blue5}
          mt="8px"
        >
          IDEAFUSE AI SOLUTIONS LLC collects and uses your personal information
          to operate and deliver the services you have requested.
        </NotoP>
        <NotoP
          fs="16px"
          lh="22px"
          tfs="14px"
          tlh="20px"
          mfs="12px"
          mlh="18px"
          fw="400"
          c={colors.blue5}
          mt="8px"
        >
          IDEAFUSE AI SOLUTIONS LLC may also use your personally identifiable
          information to inform you of other products or services available from
          IDEAFUSE AI SOLUTIONS LLC and its affiliates.
        </NotoP>
        {delSubject.map((x, i) => (
          <div key={i} className="d-flex">
            <div>
              <div className="privacy-sm--dot"></div>
            </div>
            <NotoP
              fs="16px"
              lh="22px"
              tfs="14px"
              tlh="20px"
              mfs="12px"
              mlh="18px"
              fw="400"
              c={colors.blue5}
            >
              {x}
            </NotoP>
          </div>
        ))}
        <NotoP
          fs="16px"
          lh="22px"
          tfs="14px"
          tlh="20px"
          mfs="12px"
          mlh="18px"
          fw="400"
          c={colors.blue5}
          mt="8px"
        >
          Please note that we may not be able to comply with requests to delete
          your personal information if it is necessary to:
        </NotoP>
        {delNote.map((x, i) => (
          <div key={i} className="d-flex">
            <div>
              <div className="privacy-sm--dot"></div>
            </div>
            <NotoP
              fs="16px"
              lh="22px"
              tfs="14px"
              tlh="20px"
              mfs="12px"
              mlh="18px"
              fw="400"
              c={colors.blue5}
            >
              {x}
            </NotoP>
          </div>
        ))}
        <MontP
          fs="18px"
          lh="25px"
          tfs="17px"
          tlh="24px"
          mfs="15px"
          mlh="20px"
          b
          c={colors.blue5}
          mt="1.5vw"
        >
          Sharing Information with Third Parties
        </MontP>
        <NotoP
          fs="16px"
          lh="22px"
          tfs="14px"
          tlh="20px"
          mfs="12px"
          mlh="18px"
          fw="400"
          c={colors.blue5}
          mt="8px"
        >
          IDEAFUSE AI SOLUTIONS LLC does not sell, rent or lease its customer
          lists to third parties.
        </NotoP>
        <NotoP
          fs="16px"
          lh="22px"
          tfs="14px"
          tlh="20px"
          mfs="12px"
          mlh="18px"
          fw="400"
          c={colors.blue5}
          mt="8px"
        >
          IDEAFUSE AI SOLUTIONS LLC may, from time to time, contact you on
          behalf of external business partners about a particular offering that
          may be of interest to you. In those cases, your unique personally
          identifiable information (e-mail, name, address, telephone number) is
          not transferred to the third party. IDEAFUSE AI SOLUTIONS LLC may
          share data with trusted partners to help perform statistical analysis,
          send you email or postal mail, provide customer support, or arrange
          for deliveries. All such third parties are prohibited from using your
          personal information except to provide these services to IDEAFUSE AI
          SOLUTIONS LLC, and they are required to maintain the confidentiality
          of your information.
        </NotoP>
        <NotoP
          fs="16px"
          lh="22px"
          tfs="14px"
          tlh="20px"
          mfs="12px"
          mlh="18px"
          fw="400"
          c={colors.blue5}
          mt="8px"
        >
          IDEAFUSE AI SOLUTIONS LLC may disclose your personal information,
          without notice, if required to do so by law or in the good faith
          belief that such action is necessary to: (a) conform to the edicts of
          the law or comply with legal process served on IDEAFUSE AI SOLUTIONS
          LLC or the site; (b) protect and defend the rights or property of
          IDEAFUSE AI SOLUTIONS LLC; and/or (c) act under exigent circumstances
          to protect the personal safety of users of IDEAFUSE AI SOLUTIONS LLC,
          or the public.
        </NotoP>
        <MontP
          fs="18px"
          lh="25px"
          tfs="17px"
          tlh="24px"
          mfs="15px"
          mlh="20px"
          b
          c={colors.blue5}
          mt="1.5vw"
        >
          Security of your Personal Information
        </MontP>
        <NotoP
          fs="16px"
          lh="22px"
          tfs="14px"
          tlh="20px"
          mfs="12px"
          mlh="18px"
          fw="400"
          c={colors.blue5}
          mt="8px"
        >
          IDEAFUSE AI SOLUTIONS LLC secures your personal information from
          unauthorized access, use, or disclosure. IDEAFUSE AI SOLUTIONS LLC
          uses the following methods for this purpose:
        </NotoP>
        <NotoP
          fs="16px"
          lh="22px"
          tfs="14px"
          tlh="20px"
          mfs="12px"
          mlh="18px"
          fw="400"
          c={colors.blue5}
          mt="8px"
        >
          We strive to take appropriate security measures to protect against
          unauthorized access to or alteration of your personal information.
          Unfortunately, no data transmission over the Internet or any wireless
          network can be guaranteed to be 100% secure. As a result, while we
          strive to protect your personal information, you acknowledge that: (a)
          there are security and privacy limitations inherent to the Internet
          which are beyond our control; and (b) security, integrity, and privacy
          of any and all information and data exchanged between you and us
          through this Site cannot be guaranteed.
        </NotoP>
        <MontP
          fs="18px"
          lh="25px"
          tfs="17px"
          tlh="24px"
          mfs="15px"
          mlh="20px"
          b
          c={colors.blue5}
          mt="1.5vw"
        >
          Right to Deletion
        </MontP>
        <NotoP
          fs="16px"
          lh="22px"
          tfs="14px"
          tlh="20px"
          mfs="12px"
          mlh="18px"
          c={colors.blue5}
          mt="8px"
        >
          Subject to certain exceptions set out below, on receipt of a
          verifiable request from you, we will:
        </NotoP>

        <MontP
          fs="18px"
          lh="25px"
          tfs="17px"
          tlh="24px"
          mfs="15px"
          mlh="20px"
          b
          c={colors.blue5}
          mt="1.5vw"
        >
          Children Under Thirteen
        </MontP>
        <NotoP
          fs="16px"
          lh="22px"
          tfs="14px"
          tlh="20px"
          mfs="12px"
          mlh="18px"
          fw="400"
          c={colors.blue5}
          mt="8px"
        >
          IDEAFUSE AI SOLUTIONS LLC does not knowingly collect personally
          identifiable information from children under the age of thirteen. If
          you are under the age of thirteen, you must ask your parent or
          guardian for permission to use this application.
        </NotoP>
        <MontP
          fs="18px"
          lh="25px"
          tfs="17px"
          tlh="24px"
          mfs="15px"
          mlh="20px"
          b
          c={colors.blue5}
          mt="1.5vw"
        >
          E-mail Communications
        </MontP>
        <NotoP
          fs="16px"
          lh="22px"
          tfs="14px"
          tlh="20px"
          mfs="12px"
          mlh="18px"
          fw="400"
          c={colors.blue5}
          mt="8px"
        >
          From time to time, IDEAFUSE AI SOLUTIONS LLC may contact you via email
          for the purpose of providing announcements, promotional offers,
          alerts, confirmations, surveys, and/or other general communication. In
          order to improve our Services, we may receive a notification when you
          open an email from IDEAFUSE AI SOLUTIONS LLC or click on a link
          therein.
        </NotoP>
        <MontP
          fs="18px"
          lh="25px"
          tfs="17px"
          tlh="24px"
          mfs="15px"
          mlh="20px"
          b
          c={colors.blue5}
          mt="1.5vw"
        >
          External Data Storage Sites
        </MontP>
        <NotoP
          fs="16px"
          lh="22px"
          tfs="14px"
          tlh="20px"
          mfs="12px"
          mlh="18px"
          fw="400"
          c={colors.blue5}
          mt="8px"
        >
          We may store your data on servers provided by third party hosting
          vendors with whom we have contracted.
        </NotoP>
        <MontP
          fs="18px"
          lh="25px"
          tfs="17px"
          tlh="24px"
          mfs="15px"
          mlh="20px"
          b
          c={colors.blue5}
          mt="1.5vw"
        >
          Changes to this Statement
        </MontP>
        <NotoP
          fs="16px"
          lh="22px"
          tfs="14px"
          tlh="20px"
          mfs="12px"
          mlh="18px"
          fw="400"
          c={colors.blue5}
          mt="8px"
        >
          IDEAFUSE AI SOLUTIONS LLC reserves the right to change this Privacy
          Policy from time to time. We will notify you about significant changes
          in the way we treat personal information by sending a notice to the
          primary email address specified in your account, by placing a
          prominent notice on our application, and/or by updating any privacy
          information. Your continued use of the application and/or Services
          available after such modifications will constitute your: (a)
          acknowledgment of the modified Privacy Policy; and (b) agreement to
          abide and be bound by that Policy.
        </NotoP>
        <MontP
          fs="18px"
          lh="25px"
          tfs="17px"
          tlh="24px"
          mfs="15px"
          mlh="20px"
          b
          c={colors.blue5}
          mt="1.5vw"
        >
          Contact Information
        </MontP>
        <NotoP
          fs="16px"
          lh="22px"
          tfs="14px"
          tlh="20px"
          mfs="12px"
          mlh="18px"
          fw="400"
          c={colors.blue5}
          mt="8px"
        >
          IDEAFUSE AI SOLUTIONS LLC welcomes your questions or comments
          regarding this Statement of Privacy. If you believe that IDEAFUSE AI
          SOLUTIONS LLC has not adhered to this Statement, please contact
          IDEAFUSE AI SOLUTIONS LLC at:
        </NotoP>
        <pre className="privacy-contact-info">
          {`\nIDEAFUSE AI SOLUTIONS LLC\n244 S Randall Rd\nSuite # 1071\nElgin,Illinois 60123\n`}
        </pre>
        <NotoP
          fs="16px"
          lh="22px"
          tfs="14px"
          tlh="20px"
          mfs="12px"
          mlh="18px"
          fw="400"
          c={colors.blue5}
        >
          Email Address:
        </NotoP>
        <NotoP
          fs="14px"
          lh="20px"
          tfs="14px"
          tlh="20px"
          mfs="10px"
          mlh="16px"
          fw="400"
          b
          c={colors.secondary}
        >
          info@ideafuse.ai
        </NotoP>
        <pre className="privacy-contact-info">{`_________________\n`}</pre>
        <NotoP
          fs="14px"
          lh="20px"
          tfs="14px"
          tlh="20px"
          mfs="10px"
          mlh="16px"
          b
          c={colors.secondary}
        >
          Last Updated Date June 01, 2021
        </NotoP>
      </div>
    </>
  );
}
