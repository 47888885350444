import { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { Row, Col, Table } from "reactstrap";
import styles from "../User.module.css";
import { MontP, NotoP } from "../../../styles/common";
import { colors } from "../../../styles/theme";
import MyCheckbox from "../../../components/MyCheckbox";
import BtnSpinner from "../../../components/BtnSpinner";
import NotFoundMsg from "../../../components/NotFoundMsg";
import { API, PATH } from "../../../services/Axios";
import { capitalize, notify } from "../../../utils";

export default function RoleAccess(props) {
  const { user_data } = props;

  const [randomNo, setRandomNo] = useState(0);
  const [loading, setLoading] = useState(false);
  const [labels, setLabels] = useState([]);
  const [pagesRole, setPagesRole] = useState([]);

  const getPagesRoleData = async () => {
    setLoading(true);
    const data = {
      CustId: user_data?.customerInfo?.custId,
    };
    try {
      const result = await API.post(PATH.userRoles, data, user_data.token);
      // console.log(">>", result);
      if (result) {
        setPagesRole(result);
        // Generate Dynamic Labels
        const tempLabels = [];
        result[0].fields.forEach((x, i) => {
          if (i === 0) {
            tempLabels.push("Page Name");
          }
          tempLabels.push(capitalize(x.roleName));
        });
        setLabels(tempLabels);
      }
    } catch (err) {
      console.log(err);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getPagesRoleData();
  }, []);

  const handleSave = async () => {
    setLoading(true);
    const data = {
      LoggedInUserId: user_data?.userInfo?.userEmail,
      CustId: user_data?.customerInfo?.custId,
      LstData: pagesRole,
    };

    try {
      const result = await API.post(
        PATH.updateUserRoles,
        data,
        user_data.token
      );
      console.log(result);
      if (result) {
        notify("Saved successfully", true);
      } else {
        notify("Save failed");
      }
    } catch (err) {
      console.log(err);
      notify("Save failed");
    } finally {
      setLoading(false);
    }
  };

  return (
    <Row className="mt-5 justify-content-center">
      <Col md={8}>
        {/* TABLE */}
        {pagesRole?.length > 0 ? (
          <>
            <div className="d-flex justify-content-end">
              <button onClick={handleSave} className={styles.pagerole_savebtn}>
                {loading && <BtnSpinner />}
                Save
              </button>
            </div>
            <Table responsive className={styles.table}>
              <thead className={styles.thead}>
                <tr>
                  {labels.map((x, i) => (
                    <th key={i} className={i < 2 && "text-start"}>
                      <MontP fs="12px" lh="15px" b c={colors.primary}>
                        {x}
                      </MontP>
                    </th>
                  ))}
                </tr>
              </thead>
              <tbody className={styles.tbody}>
                {pagesRole.map((item, index) => (
                  <tr
                    key={item.pageId}
                    style={{
                      backgroundColor:
                        index % 2 === 0
                          ? "rgba(37, 65, 178, 0.1)"
                          : colors.white,
                    }}
                  >
                    <td className="text-start">
                      <NotoP fs="13px" lh="16px" fw="600" c={colors.blue5}>
                        {item.pageName}
                      </NotoP>
                    </td>
                    {item.fields.map((sItem, sIndex) => (
                      <td key={sItem.roleId}>
                        <MyCheckbox
                          checked={sItem.enabled}
                          onClick={() => {
                            sItem.enabled = !sItem.enabled;
                            setRandomNo(Math.random());
                          }}
                        />
                      </td>
                    ))}
                  </tr>
                ))}
              </tbody>
            </Table>
          </>
        ) : (
          <>
            {loading ? (
              <NotFoundMsg msg="Please wait..." />
            ) : (
              <NotFoundMsg msg="No record found" />
            )}
          </>
        )}
      </Col>
    </Row>
  );
}

RoleAccess.propTypes = {
  user_data: PropTypes.object,
};
