import "bootstrap/dist/css/bootstrap.min.css";
import "./styles/common.css";
import "./styles/common.scss";
import CssBaseline from "@material-ui/core/CssBaseline";
import ReactNotification from "react-notifications-component";
import "react-notifications-component/dist/theme.css";

import ScrollToTop from "./components/ScrollToTop";
import { BrowserRouter } from "react-router-dom";
import { Provider } from "react-redux";
import { PersistGate } from "redux-persist/integration/react";
import { persistor, store } from "./redux/store";
import SwitchRoutes from "./routes/switchRoutes";
import CacheBuster from 'react-cache-buster';
import { version } from '../package.json';
import NotFoundMsg from "./components/NotFoundMsg";
import './App.css';

function App() {
  const isProduction = process.env.NODE_ENV === 'production';

  return (
    <CacheBuster
      currentVersion={version}
      isEnabled={isProduction}
      isVerboseMode={false}
      loadingComponent={<NotFoundMsg msg="Please wait..." />}
    >
      <Provider store={store}>
        <PersistGate persistor={persistor} loading={null}>
          <BrowserRouter basename={process.env.PUBLIC_URL} forceRefresh={false}>
            <ScrollToTop>
              <CssBaseline />
              <ReactNotification />
              <SwitchRoutes />
            </ScrollToTop>
          </BrowserRouter>
        </PersistGate>
      </Provider>
    </CacheBuster>
  );
}

export default App;
