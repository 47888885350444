import styled from "styled-components";
import { useSelector } from "react-redux";
import SidebarMenu from "./SidebarMenu";
import SidebarBottomMenu from "./SidebarBottomMenu";
import SidebarAppLogo from "./SidebarAppLogo";
import adminMenus from "../../../menus/adminMenus";
import userBottomMenus from "../../../menus/userBottomMenus";
import { colors } from "../../../styles/theme";
import { navbar_height, navbar_sidebar_space } from "../../../utils";
import asset_icon from '../../../menus/icons/approval.svg'
import asset_icon_white from '../../../menus/icons/approval_white.svg'

const SidebarCoverFixed = styled.div`
  background: ${colors.white};
  /* margin-top: ${navbar_sidebar_space};
  height: calc(100vh - 50px); */
  height: calc(100vh - ${navbar_height});
  z-index: 10;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  overflow-y: auto;
`;

const LineDiv = styled.div`
  border-top: 2px solid #d3d2f9;
  margin: 16px auto;
  width: 90%;
`;

const SimpleSidebar = () => {
  const { user_data } = useSelector((state) => ({
    user_data: state.auth.user_data,
  }));

  const activeMenu = user_data ? adminMenus : [];
  const activeSubmenu = user_data ? userBottomMenus : [];


  return (
    <SidebarCoverFixed className="d-none d-md-block d-lg-block position-relative">
      <SidebarAppLogo />
      {activeMenu?.slice(0, 5).map((item, index) => (
        <SidebarMenu user_data={user_data} item={item} key={index} />
      ))}

      <LineDiv />
      {activeMenu.slice(5).map((item, index) => (
        <SidebarMenu user_data={user_data} item={item} key={index} />
      ))}
      <LineDiv />
      {activeSubmenu.map((item, index) => (
        <SidebarBottomMenu user_data={user_data} item={item} key={index} />
      ))}
    </SidebarCoverFixed>
  );
};

export default SimpleSidebar;
