import { useState } from "react";
import PropTypes from "prop-types";
import { Row, Col } from "reactstrap";
import validator from "validator";
import TitleInput from "../../../components/TitleInput";
import { colors } from "../../../styles/theme";
import { MiButton, NotoP } from "../../../styles/common";
import TitleInputGroup from "../../../components/TitleInputGroup";
import { API, PATH } from "../../../services/Axios";
import { notify } from "../../../utils";
import FormSpinner from "../../../components/FormSpinner";
import BtnSpinner from "../../../components/BtnSpinner";

function Profile(props) {
  const { user_data } = props;
  const [loading, setLoading] = useState(false);

  const initialUserData = () => {
    return {
      firstName: user_data?.customerInfo?.custFirstName || "",
      lastName: user_data?.customerInfo?.custLastName || "",
      companyName: user_data?.customerInfo?.custCompanyName || "",
      phoneNumber: user_data?.customerInfo?.custPhone || "",
      email: user_data?.customerInfo?.custRegisteredEmail || "",
    };
  };
  const initialUserId = () => user_data?.userInfo?.userEmail || "";

  const [userId, setUserId] = useState(initialUserId);
  const [secretId, setSecretId] = useState(true);

  const [showError, setShowError] = useState(false); // IMP  bydefault should be false
  const [code, setCode] = useState("+91");
  const [profileData, setProfileData] = useState(initialUserData);
  const { firstName, lastName, phoneNumber, companyName, email } = profileData;

  const handleSave = async () => {
    const conditions =
      validator.isAlpha(firstName) &&
      validator.isAlpha(lastName) &&
      companyName.length > 0 &&
      validator.isMobilePhone(phoneNumber);
    //validator.isEmail(email);

    if (conditions) {
      const data = {
        LoggedInUserId: user_data?.userInfo?.userEmail,
        CustFirstName: firstName,
        CustLastName: lastName,
        CustCompany: companyName,
        CustPhone: phoneNumber,
      };
      // console.log(data);
      setLoading(true);
      try {
        const result = await API.post(
          PATH.updateCustomerProfile,
          data,
          user_data.token
        );
        console.log(result);
        if (result) {
          notify("Customer Profile Updated", true);
        } else {
          notify(
            "Customer Profile not updated. Please send an email to arka.admin@ideafuse or create an issue using the Create Issue Screen"
          );
        }
        setLoading(false);
      } catch (err) {
        console.log(err);
        notify(
          "Customer Profile not updated. Please send an email to arka.admin@ideafuse or create an issue using the Create Issue Screen"
        );
        setLoading(false);
      }
    } else {
      setShowError(true);
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setProfileData((prev) => ({ ...prev, [name]: value }));
  };

  return (
    <Row >
      <Col xs={12} sm={12} md={12} lg={6} className="mx-auto">
        {loading && <FormSpinner />}
        <TitleInput
          label="First name"
          name="firstName"
          placeholder="Olivia"
          value={firstName}
          onChange={handleChange}
          showerr={showError}
          errmsg="First name contains only letters (a-zA-Z)"
          valid={validator.isAlpha(firstName)}
        />
        <TitleInput
          label="Last name"
          name="lastName"
          placeholder="Max"
          value={lastName}
          onChange={handleChange}
          showerr={showError}
          errmsg="Last name contains only letters (a-zA-Z)"
          valid={validator.isAlpha(lastName)}
        />
        <TitleInput
          label="User ID (read only)"
          name="userId"
          disabled
          placeholder={
            secretId
              ? userId.substr(userId.length - 3).padStart(16, "*")
              : userId
          }
          handleEye={() => setSecretId(!secretId)}
        />
        <TitleInputGroup
          label="Phone Number"
          name="phoneNumber"
          placeholder="Enter Your Mobile Number"
          code={code}
          handleCode={setCode}
          value={phoneNumber}
          onChange={handleChange}
          showerr={showError}
          errmsg="Invalid Phone number"
          valid={validator.isMobilePhone(phoneNumber)}
        />
        <TitleInput
          label="Company"
          name="companyName"
          placeholder="XYZ pvt. lmt."
          value={companyName}
          onChange={handleChange}
          showerr={showError}
          errmsg="Minimum length should be 6"
          valid={companyName.length >= 6}
        />
        <TitleInput
          label="Registered Email ID"
          name="email"
          placeholder="Olivia363max@aviskaran.com"
          value={email}
          disabled
          onChange={handleChange}
          showerr={showError}
          errmsg="Invalid email"
          valid={validator.isEmail(email)}
        />

        <button
          onClick={handleSave}
          disabled={loading}
          className='saveBtn mt-4'
        >
          Save
        </button>

      </Col>
    </Row>
  );
}

Profile.propTypes = {
  user_data: PropTypes.object,
};

export default Profile;
