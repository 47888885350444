import { useState, useRef, useEffect } from "react";
import PropTypes from "prop-types";
import {
  Row,
  Col,
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap";
import validator from "validator";
import styled from "styled-components";
import TitleInput from "../../../components/TitleInput";
import { colors } from "../../../styles/theme";
import { CustomImg, MiButton, NotoP } from "../../../styles/common";
import { API, PATH } from "../../../services/Axios";
import { notify } from "../../../utils";
import FormSpinner from "../../../components/FormSpinner";
import SimpleDocPicker from "../../../components/SimpleDocPicker";

import chev_down from "../../../assets/icons/chev-down.svg";
import styles from './CustomSettings.module.css'
import BtnSpinner from "../../../components/BtnSpinner";

const CustomDropdownToggle = styled(DropdownToggle)`
  width: 110px;
  height: 40px;
  border-radius: 49px;
  background: #03256c;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 15px;
  outline: none;
  border: none;
  z-index: 10;

  &:hover,
  &:active,
  &:focus {
    background-color: #03256c;
    outline: none;
    border: none;
    box-shadow: none;
  }
`;

const FooterText = styled.span`
  font-family: Montserrat;
  font-style: italic;
  font-weight: normal;
  font-size: 12px;
  line-height: 18px;
  /* identical to box height */
  display: block;
  align-items: center;
  text-align: ${({ align }) => (align ? align : "left")};
  /* primary-color */
  color: #03256c;
`;

function CustomSettings(props) {
  const { user_data, settings_data } = props;
  const docRef = useRef();
  const [loading, setLoading] = useState(false);
  const [secretId, setSecretId] = useState('');
  const [secret, setSecret] = useState(true);
  const [eye, setEye] = useState(true);

  const userType = ["True", "False"];
  const [role, setRole] = useState("False");

  const initialApiData = () => {
    return {
      serverName: "",
      serverIp: "",
      connection_strings: "",
      additional_details: "",
    };
  };

  const [showError, setShowError] = useState(false);

  const handleSubmit = async () => {

  };


  const [securityKey, setSecurityKey] = useState("");
  const [customizedSettings, setCustomizedSettings] = useState([]);

  useEffect(() => {
    API.post(PATH.getSecurityKey, {}, user_data.token).then(res => {
      console.log("getSecurityKey", res);
      setSecurityKey(res);
    });
    API.post(PATH.getCustomizedSettings, {}, user_data.token).then(res => {
      console.log("getCustomizedSettings", res);
      setCustomizedSettings(res);
      res.forEach(x => {
        if (x.dataType === 'Bool') {
          setRole(x.value);
        }
      });
    });
  }, []);

  const handleSave = () => {
    API.post(PATH.updateSecurityKey, { securityKey: securityKey }, user_data.token).then(res => {
      console.log("getSecurityKey", res);
      notify("Security key updated successfully", true);
    });
  };

  const handleSaveCustomSettings = () => {
    let copy = customizedSettings;

    const newSaveData = copy.map(x => {
      if (x.dataType === 'Bool') {
        return { ...x, "key": x.key, "value": role }
      }
      else {
        return { ...x, "key": x.key, "value": x.value }
      }
    });
    API.post(PATH.updateCustomizedSettings, { lstItems: newSaveData }, user_data.token).then(res => {
      notify("Settings updated successfully", true);
    });
  };

  const onCustomChange = (e, index) => {
    let copy = customizedSettings;
    copy[index].value = e.target.value;
    setCustomizedSettings([...copy]);
  }

  const handleEyeButton = (key, isTrue) => {
    if (isTrue) {
      setSecretId(key);
      setEye(false);
    }
    else {
      setSecretId('');
      setEye(true);
    }
  };

  return (
    <Row className="mt-4 ps-4 pe-2">
      <Col md={{ size: 6, offset: 3 }} className="position-relative">
        {loading && <FormSpinner />}

        <div className="text-left">
          <NotoP
            fs="18px"
            lh="21px"
            mfs="12px"
            mlh="16px"
            fw="600"
            c={colors.primary}
          >ARKA API</NotoP>
          <Row className="align-items-center">
            <Col md={8}>
              <TitleInput
                required={false}
                label="API Security Key"
                name="api_key"
                type={secret ? 'password' : 'text'}
                handleEye={() => setSecret(!secret)}
                placeholder="API Security Key"
                value={securityKey}
                onChange={e => setSecurityKey(e.target.value)}
              /></Col>
            <Col md={4}>
              <button
                onClick={handleSave}
                disabled={loading}
                className='saveBtn mt-4'
              >
                Save
              </button>
            </Col>
          </Row>


          <NotoP
            fs="18px"
            lh="21px"
            mfs="12px"
            mlh="16px"
            fw="600"
            mt={'30px'}
            c={colors.primary}
          >Environment Configurations</NotoP>
          <Row>
            <Col md={12}>


              {customizedSettings?.map((item, index) => (
                <Row key={index.toString()} style={{}}>
                  <Col md={6}>
                    <TitleInput
                      required={false}
                      label=""
                      name="label"
                      placeholder="LABLE_HERE"
                      disabled={true}
                      value={item.displayName}
                    />
                    <p style={{ fontSize: '11px' }} className="text-muted mt-2 mb-0">{item.hint}</p>
                  </Col>
                  <Col md={6}>
                    {
                      (item.dataType !== 'Int' && item.dataType !== 'Bool') &&
                      <TitleInput
                        required={false}
                        label=""
                        name={item.key}
                        type={secretId === item.key ? 'text' : 'password'}
                        handleEye={() => handleEyeButton(item.key, eye)}
                        placeholder="VALUE_HERE"
                        value={item.value}
                        onChange={e => onCustomChange(e, index)}
                      />
                    }
                    {
                      item.dataType === 'Int' &&
                      <TitleInput
                        required={false}
                        label=""
                        name={item.key}
                        type={secretId === item.key ? 'number' : 'password'}
                        handleEye={() => handleEyeButton(item.key, eye)}
                        // handleEye={() => setSecretId(!secretId)}
                        placeholder="VALUE_HERE"
                        value={item.value}
                        onChange={e => onCustomChange(e, index)}
                      />
                    }
                    {
                      item.dataType === 'Bool' &&
                      <div className="d-flex align-items-center mt-4">
                        {userType.map((itm, idx) => (
                          <div
                            key={idx}
                            role="button"
                            onClick={() => setRole(itm)}
                            onKeyPress={null}
                            className="d-flex align-items-center ms-4"
                          >
                            <span
                              className={itm === role ? styles.activedot : styles.dot}
                            ></span>
                            <NotoP
                              fs="14px"
                              lh="18px"
                              fw="500"
                              c={colors.secondary}
                              ml="3px"
                            >
                              {itm}
                            </NotoP>
                          </div>
                        ))}
                      </div>
                    }
                  </Col>
                </Row>
              ))}
            </Col>
            <Col md={12} className="text-center">

              <button
                onClick={handleSaveCustomSettings}
                disabled={loading}
                className='saveBtn w-50 mx-auto mt-4'
              >
                {loading && <BtnSpinner />}
                Save
              </button>
            </Col>
          </Row>

        </div>
      </Col>
    </Row>
  );
}

CustomSettings.propTypes = {
  user_data: PropTypes.object,
  settings_data: PropTypes.object,
};

export default CustomSettings;
