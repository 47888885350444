import { useState, useEffect } from "react";
import { Container, TabContent, TabPane, Nav, Row, Col } from "reactstrap";
import { useSelector } from "react-redux";
import RestApi from "./Tabs/RestApi";
import DataDrop from "./Tabs/DataDrop";
import TabsCover from "../../components/tabbar/TabsCover";
import MyTab from "../../components/tabbar/MyTab";
import { API, PATH } from "../../services/Axios";
import { useAccessTabs } from "../../hooks/useAccessTabs";
import CustomSettings from './Tabs/CustomSettings';
import EmailSettings from "./Tabs/EmailSettings";

export default function Settings() {
  const user_data = useSelector((state) => state.auth.user_data);

  const initialSettingsData = {
    CusIntegrationType: "Storage",
    CusServerName: "",
    CusServerIp: "",
    CusConnString: "",
    CusAdditionalDetails: "",
    CusStodbFileName: "",
    CusApiUrl: "",
    CusApiEndpoint: "",
    CusApiInputParameters: "",
    CusApiAuthorization: "",
    CusApiAdditionalDetails: "",
    CusApiFileName: "",
    CusIntegrationId: null,
    IsUpdate: false,
  };

  const [tabs, activeTab, setActiveTab] = useAccessTabs();

  const [settingsData, setSettingsData] = useState(initialSettingsData);

  const getSettingsData = async () => {
    const data = {
      CustId: user_data?.customerInfo?.custId,
    };
    try {
      const result = await API.post(PATH.integSettings, data, user_data.token);
      console.log(result);
      if (result) {
        setSettingsData(result);
      }
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    getSettingsData();
  }, []);

  const toggle = (tab) => {
    if (activeTab !== tab) setActiveTab(tab);
  };
  console.log('tabs', tabs);
  return (
    <Container fluid className="pb-5">
      <Row className="justify-content-center mt-4">
        <Col xs={12} sm={12} md={12} className="py-5">
          
          <Nav tabs style={{marginTop:'-30px'}}  className="justify-content-center border-0">
            <TabsCover>
              <div className="d-lg-flex">
                {tabs.map((item, index) => (
                  <MyTab
                    title={item.menuTabName}
                    onClick={() => toggle(item.menuTabName_path)}
                    isActive={activeTab === item.menuTabName_path}
                  />
                ))}
              </div>
            </TabsCover>
          </Nav>

          <TabContent activeTab={activeTab} className="position-relative">
            <TabPane tabId="1">
              <RestApi user_data={user_data} settings_data={settingsData} />
            </TabPane>
            <TabPane tabId="2">
              <DataDrop user_data={user_data} settings_data={settingsData} />
            </TabPane>
            <TabPane tabId="3">
              <EmailSettings user_data={user_data} />
            </TabPane>
            <TabPane tabId="4">
              <CustomSettings user_data={user_data} settings_data={settingsData} />
            </TabPane>
          </TabContent>

        </Col>
      </Row>
    </Container>
  );
}
