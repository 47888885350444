import { useState } from "react";
import PropTypes from "prop-types";
import styles from "./Components.module.css";
import MyCheckbox from "./MyCheckbox";
import { NotoP } from "../styles/common";

const DocThresholdRow = ({ bgColor, item }) => {
  const [, setRandomNo] = useState(0);

  return (
    <tr style={{ backgroundColor: bgColor, height: "30px" }}>
      <td>{item.docTypeName}</td>
      <td>{item.systemThreshold}</td>
      <td>
        <input
          value={item.customThreshold}
          onChange={(e) => {
            item.customThreshold = e.target.value;
            setRandomNo(Math.random(2));
          }}
          className={styles.thresholdInput}
        />
        {/* {!item.customThreshold && (
          <NotoP fs="10px" className="text-danger py-1">
            Enter a Valid Threshold Value
          </NotoP>
        )} */}
      </td>
      <td>
        <MyCheckbox
          checked={item.analyticsKey}
          onChange={(e) => {
            item.analyticsKey = e.target.checked;
            setRandomNo(Math.random(2));
          }}
        />
      </td>
      <td>
        <MyCheckbox
          checked={item.enabled}
          onChange={(e) => {
            item.enabled = e.target.checked;
            setRandomNo(Math.random(2));
          }}
        />
      </td>
    </tr>
  );
};

DocThresholdRow.propTypes = {
  bgColor: PropTypes.string,
  item: PropTypes.object,
};

export default DocThresholdRow;
