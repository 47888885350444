import PropTypes from "prop-types";

function Vectors({ rightvectorclass }) {
  return (
    <div className="d-flex justify-content-between">
      <div className="round-gold--vector" />
      <div>
        <div className={"round-rect--vector " + rightvectorclass} />
        <div className="round-pink--vector mt--xlg" />
      </div>
    </div>
  );
}

Vectors.propTypes = {
  rightvectorclass: PropTypes.string,
};

export default Vectors;
