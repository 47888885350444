import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import styled from "styled-components";
import { CustomIcon, MontP } from "../styles/common";
import { colors } from "../styles/theme";
import MailTo from "./MailTo";

import copy from "../assets/icons/copy-right.svg";

const FooterDiv = styled.div`
  height: 30px;
  /* background-color: red; */
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 1vh 3vw 1vh 1vw;

  @media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (-webkit-min-device-pixel-ratio: 2) {
    height: 50px;
  }
`;

function Footer({ helpEmail }) {
  return (
    <FooterDiv>
      <div className="d-flex align-items-center">
        <CustomIcon src={copy} alt="copy" w="18px" h="18px" mr="4px" />
        <MontP
          fs="14px"
          lh="20px"
          tfs="14px"
          tlh="20px"
          mfs="10px"
          mlh="15px"
          fw="600"
          c={colors.grey5}
        >
          Copyright IDEAFUSE AI SOLUTIONS LLC. All rights reserved.{" "}
          <a
            href="https://www.ideafuse.ai/privacy-policy"
            className="text-dark"
          >
            <span>Privacy Policy</span>
          </a>
        </MontP>
      </div>
      {/* <Link to="/contact-us" className="text-decoration-none">
        </Link> */}
      {helpEmail && (
        <MailTo email="info@ideafuse.ai" subject="Subject" body="Body">
          <MontP
            fs="14px"
            lh="20px"
            tfs="14px"
            tlh="20px"
            mfs="10px"
            mlh="15px"
            fw="600"
            c={colors.grey5}
          >
            Connect with us: Info@ideafuse.ai
          </MontP>
        </MailTo>
      )}
    </FooterDiv>
  );
}

Footer.propTypes = {
  helpEmail: PropTypes.bool,
};
Footer.defaultProps = {
  helpEmail: true,
};

export default Footer;
