import { useState } from "react";
import { Collapse, Table } from "reactstrap";
import PropTypes from "prop-types";
import moment from "moment";
import classnames from "classnames";
import styles from "./Components.module.css";

import chevup from "../assets/icons/chev-up-d.svg";
import chevdown from "../assets/icons/chev-down-d.svg";

function SummaryCollapseable({ bgColor, item }) {
  const [isOpen, setIsOpen] = useState(false);

  const toggle = () => setIsOpen(!isOpen);

  return (
    <>
      <tr
        role="button"
        onClick={toggle}
        onKeyPress={toggle}
        style={{ backgroundColor: bgColor }}
      >
        <td>
          <div className="d-flex align-items-center">
            <img
              src={isOpen ? chevup : chevdown}
              alt="up"
              width="20px"
              height="20px"
              className="me-2"
            />
            {item.custAdaptionId}
          </div>
        </td>
        <td>
          {moment(item.lstAdaptionItemDetails[0].creationDate).format(
            "MM/DD/YYYY"
          )}
        </td>
        <td>--</td>
        <td>--</td>
        <td>{item.lstAdaptionItemDetails[0].adaptionStatus}</td>
        <td>{item.lstAdaptionItemDetails[0].trainingStatus}</td>
      </tr>
      <tr
        className={classnames(
          isOpen ? styles.summary_tr_show : styles.summary_tr_hide
        )}
      >
        <td colSpan={12} className="p-0">
          <Collapse isOpen={isOpen}>
            <Table borderless>
              <thead>
                <tr style={{ backgroundColor: bgColor }}>
                  <th>Adaption Upload</th>
                  <th>Creation Date</th>
                  <th>Adaption Type</th>
                  <th>File Name</th>
                  <th>Status</th>
                  <th>Training Status</th>
                </tr>
              </thead>
              <tbody>
                {item.lstAdaptionItemDetails.map((item, index) => (
                  <tr key={index} style={{ backgroundColor: bgColor }}>
                    <td>{item.adaptionFileId}</td>
                    <td>{moment(item.creationDate).format("MM/DD/YYYY")}</td>
                    <td>{item.adaptionType}</td>
                    <td>{item.adaptionFileName}</td>
                    <td>{item.adaptionStatus}</td>
                    <td>{item.trainingStatus}</td>
                  </tr>
                ))}
              </tbody>
            </Table>
          </Collapse>
        </td>
      </tr>
    </>
  );
}

SummaryCollapseable.propTypes = {
  bgColor: PropTypes.string,
  item: PropTypes.object,
};

SummaryCollapseable.defaultProps = {
  bgColor: "#FFF",
};

export default SummaryCollapseable;
