import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import validator from "validator";
import Cookies from "js-cookie";
import { Card, Col, Container, Row } from "reactstrap";
import { Link, useHistory } from "react-router-dom";
import cls from "classnames";
import TitleInput from "../../components/title-input";
import styles from "./login.module.css";
import { API, PATH } from "../../services/Axios";
import { notify } from "../../utils";
import BtnSpinner from "../../components/BtnSpinner";

import logo from "../../assets/images/ideafuse-logo.svg";
import arka from "../../assets/images/arka/arka-text.svg";

const Login = () => {
  const history = useHistory();

  const { user_data } = useSelector((state) => ({
    user_data: state.auth.user_data,
  }));

  const [loading, setLoading] = useState(false);

  const [showError, setShowError] = useState(false); // NOTE  by default should be false
  const [userId, setUserId] = useState("");

  useEffect(() => {
    const cookieData = Cookies.get();
    console.log(cookieData);
    if (cookieData?.rememberMe === "yes") {
      setUserId(cookieData?.userId);
    }
  }, []);

  useEffect(() => {
    if (user_data) {
      history.replace("/home");
    }
  }, [user_data]);

  const handleSubmit = async () => {
    const conditions = validator.isEmail(userId);

    if (conditions) {
      setLoading(true);
      setShowError(false);
      const data = {
        LoginId: userId,
      };
      try {
        const result = await API.postAuth(PATH.preSignin, data);
        console.log("Email Res:", result);
        if (result?.isValid) {
          if (
            window.location.origin === "https://ideafuse-dev.netlify.app" ||
            window.location.origin === "http://localhost:3000"
          ) {
            const newUrl = `${window.location.origin}/signin/${userId}`;
            window.location.replace(newUrl);
          } else {
            const newUrl = `https://${result.custDomain}.ideafuse.ai/signin/${userId}`;
            window.location.replace(newUrl);
          }
        } else {
          notify("Account not found!!! Please signup.");
        }
      } catch (err) {
        console.error(err);
        notify("something went wrong!!!. Please try later.");
      } finally {
        setLoading(false);
      }
    } else {
      setShowError(true);
    }
  };

  const navToParent = (route) => {
    if (
      window.location.hostname === "www.ideafuse.ai" ||
      window.location.hostname === "ideafuse.ai" ||
      window.location.origin == "http://localhost:3000" ||
      window.location.origin === "https://ideafuse-dev.netlify.app"
    ) {
      history.replace(route);
    } else {
      window.location.href = `https://www.ideafuse.ai${route}`;
    }
  };

  return (
    <div className={styles.wrapper}>
      <Card className={cls(styles.card, "custom-scrollbar")}>
        <Container fluid>
          <Row>
            <Col className="p-md-3 py-md-4 p-3">
              <div>
                <a href="https://ideafuse.ai/#home">
                  <img src={logo} alt="Ideafuse" className={styles.logo} />
                </a>
                <p className={styles.title}>User Login</p>
                <p className={styles.subtitle}>
                  Enter your registered email address to access your account
                </p>
                <TitleInput
                  label="User ID"
                  name="userId"
                  value={userId}
                  onChange={(e) => setUserId(e.target.value)}
                  placeholder="Enter Your User ID / Registered Email ID"
                  showerr={showError}
                  errmsg="Invalid userId"
                  valid={validator.isEmail(userId)}
                />
                <button
                  onClick={handleSubmit}
                  disabled={loading}
                  className={styles.continueBtn}
                >
                  {loading && <BtnSpinner />}
                  Continue with Email
                </button>

                <p className={styles.notYetText}>
                  Not yet registered with{" "}
                  <span>
                    <img src={arka} alt="arka" className={styles.arkaText} />
                  </span>{" "}
                  ?
                </p>

                {/* <Link className="text-decoration-none" to="/register">
                  <p className={styles.register}>Register</p>
                </Link> */}
                <div onClick={() => navToParent("/register")}>
                  <p className={styles.register}>Register</p>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </Card>
    </div>
  );
};

export default Login;
