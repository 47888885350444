import { useSelector } from "react-redux";
import { Card, Col, Container, Row } from "reactstrap";
import styles from "./Home.module.css";
import { CustomImg, MontP } from "../../styles/common";

import aviskaran from "../../assets/images/aviskaran.svg";
import { colors } from "../../styles/theme";
import PieCard from "../../components/PieCard";
import { secToMin } from "../../utils";

const EffortList = ({ title, cost, time, mt = 0 }) => (
  <>
    <MontP fs="16px" lh="20px" c={colors.white} b mt={mt}>
      {title}
    </MontP>
    <div className="d-flex align-items-center mt-2">
      <div className="d-flex align-items-center w--20">
        <MontP fs="14px" lh="18px" fw="600" c={colors.white}>
          Cost per document
        </MontP>
        <MontP fs="14px" lh="18px" fw="600" c={colors.yellow} ml="15px">
          ${cost}
        </MontP>
      </div>
      <div className="d-flex align-items-center ms-md-5 ms-3">
        <MontP fs="14px" lh="18px" fw="600" c={colors.white}>
          Time per document
        </MontP>
        <MontP fs="14px" lh="18px" fw="600" c={colors.yellow} ml="15px">
          {time}
        </MontP>
      </div>
    </div>
  </>
);

const CountCard = ({ title, count }) => (
  <Card className={styles.countcard}>
    <MontP fs="20px" lh="20px" b c={colors.secondary}>
      {count}
    </MontP>
    <MontP fs="14px" lh="15px" fw="600" c={colors.primary} mt="15px">
      {title}
    </MontP>
  </Card>
);

export default function Home() {
  const { user_data } = useSelector((state) => ({
    user_data: state.auth.user_data,
  }));

  const {
    cusManualDocCost,
    cusManualDocTime,
    cusArkaDocCost,
    cusArkaDocTime,
    cusTotalDailyDocs,
    cusUniqueTrainedDocs,
    cusUniqueConsumedDocs,
    cusCostManual,
    cusCostArka,
    cusCostGain,
    cusTimeManual,
    cusTimeArka,
    cusTimeGain,
  } = user_data?.dashboardInfo || {};

  return (
    <Container fluid className="px-md-5 px-4 py-3 mt-md-0 mt-5">
      <CustomImg
        src={
          user_data?.customerInfo?.custLogo
            ? `data:image/jpeg;base64,${user_data.customerInfo.custLogo}`
            : aviskaran
        }
        //src={user_data?.customerInfo?.custLogo || aviskaran}
        w="160px"
        h="37px"
        mb="10px"
      />
      <Row>
        <Col md={12}>
          <div className={styles.blueglass}>
            <EffortList
              title={"Manual Effort"}
              cost={cusManualDocCost}
              time={secToMin(cusManualDocTime)}
            />
            <EffortList
              title={"ARKA Effort"}
              cost={cusArkaDocCost}
              time={secToMin(cusArkaDocTime)}
              mt="20px"
            />
          </div>
        </Col>
        <Col md={6} className="mt-3">
          <PieCard
            title="Cost Gain"
            text1="Manual Cost"
            text2="ARKA Cost"
            text3="Cost Gain"
            value1={cusCostManual}
            value1Unit={"$"}
            value2={cusCostArka}
            value2Unit={"$"}
            value3={cusCostGain}
            value3Unit={"$"}
            boxColor1={colors.grey}
            boxColor2={colors.secondary}
            boxColor3={colors.green}
          />
        </Col>
        <Col md={6} className="mt-3">
          <PieCard
            title="Time Gain"
            text1="Manual Effort"
            text2="ARKA Effort"
            text3="Time Gain"
            value1={cusTimeManual ? Math.floor(cusTimeManual / 60) : 0}
            value2={cusTimeArka ? Math.floor(cusTimeArka / 60) : 0}
            value3={cusTimeGain ? Math.floor(cusTimeGain / 60) : 0}
            value1rightUnit={" mins"}
            value2rightUnit={" mins"}
            value3rightUnit={" mins"}
            boxColor1={colors.grey}
            boxColor2={colors.secondary}
            boxColor3={colors.green}
          />
        </Col>
        <Col md={6} className="mt-3">
          <PieCard
            title="Trained Document Type Consumption"
            //text1="Run Consumption"
            //text2="Unique Document Types"
            //value1={cusUniqueConsumedDocs}
            //value2={cusUniqueTrainedDocs}
            //boxColor1={colors.grey}
            //boxColor2={colors.yellow}
            text1="Unique Document Types"
            text2="Run Consumption"
            value1={cusUniqueTrainedDocs}
            value2={cusUniqueConsumedDocs}
            boxColor1={colors.yellow}
            boxColor2={colors.grey}
          />
        </Col>
        <Col md={6} className="mt-3">
          <div className="d-flex flex-wrap justify-content-between">
            <CountCard
              title="Total Documents Processed"
              count={cusTotalDailyDocs}
            />
            <CountCard
              title="Run Consumption For Unique Document Type"
              count={cusUniqueConsumedDocs}
            />
            <CountCard
              title="Trained Unique Document Types"
              count={cusUniqueTrainedDocs}
            />
          </div>
        </Col>
      </Row>
    </Container>
  );
}
