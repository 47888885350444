import React from "react";
import { Container, Row, Col } from "reactstrap";
import ArkaToast from "../components/arka-toast";
import Footer from "../components/footer/index";
import Header from "../components/header/index";

const HeaderFooter = (props) => {
  return (
    <Container fluid>
      <Row>
        <Col className="p-0 position-relative">
          <ArkaToast />
          <Header />
          {props.children}
          <Footer />
        </Col>
      </Row>
    </Container>
  );
};

export default HeaderFooter;
