import { useState, useEffect } from "react";
import PropTypes from "prop-types";
import classnames from "classnames";
import { useSelector } from "react-redux";
import {
  Row,
  Col,
  Table,
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap";
import { colors } from "../../../styles/theme";
import { CustomImg, ElementPos, MontP, NotoP } from "../../../styles/common";
import styles from "../User.module.css";

import searchIcon from "../../../assets/icons/search.svg";
import chev_down from "../../../assets/icons/chev-down.svg";
import MyModal from "../../../components/MyModal";
import ConfirmCard from "../../../components/ConfirmCard";
import NotFoundMsg from "../../../components/NotFoundMsg";
import { capitalize, notify, trimString } from "../../../utils";
import { API, PATH } from "../../../services/Axios";
import MyCheckbox from "../../../components/MyCheckbox";

export default function SearchUsers(props) {
  const { userType, usersList, user_data } = props;

  const [loading, setLoading] = useState(false);

  const [search, setSearch] = useState("");
  // const [searchUsersList, setSearchUsersList] = useState([]);
  // const [randomNo, setRandomNo] = useState(0);

  const [userRoleType, setUserRoleType] = useState([]);
  const [showRoleType, setShowRoleType] = useState(false);
  const [roleType, setRoleType] = useState("ALL");

  const [isOpen, setIsOpen] = useState(false);
  const [tempItem, setTempItem] = useState(null);

  const getUserRoleType = () => {
    let roleData = [];
    userType.forEach(i => {
      roleData.push(i.userRole);
    });
    setUserRoleType(["ALL", ...roleData]);
  };

  useEffect(() => {
    getUserRoleType();
  }, [userType, user_data]);

  const toggleStatus = async () => {
    const data = {
      UserId: tempItem.userId,
      IsActive: !tempItem.isActive,
      LoggedInUserId: user_data?.userInfo?.userEmail,
    };

    setLoading(true);
    try {
      const result = await API.post(PATH.AcDeUser, data, user_data.token);
      console.log(result);
      if (result) {
        tempItem.isActive = !tempItem.isActive;
        setTempItem(null);
        setIsOpen(false);
      } else {
        notify(
          "Error encountered adding the user, please send an email to aka.admin@ideafuse.ai with error details or create an issue using the Issue Management portal"
        );
      }
      setLoading(false);
    } catch (err) {
      console.log(err);
      notify(
        "Error encountered adding the user, please send an email to aka.admin@ideafuse.ai with error details or create an issue using the Issue Management portal"
      );
      setLoading(false);
    }
  };

  const handleOpenModal = (item) => {
    setTempItem(item);
    setIsOpen(true);
  };

  const handleRoleType = (type) => {
    if (type === "ALL") {
      setSearch("");
    } else {
      setSearch(type);
    }
    setRoleType(type);
  };

  const searchUsers = (arr, s) => {
    s = trimString(s);
    return arr.filter((item) => {
      return (
        (item.userId.toLowerCase() && item.userId.toLowerCase().includes(s)) ||
        (item.firstName.toLowerCase() &&
          item.firstName.toLowerCase().includes(s)) ||
        (item.lastName.toLowerCase() &&
          item.lastName.toLowerCase().includes(s)) ||
        (item.userRole.toLowerCase() && item.userRole.toLowerCase().includes(s))
      );
    });
  };

  let finalArr =
    search.length > 0
      ? searchUsers(usersList?.lstUsers || [], search.toLowerCase())
      : usersList?.lstUsers || [];

  return (
    <>
      <MyModal isOpen={isOpen} setIsOpen={setIsOpen}>
        {tempItem && (
          <ConfirmCard
            title="Do you want De-activate user?"
            message="User won’t be able to perform there tasks. You can active the user later."
            cancelText="Cancel"
            confirmText={tempItem.isActive ? "De-activate" : "Activate"}
            handleCancel={() => setIsOpen(false)}
            handleConfirm={toggleStatus}
            loading={loading}
          />
        )}
      </MyModal>
      <Row className="mt-5 px-md-5">
        <Col xs={12} sm={12} md={12}>
          <div className="d-flex align-items-center justify-content-between">
            <div className="position-relative">
              <input
                className={styles.searchbar}
                id="search"
                name="search"
                type="text"
                autoComplete="off"
                value={search}
                onChange={(e) => setSearch(e.target.value)}
                placeholder={
                  window.innerWidth > 768
                    ? "Search user with name, email, phone or role"
                    : "Search user"
                }
              />
              <ElementPos r="15px" t="5px" role="button">
                <CustomImg src={searchIcon} w="16px" h="16px" />
              </ElementPos>
            </div>
            <Dropdown
              isOpen={showRoleType}
              toggle={() => setShowRoleType(!showRoleType)}
            >
              <DropdownToggle className={styles.filterbtn}>
                <NotoP
                  fs="13px"
                  lh="18px"
                  mfs="11px"
                  mlh="14px"
                  fw="600"
                  c={colors.white}
                >
                  {roleType}
                </NotoP>
                <CustomImg src={chev_down} w="12px" h="8px" ml="5px" />
              </DropdownToggle>
              <DropdownMenu>
                {userRoleType.map((x, i) => (
                  <DropdownItem
                    key={i}
                    disabled={roleType === x}
                    onClick={() => handleRoleType(x)}
                  >
                    <NotoP fs="11px" lh="14px" fw="600" c={colors.secondary}>
                      {x}
                    </NotoP>
                  </DropdownItem>
                ))}
              </DropdownMenu>
            </Dropdown>
          </div>
          {/* TABLE */}
          {finalArr.length > 0 ? (
            <Table responsive className={classnames(styles.table, "mt-3")}>
              <thead className={styles.thead}>
                <tr className={classnames(styles.theadtr, "text-center")}>
                  {["Status", "Full Name", "Email", "Role"].map((x, i) => (
                    <th key={i}>
                      <MontP fs="12px" lh="15px" b c={colors.primary}>
                        {x}
                      </MontP>
                    </th>
                  ))}
                </tr>
              </thead>
              <tbody className={styles.tbody}>
                {finalArr.map((item, index) => (
                  <tr
                    key={index}
                    style={{
                      backgroundColor:
                        index % 2 === 0
                          ? "rgba(37, 65, 178, 0.1)"
                          : colors.white,
                    }}
                    className={styles.tbodytr}
                  >
                    <td className={styles.tbodytrtd}>
                      {/* <button
                        onClick={() => handleOpenModal(item)}
                        className={classnames(
                          item.isActive ? styles.activerect : styles.rect
                        )}
                      >
                        {" "}
                      </button> */}
                      <MyCheckbox
                        checked={item.isActive}
                        onClick={() => handleOpenModal(item)}
                      />
                    </td>
                    <td className={styles.tbodytrtd}>
                      <NotoP fs="13px" lh="16px" fw="600" c={colors.blue5}>
                        {`${item.firstName} ${item.lastName}`}
                      </NotoP>
                    </td>
                    <td className={styles.tbodytrtd}>
                      <NotoP fs="13px" lh="16px" fw="600" c={colors.blue5}>
                        {item.userId}
                      </NotoP>
                    </td>
                    <td className={styles.tbodytrtd}>
                      <button disabled className={styles.rolebtn}>
                        <NotoP fs="13px" lh="16px" fw="400" c={colors.white}>
                          {capitalize(item.userRole)}
                        </NotoP>
                      </button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
          ) : (
            <NotFoundMsg />
          )}
        </Col>
      </Row>
    </>
  );
}

SearchUsers.propTypes = {
  usersList: PropTypes.object,
  user_data: PropTypes.object,
};
