import { TextField } from "@material-ui/core";
import PropTypes from "prop-types";
import styled from "styled-components";
import styles from "./title-textarea.module.css";
import { NotoP } from "../../styles/common";

const MyTextField = styled(TextField)`
  .MuiInputBase-multiline {
    border: 2px solid #03256c;
    border-radius: 5px;
    padding: 10px 15px;
    font-family: var(--dm-font) !important;
    font-style: normal;
    font-size: 16px;
    line-height: 19px;
    color: #000;
    margin: 0;
  }

  .MuiInputBase-input::placeholder {
    /* color: #212529; */
    color: #000;
    opacity: 0.6;
  }

  /* @media only screen and (max-width: 768px) {
    font-size: 10px;
  } */
`;

function TitleTextArea(props) {
  const {
    coverstyle,
    label,
    name,
    type,
    value,
    onChange,
    placeholder,
    rows,
    required,
    showerr,
    valid,
    errmsg,
  } = props;
  return (
    <div className={coverstyle}>
      <p className={styles.label}>
        {label}
        {required && <span>*</span>}
      </p>
      <MyTextField
        className="form-control"
        id={label}
        name={name}
        type={type}
        inputMode={type}
        value={value}
        onChange={onChange}
        placeholder={placeholder}
        label=""
        InputLabelProps={{ hidden: true }}
        multiline
        rows={rows}
        focused
        InputProps={{ disableUnderline: true }}
        {...props}
      />
      {required &&
        showerr &&
        (!value ? (
          <NotoP
            fs=".6rem"
            className="text-danger py-1"
          >{`${label} cannot be empty`}</NotoP>
        ) : !valid && errmsg ? (
          <NotoP fs=".6rem" className="text-danger py-1">
            {errmsg}
          </NotoP>
        ) : (
          ""
        ))}
    </div>
  );
}

TitleTextArea.propTypes = {
  label: PropTypes.string,
  name: PropTypes.string,
  type: PropTypes.string,
  value: PropTypes.any,
  onChange: PropTypes.func,
  placeholder: PropTypes.string,
  coverstyle: PropTypes.string,
  rows: PropTypes.number,
  required: PropTypes.bool,
  showerr: PropTypes.bool,
  valid: PropTypes.bool,
  errmsg: PropTypes.string,
};

TitleTextArea.defaultProps = {
  type: "text",
  coverstyle: "mt-3",
  rows: 2,
  required: true,
  valid: false,
};

export default TitleTextArea;
