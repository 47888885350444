import PropTypes from "prop-types";

function MailTo({ email, subject, body, children, coverstyle }) {
  return (
    <a
      href={`mailto:${email}?subject=${subject || ""}&body=${body || ""}`}
      className={coverstyle}
    >
      {children}
    </a>
  );
}

MailTo.propTypes = {
  email: PropTypes.string,
  subject: PropTypes.string,
  body: PropTypes.string,
  children: PropTypes.any,
  coverstyle: PropTypes.string,
};

MailTo.defaultProps = {
  coverstyle: "text-decoration-none",
};

export default MailTo;
