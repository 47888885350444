import React, { useEffect } from "react";
import { Col, Container, Row } from "reactstrap";
import { useHistory, useLocation } from "react-router-dom";
import LandingNote from "../../components/landing-note";
import LandingWorkSteps from "../../components/landing-work-steps";
import LandingCarousel from "../../components/landing-caraousel";
import BusinessPlan from "../../components/business-plan";
import LandingArka from "../../components/landing-arka";
import LandingCatalog from "../../components/landing-catalog";
import styles from "./landing.module.css";

import microsoftImg from "../../assets/images/landing/microsoft.png";
import businessSupportedImg from "../../assets/images/landing/business-supported.svg";
import classNames from "classnames";

const Landing = () => {
  const history = useHistory();
  const { hash } = useLocation();

  useEffect(() => {
    if (!hash) {
      history.replace("/#home");
    }
  }, []);

  return (
    <>
      {/* SECTION 1 Intro */}
      <LandingCarousel />
      {/* SECTION 2 How it works */}
      <LandingArka />
      {/* SECTION 3 ARKA */}
      <LandingWorkSteps />
      {/* SECTION 4 Service Catalog */}
      <LandingCatalog />
      {/* SECTION 5 Offerings */}
      <div className={styles.wrapper}>
        <Container fluid>
          <Row>
            <Col md={8}>
              <div className={styles.tabletCenter}>
                <p className={styles.sectionTitle}>
                  We are offering 4 Weeks Free trial
                </p>
                <ol className={styles.ol}>
                  <li className={styles.sectionSubtitle}>
                    Discover the potential of our next generation AI product
                  </li>
                  <li className={styles.sectionSubtitle}>
                    Do It Yourself . . . “Technical team required” is no longer
                    a dream
                  </li>
                  <li className={styles.sectionSubtitle}>
                    Experience the power of Cognitive Process
                  </li>
                  <li className={styles.sectionSubtitle}>
                    Easily add intelligence to your Business Workflow
                  </li>
                  <li className={styles.sectionSubtitle}>
                    Experience the human like behavior of machines
                  </li>
                  <li className={styles.sectionSubtitle}>
                    Get Exposed to a unique way of data extraction and
                    processing for
                  </li>
                  {/* <ul className={styles.ul}>
                    <li className={styles.sectionSubtitle}>Printed text</li>
                    <li className={styles.sectionSubtitle}>
                      Handwritten Text and much more ....
                    </li>
                  </ul> */}
                </ol>
                <ul className={styles.ul}>
                  <li className={styles.sectionSubtitle}>Printed text</li>
                  <li className={styles.sectionSubtitle}>Handwritten Text</li>
                  <li className={styles.sectionSubtitle}>
                    Signature Detection
                  </li>
                  <li className={styles.sectionSubtitle}>
                    Free Text and much more ....
                  </li>
                </ul>
              </div>
            </Col>
            <Col md={4}>
              <div className="d-flex align-items-center justify-content-md-end justify-content-center h-100">
                <button onClick={() => history.push('/request-demo')} className={styles.tryNow}>Try Now</button>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
      {/* SECTION 6 Plans */}
      <BusinessPlan />
      {/* SECTION 7 Business Vertical */}
      <div className={styles.wrapper} style={{ backgroundColor: "#F7F7FF" }}>
        <Container fluid>
          <Row>
            <Col>
              <div className="text-center">
                <p className={styles.sectionTitle}>
                  Business Verticals Supported and expanding ...
                </p>
                <img
                  src={businessSupportedImg}
                  alt="Business supported and expanding"
                  className={styles.bizSupportImg}
                />
              </div>
            </Col>
          </Row>
        </Container>
      </div>
      {/* SECTION 8 Contact */}
      <LandingNote />
      {/* SECTION 9 Alliances and Partnerships */}
      <div
        id="partners"
        className={styles.wrapper}
        style={{ backgroundColor: "#F0F2FC", height: "auto" }}
      >
        <div className="header_content--fix" />
        <Container fluid>
          <Row>
            <Col md={6} sm={8}>
              <div>
                <p className={styles.sectionTitle}>Alliances & Partnerships</p>
                <p className={styles.sectionSubtitle}>
                  Ideafuse partners includes business software providers, niche
                  technology developers, and platform and IT infrastructure
                  vendors.
                </p>
              </div>
            </Col>
            <Col md={6} sm={4}>
              <div className="d-flex align-items-center justify-content-md-end justify-content-sm-end justify-content-start h-100">
                <img
                  src={microsoftImg}
                  alt="microsoft"
                  className={styles.microsoft}
                />
              </div>
            </Col>
          </Row>
        </Container>
        <div className="header_content--fix" />
      
      </div>
      <div
        id="testimonials"
        className={classNames(styles.wrapper, styles.gradientBg)}
        style={{ backgroundColor: "#333", height: "auto" }}
      >
        <Row>
        <Col md={12} sm={12}>
      <div className="container-md">
        <Row className="g-0">
          <Col md={7} sm={5}>
            <div>
              <p className={classNames(styles.sectionTitle, "text-white mb-5")}>Client Testimonials</p>
              <p className={classNames(styles.sectionSubtitle, "text-white")} style={{ fontSize: 18 }}>
              “IdeaFuse ARKA is powerful tool for classifying documents and extracting information in a very usable format.  It can be a key solution for businesses that are interested in achieving end to end automation in a document dependent environment.”
              </p>
              <span className={classNames(styles.sectionSubtitle, "text-white")} style={{ opacity: 0.8, marginTop: '1em' }}> Sr. Manager, Software Engineering – Financial Professional & Agent Marketing Services</span>
          </div></Col>
          </Row>
          </div>
          </Col>
        </Row>
        </div>
    </>
  );
};

export default Landing;
