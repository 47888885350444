import React, { useState } from "react";
import {
  Col,
  Container,
  Navbar,
  NavbarBrand,
  Row,
  Nav,
  NavItem,
  NavLink,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap";
import cls from "classnames";
import { useLocation, useHistory } from "react-router-dom";
import styles from "./header.module.css";

import logo from "../../assets/images/ideafuse-logo.svg";
import arrowDown from "../../assets/icons/arrow-down-blue.svg";
import menuImg from "../../assets/icons/menu-primary.svg";
import closeImg from "../../assets/icons/close-primary.svg";
import { colors } from "../../styles/theme";

const Header = () => {
  const history = useHistory();
  const { pathname, hash } = useLocation();
  const [isOpen, setIsOpen] = useState(false);
  const [showProducts, setShowProducts] = useState(false);
  // console.log({ pathname });
  // console.log({ hash });

  const resetState = () => {
    setShowProducts(false);
    setIsOpen(false);
  };

  const checkIsActive = (routeName = "") => {
    if (pathname === "/") {
      if (hash) {
        return routeName.includes(hash);
      } else {
        return routeName === "/#home";
        // return pathname === routeName;
      }
    }

    return pathname.includes(routeName);
  };

  const MyNavItem = ({ name, routeName }) => (
    <NavItem className={styles.navItem}>
      <NavLink
        className={styles.navLink}
        //active={pathname === routeName}
        active={checkIsActive(routeName)}
        href={routeName}
        onClick={() => setIsOpen(false)}
      >
        {name}
      </NavLink>
      {/* {pathname === routeName && !isOpen && <div className={styles.active} />} */}
      {checkIsActive(routeName) && !isOpen && <div className={styles.active} />}
    </NavItem>
  );

  return (
    <Container>
      <Row>
        <Col>
          {/* <Navbar light fixed="top" expand="md" className={styles.navbar}> */}
          <div className={styles.navbarPos}>
            <Navbar light expand="md" className={styles.navbar}>
              <NavbarBrand href="/#home">
                <img src={logo} alt="logo" className={styles.logo} />
              </NavbarBrand>
              <button
                onClick={() => setIsOpen(!isOpen)}
                className={styles.mobileMenuBtn}
              >
                <img src={isOpen ? closeImg : menuImg} alt="toggle" />
              </button>
              <Nav className={cls(styles.nav, "mx-md-auto")} navbar>
                <MyNavItem name="Home" routeName="/#home" />
                <UncontrolledDropdown nav inNavbar>
                  <DropdownToggle nav className={styles.navLink}>
                    Products{" "}
                    <span>
                      <img src={arrowDown} alt="arrow" />
                    </span>
                  </DropdownToggle>
                  {checkIsActive("/#arka") && !isOpen && (
                    <div className={styles.active} />
                  )}
                  <DropdownMenu className={styles.ddMenu}>
                    <div className={styles.ddItemCover}>
                      <DropdownItem className={styles.ddItem}>
                        <NavLink className={styles.navLink} href="/#arka">
                          ARKA
                        </NavLink>
                      </DropdownItem>
                    </div>
                  </DropdownMenu>
                </UncontrolledDropdown>
                <MyNavItem name="Offerings" routeName="/#offerings" />
                <MyNavItem name="Plans" routeName="/#plans" />
                <MyNavItem name="Services" routeName="/#services" />
                <MyNavItem name="Partners" routeName="/#partners" />
                <MyNavItem name="About Us" routeName="/about-us" />
                <MyNavItem name="Contact" routeName="/#contact" />
              </Nav>
              <Nav className={cls(styles.nav, "ms-md-auto")} navbar>
                <button
                  onClick={() => history.push("/request-demo")}
                  className={styles.demoBtn}
                >
                  Request a demo
                </button>
                <MyNavItem name="Sign in" routeName="/login" />
              </Nav>

              {/* Mobile View */}
              <nav
                className={cls(styles.mobileNav, {
                  [styles.mobileNavActive]: isOpen,
                })}
              >
                <div className={styles.mobileNavContent}>
                  <MyNavItem name="Home" routeName="/#home" />
                  <MyNavItem name="About" routeName="/about-us" />
                  <div className="text-center">
                    <p
                      onClick={() => setShowProducts(!showProducts)}
                      className={styles.navLink}
                      style={{
                        color: showProducts
                          ? colors.secondary
                          : colors.linkBlack,
                      }}
                    >
                      Products
                      <span className="ms-2">
                        <img src={arrowDown} alt="arrow" />
                      </span>
                    </p>
                    {showProducts && (
                      <>
                        <NavLink
                          href="/#arka"
                          className="p-0"
                          onClick={resetState}
                        >
                          <p className={styles.navLink}>ARKA</p>
                        </NavLink>
                      </>
                    )}
                  </div>
                  <MyNavItem name="Services" routeName="/#services" />
                  <MyNavItem name="Pricing" routeName="/#offerings" />
                  <MyNavItem name="Plans" routeName="/#plans" />
                  <MyNavItem name="Contact" routeName="/#contact" />
                  <div className={styles.bottomPos}>
                    <button
                      onClick={() => history.push("/request-demo")}
                      className={styles.demoBtn}
                    >
                      Request a demo
                    </button>
                    <MyNavItem name="Sign in" routeName="/login" />
                  </div>
                </div>
              </nav>
            </Navbar>
          </div>
        </Col>
      </Row>
    </Container>
  );
};

export default Header;
