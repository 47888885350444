import React from "react";
import { Card, Col, Container, Row } from "reactstrap";
import cls from "classnames";
import styles from "./about-us.module.css";

import whoWeAre from "../../assets/images/aboutUs/who-we-are.png";
import mission from "../../assets/images/aboutUs/our-mission.png";
import vision from "../../assets/images/aboutUs/our-vision.png";
import products from "../../assets/images/aboutUs/offerings-products.svg";
import aiServices from "../../assets/images/aboutUs/offerings-ai-services.svg";
import innovation from "../../assets/images/aboutUs/offerings-innovation.svg";
import services from "../../assets/images/aboutUs/offerings-services.svg";
import businessBanner from "../../assets/images/aboutUs/business-banner.svg";

const SectionDiv = ({ title, subtitle, pic }) => (
  <div className={styles.section}>
    <Container>
      {/* <Row className="flex-column-reverse"> */}
      <Row className={styles.sectionRow}>
        <Col md={6}>
          <div className="ms-md-4">
            <img src={pic} alt={title} className={styles.sectionPic} />
          </div>
        </Col>
        <Col md={6}>
          <div className="me-md-4">
            <p className={styles.sectionTitle}>{title}</p>
            <p className={styles.sectionSubtitle}>{subtitle}</p>
          </div>
        </Col>
      </Row>
    </Container>
  </div>
);

const OfferingCard = ({ title, subtitle, pic, isEnd }) => (
  <div
    className={cls(
      "d-flex align-items-center",
      isEnd
        ? "justify-content-md-end justify-content-center"
        : "justify-content-center"
    )}
  >
    <Card className={cls(styles.sectionCard, "content_center--flex")}>
      <img src={pic} alt={title} className={styles.sectionCardImg} />
      <p className={styles.sectionCardTitle}>{title}</p>
      <p className={styles.sectionCardSubTitle}>{subtitle}</p>
    </Card>
  </div>
);

const AboutUs = () => {
  return (
    <>
      <div className="header_content--fix" />
      {/* SECTION 1 */}
      <div className="position-relative">
        <div className={styles.wrapper} />
        <div className={styles.darkEffect} />
        <div className={styles.bannerContent}>
          <Container>
            <Row>
              <Col>
                <div className={styles.bannerTextWrapper}>
                  <p className={styles.bannerTitle}>About Us</p>
                  <p className={styles.bannerSubtitle}>
                    Ideafuse delivers you the technology of your dreams from the
                    future, right to your doorstep. We not only build Innovative
                    Solutions powered by Artificial Intelligence and Machine
                    Learning Capabilities, but also works closely with our
                    clients in scaling and automating AI & ML powered solutions.
                  </p>
                </div>
              </Col>
            </Row>
          </Container>
        </div>
      </div>
      {/* SECTION 2 */}
      <SectionDiv
        title="Who We Are"
        subtitle="Founded by a group of enthusiastic AI architects and engineers, Ideafuse.ai brings a portfolio of AI and ML powered cloud-hosted solutions for real-world applications. We are uniquely positioned to help our clients to successfully transform their AI efforts from a vision to full-scale production solutions. We work with our clients implementing and scaling applied intelligence to help them achieve their growth objectives. With our exclusive access to the global executive-suite and the trendsetters of the technology world, we can bring our readers up-to-the-minute insights into how AI technologies are transforming the global economy."
        pic={whoWeAre}
      />
      {/* SECTION 3 */}
      <div className={cls(styles.section, "bg-white")}>
        <Container>
          <Row>
            <Col md={6}>
              <div className="ms-md-4">
                <p className={styles.sectionTitle}>Our Mission</p>
                <p className={styles.sectionSubtitle0}>Seeing is Believing…</p>
                <p className={styles.sectionSubtitle}>
                  No longer is artificial intelligence the domain of futurists
                  and technologists, it has evolved into a useful tool with
                  hundreds of business applications. At Ideafuse.ai, we will
                  transport you through the AI road-mapping journey. We focus on
                  the practical applications of AI technologies and the way they
                  are dramatically transforming the future of work. Together
                  with our clients, we intend to create a difference in the AI
                  business global portfolio leveraging
                </p>
                <ul className="mt-4">
                  <li className={styles.li}>AI Exchange</li>
                  <li className={styles.li}>Content Variety</li>
                  <li className={styles.li}>
                    Easy to digest industry-specific content
                  </li>
                  <li className={styles.li}>Practitioner Portal</li>
                </ul>
              </div>
            </Col>
            <Col md={6}>
              <div className="me-md-4 d-flex justify-content-end">
                <img
                  src={mission}
                  alt="Our mission"
                  className={styles.sectionPic}
                />
              </div>
            </Col>
          </Row>
        </Container>
      </div>
      {/* SECTION 4 */}
      <SectionDiv
        title="Our Vision"
        subtitle="Ideafuse.ai vision is to accelerate the advancement of AI through informing, educating and building real time AI powered applications, bringing them down as viable and consumable solutions. Unlock trustworthy insights, analysis, and trends from world-renowned AI pioneers to stay ahead of change, support your AI integration, and make better-informed decisions."
        pic={vision}
      />
      {/* SECTION 5 */}
      <div className={cls(styles.section, "bg-white")}>
        <Container>
          <Row>
            <Col md={{ size: 4, offset: 1 }}>
              <div>
                <p className={styles.sectionTitle}>Our Offerings</p>
              </div>
            </Col>
            <Col md={3}>
              <OfferingCard
                title="Products"
                subtitle="Custom AI powered solutions"
                pic={products}
              />
            </Col>
            <Col md={3}>
              <OfferingCard
                title="AI Powered Services"
                subtitle="Applied Intelligence Services"
                pic={aiServices}
              />
            </Col>
          </Row>
          <Row className="mt-md-5 mt-4">
            <Col md={{ size: 4, offset: 1 }}>
              <OfferingCard
                isEnd
                title="Innovation & Applied Sciences Arm"
                subtitle="Trusted Partnership"
                pic={innovation}
              />
            </Col>
            <Col md={3}>
              <OfferingCard
                title="Professional Services"
                subtitle="Implementation Partners And Talent Provisioning"
                pic={services}
              />
            </Col>
          </Row>
        </Container>
      </div>
      {/* SECTION 6 */}
      <div className={styles.businessSection}>
        <Container>
          <Row>
            <Col>
              <div>
                <p className={styles.businessTitle}>
                  Business Verticals Supported and expanding ...
                </p>
                <div className="d-flex align-items-center justify-content-center">
                  <img
                    className={styles.businessBanner}
                    src={businessBanner}
                    alt="business banner"
                  />
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
};

export default AboutUs;
