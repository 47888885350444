import { useEffect, useState } from "react";
import PropTypes from "prop-types";
import {
  ButtonDropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  InputGroup,
} from "reactstrap";
import { NotoP } from "../styles/common";
import { colors } from "../styles/theme";
import { countriesCode } from "../assets/data/countryCodes.json";

import chev_down from "../assets/icons/chev-down.svg";
import { useSelector } from "react-redux";
import { API, PATH } from "../services/Axios";

function TitleInputGroup(props) {
  const { user_data = {} } = useSelector((state) => ({
    user_data: state.auth.user_data,
  }));
  const [open, setOpen] = useState(false);
  const {
    coverstyle,
    label,
    name,
    type,
    value,
    onChange,
    placeholder,
    disabled,
    code,
    handleCode,
    required,
    showerr,
    valid,
    errmsg,
  } = props;

  const toggleOpen = () => {
    setOpen(!open);
  };

  const [countries, setCountries] = useState([]);

  const getCountryCodes = async () => {
    if (user_data?.token) {
      const result = await API.post(
        PATH.getCountryCodes,
        {},
        user_data.token
      );
      setCountries(result);
    } else {
      setCountries(JSON.parse(JSON.stringify(countriesCode)));
    }
  };

  useEffect(() => {
    getCountryCodes();
  }, [user_data]);

  return (
    <div className={coverstyle}>
      <NotoP
        fs="14px"
        lh="19px"
        mfs="12px"
        mlh="16px"
        fw="600"
        c={colors.primary}
      >
        {label}
        {required && <span className="iRed">*</span>}
      </NotoP>
      <InputGroup>
        <ButtonDropdown isOpen={open} toggle={toggleOpen}>
          <DropdownToggle className="title--inputbtn">
            {code}
            <img
              src={chev_down}
              alt="choose"
              width="12px"
              height="7px"
              className="ms-1"
            />
          </DropdownToggle>
          <DropdownMenu style={{ height: "30vh" }} className="overflow-auto">
            {countries.map((item, index) => (
              <DropdownItem onClick={() => handleCode(item.countryCode)} key={index}>
                {item.countryName} {item.countryCode}
              </DropdownItem>
            ))}
          </DropdownMenu>
        </ButtonDropdown>
        <input
          className="form-control title--input"
          id={label}
          name={name}
          type={type}
          inputMode={type}
          value={value}
          onChange={onChange}
          disabled={disabled}
          placeholder={placeholder}
          {...props}
        />
      </InputGroup>
      {required &&
        showerr &&
        (!value ? (
          <NotoP
            fs="10px"
            className="text-danger py-1"
          >{`${label} cannot be empty`}</NotoP>
        ) : !valid && errmsg ? (
          <NotoP fs="10px" className="text-danger py-1">
            {errmsg}
          </NotoP>
        ) : (
          ""
        ))}
    </div>
  );
}

TitleInputGroup.propTypes = {
  label: PropTypes.string,
  name: PropTypes.string,
  type: PropTypes.string,
  value: PropTypes.any,
  onChange: PropTypes.func,
  placeholder: PropTypes.string,
  coverstyle: PropTypes.string,
  disabled: PropTypes.bool,
  code: PropTypes.string,
  handleCode: PropTypes.func,
  required: PropTypes.bool,
  showerr: PropTypes.bool,
  valid: PropTypes.bool,
  errmsg: PropTypes.string,
};

TitleInputGroup.defaultProps = {
  type: "text",
  coverstyle: "mt-3 position-relative",
  required: true,
  valid: false,
};

export default TitleInputGroup;
