import { useState, useEffect } from "react";
import moment from "moment";
import { Col, Container, Row } from "reactstrap";
import { useSelector } from "react-redux";
import CostComparision from "../../components/graphs/CostComparision";
import DocumentProcessed from "../../components/graphs/DocumentProcessed";
import ReportCards from "../../components/ReportCards";
import FormSpinner from "../../components/FormSpinner";
import { API, PATH } from "../../services/Axios";
import GraphHeader from "../../components/graphs/GraphHeader";
import AnalyticsPieChart from "./AnalyticsPieChart";

// import AnalyticsBarChart from "./AnalyticsBarChart";

export default function Analytics() {
  const { user_data } = useSelector((state) => ({
    user_data: state.auth.user_data,
  }));

  const minDate = new Date(moment().subtract(1, "years"));
  // const startOfMonth = moment().startOf("month").format("YYYY-MM-DD hh:mm");
  const startOfPrevMonth = moment()
    .subtract(1, "months")
    .startOf("month")
    .format("YYYY-MM-DD hh:mm");
  const endOfMonth = moment().endOf("month").format("YYYY-MM-DD hh:mm");

  const [startDate, setStartDate] = useState(moment(startOfPrevMonth).toDate());
  const [endDate, setEndDate] = useState(moment(endOfMonth).toDate());

  const initialMainSummary = {
    totalDocs: 0,
    uniqueDocs: 0,
    docsSamples: 0,
    businessKpis: 0,
  };
  const [summaryLoading, setSummaryloading] = useState(false);
  const [mainSummary, setMainSummary] = useState(initialMainSummary);

  // Bar Chart
  const [costLoading, setCostloading] = useState(false);
  const defaultMonth = null;
  const initialMonthLabels = () => {
    const tempDates = [];
    for (let i = defaultMonth - 1; i >= 0; i--) {
      tempDates.push(moment().subtract(i, "months").format("MMM YYYY"));
    }
    return tempDates;
  };
  const [monthsCount, setMonthsCount] = useState(defaultMonth);
  const [monthLabels, setMonthLabels] = useState(initialMonthLabels);
  const [arkaData, setArkaData] = useState([]);

  // Line Chart
  const [docLoading, setDocloading] = useState(false);
  const defaultDay = null;
  const getDaysLabels = (days = defaultDay) => {
    const tempDates = [];
    for (let i = days - 1; i >= 0; i--) {
      tempDates.push(moment().subtract(i, "days").format("DD/MM"));
    }
    return tempDates;
  };
  const [daysCount, setDaysCount] = useState(defaultDay);
  const [dayLabels, setDayLabels] = useState(getDaysLabels);
  const [documentData, setDocumentData] = useState([]);

  const [enableCall, setEnableCall] = useState(false);

  const [kpiList, setKpiList] = useState([]);

  const getKpiList = async () => {
    const data = {
      CustId: user_data?.customerInfo?.custId,
    };
    try {
      const result = await API.post(PATH.kpiList, data, user_data.token);
      // console.log(result);
      if (result) {
        setKpiList(result);
      }
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    getKpiList();
  }, []);

  const getMainSummaryData = async () => {
    setSummaryloading(true);
    const data = {
      CustId: user_data?.customerInfo?.custId,
      FromMonth: startDate.getMonth() + 1, // bydefault month counts from 0 so i add 1 here
      FromYear: startDate.getFullYear(),
      ToMonth: endDate.getMonth() + 1,
      ToYear: endDate.getFullYear(),
    };
    // console.log(">>>>", data);
    try {
      const result = await API.post(PATH.mainSummary, data, user_data.token);
      console.log(result);
      if (result) {
        setMainSummary(result);
      }
    } catch (err) {
      console.log(err);
    } finally {
      setSummaryloading(false);
    }
  };

  // useEffect(() => {
  //   if (enableCall) {
  //     getMainSummaryData();
  //   } else {
  //     setEnableCall(true);
  //   }
  // }, [startDate, endDate]);

  useEffect(() => {
    setEnableCall(true);
  }, []);

  const updateMonthsState = (dataArr) => {
    let arr = dataArr;

    arr.map(x=> {
      x.ArkaCost = x.totalDocs;
      x.ManualCost = x.manualCost;
      x.date = new Date(moment(x.period, "MMM YYYY"));
      return x;
    })
    let finalArr = arr.sort((a,b) => a.date - b.date);
    setArkaData(finalArr);
    setCostloading(false);
  };

  const getMonthsData = async () => {
    setCostloading(true);
    const data = {
      CustId: user_data?.customerInfo?.custId,
      NoOfMonths: monthsCount,
    };
    // console.log(">>>>", data);
    try {
      const result = await API.post(PATH.monthlySummary, data, user_data.token);
      // console.log("getMonths", result);
      if (result) {
        updateMonthsState(result);
      } else {
        setCostloading(false);
      }
    } catch (err) {
      setCostloading(false);
      console.log(err);
    }
  };

  useEffect(() => {
    if (enableCall) {
      console.log(">>MONTH/s>>", monthsCount);
      getMonthsData();
    }
  }, [monthsCount]);

  const updateDaysState = (dataArr) => {
    const tempLabels = getDaysLabels(daysCount);
    const tempData = [];
    // to match days format
    const newDataArr = dataArr.map((item) => {
      return {
        analyticsDate: moment(item.analyticsDate).format("DD/MM"),
        docCount: item.docCount,
      };
    });
    // check if it matchs then push data else push 0/null
    tempLabels.forEach((item) => {
      const isData = newDataArr.find((x) => x.analyticsDate === item);
      if (isData) {
        tempData.push(isData.docCount);
      } else {
        tempData.push(null);
      }
    });

    setDayLabels(tempLabels);
    setDocumentData(tempData);
    setDocloading(false);
  };

  const getDaysData = async () => {
    setDocloading(true);
    const data = {
      CustId: user_data?.customerInfo?.custId,
      NoOfDays: daysCount,
    };
    // console.log(">>>>", data);
    try {
      const result = await API.post(PATH.daywiseSummary, data, user_data.token);
      console.log("getDays", result);
      if (result) {
        updateDaysState(result);
      } else {
        setDocloading(false);
      }
    } catch (err) {
      console.log(err);
      setDocloading(false);
    }
  };

  useEffect(() => {
    if (enableCall) {
      console.log(">>DAY/S>>", daysCount);
      getDaysData();
    }
  }, [daysCount]);

  return (
    <Container fluid className="p-4 mt-5 px-md-5">
      <ReportCards
        title="Training Metrics"
        firstText="Documents Processed"
        firstValue={mainSummary.totalDocs}
        secondText="Unique Documents"
        secondValue={mainSummary.uniqueDocs}
        thirdText="Document samples"
        thirdValue={mainSummary.docsSamples}
        fourthText="Business KPI’s"
        fourthValue={mainSummary.businessKpis}
        monthYearPicker
        minDate={minDate}
        startDate={startDate}
        setStartDate={setStartDate}
        endDate={endDate}
        setEndDate={setEndDate}
        loading={summaryLoading}
        handleSubmit={getMainSummaryData}
      />
      {/* GRAPHS */}
      <Container fluid className="card py-2 mt-4">
        <Row>
          {/* <Col md={12} className="w-100--ipad">
            <AnalyticsBarChart />
          </Col> */}
          <Col xs={12} sm={12} md={6} className="w-100--ipad">
            <GraphHeader
              title="Cost Comparision"
              btnText={monthsCount !== null ? `Last ${monthsCount} ${
                monthsCount > 1 ? "months" : "month"
              }` : 'Select Months'}
              count={monthsCount}
              setCount={setMonthsCount}
              options={[1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12]}
            />
            <div className="position-relative">
              {costLoading && <FormSpinner />}
              <CostComparision labels={monthLabels} arkaData={arkaData} />
            </div>
          </Col>
          <Col xs={12} sm={12} md={6} className="w-100--ipad">
            <GraphHeader
              title="Documents Processed"
              btnText={daysCount !== null ? `Last ${daysCount} ${daysCount > 1 ? "days" : "day"}` : 'Select Days'}
              count={daysCount}
              setCount={setDaysCount}
              options={[1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15]}
            />
            <div className="position-relative">
              {docLoading && <FormSpinner />}
              <DocumentProcessed
                labels={dayLabels}
                documentData={documentData}
              />
            </div>
          </Col>
          <AnalyticsPieChart kpiList={kpiList} user_data={user_data} />
        </Row>
      </Container>
    </Container>
  );
}
