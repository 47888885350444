export const colors = {
  blank: "rgba(0,0,0,0)",
  black: "#000",
  white: "#FFF",
  blue: "#2A3885",
  blue4: "#2249B3",
  blue5: "#0B1B4D",
  primary: "#03256C",
  secondary: "#2541B2",
  grey: "#DBDBDB",
  grey5: "#616161",
  grey4: "#464646",
  disable: "#dddddd",
  linkBlack: "#323232",

  red: "#FA4747",
  yellow: "#F6C31D",
  green: "#07EA54",
};
