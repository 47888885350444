import * as types from "../types";

export const toggleMobileSidebar = (status) => {
  return (dispatch) => {
    dispatch({ type: types.MOBILE_SIDEBAR, payload: status });
  };
};

export const toggleMainSidebar = () => {
  return (dispatch) => {
    dispatch({ type: types.MAIN_SIDEBAR });
  };
};
