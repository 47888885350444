import React from 'react';
import { useState } from 'react';

const ShowComment = ({ text, width }) => {
    const [moreText, setMoreText] = useState(true);


    return (
        <>
            {
                text ?
                    <div style={{ width: width }}  className='mx-auto'>

                        <span>
                            {

                                moreText ? text.slice(0, 50) : text

                            }
                        </span>

                        {
                            (text.length > 50) &&
                            <span className="ms-2" style={{ textDecoration: "underline", cursor: 'pointer' }} onClick={() => setMoreText(!moreText)} >
                                {moreText ? "show more" : "show less"}
                            </span>
                        }
                    </div>
                    :
                    <span style={{ width: '200px' }}> -- </span>
            }

        </>
    );
};

export default ShowComment;