import issue from "./icons/issue.svg";
import issue_a from "./icons/issue-a.svg";
import help from "./icons/help.svg";
import help_a from "./icons/help-a.svg";
import privacy from "./icons/privacy.svg";
import privacy_a from "./icons/privacy-a.svg";

const data = [
  {
    title: "Issue Management",
    path: "/issue-management",
    inactiveIcon: issue,
    activeIcon: issue_a,
  },
  {
    title: "Find Help",
    path: "/help",
    inactiveIcon: help,
    activeIcon: help_a,
  },
  {
    title: "Privacy policy",
    path: "/privacy-policy",
    inactiveIcon: privacy,
    activeIcon: privacy_a,
  },
];

export default data;
