import { Spinner } from "reactstrap";
import styled from "styled-components";
import { NotoP } from "../styles/common";
import { colors } from "../styles/theme";

const LoaderDiv = styled.div`
  position: absolute;
  width: 99%;
  height: 99%;
  background: linear-gradient(
    0deg,
    rgba(37, 65, 178, 0.75),
    rgba(37, 65, 178, 0.75)
  );
  border-radius: 5px;
`;

const Loader = styled(Spinner)`
  color: #fff !important;
  width: 100px;
  height: 100px;
  border-width: 6px;
`;

const LoaderSpan = styled.span`
  width: 100px;
  height: 100px;
  border-radius: 50%;
  border: 5px solid #2541b2;
  position: absolute;
  top: 0;
`;

export default function PreviewSpinner({ loadingText = "Loading..." }) {
  return (
    <LoaderDiv className="content_center--flex">
      <div className="position-relative text-center">
        <LoaderSpan />
        <Loader children={null} />
        <NotoP fs="18px" lh="25px" fw="600" c={colors.white} mt="10px">
          {loadingText}
        </NotoP>
      </div>
    </LoaderDiv>
  );
}
