import { useState } from "react";
import PropTypes from "prop-types";
import cls from "classnames";
import { CustomImg, ElementPos, NotoP } from "../../styles/common";
import { colors } from "../../styles/theme";
import styles from "./title-input.module.css";

import eyeOn from "../../assets/icons/eye-on.svg";
import eyeOff from "../../assets/icons/eye-off.svg";

function TitleInput(props) {
  const [eyeIcon, setEyeIcon] = useState(eyeOff);
  const {
    coverstyle,
    label,
    name,
    type,
    value,
    onChange,
    placeholder,
    disabled,
    handleEye,
    secret,
    required,
    showerr,
    valid,
    errmsg,
  } = props;

  const toggleSecret = () => {
    var x = document.getElementById(label);
    if (x.type === "password") {
      x.type = "text";
      setEyeIcon(eyeOn);
    } else {
      x.type = "password";
      setEyeIcon(eyeOff);
    }
  };

  const beforehandleEye = () => {
    if (eyeIcon === eyeOff) {
      setEyeIcon(eyeOn);
    } else {
      setEyeIcon(eyeOff);
    }
    handleEye();
  };

  return (
    <div className={coverstyle}>
      <p className={styles.label}>
        {label}
        {required && <span>*</span>}
      </p>
      <div className="position-relative">
        <input
          className={cls("form-control", styles.input)}
          id={label}
          name={name}
          type={type}
          inputMode={type}
          value={value}
          onChange={onChange}
          disabled={disabled}
          placeholder={placeholder || ""}
          {...props}
        />
        {handleEye && (
          <ElementPos b="10px" r="10px" role="button" onClick={beforehandleEye}>
            <CustomImg src={eyeIcon} w="18px" h="16px" />
          </ElementPos>
        )}
        {secret && (
          <ElementPos b="10px" r="10px" role="button" onClick={toggleSecret}>
            <CustomImg src={eyeIcon} w="18px" h="16px" />
          </ElementPos>
        )}
      </div>
      {required &&
        showerr &&
        (!value ? (
          <NotoP
            fs="10px"
            className="text-danger py-1"
          >{`${label} cannot be empty`}</NotoP>
        ) : !valid && errmsg ? (
          <NotoP fs="10px" className="text-danger py-1">
            {errmsg}
          </NotoP>
        ) : (
          ""
        ))}
    </div>
  );
}

TitleInput.propTypes = {
  label: PropTypes.string,
  name: PropTypes.string,
  type: PropTypes.string,
  value: PropTypes.any,
  onChange: PropTypes.func,
  placeholder: PropTypes.string,
  coverstyle: PropTypes.string,
  disabled: PropTypes.bool,
  handleEye: PropTypes.func,
  secret: PropTypes.bool,
  required: PropTypes.bool,
  showerr: PropTypes.bool,
  valid: PropTypes.bool,
  errmsg: PropTypes.string,
};

TitleInput.defaultProps = {
  type: "text",
  coverstyle: "mt-3 position-relative",
  secret: false,
  required: true,
  valid: false,
};

export default TitleInput;
