import PropTypes from "prop-types";
import { MontP } from "../styles/common";
import { colors } from "../styles/theme";

export default function NotFoundMsg({ coverstyle, msg, color }) {
  return (
    <div className={coverstyle}>
      <MontP fs="16px" lh="22px" b c={color}>
        {msg}
      </MontP>
    </div>
  );
}

NotFoundMsg.propTypes = {
  msg: PropTypes.string,
  color: PropTypes.string,
  coverstyle: PropTypes.string,
};

NotFoundMsg.defaultProps = {
  msg: "No Data Found. Update your search criteria.",
  color: colors.primary,
  coverstyle: "my-5 text-center",
};
