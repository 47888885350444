import { useState, useRef, useEffect } from "react";
import { Row, Col, Table } from "reactstrap";
import classnames from "classnames";
import DatePicker from "react-datepicker";
import { useSelector } from "react-redux";
import moment from "moment";

import TitleInput from "../../../components/TitleInput";
import { colors } from "../../../styles/theme";
import { CustomImg, MontP, NotoP } from "../../../styles/common";
import styles from "../Document.module.css";
import DocPicker from "../../../components/DocPicker";
import MailTo from "../../../components/MailTo";
import BtnSpinner from "../../../components/BtnSpinner";
import { API, PATH } from "../../../services/Axios";
import { notify } from "../../../utils";
import NotFoundMsg from "../../../components/NotFoundMsg";

import folderW from "../../../assets/icons/folder-plus-w.svg";
// import findW from "../../../assets/icons/find-file-w.svg";
import searchW from "../../../assets/icons/search-w.svg";

export default function BatchProcess() {
  const { user_data } = useSelector((state) => ({
    user_data: state.auth.user_data,
  }));

  const docRef = useRef();

  const [loading, setLoading] = useState(false);

  const minDate = new Date(moment().subtract(60, "days"));
  const startOfMonth = moment().startOf("month").format("YYYY-MM-DD hh:mm");
  const endOfMonth = moment().endOf("month").format("YYYY-MM-DD hh:mm");

  const [startDate, setStartDate] = useState(moment(startOfMonth).toDate());
  const [endDate, setEndDate] = useState(moment(endOfMonth).toDate());
  const [summaryData, setSummaryData] = useState([]);

  const [batchLoading, setBatchLoading] = useState(false);
  const [batchId, setBatchId] = useState("");
  const [uploadLoading, setUploadLoading] = useState(false);
  const [uploadResMsg, setUploadResMsg] = useState(null);
  const [uploadStatus, setUploadStatus] = useState("none");
  const [doc, setDoc] = useState(null);

  const handleSearch = async () => {
    const data = {
      CustId: user_data?.customerInfo?.custId,
      FromDate: moment(startDate).format("YYYY-MM-DD"),
      ToDate: moment(endDate).format("YYYY-MM-DD"),
    };
    // console.log(data);
    setLoading(true);
    try {
      const result = await API.post(PATH.batchSummary, data, user_data.token);
      console.log(result);
      setSummaryData(result);
      setLoading(false);
    } catch (err) {
      console.log(err);
      setLoading(false);
    }
  };

  // useEffect(() => {
  //   handleSearch();
  // }, []);

  const handleGetBatchId = async () => {
    setBatchLoading(true);

    let data = {
      LoggedInUserId: user_data?.userInfo?.userEmail,
      CustId: user_data?.customerInfo?.custId,
    };
    try {
      const result = await API.post(
        PATH.generateBatchId,
        data,
        user_data.token
      );
      // console.log(result);
      setBatchId(result);
      setBatchLoading(false);
    } catch (err) {
      setBatchLoading(false);
      console.log(err);
    }
  };

  const uploadBatchDoc = async () => {
    setUploadResMsg(null);
    setUploadStatus("none");
    if (!doc && !batchId) return;

    setUploadLoading(true);
    let fileFormData = new FormData();
    fileFormData.append("LoggedInUserId", user_data?.userInfo?.userEmail);
    fileFormData.append("CustId", user_data?.customerInfo?.custId);
    fileFormData.append("BatchName", batchId);
    fileFormData.append("FileName", doc.name);
    fileFormData.append("FileObject", doc);

    try {
      const result = await API.postFileKey(
        PATH.uploadBatchDoc,
        fileFormData,
        user_data.token
      );
      console.log(result);
      if (result) {
        setDoc(null);
        setUploadResMsg("Uploaded successfully.");
        setUploadStatus("success");
        // notify(
        //   "Your Batch File was sucessfully Uploaded and you will receive an email shortly with details.",
        //   true
        // );
      } else {
        setUploadResMsg("Upload failed.");
        setUploadStatus("fail");
      }
      setUploadLoading(false);
    } catch (err) {
      console.log(err);
      setUploadLoading(false);
    }
  };

  const handleDocPicker = (e) => {
    const { name, files } = e.target;
    // console.log(name);
    
    if (files.length > 0) {
      // console.log(files[0]?.type);
      setDoc(files[0]);
    }
  };

  // const DatePickerBtn = (callBack) => {
  //   const [startDate, setStartDate] = useState(new Date());
  //   const CustomInput = forwardRef(({ value, onClick }, ref) => (
  //     <button className={styles.datebtn} onClick={onClick} ref={ref}>
  //       {value ? value : "DD/MM/YY"}
  //     </button>
  //   ));
  //   return (
  //     <DatePicker
  //       selected={startDate}
  //       onChange={(date) => setStartDate(date)}
  //       customInput={<CustomInput />}
  //     />
  //   );
  // };

  const paintColor = (status) => {
    if (!status) {
      return colors.primary;
    }

    switch (status.toLowerCase()) {
      case "new":
        return colors.red;
      case "in progress":
      case "processing":
        return colors.yellow;
      case "completed":
        return colors.green;

      default:
        return colors.yellow;
    }
  };

  return (
    <Row className="mt-5 ps-4 pe-2">
      <Col xs={12} sm={12} md={4}>
        <TitleInput
          label="New Batch"
          name="newbatch"
          value={batchId}
          placeholder="Generate New Batch ID"
          disabled
        />
        <button
          disabled={batchLoading}
          onClick={handleGetBatchId}
          className={classnames(styles.smallbtn, "mt-3")}
        >
          {batchLoading ? (
            <BtnSpinner />
          ) : (
            <CustomImg src={folderW} w="18px" h="18px" mr="5px" />
          )}
          <NotoP fs="16px" lh="24px" fw="400" c={colors.white}>
            Generate
          </NotoP>
        </button>
        <DocPicker
          title="Batch File (.zip)"
          setFile={setDoc}
          inputRef={docRef}
          inputName="doc"
          handleChange={handleDocPicker}
          filename={doc?.name}
          loading={uploadLoading}
          disabled={!batchId || !doc}
          handleUpload={uploadBatchDoc}
          showStatus={false}
          accept='.zip'
        />
        {uploadResMsg && (
          <NotoP
            fs="14px"
            lh="20px"
            fw="500"
            c={
              uploadStatus === "success"
                ? colors.green
                : uploadStatus === "fail"
                ? colors.red
                : colors.black
            }
            mt="10px"
          >
            {uploadResMsg}
          </NotoP>
        )}
        {/* // BATCH ID */}
        {/* <TitleInput
          label="Batch ID"
          name="16266146"
          placeholder="Generate New Batch ID"
        />
        <button className={classnames(styles.smallbtn, "my-3")}>
          <CustomImg src={findW} w="18px" h="18px" mr="5px" />
          <NotoP fs="16px" lh="24px" fw="400" c={colors.white}>
            Find
          </NotoP>
        </button>
        <div className={styles.yellowcard}>
          <div className="d-flex justify-content-between">
            <div className="">
              <NotoP fs="15px" lh="21px" fw="600" c={colors.blue5}>
                Batch ID: 16266146
              </NotoP>
              <NotoP fs="11px" lh="15px" fw="400" c={colors.blue5}>
                Uploaded 47 min ago
              </NotoP>
            </div>
            <NotoP fs="11px" lh="15px" fw="400" c={colors.blue5}>
              14.6 MB
            </NotoP>
          </div>
          <div className="d-flex justify-content-between h-100 mt-auto">
            <NotoP fs="12px" lh="15px" fw="400" c={colors.blue5}>
              Estimated time 3 mins
            </NotoP>
            <NotoP fs="12px" lh="15px" fw="400" c={colors.blue5}>
              Processing...
            </NotoP>
          </div>
        </div> */}
      </Col>
      <Col xs={12} sm={12} md={8}>
        <div className="d-flex align-items-end justify-content-between px-md-5 flex-wrap">
          <div className="mt-2 mt-md-0">
            <NotoP fs="12px" lh="19px" fw="600" c={colors.primary}>
              From Date
            </NotoP>
            <DatePicker
              selected={startDate}
              onChange={(date) => setStartDate(date)}
              minDate={minDate}
              maxDate={endDate}
              customInput={
                <button className={styles.datebtn}>
                  {moment(startDate).format("MM/DD/YYYY")}
                </button>
              }
            />
          </div>
          <div className="mt-2 mt-md-0">
            <NotoP fs="12px" lh="19px" fw="600" c={colors.primary}>
              To Date
            </NotoP>
            <DatePicker
              selected={endDate}
              onChange={(date) => setEndDate(date)}
              minDate={startDate}
              //maxDate={endDate}
              customInput={
                <button className={styles.datebtn}>
                  {moment(endDate).format("MM/DD/YYYY")}
                </button>
              }
            />
          </div>
          <button
            disabled={loading}
            onClick={handleSearch}
            className={classnames(styles.searchbtn, "mt-2 mt-md-0")}
          >
            {loading ? (
              <BtnSpinner />
            ) : (
              <CustomImg src={searchW} w="20px" h="20px" mr="10px" />
            )}
            Search
          </button>
        </div>
        <div className="card bg-transparent border-0 my-2">
          <MontP fs="11px" lh="15px" fw="500" c="#03256c">
            Historically you can go back 60 days for batch Summary details. Send
            an email to{" "}
            <MailTo email="arka.admin@ideafuse.ai" subject="" body="">
              <span>arka.admin@ideafuse.ai</span>
            </MailTo>{" "}
            for information older than 60 days.
          </MontP>
        </div>
        {/* TABLES */}
        {summaryData.length > 0 ? (
          <Table borderless>
            <thead>
              <tr>
                <th>Batch ID</th>
                <th>Creation Date</th>
                <th>Batch Status</th>
              </tr>
            </thead>
            <tbody>
              {summaryData.map((item, index) => (
                <tr
                  key={index}
                  style={{
                    backgroundColor:
                      index % 2 === 0 ? "rgba(37, 65, 178, 0.1)" : colors.white,
                  }}
                >
                  <td>{item.batchId}</td>
                  <td>{moment(item.creationDate).format("MM/DD/YYYY")}</td>
                  <td style={{ color: paintColor(item.batchStatus) }}>
                    {item.batchStatus}
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
        ) : (
          <>
            {loading ? <NotFoundMsg msg="Please wait..." /> : <NotFoundMsg />}
          </>
        )}
      </Col>
    </Row>
  );
}
