import classnames from "classnames";
import PropTypes from "prop-types";
import { CustomImg, MontP, NotoP } from "../styles/common";
import { colors } from "../styles/theme";
import BtnSpinner from "./BtnSpinner";
import styles from "./Components.module.css";

import folderPlus from "../assets/icons/folder-plus.svg";
import uploadW from "../assets/icons/upload-w.svg";
import helpD from "../assets/icons/help-circle-d.svg";

function DocPicker({
  title,
  inputRef,
  handleChange,
  accept,
  inputName,
  filename,
  setFile,
  coverstyle,
  dropheight,
  loading,
  disabled,
  handleUpload,
  showStatus,
  btnCenter,
  dotColor,
}) {
  return (
    <div className={coverstyle}>
      <input
        style={{ display: "none" }}
        type="file"
        accept={accept}
        onChange={handleChange}
        name={inputName}
        ref={inputRef}
      />
      <div className="d-flex align-items-center justify-content-between">
        <div className="d-flex align-items-center">
          <NotoP
            fs="16px"
            lh="20px"
            fw="600"
            mfs="14px"
            mlh="18px"
            c={colors.primary}
            mr="20px"
          >
            {title}
          </NotoP>
          <CustomImg src={helpD} w="20px" h="20px" />
        </div>
        {showStatus && (
          <div className="d-flex align-items-center">
            <p className={styles.docpicker_status}>Status</p>
            <div
              className={styles.docpicker_statuscircle}
              style={{
                backgroundColor: dotColor,
              }}
            />
          </div>
        )}
      </div>
      <div
        onDragOver={(e) => {
          e.preventDefault();
        }}
        onDragLeave={(e) => {
          e.preventDefault();
        }}
        onDrop={(e) => {
          e.preventDefault();
          const droppedFile = Array.from(e.dataTransfer.files);
          // console.log(droppedFile[0]);
          if (droppedFile.length > 0) {
            setFile(droppedFile[0]);
          }
        }}
      >
        <div
          role="button"
          onClick={() => inputRef.current.click()}
          onKeyPress={() => inputRef.current.click()}
          style={{ height: dropheight }}
          className={classnames(styles.docpicker, "content_center--flex mt-2")}
        >
          {filename ? (
            <MontP fs="11px" lh="15px" b c={colors.primary}>
              {filename}
            </MontP>
          ) : (
            <div className="d-flex align-items-center">
              <CustomImg src={folderPlus} w="20px" h="20px" mr="5px" />
              <MontP fs="11px" lh="15px" b c={colors.primary}>
                Click to upload or Drag & Drop
              </MontP>
            </div>
          )}
        </div>
      </div>
      <button
        disabled={disabled || loading}
        onClick={handleUpload}
        className={classnames(styles.docpicker_smallbtn, "mt-3")}
      >
        {loading ? (
          <BtnSpinner />
        ) : (
          <CustomImg src={uploadW} w="18px" h="18px" mr="5px" />
        )}
        <NotoP fs="16px" lh="24px" fw="400" c={colors.white}>
          Upload
        </NotoP>
      </button>
    </div>
  );
}

DocPicker.propTypes = {
  inputRef: PropTypes.element,
  inputName: PropTypes.string,
  handleChange: PropTypes.func,
  setFile: PropTypes.func,
  accept: PropTypes.string,
  title: PropTypes.string,
  coverstyle: PropTypes.string,
  dropheight: PropTypes.string,
  loading: PropTypes.bool,
  disabled: PropTypes.bool,
  handleUpload: PropTypes.func,
  showStatus: PropTypes.bool,
  btnCenter: PropTypes.bool,
  dotColor: PropTypes.string,
};

DocPicker.defaultProps = {
  accept: "image/jpeg,image/jpg,image/png,image/tiff,.tif",
  inputName: "doc",
  coverstyle: "mt-3",
  dropheight: "50px",
  showStatus: true,
  dotColor: colors.disable,
};

export default DocPicker;
