import { Link, useLocation } from "react-router-dom";
import styled, { css } from "styled-components";
import { colors } from "../../../styles/theme";

import { NotoP } from "../../../styles/common";
import { isPathDisabled } from "../../../utils";
import { useSelector } from 'react-redux';

const SidebarLink = styled(Link)`
  display: flex;
  justify-content: space-between;
  align-items: center;
  /* color: ${colors.secondary}; */
  height: 30px;
  list-style: none;
  text-decoration: none;
  padding-left: 2vw;
  margin-bottom: 1px;

  color: ${({ disabledpath }) =>
    disabledpath ? colors.grey5 : colors.secondary};

  .sidebar--menuicon {
    width: 18px;
    height: 18px;
    margin-right: 10px;
    /* background-size: auto; */
    background-repeat: no-repeat;
    background-image: ${({ inactiveicon }) => `url(${inactiveicon})`};
    background-size: ${({ iconwidth, iconheight }) =>
      `${iconwidth || "18px"} ${iconheight || "18px"}`};
  }

  @media only screen and (max-width: 768px) {
    padding-left: 5vw;
  }

  ${({ disabledpath }) =>
    disabledpath &&
    css`
      background-color: ${colors.disable};
      cursor: not-allowed;
      pointer-events: none;
    `}
`;

const SidebarBottomMenu = (props) => {
  const location = useLocation();
  const { user_data } = useSelector((state) => ({
    user_data: state.auth.user_data,
  }));

  const { item } = props;

  // console.log(">>>>>>", window.location.pathname);
  // console.log("Location", location);

  const active =
    location.pathname === item.path || location.pathname === item.rootpath;

  return (
    <SidebarLink
      to={
        isPathDisabled(item.path, user_data?.lstUserRoleAccess)
          ? "#"
          : item.path
      }
      active={active ? "yes" : "no"}
      activeicon={item.activeIcon}
      inactiveicon={item.inactiveIcon}
      iconwidth={item.iconWidth}
      iconheight={item.iconHeight}
      disabledpath={isPathDisabled(item.path, user_data?.lstUserRoleAccess)}
    >
      <div className="d-flex align-items-center">
        <div className="sidebar--menuicon" />
        <NotoP fs="12px" lh="16px" tfs="12px" tlh="16px" ls=".1px" c="inherit">
          {item.title}
        </NotoP>
      </div>
    </SidebarLink>
  );
};

SidebarBottomMenu.propTypes = {};

export default SidebarBottomMenu;
