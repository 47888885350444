import axios from "axios";

axios.defaults.headers.post["Content-Type"] = "application/json";

const PATH = {
  addProspect: "https://arkacustgenapi.azurewebsites.net/AddCustomerProspects",
  userSignup: "https://arkacustloginapi.azurewebsites.net/cusregis",
  userSignin: "https://arkacustloginapi.azurewebsites.net/usersignin",
  preSignin: "https://arkacustgenapi.azurewebsites.net/PreLogin",
  forgotPassword:
    "https://arkacustusermgmtapi.azurewebsites.net/ForgotPasswordUpdate",
  createIssue: "https://arkacustgenapi.azurewebsites.net/AddCustomerIssues",
  getCustomerList: "https://arkacustgenapi.azurewebsites.net/GetCustomerList",
  getStatuses: "https://arkacustgenapi.azurewebsites.net/GetStatuses",
  issueList: "https://arkacustgenapi.azurewebsites.net/GetCustomerIssuesList",
  issueUpdate:
    "https://arkacustgenapi.azurewebsites.net/AddCustomerIssueUpdates",
  arkaInterface: "https://arkaapi-dm.azurewebsites.net/api/arka",
  adaptionSummary:
    "https://arkacustdocuploadapi.azurewebsites.net/GetAdaptionSummary",
  documentAdaption: "https://arkacustdocuploadapi.azurewebsites.net/UploadFile",
  trainedDocuments:
    "https://arkacustdocuploadapi.azurewebsites.net/GetTrainedDocuments",
  trainedDocumentImage:
    "https://arkacustdocuploadapi.azurewebsites.net/GetTrainedDocumentImage",
  kpiAdaption:
    "https://arkacustmgmtanalyticsapi.azurewebsites.net/GetDocAdaptionAnalytics",
  updateKpiAdaption:
    "https://arkacustmgmtanalyticsapi.azurewebsites.net/UpdateDocAdaptionAnalytics",
  documentThresholds:
    "https://arkacustmgmtanalyticsapi.azurewebsites.net/GetDocTypeThresholds",
  updateThresholds:
    "https://arkacustmgmtanalyticsapi.azurewebsites.net/UpdateDocTypeThresholds",
  generateBatchId:
    "https://arkacustdocuploadapi.azurewebsites.net/GenerateBatchNumber",
  uploadBatchDoc: "https://arkacustdocuploadapi.azurewebsites.net/UploadBatch",
  searchDocuments:
    "https://arkacustdocuploadapi.azurewebsites.net/SearchDocuments",
  batchSummary:
    "https://arkacustdocuploadapi.azurewebsites.net/GetBatchSummary",
  invoiceDetails:
    "https://arkacustloginapi.azurewebsites.net/GetInvoiceDetails",
  helpDocument:
    "https://arkacustdocuploadapi.azurewebsites.net/GetHelpDocument",
  usersList: "https://arkacustusermgmtapi.azurewebsites.net/GetUsersLists",
  userType: "https://arkacustusermgmtapi.azurewebsites.net/GetUserRoles",
  newUser: "https://arkacustusermgmtapi.azurewebsites.net/AddNewUser",
  AcDeUser:
    "https://arkacustusermgmtapi.azurewebsites.net/ActivateDeactivateUser",
  updateCustomerProfile:
    "https://arkacustloginapi.azurewebsites.net/UpdateCustomerProfile",
  getCustomerContacts:
    "https://arkacustloginapi.azurewebsites.net/GetCustomerContacts",
  addUpdateUserContacts:
    "https://arkacustloginapi.azurewebsites.net/InsertUpdateUserContacts",
  deleteUserContacts:
    "https://arkacustloginapi.azurewebsites.net/DeleteUserContacts",
  getCustomerAddressTypes:
    "https://arkacustgenapi.azurewebsites.net/GetCustomerAddressTypes",
  getCustomerContactAddress:
    "https://arkacustloginapi.azurewebsites.net/GetCustomerContactAddress",
  addUpdateUserContactAddress:
    "https://arkacustloginapi.azurewebsites.net/InsertUpdateUserContactAddress",
  deleteUserContactAddress:
    "https://arkacustloginapi.azurewebsites.net/DeleteUserContactAddress",
  updateUserPassword:
    "https://arkacustusermgmtapi.azurewebsites.net/ResetUserPassword",
  mainSummary:
    "https://arkacustmgmtanalyticsapi.azurewebsites.net/GetMainSummary",
  monthlySummary:
    "https://arkacustmgmtanalyticsapi.azurewebsites.net/GetMonthlySummary",
  daywiseSummary:
    "https://arkacustmgmtanalyticsapi.azurewebsites.net/GetDayWiseSummary",
  integSettings:
    "https://arkacustgenapi.azurewebsites.net/GetIntegrationSettings",

  assetDashboard:
    "https://arkacustusermgmtapi.azurewebsites.net/GetCustomerAssetApprovalDashboard",
  getCustomerAsset:
    "https://arkacustusermgmtapi.azurewebsites.net/GetCustomerAsset",
  addUpdateCustomerAsset:
    "https://arkacustusermgmtapi.azurewebsites.net/AddUpdateCustomerAsset",
  deleteCustomerAsset:
    "https://arkacustusermgmtapi.azurewebsites.net/DeleteCustomerAsset",
    getApproverList:
    "https://arkacustusermgmtapi.azurewebsites.net/GetApproverList",
  getCustomerAssetApproval:
    "https://arkacustusermgmtapi.azurewebsites.net/GetCustomerAssetApproval",
  addUpdateCustomerAssetApproval:
    "https://arkacustusermgmtapi.azurewebsites.net/AddUpdateCustomerAssetApproval",
  deleteCustomerAssetApproval:
    "https://arkacustusermgmtapi.azurewebsites.net/DeleteCustomerAssetApproval",
    getCustomerAssetApprovalReport:
    "https://arkacustusermgmtapi.azurewebsites.net/GetCustomerAssetApprovalReport",

  CustomerEmailSetting:
    "https://arkacustusermgmtapi.azurewebsites.net/GetCustomerEmailSetting",
  AddUpdateCustomerEmailSetting:
    "https://arkacustusermgmtapi.azurewebsites.net/AddUpdateCustomerEmailSetting",
  updateIntegSettings:
    "https://arkacustgenapi.azurewebsites.net/AddUpdateIntegrationSettings",
  kpiList:
    "https://arkacustmgmtanalyticsapi.azurewebsites.net/GetDocAdaptionAnalyticsEnabledKpis",
  kpiSummary:
    "https://arkacustmgmtanalyticsapi.azurewebsites.net/GetDocAdaptionAnalyticsKpiSummary",
  userRoles:
    "https://arkacustusermgmtapi.azurewebsites.net/GetUserRoleManagement",
  updateUserRoles:
    "https://arkacustusermgmtapi.azurewebsites.net/UpdateUserRoleManagement",
  docImage:
    "https://arkacustdocuploadapi.azurewebsites.net/GetSearchDocumentImage",

  getSecurityKey: "https://arkacustgenapi.azurewebsites.net/GetSecurityKey",
  updateSecurityKey: "https://arkacustgenapi.azurewebsites.net//UpdateSecurityKey",
  getCustomizedSettings: "https://arkacustgenapi.azurewebsites.net/GetCustomizedSettings",
  updateCustomizedSettings: "https://arkacustgenapi.azurewebsites.net/UpdateCustomizedSettings",
  getCountryCodes: "https://arkacustloginapi.azurewebsites.net/GetCountryCodes",
  getApprovalStatus: "https://arkacustusermgmtapi.azurewebsites.net/GetApprovalStatus",
  // test: (id) => `address/${id}`,
};

const API = {
  get: (url, token) => {
    axios.defaults.headers.common["Authorization"] = token
      ? "Bearer " + token
      : null;

    console.log(url);
    return new Promise((resolve, reject) => {
      return axios
        .get(url)
        .then((res) => {
          // console.log(res.data);
          resolve(res.data);
        })
        .catch((error) => {
          console.log(error.response);
          reject(error?.response);
        });
    });
  },

  post: (url, data, token) => {
    axios.defaults.headers.common["Authorization"] = token
      ? "Bearer " + token
      : null;

    console.log(url);
    return new Promise((resolve, reject) => {
      return axios
        .post(url, data)
        .then((res) => {
          // console.log(res.data);
          resolve(res.data);
        })
        .catch((error) => {
          console.log(error.response);
          reject(error?.response);
          // return; // TODO  Comment for PROD
          if (error?.response?.status === 401) {
            if (
              process.env.NODE_ENV === "development" ||
              window.location.origin === "https://ideafuse-dev.netlify.app" ||
              window.location.origin === "http://localhost:3000"
            ) {
              window.location.replace(`${window.location.origin}/login`);
            } else {
              window.location.replace("https://ideafuse.ai/login");
            }
          }
        });
    });
  },

  postAuth: (url, data, token) => {
    axios.defaults.headers.common["Authorization"] = token
      ? "Bearer " + token
      : null;

    console.log(url);
    return new Promise((resolve, reject) => {
      return axios
        .post(url, data)
        .then((res) => {
          // console.log(res.data);
          resolve(res.data);
        })
        .catch((error) => {
          console.log(error.response);
          reject(error?.response);
        });
    });
  },

  postFileKey: (url, data, token) => {
    axios.defaults.headers.common["Authorization"] = token
      ? "Bearer " + token
      : null;
    axios.defaults.headers.post["Content-Type"] = "multipart/form-data";
    axios.defaults.headers.post["ARKA-Security-Key"] = "Test1234";

    console.log(url);
    return new Promise((resolve, reject) => {
      return axios
        .post(url, data, { withCredentials: true })
        .then((res) => {
          // console.log(res.data);
          resolve(res.data);
        })
        .catch((error) => {
          console.log(error.response);
          reject(error?.response);
          if (error?.response?.status === 401) {
            if (
              process.env.NODE_ENV === "development" ||
              window.location.origin === "https://ideafuse-dev.netlify.app"
            ) {
              window.location.replace(`${window.location.origin}/login`);
            } else {
              window.location.replace("https://ideafuse.ai/login");
            }
          }
        });
    });
  },

  postImport: (url, formdata, token) => {
    axios.defaults.headers.common["Authorization"] = token
      ? "Bearer " + token
      : null;

    return new Promise((resolve, reject) => {
      return axios
        .post(url, formdata, {
          headers: {
            "Content-Type":
              "multipart/form-datacharset=utf-8; boundary=" +
              Math.random().toString().substr(2),
          },
        })
        .then((res) => {
          // console.log(res.data);
          return res.data;
        })
        .catch((error) => {
          console.log(error.response);
          reject(error?.response);
        });
    });
  },

  put: (url, data, token) => {
    axios.defaults.headers.common["Authorization"] = token
      ? "Bearer " + token
      : null;

    console.log(url);
    return new Promise((resolve, reject) => {
      return axios
        .put(url, data)
        .then((res) => {
          // console.log(res.data);
          return res.data;
        })
        .catch((error) => {
          console.log(error.response);
          reject(error?.response);
        });
    });
  },

  delete: (url, token) => {
    axios.defaults.headers.common["Authorization"] = token
      ? "Bearer " + token
      : null;

    console.log(url);
    return new Promise((resolve, reject) => {
      return axios
        .delete(url)
        .then((res) => {
          // console.log(res.data);
          resolve(res.data);
        })
        .catch((error) => {
          console.log(error.response);
          reject(error?.response);
        });
    });
  },

  patch: (url, data, token) => {
    axios.defaults.headers.common["Authorization"] = token
      ? "Bearer " + token
      : null;

    console.log(url);
    return new Promise((resolve, reject) => {
      return axios
        .patch(url, data)
        .then((res) => {
          // console.log(res.data);
          resolve(res.data);
        })
        .catch((error) => {
          console.log(error.response);
          reject(error?.response);
        });
    });
  },
};

export { API, PATH };
