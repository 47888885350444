import { Redirect, Route } from "react-router-dom";
import { useSelector } from "react-redux";
import CommonLayout from "../layouts/CommonLayout";
import HeaderFooterLayout from "../layouts/HeaderFooterLayout";
import HeaderFooter from "../layouts/header-footer";
import NavbarSidebarLayout from "../layouts/NavbarSidebarLayout";
import SignIn from "../pages/auth/SignIn";
import SignUp from "../pages/auth/SignUp";
import RegisteredEmail from "../pages/auth/RegisteredEmail";
import PrivacyPolicy from "../pages/PrivacyPolicy";
import Home from "../pages/Home";
import NotFound from "../pages/NotFound";
import NewsNRelease from "../pages/NewsNRelease";

// Old
// import RequestDemo from "../pages/RequestDemo";
// import AboutUs from "../pages/AboutUs";
// import IdeaFuse from "../pages/IdeaFuse";
// New
import RequestDemo from "../pages/request-demo";
import AboutUs from "../pages/about-us";
import Landing from "../pages/landing";
import Login from "../pages/login";
import Register from "../pages/register";

// NOTE  /registered-email => /login, /signup => /register

const PrivateRoute = ({ component: Component, ...rest }) => {
  const { user_data } = useSelector((state) => ({
    user_data: state.auth.user_data,
  }));

  return (
    <Route
      {...rest}
      render={(props) =>
        user_data ? <Component {...props} /> : <Redirect to="/login" />
      }
    />
  );
};

const PublicRoute = ({ component: Component, restricted, ...rest }) => {
  return (
    <Route
      {...rest}
      render={(props) =>
        restricted ? <Redirect to="/login" /> : <Component {...props} />
      }
    />
  );
};

const routes = [
  // {
  //   path: "/",
  //   component: () => <PublicRoute to="/home" component={IdeaFuse} />,
  //   exact: true,
  //   layout: HeaderFooterLayout,
  // },
  {
    path: "/",
    component: () => <PublicRoute to="/home" component={Landing} />,
    exact: true,
    layout: HeaderFooter,
  },
  {
    path: "/privacy-policy",
    component: () => (
      <PublicRoute to="/privacy-policy" component={PrivacyPolicy} />
    ),
    exact: true,
    layout: HeaderFooter,
  },
  {
    path: "/news-and-releases",
    component: () => (
      <PublicRoute to="/news-and-releases" component={NewsNRelease} />
    ),
    exact: true,
    layout: HeaderFooter,
  },
  // {
  //   path: "/signup",
  //   component: () => <PublicRoute to="/signup" component={SignUp} />,
  //   exact: true,
  //   layout: HeaderFooterLayout,
  // },
  {
    path: "/signin/:email",
    component: () => <PublicRoute to="/signin/:email" component={SignIn} />,
    exact: true,
    // layout: HeaderFooterLayout,
    // layout: HeaderFooter,
    layout: CommonLayout,
  },
  // {
  //   path: "/registered-email",
  //   component: () => (
  //     <PublicRoute to="/registered-email" component={RegisteredEmail} />
  //   ),
  //   exact: true,
  //   layout: HeaderFooterLayout,
  // },
  // {
  //   path: "/request-demo",
  //   component: () => <PublicRoute to="/request-demo" component={RequestDemo} />,
  //   exact: true,
  //   layout: HeaderFooterLayout,
  // },
  // {
  //   path: "/about-us",
  //   component: () => <PublicRoute to="/about-us" component={AboutUs} />,
  //   exact: true,
  //   layout: HeaderFooterLayout,
  // },
  {
    path: "/request-demo",
    component: () => <PublicRoute to="/request-demo" component={RequestDemo} />,
    exact: true,
    layout: HeaderFooter,
  },
  {
    path: "/about-us",
    component: () => <PublicRoute to="/about-us" component={AboutUs} />,
    exact: true,
    layout: HeaderFooter,
  },
  {
    path: "/login",
    component: () => <PublicRoute to="/login" component={Login} />,
    exact: true,
    layout: CommonLayout,
  },
  {
    path: "/register",
    component: () => <PublicRoute to="/register" component={Register} />,
    exact: true,
    layout: CommonLayout,
  },

  // AUTH SCREEN
  {
    path: "/home",
    component: () => <PrivateRoute to="/home" component={Home} />,
    exact: true,
    layout: NavbarSidebarLayout,
  },

  // NOT FOUND
  {
    component: () => <PrivateRoute component={NotFound} />,
    layout: CommonLayout,
  },
];

export default routes;
