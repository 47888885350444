import { useState, useEffect } from "react";
import { Row, Col, Container } from "reactstrap";
import classnames from "classnames";
import { useSelector } from "react-redux";

import styles from "../Adaption.module.css";
import TrainedCollapseable from "../../../components/TrainedCollapseable";
import { MontP } from "../../../styles/common";
import { colors } from "../../../styles/theme";
import { API, PATH } from "../../../services/Axios";
import PreviewSpinner from "../../../components/PreviewSpinner";

// const [data, setData] = useState([
//   { id: 1, title: "Document Type", subdata: [0, 1, 2, 3, 4, 5] },
//   { id: 2, title: "Document Type", subdata: [0, 1, 2, 3] },
//   { id: 3, title: "Document Type", subdata: [0, 1, 2, 3, 4] },
//   { id: 4, title: "Document Type", subdata: [0, 1, 2] },
//   { id: 5, title: "Document Type", subdata: [0, 1, 2, 3] },
//   { id: 6, title: "Document Type", subdata: [0, 1, 2, 3, 4] },
// ])

export default function TrainedDocuments() {
  const { user_data } = useSelector((state) => ({
    user_data: state.auth.user_data,
  }));

  const [loading, setLoading] = useState("false");
  const [trainedDocs, setTrainedDocs] = useState([]);
  const [trainedImage, setTrainedImage] = useState(null);

  const getTrainedDocuments = async () => {
    setLoading(true);

    let data = {
      LoggedInUserId: user_data?.userInfo?.userEmail,
      CustId: user_data?.customerInfo?.custId,
    };
    try {
      const result = await API.post(
        PATH.trainedDocuments,
        data,
        user_data.token
      );
      // console.log(result);
      if (result) {
        setTrainedDocs(result.documentTypes);
      }
      setLoading(false);
    } catch (err) {
      setLoading(false);
      console.log(err);
    }
  };

  useEffect(() => {
    getTrainedDocuments();
  }, []);

  const handleDocumentImage = async (cbData) => {
    // console.log(">>>>", cbData);
    setLoading(true);
    let data = {
      LoggedInUserId: user_data?.userInfo?.userEmail,
      CustId: user_data?.customerInfo?.custId,
      DocumentType: cbData.documentType,
      FileName: cbData.fileName,
    };
    try {
      const result = await API.post(
        PATH.trainedDocumentImage,
        data,
        user_data.token
      );
      // console.log(result);
      setTrainedImage(result);
      setLoading(false);
    } catch (err) {
      setLoading(false);
      console.log(err);
    }
  };

  return (
    <Container fluid>
      <Row>
        <Col md={5}>
          <div className={classnames(styles.cardcover, "mt-5 p-1")}>
            {trainedDocs?.length > 0 &&
              trainedDocs.map((item, index) => (
                <TrainedCollapseable
                  key={index}
                  title={item.documentType}
                  data={item.documents}
                  bgColor={
                    index % 2 === 0 ? colors.white : "rgba(37, 65, 178, 0.1)"
                  }
                  cbImageLink={handleDocumentImage}
                />
              ))}
          </div>
        </Col>
        <Col md={7}>
          <MontP
            fs="22px"
            lh="28px"
            b
            c={colors.blue5}
            className="text-center mt-1"
          >
            Document View
          </MontP>
          <div className={classnames(styles.cardcover, "p-1 mt-3")}>
            {loading && <PreviewSpinner />}
            {trainedImage && (
              <img
                src={`data:image/jpeg;base64,${trainedImage}`}
                alt="trainedImage"
                className="adaption-img-preview"
              />
            )}
          </div>
        </Col>
      </Row>
    </Container>
  );
}
