import { useState, useEffect } from "react";
import {
  Row,
  Col,
  Table,
  Container,
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Spinner,
} from "reactstrap";
import classnames from "classnames";
import DatePicker from "react-datepicker";
import { useSelector } from "react-redux";
import moment from "moment";
import exportFromJSON from "export-from-json";
import { Document, Page, pdfjs } from "react-pdf";

import { AiOutlineExport } from "react-icons/ai";
import styles from "../Document.module.css";
import { API, PATH } from "../../../services/Axios";
import NotFoundMsg from "../../../components/NotFoundMsg";
import BtnSpinner from "../../../components/BtnSpinner";
import MyModal from "../../../components/MyModal";
import { colors } from "../../../styles/theme";
import { CustomImg, ElementPos, NotoP } from "../../../styles/common";

import searchW from "../../../assets/icons/search-w.svg";
import close from "../../../assets/icons/close-menu.svg";

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

export default function SearchDocuments() {
  const { user_data } = useSelector((state) => ({
    user_data: state.auth.user_data,
  }));

  const [loading, setLoading] = useState(false);

  const startOfMonth = moment().startOf("month").format("YYYY-MM-DD hh:mm");
  const endOfMonth = moment().endOf("month").format("YYYY-MM-DD hh:mm");

  const [startDate, setStartDate] = useState(moment(startOfMonth).toDate());
  const [endDate, setEndDate] = useState(moment(endOfMonth).toDate());
  const [batchId, setBatchId] = useState("");

  const [searchResults, setSearchResults] = useState([]);
  const [open, setOpen] = useState(false);

  // DOC IMAGE/PDF
  const [docFile, setDocFile] = useState(null);
  const [docFileType, setDocFileType] = useState(null);
  const [numPages, setNumPages] = useState(null);
  const [pageNumber, setPageNumber] = useState(1);
  const [isOpen, setIsOpen] = useState(false);

  const handleDocImage = async (item) => {
    const getFieldValue = (key) => {
      const fieldObj = item.rowItem.find((x) => x.fieldName === key);
      return fieldObj?.fieldValue || "";
    };

    setDocFile(null);
    setDocFileType(null);
    setIsOpen(true);

    const data = {
      CustId: user_data?.customerInfo?.custId,
      RequestId: getFieldValue("RequestId"),
      DocumentId: getFieldValue("Document Id"),
      DocumentType: getFieldValue("Document Type"),
      PageNo: getFieldValue("PageNo"),
    };
    // console.log(">>>", data);
    setLoading(true);
    setDocFileType(data.DocumentType);
    try {
      const result = await API.post(PATH.docImage, data, user_data.token);
      // console.log("Result ", result);
      if (result) {
        setDocFile(result.imgByteArr);
      }
    } catch (err) {
      console.log(err);
    } finally {
      setLoading(false);
    }
  };

  const handleSearch = async () => {
    const data = {
      CustId: user_data?.customerInfo?.custId,
      FromDate: moment(startDate).format("YYYY-MM-DD"),
      ToDate: moment(endDate).format("YYYY-MM-DD"),
      BatchId: batchId,
    };
    // console.log(data);
    setLoading(true);
    try {
      const result = await API.post(
        PATH.searchDocuments,
        data,
        user_data.token
      );
      // console.log(result);
      setSearchResults(result);
      setLoading(false);
    } catch (err) {
      console.log(err);
      setLoading(false);
    }
  };

  const exportData = () => {
    const finalData = [];

    if (searchResults?.length > 0) {
      searchResults.forEach((item) => {
        let dataObj = {};
        item.rowItem.forEach((subItem, subIdx) => {
          const rec = { [subItem.fieldName]: subItem.fieldValue };
          dataObj = { ...dataObj, ...rec };
        });
        // console.log(dataObj);
        finalData.push(dataObj);
      });
    }

    // console.log(finalData);
    return finalData;
  };

  const handleExport = (type) => {
    const data = exportData();
    let fileName = "arka-document";
    let exportType;
    let extension = type;
    switch (type) {
      case ".csv":
        exportType = exportFromJSON.types.csv;
        break;
      case ".dat":
        exportType = exportFromJSON.types.txt;
        break;
      case ".txt":
        exportType = exportFromJSON.types.txt;
        break;
      default:
        return;
    }
    exportFromJSON({ data, fileName, exportType, extension });
  };

  const onDocumentLoadSuccess = ({ numPages }) => {
    setNumPages(numPages);
  };

  const filteredColumns = (colsArr) => {
    return colsArr.filter((x) => x.fieldName !== "RequestId");
  };

  return (
    <Container fluid>
      <MyModal isOpen={isOpen} setIsOpen={setIsOpen}>
        <div
          className={classnames(
            styles.imgviewCover,
            "custom-scrollbar content_center--flex"
          )}
        >
          {loading ? (
            <Spinner className={styles.spinner} children={null} />
          ) : (
            <>
              {docFile ? (
                <>
                  {/* {docFileType === "pdf" ? (
                    <div className="position-relative">
                      <Document
                        file={`data:application/pdf;base64,${docFile}`}
                        onLoadSuccess={onDocumentLoadSuccess}
                        onLoadError={(err) => console.log(err)}
                      >
                        <Page pageNumber={pageNumber} />
                      </Document>
                      <ElementPos b="6%" l="45%" pos="fixed">
                        <div className="d-flex align-items-center">
                          <button
                            className="btn btn-sm"
                            disabled={pageNumber === 1}
                            onClick={() => setPageNumber(pageNumber - 1)}
                          >
                            Prev
                          </button>
                          <button
                            className="btn btn-sm"
                            disabled={pageNumber === numPages}
                            onClick={() => setPageNumber(pageNumber + 1)}
                          >
                            Next
                          </button>
                        </div>
                      </ElementPos>
                    </div>
                  ) : ( */}
                    <img
                      src={`data:image/jpeg;base64,${docFile}`}
                      alt="doc pic"
                      className={styles.docImage}
                    />
                  {/* )} */}
                </>
              ) : (
                <p className={styles.notfoundText}>Document not found</p>
              )}
            </>
          )}
          <ElementPos
            onClick={() => setIsOpen(false)}
            t="15px"
            r="15px"
            //pos="fixed"
            //style={{width: '50px', height: '50px'}}
          >
            <img src={close} alt="close" className={styles.closeImage} />
          </ElementPos>
        </div>
      </MyModal>
      <Row className="mt-5">
        <Col md={12}>
          <div className="d-flex align-items-end justify-content-between flex-wrap">
            <div className="mt-2 mt-md-0">
              <NotoP fs="12px" lh="19px" fw="600" c={colors.primary}>
                From Date
              </NotoP>
              <DatePicker
                selected={startDate}
                onChange={(date) => setStartDate(date)}
                //minDate={new Date()}
                maxDate={endDate}
                customInput={
                  <button className={styles.datebtn}>
                    {moment(startDate).format("MM/DD/YYYY")}
                  </button>
                }
              />
            </div>
            <div className="mt-2 mt-md-0">
              <NotoP fs="12px" lh="19px" fw="600" c={colors.primary}>
                To Date
              </NotoP>
              <DatePicker
                selected={endDate}
                onChange={(date) => setEndDate(date)}
                minDate={startDate}
                //maxDate={endDate}
                customInput={
                  <button className={styles.datebtn}>
                    {moment(endDate).format("MM/DD/YYYY")}
                  </button>
                }
              />
            </div>
            <div className="mt-2 mt-md-0">
              <NotoP fs="12px" lh="19px" fw="600" c={colors.primary}>
                Batch ID
              </NotoP>
              <input
                className={styles.textbtn}
                placeholder="Batch ID"
                value={batchId}
                onChange={(e) => setBatchId(e.target.value)}
              ></input>
            </div>
            <button
              disabled={loading}
              onClick={handleSearch}
              className={classnames(styles.searchbtn, "mt-2 mt-md-0")}
            >
              {loading ? (
                <BtnSpinner />
              ) : (
                <CustomImg src={searchW} w="20px" h="20px" mr="10px" />
              )}
              Search
            </button>
            {/* START  EXPORT BUTTON */}
            <Dropdown isOpen={open} toggle={() => setOpen(!open)}>
              <DropdownToggle
                disabled={loading || searchResults.length === 0}
                className={classnames(styles.exportbtn, "mt-2 mt-md-0")}
              >
                <AiOutlineExport
                  size={20}
                  color={colors.white}
                  className="me-3"
                />
                Export
              </DropdownToggle>
              <DropdownMenu>
                {[".csv", ".dat", ".txt"].map((x, i) => (
                  <DropdownItem key={i} onClick={() => handleExport(x)}>
                    <NotoP fs="13px" lh="18px" b c={colors.secondary}>
                      Export in {x}
                    </NotoP>
                  </DropdownItem>
                ))}
              </DropdownMenu>
            </Dropdown>
            {/* END  EXPORT */}
          </div>
          {/* TABLES */}
          <div
            style={{ maxHeight: "60vh" }}
            className="mt-4 position-relative overflow-auto custom-scrollbar"
          >
            {searchResults.length > 0 ? (
              <Table striped borderless>
                <thead className={styles.thead}>
                  <tr>
                    {filteredColumns(searchResults[0].rowItem).map(
                      (item, index) => (
                        <th key={index} className="text-white">
                          {item.fieldName}
                        </th>
                      )
                    )}
                  </tr>
                </thead>
                <tbody>
                  {searchResults.map((item, index) => (
                    <tr
                      key={index}
                      style={{
                        backgroundColor:
                          index % 2 === 0
                            ? "rgba(37, 65, 178, 0.1)"
                            : colors.white,
                      }}
                    >
                      {filteredColumns(item.rowItem).map((val, idx) => (
                        <td key={idx}>
                          <span
                            onClick={() => idx === 0 && handleDocImage(item)}
                            className={idx === 0 && styles.linktd}
                          >
                            {val.fieldValue}
                          </span>
                        </td>
                      ))}
                    </tr>
                  ))}
                </tbody>
              </Table>
            ) : (
              <>
                {loading ? (
                  <NotFoundMsg msg="Please wait..." />
                ) : (
                  <NotFoundMsg />
                )}
              </>
            )}
          </div>
        </Col>
      </Row>
    </Container>
  );
}
