import PropTypes from "prop-types";
import { Card, Col, Row } from "reactstrap";
import classnames from "classnames";
import DatePicker from "react-datepicker";
import moment from "moment";
import "react-datepicker/dist/react-datepicker.css";
import styles from "./Components.module.css";
import { colors } from "../styles/theme";
import { CustomImg, ElementPos, MontP, NotoP } from "../styles/common";

import chev_down from "../assets/icons/chev-down.svg";
import BtnSpinner from "./BtnSpinner";

function ReportCards({
  title,
  firstText,
  firstValue,
  secondText,
  secondValue,
  thirdText,
  thirdValue,
  fourthText,
  fourthValue,
  minDate,
  startDate,
  setStartDate,
  endDate,
  setEndDate,
  monthYearPicker,
  loading,
  handleSubmit,
}) {
  return (
    <div className="position-relative">
      {/* <ElementPos t="-50px" zi="10"> */}
      <div style={{ marginTop: "-30px" }} className="d-flex flex-column flex-lg-row gap-2 mb-2">
        <div className="d-flex align-items-center">

          <NotoP fs="14px" lh="20px" mfs="12px" mlh="16px" b mr="10px">
            FROM
          </NotoP>
          <DatePicker
            selected={startDate}
            onChange={(date) => setStartDate(date)}
            minDate={minDate}
            maxDate={endDate}
            showMonthYearPicker={monthYearPicker}
            customInput={
              <button className={styles.report_curvebtn}>
                <NotoP
                  fs="14px"
                  lh="20px"
                  mfs="12px"
                  mlh="16px"
                  fw="600"
                  c={colors.white}
                >
                  {moment(startDate).format(
                    monthYearPicker ? "MMM, YYYY" : "MM/DD/YYYY"
                  )}
                </NotoP>
                <CustomImg src={chev_down} w="12px" h="8px" />
              </button>
            }
          />

          <NotoP
            fs="14px"
            lh="20px"
            mfs="12px"
            mlh="16px"
            b
            mr="10px"
            ml="20px"
          >
            TO
          </NotoP>
          <DatePicker
            selected={endDate}
            onChange={(date) => setEndDate(date)}
            minDate={startDate}
            showMonthYearPicker={monthYearPicker}
            customInput={
              <button className={styles.report_curvebtn}>
                <NotoP
                  fs="14px"
                  lh="20px"
                  mfs="12px"
                  mlh="16px"
                  fw="600"
                  c={colors.white}
                >
                  {moment(endDate).format(
                    monthYearPicker ? "MMM, YYYY" : "MM/DD/YYYY"
                  )}
                </NotoP>
                <CustomImg src={chev_down} w="12px" h="8px" />
              </button>
            }
          />

        </div>

        <button
          disabled={loading}
          onClick={handleSubmit}
          className='saveBtn w-25'
        >
          {loading && <BtnSpinner />}
          Submit
        </button>

      </div>
      {/* </ElementPos> */}

      {/* PART CARD */}
      <Card className={styles.report_card}>
        <Row>
          <Col md={3}>
            <div
              style={{ backgroundColor: colors.primary }}
              className={classnames(
                styles.report_innercard,
                "content_center--flex"
              )}
            >
              <div className="text-center">
                <NotoP
                  fs="20px"
                  lh="30px"
                  mfs="16px"
                  mlh="22px"
                  b
                  c={colors.white}
                >
                  {firstText}
                </NotoP>
                <MontP fs="36px" lh="45px" fw="800" c={colors.white}>
                  {firstValue}
                </MontP>
              </div>
            </div>
          </Col>
          <Col md={9}>
            <Row>
              <Col md={{ size: 8, offset: 4 }}>
                <div
                  className="d-flex align-items-center px-4 p-2"
                  style={{ height: "60px" }}
                >
                  <p className={styles.report_headerP}>{title || ""}</p>
                  {/* {title && <p className={styles.report_headerP}>{title}</p>}
                <button
                  className={classnames(styles.report_curvebtn, "ms-auto")}
                >
                  <NotoP fs="14px" lh="20px" fw="600" c={colors.white}>
                    June, 2021
                  </NotoP>
                  <CustomImg src={chev_down} w="12px" h="8px" />
                </button> */}
                </div>
              </Col>
              <Col md={4}>
                <div className="content_center--flex">
                  <div>
                    <MontP
                      fs="18px"
                      lh="25px"
                      mfs="15px"
                      mlh="20px"
                      b
                      c={colors.primary}
                      className="px-3"
                    >
                      {secondText}
                    </MontP>
                    <div className={styles.report_card_valueDiv}>
                      <p className={styles.report_card_valueP}>{secondValue}</p>
                    </div>
                  </div>
                </div>
              </Col>
              <Col md={4} className="position-relative">
                <div className="content_center--flex">
                  <div>
                    <MontP
                      fs="18px"
                      lh="25px"
                      mfs="15px"
                      mlh="20px"
                      b
                      c={colors.primary}
                      className="px-3"
                    >
                      {thirdText}
                    </MontP>
                    <div className={styles.report_card_valueDiv}>
                      <p className={styles.report_card_valueP}>{thirdValue}</p>
                    </div>
                  </div>
                </div>
              </Col>
              <Col md={4} className="position-relative">
                <div className="content_center--flex">
                  <div>
                    <MontP
                      fs="18px"
                      lh="25px"
                      mfs="15px"
                      mlh="20px"
                      b
                      c={colors.primary}
                      className="px-3"
                    >
                      {fourthText}
                    </MontP>
                    <div
                      className={styles.report_card_valueDiv}
                      style={{ borderRight: "none" }}
                    >
                      <p className={styles.report_card_valueP}>{fourthValue}</p>
                    </div>
                  </div>
                </div>
              </Col>
            </Row>
          </Col>
        </Row>
      </Card>
    </div>
  );
}

ReportCards.propTypes = {
  title: PropTypes.string,
  firstText: PropTypes.string,
  firstValue: PropTypes.string,
  secondText: PropTypes.string,
  secondValue: PropTypes.string,
  thirdText: PropTypes.string,
  thirdValue: PropTypes.string,
  fourthText: PropTypes.string,
  fourthValue: PropTypes.string,
  minDate: PropTypes.string,
  startDate: PropTypes.string,
  setStartDate: PropTypes.func,
  endDate: PropTypes.string,
  setEndDate: PropTypes.func,
  monthYearPicker: PropTypes.bool,
  loading: PropTypes.bool,
  handleSubmit: PropTypes.func,
};

export default ReportCards;
