import React from "react";
import { useHistory } from "react-router-dom";
import styled from "styled-components";
import { colors } from "../styles/theme";

const Div404 = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 8rem;

  p {
    font-size: 8rem;
    color: #fff;
    margin: 0 0.5rem;
  }
`;

const NotFound = () => {
  const history = useHistory();
  history.replace('/');

  return (
    <div className="container-fluid">
      <div className="row">
        <div
          className="col-md-12 vh-100 text-center"
          style={{ backgroundColor: colors.primary }}
        >
          <Div404>
            <p>4</p>
            <p>0</p>
            <p>4</p>
          </Div404>
          <button
            onClick={() => history.replace("/")}
            style={{ backgroundColor: colors.secondary }}
            className="btn btn-dark mt-4"
          >
            Back to Homepage
          </button>
        </div>
      </div>
    </div>
  );
};

export default NotFound;
