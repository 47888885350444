import React from 'react';
import { MontP, NotoP } from '../../styles/common';
import { colors } from '../../styles/theme';

function NewsNRelease() {
  return (
    <div className='ps-md-5 pe-md-3 p-5 m-4' style={{ marginLeft: 100 }}>
      <MontP
        fs='24px'
        lh='30px'
        tfs='21px'
        tlh='26px'
        mfs='18px'
        mlh='24px'
        b
        c={colors.blue5}
        mt='100px'
      >
        News and Releases
      </MontP>
      <ul className='mt-3'>
        <li>
          <NotoP
            fs='16px'
            lh='22px'
            tfs='14px'
            tlh='20px'
            mfs='12px'
            mlh='24px'
            fw='400'
            c={colors.blue5}
            mt='8px'
          >
            March 04 2022 : Ideafuse mordernizes its platform to a compelte new
            technology platform.
          </NotoP>
        </li>
        <li>
          <NotoP
            fs='16px'
            lh='22px'
            tfs='14px'
            tlh='20px'
            mfs='12px'
            mlh='24px'
            fw='400'
            c={colors.blue5}
            mt='8px'
          >
            December 01 2021 : Ideafuse adds ARKA to its poduct lineup
          </NotoP>
        </li>
        <li>
          <NotoP
            fs='16px'
            lh='22px'
            tfs='14px'
            tlh='20px'
            mfs='12px'
            mlh='24px'
            fw='400'
            c={colors.blue5}
            mt='8px'
          >
            November 09 2021 : Ideafuse launches itself in the market for
            the very first time.
          </NotoP>
        </li>
        <li>
          <NotoP
            fs='16px'
            lh='22px'
            tfs='14px'
            tlh='20px'
            mfs='12px'
            mlh='24px'
            fw='400'
            c={colors.blue5}
            mt='8px'
          >
            June 07 2021 : Ideafuse is incorporated and registers itself
            with State of Illinois , USA
          </NotoP>
        </li>
        <li>
          <NotoP
            fs='16px'
            lh='22px'
            tfs='14px'
            tlh='20px'
            mfs='12px'
            mlh='24px'
            fw='400'
            c={colors.blue5}
            mt='8px'
          >
            April 10 2021 : Core team members decide to create a company to
            deliver cost effective AI &amp; ML solutions and Services
          </NotoP>
        </li>
      </ul>
    </div>
  );
}

export default NewsNRelease;
