/* eslint-disable array-callback-return */
/* eslint-disable no-sequences */
import { store } from "react-notifications-component";
// import moment from "moment";

import routesArr from "../routes/route_names";

export const notify = (msg, success) => {
  store.addNotification({
    // title: success ? "Great!" : "Oops!",
    message: msg,
    type: success ? "success" : "danger",
    insert: "bottom",
    container: "top-right",
    animationIn: ["animated", "fadeIn"],
    animationOut: ["animated", "fadeOut"],
    dismiss: {
      duration: 3000,
      onScreen: true,
      pauseOnHover: true,
    },
  });
};

export const navbar_height = "60px";
export const navbar_sidebar_space = "2px";

export const validateEmail = (email) => {
  const regexp =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return regexp.test(String(email).toLowerCase());
};

export const validatePhNumber = (phNumber) => {
  const regexp = /^\+[0-9]?()[0-9](\s|\S)(\d[0-9]{8,16})$/;
  return regexp.test(phNumber);
};

export const toBase64 = (file) =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
  });

export const validateText = (text) => {
  const regexp = /^[a-zA-Z]+$/;
  return regexp.test(text);
};

export const validateTaxnumber = (taxId) => {
  const regexp = /^[0-9]{2}[A-Z]{5}[0-9]{4}[A-Z]{1}[1-9A-Z]{1}Z[0-9A-Z]{1}$/;
  return regexp.test(taxId);
};

export default function componentLoader(lazyComponent, attemptsLeft) {
  return new Promise((resolve, reject) => {
    lazyComponent()
      .then(resolve)
      .catch((error) => {
        // let us retry after 1500 ms
        setTimeout(() => {
          if (attemptsLeft === 1) {
            reject(error);
            return;
          }
          componentLoader(lazyComponent, attemptsLeft - 1).then(
            resolve,
            reject
          );
        }, 1500);
      });
  });
}

export const getQueryParams = () =>
  window.location.search
    .replace("?", "")
    .split("&")
    .reduce(
      (r, e) => ((r[e.split("=")[0]] = decodeURIComponent(e.split("=")[1])), r),
      {}
    );

// export const strArray = (tagsText) => {
//   if (trim(tagsText)) {
//     const newTags = [];
//     tagsText.split(",").forEach((item) => {
//       newTags.push(trim(item));
//     });
//     return newTags;
//   } else {
//     return [];
//   }
// };

export const getIdx = (arrayData, id) => {
  return arrayData.findIndex((x) => x.id === id);
};

// File Download
export const downloadFile = (url, filename) => {
  fetch(url).then(function (t) {
    return t.blob().then((b) => {
      var a = document.createElement("a");
      a.href = URL.createObjectURL(b);
      a.setAttribute("download", filename);
      a.click();
    });
  });
};

export const trimString = (s) => {
  var l = 0,
    r = s.length - 1;
  while (l < s.length && s[l] === " ") l++;
  while (r > l && s[r] === " ") r -= 1;
  return s.substring(l, r + 1);
};

export const uniqueArray = (array) => {
  let newArray = [];
  const seen = new Set();
  array.filter((el) => {
    const duplicate = seen.has(el.id);
    seen.add(el.id);
    if (!duplicate) {
      newArray.push(el);
    }
  });
  return newArray;
};

export const objToArray = (obj) => {
  const newArray = [];
  Object.keys(obj).map((key) => {
    newArray.push({ name: key, value: obj[key] });
  });

  return newArray;
};

export const getFileType = (file) => {
  if (file) {
    const splitNames = file.name.split(".");
    return splitNames[splitNames.length - 1];
  } else {
    return null;
  }
};

export const capitalize = (str = "") => {
  const lowerStr = str.toLowerCase();
  return lowerStr.charAt(0).toUpperCase() + lowerStr.slice(1);
};

export const arrayBufferToBase64 = (buffer) => {
  var binary = "";
  var bytes = new Uint8Array(buffer);
  var len = bytes.byteLength;
  for (var i = 0; i < len; i++) {
    binary += String.fromCharCode(bytes[i]);
  }
  return window.btoa(binary);
};

export const base64ToArrayBuffer = (base64) => {
  var binary_string = window.atob(base64);
  var len = binary_string.length;
  var bytes = new Uint8Array(len);
  for (var i = 0; i < len; i++) {
    bytes[i] = binary_string.charCodeAt(i);
  }
  return bytes.buffer;
};

export const secToMin = (sec) => {
  if (sec) {
    const m = Math.floor(sec / 60);
    const s = sec - m * 60;
    return m > 0
      ? `${m} ${m > 1 ? "mins" : "min"}`
      : `${s} ${s > 1 ? "secs" : "sec"}`;
  } else {
    return 0;
  }

  // return `${m} ${m > 1 ? "mins" : "min"} ${s} ${s > 1 ? "secs" : "sec"}`;

  // OTHER WAY
  // const duration = moment.duration(sec, 'seconds');
  // const h = duration.hours();
  // const m = duration.minutes();
  // const s = duration.seconds();

  // return `${m} min`
};

export function isIpadOS() {
  if (/iPad/.test(navigator.platform) || (navigator.platform === 'MacIntel' && navigator.maxTouchPoints === 5)) {
    return true;
  } else {
    return false;
  }
}

export function isLandscape() {
  return window.orientation === 90 || window.orientation === -90;
}

export const getRandomColor = () =>
  "#" + parseInt(Math.random() * 0xffffff).toString(16);

// NOTE  Access Role Functions
export const getAccessRoutes = (resArr) => {
  const result = [];
  resArr.forEach((resItem) => {
    routesArr.forEach((staItem) => {
      if (
        resItem.menuName === staItem.menuName &&
        resItem.menuTabName === staItem.menuTabName
      ) {
        result.push({ ...resItem, ...staItem });
      }
    });
  });

  return result;
};

export const isPathDisabled = (pathname, resArr) => {
  if (resArr?.length) {
    for (const [index, item] of resArr.entries()) {
      if (item.menuName_path === pathname && item.itemEnabled) {
        return false;
      }

      if (resArr.length - 1 === index) {
        return true;
      }
    }
  } else {
    return true;
  }
};

export const getAccessTabs = (pathname, resArr) => {
  const result = [];
  if (pathname && resArr?.length) {
    resArr.forEach((item) => {
      if (
        item.menuName_path === pathname &&
        item.itemEnabled &&
        item.menuTabName_path
      ) {
        result.push(item);
      }
    });
  }

  return result;
};
