import { useState } from "react";
import { Collapse, Row, Col } from "reactstrap";
import PropTypes from "prop-types";
import styled from "styled-components";
import { MontP, NotoP } from "../styles/common";
import { colors } from "../styles/theme";
import styles from "./Components.module.css";

import chevup from "../assets/icons/chev-up-d.svg";
import chevdown from "../assets/icons/chev-down-d.svg";
import { objToArray } from "../utils";

const TitleDiv = styled.div`
  background: #2541b2;
  /* border-radius: 5px; */
  width: 100%;
  height: 45px;
  padding-left: 6px;
`;

const RowDiv = styled(Row)`
  background: ${({ bg }) => bg};
  min-height: 47px;
  width: 100%;
`;

const AnalyzeTitle = ({ title, classname }) => {
  return (
    <TitleDiv className={classname}>
      <NotoP fs="16px" lh="45px" b c={colors.white}>
        {title}
      </NotoP>
    </TitleDiv>
  );
};

const AnalyzeRow = ({ name, value, bgColor }) => {
  return (
    <RowDiv bg={bgColor} className="p-0 m-0">
      <Col xs={7} sm={7} md={7}>
        <NotoP fs="14px" lh="20px" fw="600" mt="14px">
          {name}
        </NotoP>
      </Col>
      <Col xs={5} sm={5} md={5}>
        <NotoP fs="14px" lh="20px" fw="600" mt="14px">
          {value}
        </NotoP>
      </Col>
    </RowDiv>
  );
};

function InterfaceCollapseable({ bgColor, data }) {
  const [isOpen, setIsOpen] = useState(false);

  const toggle = () => setIsOpen(!isOpen);

  return (
    <div className="card border-0 shadow-none mb-3">
      <div
        role="button"
        onClick={toggle}
        onKeyPress={toggle}
        className="d-flex align-items-center justify-content-between p-2"
        style={{ backgroundColor: bgColor }}
      >
        <MontP fs="15px" lh="20px" b c={colors.white}>
          Page No: {data.pageNo} - {data.tagName}
        </MontP>
        <img
          src={isOpen ? chevup : chevdown}
          alt="up"
          width="22px"
          height="22px"
        />
      </div>
      <Collapse isOpen={isOpen}>
        <div>
          <AnalyzeTitle title="Document Details" />
          <AnalyzeRow
            bgColor="#fff"
            name="Classification"
            value={data.tagName}
          />
          <AnalyzeRow
            bgColor="#fff"
            name="Accuracy (%)"
            value={data.probability}
          />
          <AnalyzeTitle title="Document KPI Data" classname="mt-3" />
          {objToArray(data.metadata).map((item, index) => (
            <AnalyzeRow
              key={index}
              bgColor="#fff"
              name={item.name}
              value={item.value || " -- "}
            />
          ))}
        </div>
      </Collapse>
    </div>
  );
}

InterfaceCollapseable.propTypes = {
  bgColor: PropTypes.string,
  data: PropTypes.object.isRequired,
};

InterfaceCollapseable.defaultProps = {
  bgColor: "#FFF",
};

export default InterfaceCollapseable;
