import { useState } from "react";
import { Container, TabContent, TabPane, Nav, Row, Col } from "reactstrap";
import "react-datepicker/dist/react-datepicker.css";
import TabsCover from "../../components/tabbar/TabsCover";
import MyTab from "../../components/tabbar/MyTab";
import Dashboard from "./tabs/Dashboard";
import CreateIssue from "./tabs/CreateIssue";
import { useAccessTabs } from "../../hooks/useAccessTabs";
import SupportDashboard from './tabs/SupportDashboard';
import { useSelector } from "react-redux";

export default function Issue() {
  const { user_data } = useSelector((state) => ({
    user_data: state.auth.user_data,
  }));
  const [tabs, activeTab, setActiveTab] = useAccessTabs();

  const toggle = (tab) => {
    if (activeTab !== tab) setActiveTab(tab);
  };

  return (
    <Container fluid className="pb-5">
      <Row className="justify-content-center mt-4">
        <Col xs={12} sm={12} md={12} className="py-5">
          <Nav tabs className="justify-content-center border-0">
            <TabsCover>
              {tabs.map((item, index) => (
                <MyTab
                  title={item.menuTabName}
                  onClick={() => toggle(item.menuTabName_path)}
                  isActive={activeTab === item.menuTabName_path}
                />
              ))}
              {user_data?.userInfo?.userRole === 'SUPPORT' && <MyTab
                title='Support Dashboard'
                onClick={() => toggle('3')}
                isActive={activeTab === '3'}
              />}
            </TabsCover>
          </Nav>
          <TabContent activeTab={activeTab} className="position-relative">
            <TabPane tabId="1">
              <Dashboard />
            </TabPane>
            <TabPane tabId="2">
              <CreateIssue />
            </TabPane>
            <TabPane tabId="3">
              <SupportDashboard />
            </TabPane>
          </TabContent>
        </Col>
      </Row>
    </Container>
  );
}
