import { useEffect, useState } from "react";
import PropTypes from "prop-types";
import {
    ButtonDropdown,
    DropdownItem,
    DropdownMenu,
    DropdownToggle,
    InputGroup,
} from "reactstrap";
import { NotoP } from "../styles/common";
import styles from "./title-input/title-input.module.css";
import { countriesCode } from "../assets/data/countryCodes.json";
import chev_down from "../assets/icons/chev-down.svg";
import { useSelector } from "react-redux";
import { API, PATH } from "../services/Axios";
import cls from "classnames";

function PhoneInput(props) {
    const { user_data = {} } = useSelector((state) => ({
        user_data: state.auth.user_data,
    }));
    const [open, setOpen] = useState(false);
    const {
        coverstyle,
        label,
        name,
        type,
        value,
        onChange,
        placeholder,
        disabled,
        code,
        handleCode,
        required,
        showerr,
        valid,
        errmsg,
    } = props;

    const toggleOpen = () => {
        setOpen(!open);
    };

    const [countries, setCountries] = useState([]);

    const getCountryCodes = async () => {
        if (user_data?.token) {
            const result = await API.post(
                PATH.getCountryCodes,
                {},
                user_data.token
            );
            setCountries(result);
        } else {
            // console.log(countriesCode);
            setCountries(JSON.parse(JSON.stringify(countriesCode)));
        }
    };

    useEffect(() => {
        getCountryCodes();
    }, [user_data]);

    return (
        <div className={coverstyle}>
            <p className={styles.label}>
                {label}
                {required && <span>*</span>}
            </p>
            <InputGroup>
            
                <ButtonDropdown isOpen={open} toggle={toggleOpen}>
                    <DropdownToggle 
                    className={styles.dropInput}
                    >
                        {code}
                        <img
                            src={chev_down}
                            alt="choose"
                            width="12px"
                            height="7px"
                            className="ms-1"
                        />
                    </DropdownToggle>
                    <DropdownMenu style={{ height: "30vh" }} className="overflow-auto">
                        {countries.map((item, index) => (
                            <DropdownItem onClick={() => handleCode(item.countryCode)} key={index}>
                                {item.countryName} {item.countryCode}
                            </DropdownItem>
                        ))}
                    </DropdownMenu>
                </ButtonDropdown>
                <input
                    className={cls("form-control", styles.input)}
                    id={label}
                    name={name}
                    type={type}
                    inputMode={type}
                    value={value}
                    onChange={onChange}
                    disabled={disabled}
                    placeholder={placeholder}
                    {...props}
                />
            </InputGroup>
            {required &&
                showerr &&
                (!value ? (
                    <NotoP
                        fs="10px"
                        className="text-danger py-1"
                    >{`${label} cannot be empty`}</NotoP>
                ) : !valid && errmsg ? (
                    <NotoP fs="10px" className="text-danger py-1">
                        {errmsg}
                    </NotoP>
                ) : (
                    ""
                ))}
        </div>
    );
}

PhoneInput.propTypes = {
    label: PropTypes.string,
    name: PropTypes.string,
    type: PropTypes.string,
    value: PropTypes.any,
    onChange: PropTypes.func,
    placeholder: PropTypes.string,
    coverstyle: PropTypes.string,
    disabled: PropTypes.bool,
    code: PropTypes.string,
    handleCode: PropTypes.func,
    required: PropTypes.bool,
    showerr: PropTypes.bool,
    valid: PropTypes.bool,
    errmsg: PropTypes.string,
};

PhoneInput.defaultProps = {
    type: "text",
    coverstyle: "mt-3 position-relative",
    required: true,
    valid: false,
};

export default PhoneInput;
