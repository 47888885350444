import React, { useState } from "react";
import { Col, Container, Row } from "reactstrap";
import { HideOn } from "react-hide-on-scroll";
import styles from "./landing-arka.module.css";
import InfoModal from "../info-modal";

import arka from "../../assets/images/arka/arka-text.svg";
import arkaRoundImg from "../../assets/images/arka/arka-round.png";
import arkaLinesImg from "../../assets/images/landing/arka-lines.png";
import techImg from "../../assets/images/landing/technology.svg";
import costImg from "../../assets/images/landing/cost-benefits.svg";
import classifyImg from "../../assets/images/landing/classification.svg";
import bizImg from "../../assets/images/landing/biz-advantage.svg";
import prodImg from "../../assets/images/landing/prod-integration.svg";
import aiMachineImg from "../../assets/images/landing/ai-machine.svg";
import smartImg from "../../assets/images/landing/smart-intelligent.svg";
import cognitiveImg from "../../assets/images/landing/cognitive.svg";
import analyticsImg from "../../assets/images/landing/analytics.svg";

const arkaData = [
  {
    id: 1,
    pic: techImg,
    title: "Technology",
    data: [
      "Cognitive learning",
      "Deep learning AI & ML model",
      "NLP (Natural Language Processing)",
      "Supervised Learning",
      "Intelligent data mapping",
    ],
  },
  {
    id: 2,
    pic: costImg,
    title: "Cost Benefits",
    data: [
      "PAY AS YOU GO Model",
      "Cost Effective",
      "Low-cost Digital Workplace Enablement",
      "Affordable maintenance",
    ],
  },
  {
    id: 3,
    pic: classifyImg,
    title: "Classification & extraction capabilities",
    data: [
      "Classify documents just as humans do",
      "Realtime Business KPI data extraction",
      "Printed Data (Key Value Pairs)",
      "Handwritten Data",
      "Signature Detection",
      "Data in Tabular Formats",
      "Free Text Data",
    ],
  },
  {
    id: 4,
    pic: bizImg,
    title: "Business Advantages",
    data: [
      "Owned and maintained by Business Users",
      "Error Reduction",
      "Faster turnaround time",
      "Train your own documents",
      "Multi Domain Adaptability",
      "Ensure Business continuity with minimized inherent manual risks",
    ],
  },
  {
    id: 5,
    pic: prodImg,
    title: "Product and Integration Capabilities",
    data: [
      "Cloud hosted end to end solution",
      "Simple Integration using an API",
      "Embedded Document Management",
      "Not Just out of the box tool",
      "Customizable to one’s needs",
      "Export data in consumable formats",
      "Handle large volumes using Batch process",
    ],
  },
];

const classifyData = [
  { pic: aiMachineImg, text: "AI & Machine Learning model" },
  { pic: smartImg, text: "Smart & Intelligent Document Processing" },
  { pic: cognitiveImg, text: "Cognitive Capability" },
  { pic: analyticsImg, text: "Data Insights & Analytics" },
];

const LandingArka = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [item, setItem] = useState(null);

  const handleModal = (x) => {
    setItem(x);
    setIsOpen(true);
  };

  return (
    <div id="arka" className={styles.wrapper}>
      <div className="header_content--fix" />
      <HideOn height="950">
        <a href="/#work-steps">
          <div class="icon-scroll icon-scroll--invert" />
        </a>
      </HideOn>
      <InfoModal isOpen={isOpen} setIsOpen={setIsOpen} item={item} />
      <Container fluid>
        <Row>
          <Col md={5}>
            <div>
              <img src={arka} alt="ARKA" className={styles.arka} />
              <p className={styles.classifySubtitle}>
                If human's can classify & read a document.. so, does
                <span className="ms-2">
                  <img src={arka} alt="arka" className={styles.arkaText} />
                </span>
              </p>
              <p className={styles.subtitle}>
                ARKA is an Intelligent and Smart Document Processing Product
                powered by it's AI & ML Driven Models along with it's Analytic
                and Cognitive Capabilities to process Structured and
                Unstructured documents. Along with it's multi business domain
                adaptability, it can be customized to one's business needs.
              </p>
              <div className="mt-md-5">
                {classifyData.map((x, i) => (
                  <div key={i} className="d-flex align-items-center mt-4">
                    <div className="me-4">
                      <img
                        src={x.pic}
                        alt={x.text}
                        className={styles.classifyImg}
                      />
                    </div>
                    <p className={styles.classifyText}>{x.text}</p>
                  </div>
                ))}
              </div>
            </div>
          </Col>
          <Col md={7}>
            <div className="d-flex align-items-center justify-content-end position-relative">
              <img
                src={arkaLinesImg}
                alt="Arka Technologies"
                className={styles.arkaLinesImg}
              />
              <img
                src={arkaRoundImg}
                alt="Arka Technologies"
                className={styles.arkaRoundImg}
              />
              {/* <picture>
                <source
                  media="(max-width: 600px)"
                  srcset={arkaRoundImg}
                  sizes="100px"
                  //sizes="(min-width: 700px) 500px,
                  //(min-width: 600px) 400px,
                  //100vw"
                  //srcset={`${arkaRoundImg} 1x`}
                  //sizes="100px"
                />
                <img
                  src={arkaLinesImg}
                  alt="Arka Technologies"
                  className={styles.arkaLinesImg}
                />
              </picture> */}
              <div className={styles.buttonsCover}>
                {arkaData.map((x) => (
                  <button
                    key={x.id}
                    onClick={() => handleModal(x)}
                    className={styles.button}
                  >
                    <img src={x.pic} alt={x.title} />
                    <p>{x.title}</p>
                  </button>
                ))}
              </div>
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default LandingArka;
