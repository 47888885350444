import React from 'react';
import styles from './RadioButton.module.css';

function RadioButton({ option1Label, option2Label, onClick, option1Value, option2Value, value }) {
  return (
    <div className={styles.container}>
      <div className={styles.radio} onClick={()=> onClick(option1Value)}>
        <input id='radio-1' name='radio' type='radio' checked={value === option1Value} />
        <label for='radio-1' className={styles.radioLabel}>
        {option1Label}
        </label>
      </div>

      <div className={styles.radio} onClick={()=> onClick(option2Value)}>
        <input id='radio-2' name='radio' type='radio' checked={value === option2Value} />
        <label for='radio-2' className={styles.radioLabel}>
        {option2Label}
        </label>
      </div>
    </div>
  );
}

export default RadioButton;
