import { useState, useRef } from "react";
import { Collapse, Table } from "reactstrap";
import PropTypes from "prop-types";
import moment from "moment";
import { NotoP } from "../styles/common";
import { colors } from "../styles/theme";
import classnames from "classnames";
import styles from "./Components.module.css";
import styled from "styled-components";

import chevup from "../assets/icons/chev-up-d.svg";
import chevdown from "../assets/icons/chev-down-d.svg";
import { API, PATH } from "../services/Axios";
import BtnSpinner from "./BtnSpinner";
import SimpleDocPicker from "./SimpleDocPicker";
import ShowComment from "./showComment";

const FooterText = styled.span`
  font-family: Montserrat;
  font-style: italic;
  font-weight: normal;
  font-size: 12px;
  line-height: 18px;
  /* identical to box height */
  display: block;
  align-items: center;
  text-align: ${({ align }) => (align ? align : "left")};
  /* primary-color */
  color: #03256c;
`;

function IssueCollapseable({ bgColor, item, user_data }) {
  const docRef = useRef();

  const [loading, setLoading] = useState(false);

  const [isOpen, setIsOpen] = useState(false);
  const [activity, setActivity] = useState("");
  const [doc, setDoc] = useState(null);

  const toggle = () => setIsOpen(!isOpen);
  // console.log(doc);

  const handleUpdateStatus = async () => {
    if (activity?.length >= 2 && activity?.length <= 1000) {
      setLoading(true);
      let fileFormData = new FormData();
      fileFormData.append("CustId", user_data?.customerInfo?.custId);
      fileFormData.append("LoginId", user_data?.userInfo?.userEmail);
      fileFormData.append("CustIssueId", item.issueId);
      // fileFormData.append("CustIssueId", item.customerIssueId);
      fileFormData.append("IssueDetails", activity);
      if (doc) {
        fileFormData.append("FileName", doc.name);
        fileFormData.append("FileObject", doc);
      }

      const data = {
        updateDetails: activity,
        createdDate: new Date().toISOString(),
      };
      try {
        const result = await API.postFileKey(
          PATH.issueUpdate,
          fileFormData,
          user_data.token
        );
        console.log(result);
        if (result?.isSuccess) {
          item.lstIssueDetails.unshift(data);
          setActivity("");
          setDoc(null);
        }
        setLoading(false);
      } catch (err) {
        console.log(err);
        setLoading(false);
      }
    }
  };

  const handleDocPicker = (e) => {
    const { name, files } = e.target;
    console.log(name);

    if (files.length > 0) {
      setDoc(files[0]);
    }
  };

  const paintColor = (status) => {
    if (!status) {
      return colors.primary;
    }

    switch (status.toLowerCase()) {
      case "new":
        return colors.red;
      case "in progress":
      case "processing":
        return colors.yellow;
      case "completed":
        return colors.green;

      default:
        return colors.yellow;
    }
  };

  return (
    <>
      <tr
        role="button"
        onClick={toggle}
        onKeyPress={toggle}
        style={{ backgroundColor: bgColor }}
      >
        <td className={classnames(styles.issuetd, "w-25")}>
          <img
            src={isOpen ? chevup : chevdown}
            alt="up"
            width="20px"
            height="20px"
            className="me-2 d-inline-block"
          />
          {moment(item.createdDate).format("MM/DD/YYYY")}
        </td>
        <td className={styles.issuetd}>{item.customerIssueId}</td>
        <td>
          <ShowComment text={item.issueSummary} width={'300px'}/>
        </td>
        <td style={{ color: paintColor(item.issueStatus) }}>
          {item.issueStatus}
        </td>
      </tr>
      <tr
        className={classnames(
          isOpen ? styles.issue_tr_show : styles.issue_tr_hide
        )}
      >
        <td
          colSpan={12}
          className={classnames(styles.issuetd, "p-0")}
          style={{ backgroundColor: bgColor }}
        >
          <Collapse isOpen={isOpen}>
            <Table borderless>
              <thead>
                <tr style={{ height: 20 }}>
                  <th className={classnames(styles.issuetd, "ps-3 w-25")}>
                    Activity Date
                  </th>
                  <th className={styles.issuetd}>Activity Details</th>
                </tr>
              </thead>
              <tbody>
                {item.lstIssueDetails?.map((subItem, index) => (
                  <tr
                    key={index}
                    style={{ backgroundColor: bgColor, height: 20 }}
                  >
                    <td className={classnames(styles.issuetd, "ps-4 w-25")}>
                      {subItem.createdDate}
                    </td>
                    <td className={styles.issuetd}>{subItem.updateDetails}</td>
                  </tr>
                ))}
              </tbody>
            </Table>
            <div
              className={classnames(
                styles.issue_activityinput_cover,
                "ps-3 pb-3"
              )}
            >
              <NotoP fs="11px" lh="14px" fw="600" c={colors.primary} mb="2px">
                Activity Details<span className="iRed">*</span>
              </NotoP>
              <input
                className={styles.issue_activityinput}
                placeholder="Issue update details"
                value={activity}
                onChange={(e) => setActivity(e.target.value)}
              />
              <FooterText>Maximum 1000 Characters</FooterText>
              <SimpleDocPicker
                inputRef={docRef}
                setFile={setDoc}
                handleChange={handleDocPicker}
                filename={doc?.name}
              />
              <FooterText>File supported .png, .jpg, .pdf</FooterText>
              <div className="justify-content-end d-flex">
                <button
                  disabled={loading || activity?.length < 2}
                  onClick={handleUpdateStatus}
                  className={styles.issue_activity_update}
                >
                  {loading && <BtnSpinner />}
                  Update
                </button>
              </div>
            </div>
          </Collapse>
        </td>
      </tr>
    </>
  );
}

IssueCollapseable.propTypes = {
  bgColor: PropTypes.string,
  item: PropTypes.object.isRequired,
  user_data: PropTypes.object.isRequired,
};

IssueCollapseable.defaultProps = {
  bgColor: "#FFF",
};

export default IssueCollapseable;
