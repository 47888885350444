import PropTypes from "prop-types";
import { Link, useLocation } from "react-router-dom";
import styled, { css } from "styled-components";
import { colors } from "../../../styles/theme";

import { NotoP } from "../../../styles/common";
import { isPathDisabled } from "../../../utils";

const SidebarLink = styled(Link)`
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: ${({ disabledpath }) =>
    disabledpath ? colors.grey5 : colors.primary};

  height: 45px;
  list-style: none;
  text-decoration: none;
  padding-left: 2vw;
  margin-bottom: 3px;

  .sidebar--menuicon {
    width: 38px;
    height: 20px;
    margin-right: 10px;
    /* background-size: auto; */
    background-repeat: no-repeat;
    background-image: ${({ inactiveicon }) => `url(${inactiveicon})`};
    background-size: ${({ iconwidth, iconheight }) =>
      `${iconwidth || "20px"} ${iconheight || "20px"}`};
  }

  &:hover {
    text-decoration: none;
    cursor: pointer;
    color: ${({ disabledpath }) =>
      disabledpath ? colors.grey5 : colors.white};
    background-color: ${({ disabledpath }) =>
      disabledpath ? colors.disable : colors.primary};
    transition: 0.25s;

    .sidebar--menuicon {
      background-image: ${({ activeicon, inactiveicon, disabledpath }) =>
        disabledpath ? `url(${inactiveicon})` : `url(${activeicon})`};
      transition: 0.25s;
    }
  }

  ${({ active }) =>
    active === "yes" &&
    css`
      color: ${colors.white};
      background-color: ${({ disabledpath }) =>
        disabledpath ? colors.disable : colors.primary};

      .sidebar--menuicon {
        background-image: ${({ activeicon }) => `url(${activeicon})`};
      }
    `}

  @media only screen and (max-width: 768px) {
    padding-left: 5vw;
  }

  ${({ disabledpath }) =>
    disabledpath &&
    css`
      background-color: ${colors.disable};
      cursor: not-allowed;
      pointer-events: none;
    `}
`;

const SidebarMenu = (props) => {
  const location = useLocation();
  const { item, user_data } = props;

  // console.log(">>>>>>", window.location.pathname);
  // console.log("Location", location);

  const active =
    location.pathname === item.path || location.pathname === item.rootpath;

  return (
    <SidebarLink
      to={item.path}
      // to={
      //   isPathDisabled(item.path, user_data?.lstUserRoleAccess)
      //     ? "#"
      //     : item.path
      // }
      active={active ? "yes" : "no"}
      activeicon={item.activeIcon}
      inactiveicon={item.inactiveIcon}
      iconwidth={item.iconWidth}
      iconheight={item.iconHeight}
      // disabledpath={isPathDisabled(item.path, user_data?.lstUserRoleAccess)}
    >
      <div className="d-flex align-items-center">
        <div className="sidebar--menuicon" />
        {/* <IconImg
          src={active ? item.activeIcon : item.inactiveIcon}
          alt="menu"
        /> */}
        <NotoP fs="14px" lh="21px" tfs="14px" tlh="21px" ls=".1px" c="inherit">
          {item.title}
        </NotoP>
      </div>
    </SidebarLink>
  );
};

SidebarMenu.propTypes = {
  item: PropTypes.object,
  user_data: PropTypes.object,
};

// SidebarMenu.defaultProps = {};

export default SidebarMenu;
