import React, { useState } from "react";
import { Card, Col, Container, Row } from "reactstrap";
import validator from "validator";
import ReCAPTCHA from "react-google-recaptcha";
import styles from "./landing-note.module.css";
import { notify } from "../../utils";
import { API, PATH } from "../../services/Axios";

import paperPlaneImg from "../../assets/images/landing/paper-plane.svg";
import TitleInput from "../title-input";
import TitleTextArea from "../title-textarea";
import BtnSpinner from "../BtnSpinner";
import { NotoP } from "../../styles/common";

const initialNote = {
  fullName: "",
  email: "",
  companyName: "",
  phoneNumber: "",
  description: "",
};

const LandingNote = () => {
  const [loading, setLoading] = useState(false);

  const [note, setNote] = useState(initialNote);
  const [showError, setShowError] = useState(false); // NOTE  by default should be false
  const [verifiedSite, setVerifiedSite] = useState(false);
  const [siteErrmsg, setSiteErrMsg] = useState(null);
  const [, setShowCaptcha] = useState(true);

  const { fullName, email, companyName, phoneNumber, description } = note;

  const conditions =
    fullName.length >= 6 &&
    validator.isEmail(email) &&
    // companyName.length >= 10 &&
    description.length >= 10;

  const handleSubmit = async () => {
    if (!verifiedSite) {
      setSiteErrMsg("reCAPTCHA verification required.");
      return;
    }

    if (conditions) {
      setLoading(true);
      setShowError(false);
      const data = {
        ProspectName: fullName,
        ProspectEmail: email,
        ProspectCompany: companyName,
        ProspectMessage: description,
        ProspectPhoneNo: phoneNumber,
      };

      try {
        const result = await API.postAuth(PATH.addProspect, data);
        console.log(">>>>> ", result);

        if (result) {
          notify(
            "Thank you for contacting Ideafuse. We will contact you at the earliest via an email.",
            true
          );
          setNote(initialNote);

          // Reset captha
          setShowCaptcha(false);
          setTimeout(() => {
            setShowCaptcha(true);
          }, 1000);
        }
        setLoading(false);
      } catch (err) {
        setLoading(false);
        console.error(err);
      }
    } else {
      setShowError(true);
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setNote((prev) => ({ ...prev, [name]: value }));
  };

  const onChange = (value) => {
    if (!value) {
      setVerifiedSite(false);
      setSiteErrMsg("reCAPTCHA verification required.");
      return;
    }
    // console.log("Captcha value:", value);
    setVerifiedSite(true);
    setSiteErrMsg(null);
  };

  const handleInvalidCaptcha = () => {
    setVerifiedSite(false);
    setSiteErrMsg("reCAPTCHA verification required.");
  };

  return (
    <div id="contact" className={styles.wrapper}>
      <div className="header_content--fix" />
      <div className="container-md">
        <Row className="g-0 px-md-5 mt-md-4">
          <Col md={7} sm={5}>
            <div>
              <p className={styles.title}>Get in Touch see how..</p>
              <p className={styles.subTitle}>
                We will help to empower your business using the technology you
                have dreamt about...
              </p>
              <img
                src={paperPlaneImg}
                alt="paper plane"
                className={styles.paperPlane}
              />
            </div>
          </Col>
          <Col md={5} sm={7}>
            <div>
              <Card className={styles.card}>
                <p className={styles.cardTitle}>Send us a note</p>
                <TitleInput
                  label="Full Name"
                  name="fullName"
                  value={fullName}
                  onChange={handleChange}
                  placeholder="Enter Full Name"
                  showerr={showError}
                  errmsg="Minimum length should be 6"
                  valid={fullName.length >= 6}
                />
                <Row>
                  <Col md={6}>
                    <TitleInput
                      label="Email"
                      name="email"
                      value={email}
                      onChange={handleChange}
                      placeholder="Enter Email address"
                      showerr={showError}
                      errmsg="Invalid email"
                      valid={validator.isEmail(email)}
                    />
                  </Col>
                  <Col md={6}>
                    <TitleInput
                      label="Phone Number"
                      name="phoneNumber"
                      required={false}
                      value={phoneNumber}
                      onChange={handleChange}
                      placeholder="Enter Phone Number"
                      showerr={showError}
                      errmsg="Invalid Phone number"
                      valid={validator.isMobilePhone(phoneNumber)}
                    />
                  </Col>
                </Row>
                <TitleInput
                  label="Company Name"
                  name="companyName"
                  required={false}
                  value={companyName}
                  onChange={handleChange}
                  placeholder="Enter Company Name"
                  showerr={showError}
                  errmsg="Minimum length should be 10"
                  valid={companyName.length >= 10}
                />
                <TitleTextArea
                  label="Message"
                  name="description"
                  value={description}
                  onChange={handleChange}
                  placeholder="Enter Message For Us"
                  showerr={showError}
                  errmsg="Minimum length should be 10"
                  valid={description.length >= 10}
                />
                <div className="content_center--flex flex-column my-3 position-relative">
                  <ReCAPTCHA
                    sitekey={process.env.REACT_APP_SITE_KEY}
                    onChange={onChange}
                    onErrored={handleInvalidCaptcha}
                    onExpired={handleInvalidCaptcha}
                  />
                  {siteErrmsg && (
                    <NotoP fs="10px" className="text-danger mt-1">
                      {siteErrmsg}
                    </NotoP>
                  )}
                  {!conditions && (
                    <span
                      role="button"
                      aria-label="captcha"
                      onClick={() => setShowError(true)}
                      onKeyPress={() => setShowError(true)}
                      className="recaptha--cover"
                    ></span>
                  )}
                </div>
                <button
                  onClick={handleSubmit}
                  disabled={!verifiedSite || loading}
                  className={styles.connectBtn}
                >
                  {loading && <BtnSpinner />}Get Connected
                </button>
              </Card>
            </div>
          </Col>
        </Row>
      </div>
    </div>
  );
};

export default LandingNote;
