import React, { useState } from "react";
import { Link } from "react-router-dom";
import { Card, Col, Container, Row } from "reactstrap";
import cls from "classnames";
import validator from "validator";
import BtnSpinner from "../../components/BtnSpinner";
import TitleInput from "../../components/title-input";
import styles from "./register.module.css";
import { API, PATH } from "../../services/Axios";
import { notify } from "../../utils";

import logo from "../../assets/images/ideafuse-logo.svg";
import PhoneInput from "../../components/PhoneInput";

const initialUser = {
  firstName: "",
  lastName: "",
  companyName: "",
  phoneNumber: "",
  email: "",
  inviteCode: ""
};

const Register = () => {
  const [loading, setLoading] = useState(false);

  const [user, setUser] = useState(initialUser);
  const [showError, setShowError] = useState(false); // NOTE  bydefault should be false

  const [phoneCode, setPhoneCode] = useState('+91');

  const { firstName, lastName, companyName, phoneNumber, email, inviteCode } = user;

  const handleSubmit = async () => {
    const conditions =
      validator.isAlpha(firstName) &&
      validator.isAlpha(lastName) &&
      companyName.length > 0 &&
      validator.isMobilePhone(phoneNumber) &&
      validator.isEmail(email);

    if (conditions) {
      setLoading(true);
      setShowError(false);
      const data = {
        firstName: firstName,
        lastName: lastName,
        companyName: companyName,
        phoneNumber: phoneNumber,
        countryCode: phoneCode,
        userId: email,
        inviteCode
      };
      try {
        const result = await API.postAuth(PATH.userSignup, data);
        console.log(result);

        if (result) {
          notify(
            "Welcome to Ideafuse. You will soon receive an email with your account details.",
            true
          );
          setUser(initialUser);
        }
        setLoading(false);
      } catch (err) {
        setLoading(false);
        console.error(err);
      }
    } else {
      setShowError(true);
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setUser((prev) => ({ ...prev, [name]: value }));
  };

  return (
    <div className={styles.wrapper}>
      <Card className={cls(styles.card, "custom-scrollbar")}>
        <Container fluid>
          <Row>
            <Col className="p-md-3 py-md-4 p-3">
              <div>
                <Link to="/#home">
                  <img src={logo} alt="Ideafuse" className={styles.logo} />
                </Link>
                <p className={styles.title}>Register</p>
                <p className={styles.subtitle}>
                  Become a part of the realm of futuristic technologies by
                  signing up.
                </p>
                <Row>
                  <Col md={6}>
                    <TitleInput
                      label="First name"
                      name="firstName"
                      value={firstName}
                      onChange={handleChange}
                      placeholder="Enter First Name"
                      showerr={showError}
                      errmsg="First name contains only letters (a-zA-Z)"
                      valid={validator.isAlpha(firstName)}
                    />
                  </Col>
                  <Col md={6}>
                    <TitleInput
                      label="Last name"
                      name="lastName"
                      value={lastName}
                      onChange={handleChange}
                      placeholder="Enter Last Name"
                      showerr={showError}
                      errmsg="Last name contains only letters (a-zA-Z)"
                      valid={validator.isAlpha(lastName)}
                    />
                  </Col>
                </Row>
                <TitleInput
                  label="Company name"
                  name="companyName"
                  value={companyName}
                  onChange={handleChange}
                  placeholder="Enter Company Name"
                  showerr={showError}
                  errmsg="Minimum length should be 10"
                  valid={companyName.length >= 10}
                />
                {/* <TitleInput
                  label="Registered phone"
                  name="phoneNumber"
                  value={phoneNumber}
                  onChange={handleChange}
                  placeholder="Enter Registered Phone"
                  showerr={showError}
                  errmsg="Invalid Phone number"
                  valid={validator.isMobilePhone(phoneNumber)}
                /> */}
                <PhoneInput
                  label="Registered phone"
                  name="phoneNumber"
                  placeholder="Enter Registered Phone"
                  code={phoneCode}
                  handleCode={setPhoneCode}
                  value={phoneNumber}
                  onChange={handleChange}
                  showerr={showError}
                  errmsg="Invalid Phone number"
                  valid={validator.isMobilePhone(phoneNumber)}
                />
                <TitleInput
                  label="Registered email"
                  name="email"
                  value={email}
                  onChange={handleChange}
                  placeholder="Enter Registered Email"
                  showerr={showError}
                  errmsg="Invalid email"
                  valid={validator.isEmail(email)}
                />
                <TitleInput
                  label="Invitation Code"
                  name="inviteCode"
                  value={inviteCode}
                  onChange={handleChange}
                  placeholder="Enter Invitation Code"
                  showerr={showError}
                  errmsg="Invalid Invitation code"
                  valid={inviteCode?.length > 0}
                />
                <button
                  onClick={handleSubmit}
                  disabled={loading}
                  className={styles.continueBtn}
                >
                  {loading && <BtnSpinner />}Register
                </button>

                <p className={styles.notYetText}>Already have an account ?</p>

                <Link className="text-decoration-none" to="/login">
                  <p className={styles.register}>Login</p>
                </Link>
              </div>
            </Col>
          </Row>
        </Container>
      </Card>
    </div>
  );
};

export default Register;
