import React, { useState } from "react";
import { Card, Col, Container, Row } from "reactstrap";
import cls from "classnames";
import styles from "./business-plan.module.css";

import checkWhiteImg from "../../assets/icons/check-w.svg";
import checkPrimaryImg from "../../assets/icons/check-p.svg";
import { colors } from "../../styles/theme";
import { useHistory } from "react-router-dom";
import CollapsePlan from "../collapse-plan";

const PlanCard = ({
  title,
  data = [],
  isSelected,
  onCardClick,
  onBtnClick,
}) => (
  <Card
    //onClick={onCardClick}
    onMouseOver={onCardClick}
    //onMouseOut={onCardClick}
    className={cls(styles.card, { [styles.cardActive]: isSelected })}
  >
    <p
      className={cls(styles.cardTitle, {
        [styles.cardTitleActive]: isSelected,
      })}
    >
      {title}
    </p>
    <div>
      {data.map((x, i) => (
        <div key={i} className={styles.featureDiv}>
          <img src={isSelected ? checkWhiteImg : checkPrimaryImg} alt="Check" />
          <p
            className={styles.featureText}
            style={{ color: isSelected ? colors.white : colors.primary }}
          >
            {x}
          </p>
        </div>
      ))}
    </div>
    <button
      onClick={onBtnClick}
      className={styles.learnMoreBtn}
      style={{
        backgroundColor: isSelected ? colors.white : colors.primary,
        color: isSelected ? colors.primary : colors.white,
      }}
    >
      Learn More
    </button>
  </Card>
);

const plans = {
  one: "STARTER",
  two: "PREFERRED",
  three: "ELITE",
};
const plansData = {
  one: [
    "Basic ARKA AI-Suite",
    "Printed Text Extraction",
    "Basic Infrastrcuture",
    "Basic Data Analytics",
    "Single Document Variation",
    "Limited Business KPI’s Supported",
    "Minimum Document Volume Threshold",
    "Basic Monthly Document Processing Limits",
  ],
  two: [
    "Standard ARKA AI-Suite",
    "Standard Text Extraction",
    "Standard Infrastructure",
    "Standard Advanced Data Analytics",
    "Multiple Document Variation with Limits",
    "Business KPI’s Supported limited by Infrastructure",
    "Medium Document Volume Threshold",
    "Standard Monthly Document Processing Limits",
  ],
  three: [
    "Premium ARKA AI-Suite",
    "Premium Text Extraction",
    "Premium Infrastructure",
    "Full blown Advanced Data Analytics",
    "Document Variations as limited by Business",
    "Unlimited Business KPI’s",
    "High Document Volume Threshold",
    "Unlimited Monthly Document Processing Limits",
  ],
};
const BusinessPlan = () => {
  const history = useHistory();
  // const [isMonthly, setIsMonthly] = useState(true);
  const [selectedPlan, setSelectedPlan] = useState(plans.two);

  return (
    <div id="plans" className={styles.wrapper}>
      <Container>
        <Row>
          <Col>
            <div>
              <p className={styles.sectionTitle}>
                The Right Plan for Your Business
              </p>
              {/* <input
              type="checkbox"
              name="plan"
              id="plan"
              checked={isMonthly}
              className={styles.inputCheckbox}
            />
            <nav className={styles.nav}>
              <p className={styles.checkboxText}>Bill Monthly</p>
              <label
                htmlFor="plan"
                className={styles.checkbox}
                onClick={() => setIsMonthly(!isMonthly)}
              ></label>
              <p className={styles.checkboxText}>Bill Annually</p>
            </nav> */}
            </div>

            <Container>
              <Row className="mt-md-5 gx-5 d-none d-md-flex">
                <Col md={4}>
                  <PlanCard
                    title={plans.one}
                    isSelected={selectedPlan === plans.one}
                    onCardClick={() => setSelectedPlan(plans.one)}
                    onBtnClick={() => history.push("/request-demo")}
                    data={plansData.one}
                  />
                </Col>
                <Col md={4}>
                  <PlanCard
                    title={plans.two}
                    isSelected={selectedPlan === plans.two}
                    onCardClick={() => setSelectedPlan(plans.two)}
                    onBtnClick={() => history.push("/request-demo")}
                    data={plansData.two}
                  />
                </Col>
                <Col md={4}>
                  <PlanCard
                    title={plans.three}
                    isSelected={selectedPlan === plans.three}
                    onCardClick={() => setSelectedPlan(plans.three)}
                    onBtnClick={() => history.push("/request-demo")}
                    data={plansData.three}
                  />
                </Col>
              </Row>
              {/* MOBILE VIEW */}
              <Row className={styles.mobileView}>
                <Col>
                  <CollapsePlan title={plans.one} data={plansData.one} />
                  <CollapsePlan title={plans.two} data={plansData.two} />
                  <CollapsePlan title={plans.three} data={plansData.three} />
                </Col>
              </Row>
            </Container>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default BusinessPlan;
